import { hideDialog, clearDialog } from "./actions";
import { connect } from "react-redux";
import { StoreState } from "../../types/storeTypes";
import { ComponentType } from "react";

/**
 * Connects the dialog component to the store
 */

export type DialogProps = {
    show: boolean;
    params: unknown;
    onHide: () => void;
    onClear: () => void;
}

export function connectDialog(dialogId: string) {
    return (component: ComponentType<any>) => connect(
        (state: StoreState) => {
            const dialog = state.dialog.dialogs.get(dialogId);
            return {
                show: dialog ? dialog.get('show') : false,
                params: dialog ? dialog.get('params') : null
            };
        },
        (dispatch) => {
            return {
                onHide: () => dispatch(hideDialog(dialogId)),
                onClear: () => dispatch(clearDialog(dialogId))
            };
        }
    )(component);
}
