import React from 'react'
import { Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

type Props = {
    show: boolean
    message: string
    handleAlertClose: (show:boolean) => void;
}

export default function ErrorAlert(props:Props) {
    const { t } = useTranslation();
    if (props.show) {
      return (
        <Alert className="mt-2" variant="danger" onClose={() => props.handleAlertClose(false)} dismissible>
          <p>
            {t(""+props.message)}
          </p>
        </Alert>
      );
    }
    return null
}
