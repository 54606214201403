import { Record, List } from "immutable";

import { PurchaseOrderLineFormData, createCalculatePurchaseOrderLinePriceData } from "../../../types/procurementTypes";
import { calculatePurchaseOrderLinePrice } from "../../remote/api";

export type PriceCalculationResult = {
    total: number;
    calculatedLines: List<Record<PurchaseOrderLineFormData>>;
}

/**
 * Calculates the price totals to show on the grid and in the summary field.
 * It's an asynchronous calculation, because when the UOMs are different we can't calculate it client-side so we have to make an API call.
 */
export async function calculateTotals(companyCode: string, lines: List<Record<PurchaseOrderLineFormData>>): Promise<PriceCalculationResult> {
    let total = 0;
    const calculatedLines: Record<PurchaseOrderLineFormData>[] = [];

    for (let line of lines) {
        if (!line.get('toBeDeleted')) {
            const lineTotal = await calculateLineTotalPrice(companyCode, line);
            calculatedLines.push(line.set('totalPrice', lineTotal));
            total += lineTotal;
        }
    }

    return {
        total,
        calculatedLines: List(calculatedLines)
    };
}

/**
 * Calculates the total price for a purchase order line
 */
export function calculateLineTotalPrice(companyCode: string, line: Record<PurchaseOrderLineFormData>): Promise<number> {
    if (line.get('quantityUom') !== line.get('priceUom')) {
        return calculateTotalPriceWithDifferentUom(companyCode, line);
    } else {
        const totalPrice = calculateTotalPriceWithSameUom(line);
        return Promise.resolve(totalPrice);
    }
}

/**
 * Calculates the total price of the purchase order line when the uoms are different, taking
 * into account, the conversion rates
 */
function calculateTotalPriceWithDifferentUom(companyCode: string, data: Record<PurchaseOrderLineFormData>): Promise<number> {
    const calculatePriceData = createCalculatePurchaseOrderLinePriceData({
        productNo : data.get('productNo'),
        companyCode : companyCode,
        quantity : data.get('quantity'),
        quantityUom : data.get('quantityUom'),
        unitPrice : data.get('price'),
        unitPriceUom : data.get('priceUom')
    });
    return calculatePurchaseOrderLinePrice(calculatePriceData);
}

/**
 * Calculates the total price of the purchase order line when the uoms are equal
 */
function calculateTotalPriceWithSameUom(data: Record<PurchaseOrderLineFormData>): number {
    const quantity = data.get('quantity');
    const price = data.get('price');
    if (quantity != null && price != null) {
        return quantity * price;
    } else {
        return 0;
    }
}
