
import React from 'react';
import { GridReactive, Column } from "@pearlchain/component-lib-react-pg";
import { List, Record } from 'immutable';

import { PurchaseOrderLineFormData } from '../../../types/procurementTypes';
import { purchaseOrderLinesToRows } from '../helpers';
import { useTranslation } from 'react-i18next';

export interface ReceivePurchaseOrderLineGridProps {
    purchaseOrderLines: List<Record<PurchaseOrderLineFormData>> | undefined;
    purchaseOrderLineCurrency?: string;
    onValueChanged: (purchaseOrderLineUuid: string, columnIndex: number, value: string) => void;
}

export const purchaseOrderLineColumns: Column[] = [
    { _key: 'uniqueIdentifier', width: 0, title: '', idField: true },
    { _key: 'productNo', width: 150, title: 'retail.procurement.receive.grid.productNo', translateHeader: true },
    { _key: 'description', width: 150, title: 'retail.procurement.receive.grid.description', translateHeader: true },
    { _key: 'quantityWithUom', width: 150, title: 'retail.procurement.receive.grid.quantityWithUom', translateHeader: true },
    { _key: 'priceWithUom', width: 150, title: 'retail.procurement.receive.grid.unitPrice', translateHeader: true },
    { _key: 'totalPrice', width: 150, title: 'retail.procurement.receive.grid.totalPrice', translateHeader: true },
    { _key: 'registered', width: 0, title: '' },
    { _key: 'quantityDelivered', width: 150, title: 'retail.procurement.receive.grid.quantityDelivered', editable: true, translateHeader: true}
];

export default function ReceivePurchaseOrderLineGrid(props: ReceivePurchaseOrderLineGridProps) {
    const { t } = useTranslation();
    const [rows] = purchaseOrderLinesToRows(props.purchaseOrderLines, purchaseOrderLineColumns, props.purchaseOrderLineCurrency, t);

    return (
        <GridReactive
        gridId="receive-purchase-order-line-grid"
        classNameOuter="wrap-grid"
        data={rows}
        columns={purchaseOrderLineColumns}
        onValueChanged={props.onValueChanged as (purchaseOrderLineUuid: string, columnIndex: number, value: unknown) => void}
        extensions={{
            editing: {
                isEditable: (record: Array<any>, column: any) => isLineRegistered(record, column),
            },
            // necessary for showing the checkboxes
            directinput: true,
            columnsizing: true
        }}/>
    )
}

const isLineRegistered = (record: Array<any>, column: any) => {
    // this is a dirty hack (and will break if we add extra columns)
    return !record[6];
}
