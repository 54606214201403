import inventoryOverviewPage from "./overview/InventoryOverview";
import stockMovementsOverviewPage from "./movements/StockMovementsOverviewPage";
import stockConversionPage from "./conversion/StockConversionPage"
import stockCreationPage from "./creation/StockCreationPage"
import outgoingStockOrdersOverviewPage from "./orders/overview/OutgoingOrdersOverviewPage"
import detailedOutgoingOrderPage from "./orders/detailed/DetailedOutgoingOrderPage"
import updateOrderLinePage from "./orders/line/UpdateOrderLinePage"
import { Auth } from "../auth/functionalityCodes";

export const updateOrderLine = {
    title: 'update.order.line.title',
    path: '/orders/line',
    component: updateOrderLinePage,
    functionalityCode: Auth.INVENTORY_UPDATE_ORDER_LINE,
    hide: true
}

export const detailedOutgoingOrder = {
    title: 'detailed.order.title',
    titleDetailed: 'Detailed Outgoing Stock Order',
    path: '/orders/detailed',
    component: detailedOutgoingOrderPage,
    functionalityCode: Auth.INVENTORY_VIEW_ORDER,
    hide: true
};

export const outgoingStockOrdersOverview = {
    title: 'orders.overview.title',
    path: '/order/overview',
    component: outgoingStockOrdersOverviewPage,
    functionalityCode: Auth.INVENTORY_VIEW_ORDERS,
    hide: false
};

export const stockCreation = {
    title: 'inventory.stock.creation.title',
    path: '/creation',
    component: stockCreationPage,
    functionalityCode: Auth.INVENTORY_NEW_STOCK_ENTRY,
    hide: true
};

export const stockConversion = {
    title: 'inventory.conversion.title',
    path: '/conversion',
    component: stockConversionPage,
    functionalityCode: Auth.INVENTORY_STOCK_CONVERSION,
    hide: true
};

export const stockMovementsOverview = {
    title: 'stock-movements.overview.title',
    titleView: 'stock-movements.overview.title',
    path: '/inventory/movements',
    component: stockMovementsOverviewPage,
    functionalityCode: Auth.INVENTORY_VIEW_PRODUCT_FLOW,
    hide: true
};

export const inventoryOverview = {
    title: 'inventory.overview.title',
    path: '/inventory',
    component: inventoryOverviewPage,
    functionalityCode: Auth.INVENTORY_SEARCH_VIEW,
    hide: false
};
