import { List, Record } from 'immutable';
import { PurchaseOrderFormData, PurchaseOrderLineFormData, SupplierIdentifier } from '../../types/procurementTypes';
import { Column, Row } from '@pearlchain/component-lib-react-pg';
import { Props as PurchaseOrderProps } from './creation/NewPurchaseOrder';
import { ReceivePurchaseOrderProps } from './receive/ReceivePurchaseOrder';
import { formatPriceWithUom, formatQuantityWithUom } from '../../powergrid/formatters';


function resolveRowId(data: Record<PurchaseOrderLineFormData>, index: number) {
    const uuid = data.get('uniqueIdentifier')
    return uuid ? uuid : '' + index;
};

export function isPurchaseOrderLineUnsaved(item: Record<PurchaseOrderLineFormData>) {
    return item.get('id') == null;
}

export function hasReceiptMasterLines(purchaseOrder:PurchaseOrderFormData | undefined) {
    const KEY_SUPPLIER = "COF001";
    const KEY_PO_STATUS = "PurchaseOrderStatus.Ordered";
    if(purchaseOrder){
        // @ts-ignore
        const supplier = purchaseOrder.supplier as SupplierIdentifier;
        const status = purchaseOrder.status;
        // @ts-ignore
        const lines = purchaseOrder.lines as PurchaseOrderLineFormData[];

        if(supplier.no !== KEY_SUPPLIER) {
            if(status === KEY_PO_STATUS) {
                return false;
            }
        }

        //Scenario 1 Supplier COF001 ,no receipt master line, purchaseorder status ordered = not possible to save
        if(supplier.no === KEY_SUPPLIER){
            if(status === KEY_PO_STATUS){
                for (const line of lines){
                    // @ts-ignore
                    if((line.receiptMasterLines as []).length === 0){
                        return true;
                    }
                }
            } 
        }

        return false      
    }
}

export function sortPurchaseOrderLines(lines: List<Record<PurchaseOrderLineFormData>>) {
    return lines.sort((a, b) => {
        const unsavedA = isPurchaseOrderLineUnsaved(a);
        const unsavedB = isPurchaseOrderLineUnsaved(b);

        if (unsavedA !== unsavedB) {
            // unsaved lines come before saved lines
            return unsavedA ? -1 : 1;

        } else {
            // keep the original order
            return 0;
        }
    })
}

export function purchaseOrderLinesToRows(
        data: List<Record<PurchaseOrderLineFormData>> | undefined,
        columns: Column[],
        purchaseOrderLineCurrency: string | undefined,
        t: (value: any) => any
): [Row[], Map<string, Record<PurchaseOrderLineFormData>>] {

    const rows: Row[] = [];

    // lookup source item by id
    const lookupItemByRowId = new Map<string, Record<PurchaseOrderLineFormData>>();

    if (data) {
        let i = 0;
        for (let item of data) {
            // only show records that are not marked as to be deleted
            if (!item.get('toBeDeleted')) {
                const quantity = item.get('quantity');
                const quantityUom = item.get('quantityUom');
                const price = item.get('price');
                const priceUom = item.get('priceUom');
                const receiptMasterLines = item.get('receiptMasterLines');
                const quantityDelivered = item.get('quantityDelivered');

                const row: Row = columns.map((column) => {
                    if (column._key === 'quantityWithUom') {
                        return quantity ? formatQuantityWithUom(t, quantity, quantityUom) : '';
                    } else if (column._key === 'priceWithUom') {
                        return price != null && purchaseOrderLineCurrency ? formatPriceWithUom(t, price, purchaseOrderLineCurrency, priceUom) : '';
                    } else if (column._key === 'totalPrice') {
                        return price != null && quantity != null && purchaseOrderLineCurrency ?
                            formatQuantityWithUom(t, price * quantity, purchaseOrderLineCurrency) : '';
                    } else if (column._key === 'registered') {
                        return receiptMasterLines.filter(line => line.get("registered") == true).size > 0;
                        //as long as one master line is registered the purchase line is registered.
                        //Not partial delivery/back order functions are currently supported
                    } else if (column._key === 'quantityDelivered') {
                        return quantityDelivered ? +quantityDelivered.toFixed(3) : 0;
                    } else {
                        return item.get(column._key as keyof PurchaseOrderLineFormData);
                    }
                }) as any;

                const id = resolveRowId(item, i);

                row.id = id;
                row.index = i;
                rows.push(row);
                lookupItemByRowId.set(id, item);
            }
            i++;
        }
    }

    return [rows, lookupItemByRowId];
}

export const determinePurchaseOrderLineCurrency = (props: PurchaseOrderProps | ReceivePurchaseOrderProps) : string => {
    if (props.purchaseOrder) {
        return props.purchaseOrder.get('currency');
    } else {
        return props.initialValues.currency;
    }
};
