import { requiredValidator } from "../../util/validators";
import { isImmutable } from "immutable";
import ProductBinder from "../../common/binders/ProductBinder";
import TextBinder from "../../common/binders/TextBinder";
import { getRealmNo } from "@pearlchain/component-lib-common";
import NumberBinder from "../binders/NumberBinder";
import { formFieldConfig, FormFieldConfig } from "../../../form/types";
import CheckboxBinder from "../../common/binders/CheckboxBinder";

export function stockConversionFormFieldsCreator(t: (input: string) => string,quantityDisable:boolean) {
    const companyCode = getRealmNo();
    
    const formFields: { [key: string]: FormFieldConfig } = {
        fromProductNo: formFieldConfig({
            label: 'retail.inventory.conversion.fromProductNo',
            translateLabel: true,
            binder: ProductBinder,
            validate: productValidator(t),
            companyCode
        }),
        toProductNo: formFieldConfig({
            label: 'retail.inventory.conversion.toProductNo',
            translateLabel: true,
            binder: ProductBinder,
            validate: productValidator(t),
            companyCode
        }),
        fromProductDescription: formFieldConfig({ label: 'retail.inventory.conversion.productDescription', translateLabel: true,  binder: TextBinder, disabled: true, validate: requiredValidator() }),
        toProductDescription: formFieldConfig({ label: 'retail.inventory.conversion.productDescription', translateLabel: true, binder: TextBinder, disabled: true, validate: requiredValidator() }),
        fromQuantity: formFieldConfig({
            label: 'retail.inventory.conversion.productQuantity',
            translateLabel: true,
            binder: NumberBinder,
            validate: fromQuantityValidator(t)
        }),
        toQuantity: formFieldConfig({ label: 'retail.inventory.conversion.productQuantity', translateLabel: true, binder: NumberBinder, validate: requiredValidator(), disabled: quantityDisable}),
        fromUom: formFieldConfig({ label: 'retail.inventory.conversion.productUom', translateLabel: true, binder: TextBinder, disabled: true, formatter: t, validate: requiredValidator() }),
        toUom: formFieldConfig({ label: 'retail.inventory.conversion.productUom', translateLabel: true, binder: TextBinder, disabled: true, formatter: t, validate: requiredValidator() }),
        roasting: formFieldConfig({ label: 'retail.inventory.conversion.roasting', translateLabel: true, binder: CheckboxBinder})
    };
    return formFields;
}

function fromQuantityValidator(t: (input: string) => string) {
    return (input: any) => {
        if (!input || input.length === 0 || (isImmutable(input) && input.isEmpty()))
            return t('retail.inventory.conversion.required');
    }
}

function productValidator(t: (input: string) => string) {
    return (input: any) => {
        if (!input || input.length === 0)
            return t('retail.inventory.conversion.required');
    }
}
