import { Action } from "redux";
import { Record } from "immutable";
import {
    ActionType,
    SetPurchaseOrderLineResultIndexAction,
    StorePurchaseOrderLineAction
} from './purchaseOrderLineActions';
import { StoreStateProcurement, createPurchaseOrderLineForm } from "../../../types/procurementTypes";
import { v4 as uuid } from 'uuid';

export default function(state: Record<StoreStateProcurement>, action: Action): Record<StoreStateProcurement> {
    switch(action.type) {
        case ActionType.SET_PURCHASE_ORDER_LINE_RESULT_INDEX:
            return setPurchaseOrderLineResultIndex(state, action as SetPurchaseOrderLineResultIndexAction);
        case ActionType.STORE_PURCHASE_ORDER_LINE:
            return storePurchaseOrderLine(state, action as StorePurchaseOrderLineAction);
    }
    return state;
}

function setPurchaseOrderLineResultIndex(state: Record<StoreStateProcurement>, action: SetPurchaseOrderLineResultIndexAction) {
    return state.set('purchaseOrderLineIndex', action.resultIndex);
}

function storePurchaseOrderLine(state: Record<StoreStateProcurement>, action: StorePurchaseOrderLineAction) {
    const index = state.get('purchaseOrderLineIndex');
    return state
        .delete('purchaseOrderLineIndex')
        .update('purchaseOrderLines', (lines) => {
            if (index == null || index < 0) {
                const newOrderLine = createPurchaseOrderLineForm(action.data)
                    // save "price" as purchasePrice, so it shows up on the purchase-order screen
                    .set('price', action.data.price!)
                    .set('uniqueIdentifier', uuid());
                return lines.push(newOrderLine);
            } else {
                const line = lines.get(index);
                if (!line) return lines;
                return lines.set(index, line.merge(action.data));
            }
        });
}
