import { Column } from "@pearlchain/component-lib-react-pg";
import { WebSortType, WebSortFields } from "./requestTypes";

/**
 * Converts grid sort-columns to names of the properties to order-by
 */
export function gridColumnsToSortFields(sortColumns: any[], columns: Column[], mapping?: Map<string, string>): WebSortFields {
    const result: WebSortFields = {};
    for (let sortColumn of sortColumns) {
        const column = columns[sortColumn.key];
        if (column && (mapping == null || mapping.has(column._key))) {
            let name: string = column._key;
            if (mapping != null && mapping.has(name)) {
                name = mapping.get(name)!;
            }

            const orderType = sortColumn.direction === 'descending' ? WebSortType.DESC : WebSortType.ASC;     
            result[name] = orderType;
        }
    }
    return result;
}
