import React, { useMemo } from 'react';
import { CompanyBinder } from '@pearlchain/stackbase-common';
import { formFieldConfig, FormFieldConfig } from '../../../form/types';
import SearchBinderModal from './SearchBinderModal';
import { validateCompanyBinder } from './ValidateCompanyBinder';
import { requiredValidator } from '../../util/validators';
import TextBinder from './TextBinder';
import CheckboxBinder from './CheckboxBinder';
import SelectBinder from './SelectBinder';
import { Column, Row, dataToRows } from '@pearlchain/component-lib-react-pg';
import { RequestBuilder } from '@pearlchain/component-lib-common';
import { useSelector } from 'react-redux';
import { getAllCompanies } from '../../procurement/selectors';
import { Company } from '@pearlchain/stackbase-common/lib/components/CompanyBinder';

/**
 * Modal dialog for the product-binder. Provides a more advanced, but less user-friendly way of 
 * querying products than auto-completion.
 */

type Props = {
    extraSelector?: { [key: string]: unknown }
    companyCode: string;
    productNo: string;
    show: boolean;
    excludeGifts?: boolean;
    onComplete: (productNo: string) => void;
    onHide: () => void;
    onExit?: () => void;
}

const URL_FIND_PRODUCTS = 'sec/api/product/find';

const FIELD_PRODUCT_NO = 'productNo';
const FIELD_COMPANY_CODE = 'companyCode';
const FIELD_EXCLUDE_GIFTS = 'excludeGifts';

const createFormFields = (companies: Company[] | undefined): { [key: string]: FormFieldConfig } => ({
    [FIELD_COMPANY_CODE]: formFieldConfig({ label: 'common.selector.company', validate: validateCompanyBinder(companies), binder: CompanyBinder }),
    [FIELD_PRODUCT_NO]: formFieldConfig({ label: 'common.binder.product.selector.no', binder: TextBinder, focus: true }),
   // [FIELD_EXCLUDE_GIFTS]: formFieldConfig({ label: 'common.binder.product.selector.excludeGifts', binder: CheckboxBinder }),
    remark: formFieldConfig({ label: 'common.binder.product.selector.remark', binder: TextBinder }),
    isActive: formFieldConfig({ label: 'common.binder.product.selector.isActive', binder: CheckboxBinder }),
    description: formFieldConfig({ label: 'common.binder.product.selector.description', binder: TextBinder }),
    limit: formFieldConfig({ label: 'common.binder.selector.limit', binder: SelectBinder, translateOptions: false,
        value: { label: '100', value: 100 },
        options: [
            { label: '100', value: 100 },
            { label: '200', value: 200 },
            { label: '300', value: 300 },
            { label: '400', value: 400 }
        ]
    })  
});

const columns: Column[] = [
    { _key: 'no', width: 150, title: 'common.binder.product.grid.no' },
    { _key: 'source', width: 150, title: 'common.binder.product.grid.source', translateValue: true },
    { _key: 'description', width: 150, title: 'common.binder.product.grid.description' },
    { _key: 'stockUom', width: 150, title: 'common.binder.product.grid.stockUom', translateValue: true },
    { _key: 'productGroup', width: 150, title: 'common.binder.product.grid.productGroup', translateValue: true },
    { _key: 'status', width: 150, title: 'common.binder.product.grid.status', translateValue: true },
    { _key: 'uom2', width: 150, title: 'common.binder.product.grid.uom2', translateValue: true },
    { _key: 'uom3', width: 150, title: 'common.binder.product.grid.uom3', translateValue: true },
    { _key: 'uom4', width: 150, title: 'common.binder.product.grid.uom4', translateValue: true },
    { _key: 'warehouse', width: 150, title: 'common.binder.product.grid.warehouse', translateValue: true },
    { _key: 'altDescription', width: 150, title: 'common.binder.product.grid.altDescription' },
    { _key: 'remark', width: 150, title: 'common.binder.product.grid.remark' }
]

function getProductNo(product: any): string {
    return product.no;
}

function onSearch(values: any): Promise<Row[]> {
    return new RequestBuilder()
        .post(URL_FIND_PRODUCTS)
        .setBodyJson(values)
        .toJS<any[]>()
        .then((data) => dataToRows(data, getProductNo, columns));
}

export default function ProductBinderModal(props: Props) {
    const { extraSelector, productNo, companyCode, excludeGifts,
            onComplete, show, onHide, onExit } = props;

    const initialValues = useMemo(() => ({
        [FIELD_PRODUCT_NO]: productNo,
        [FIELD_COMPANY_CODE]: companyCode,
        [FIELD_EXCLUDE_GIFTS]: excludeGifts
    }), [productNo, companyCode]);

    const companies = useSelector(getAllCompanies);

    return (
        <SearchBinderModal
            formId="product-binder"
            title="common.binder.product"
            extraSelector={extraSelector}
            columns={columns}
            initialValues={initialValues}
            onComplete={onComplete}
            onSearch={onSearch}
            show={show}
            onHide={onHide}
            onExit={onExit}
            formFields={createFormFields(companies)}
        />
    );
}
