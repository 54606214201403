import {all, takeEvery, put, select} from "redux-saga/effects";
import {callRequestHandler, pushLocation, replaceLocation} from "@pearlchain/component-lib-common";

import {
    ActionType,
    NewPurchaseOrderLineAction,
    ViewPurchaseOrderLineAction,
    UpdatePurchaseOrderLineAction,
    setPurchaseOrderLineResultIndex,
} from './purchaseOrderLineActions';
import {
    PARAM_MODE,
    PARAM_SUPPLIER_UUID,
    PARAM_PURCHASE_ORDER_LINE_UUID,
    PARAM_COMPANY_CODE,
    PARAM_LINE_MODE
} from "./purchaseOrderLineParams";
import {
    createPurchaseOrderLineForm, DeletePurchaseOrderLineAction, PurchaseOrderLineFormData, PurchaseOrderLineMode,
    PurchaseOrderMode,
    SavePurchaseOrderLineAction
} from "../../../types/procurementTypes";
import { newPurchaseOrderLine, updatePurchaseOrderLine } from '../procurementPage';
import { reset } from "redux-form";
import { NEW_PURCHASE_ORDER_LINE_FORM_ID } from "./PurchaseOrderLine";
import {runActionButton} from "../../util/sagaUtils";
import {RequestId} from "../../remote/requests";
import {error, success} from "react-notification-system-redux";
import {storePurchaseOrderLines} from "../actions";
import i18next from "i18next";
import {getPurchaseOrderLines} from "../selectors";
import {Record} from "immutable";

export default function() {
    return all([
        takeEvery(ActionType.NEW_PURCHASE_ORDER_LINE, handleNewPurchaseOrderLine),
        takeEvery(ActionType.VIEW_PURCHASE_ORDER_LINE, handleViewPurchaseOrderLine),
        takeEvery(ActionType.UPDATE_PURCHASE_ORDER_LINE, handleUpdatePurchaseOrderLine),
        takeEvery(ActionType.SAVE_PURCHASE_ORDER_LINE, handleSavePurchaseOrderLine),
        takeEvery(ActionType.DELETE_PURCHASE_ORDER_LINE, handleDeletePurchaseOrderLine)
    ])
}

function *handleNewPurchaseOrderLine(action: NewPurchaseOrderLineAction) {
    yield put(setPurchaseOrderLineResultIndex(-1));
    const pageParams = {
        [PARAM_MODE]: PurchaseOrderMode.NEW,
        [PARAM_LINE_MODE]: PurchaseOrderLineMode.NEW,
        [PARAM_COMPANY_CODE]: action.companyCode,
        [PARAM_SUPPLIER_UUID]: action.supplierUUID
    };
    
    if (action.replaceLocation) {
        yield put(reset(NEW_PURCHASE_ORDER_LINE_FORM_ID));
        yield put(replaceLocation(newPurchaseOrderLine.path, newPurchaseOrderLine.title, pageParams));
    } else {
        yield put(pushLocation(newPurchaseOrderLine.path, newPurchaseOrderLine.title, pageParams));
    }
}

function *handleViewPurchaseOrderLine(action: ViewPurchaseOrderLineAction) {
    yield put(setPurchaseOrderLineResultIndex(action.resultIndex));
    const pageParams = {
        [PARAM_MODE]: PurchaseOrderMode.VIEW,
        [PARAM_LINE_MODE]: PurchaseOrderLineMode.VIEW,
        [PARAM_COMPANY_CODE]: action.companyCode,
        [PARAM_SUPPLIER_UUID]: action.supplierUUID,
        [PARAM_PURCHASE_ORDER_LINE_UUID]: action.purchaseOrderLineUUID
    };
    yield put(pushLocation(updatePurchaseOrderLine.path, updatePurchaseOrderLine.title, pageParams));
}

function *handleUpdatePurchaseOrderLine(action: UpdatePurchaseOrderLineAction) {
    yield put(setPurchaseOrderLineResultIndex(action.resultIndex));
    const pageParams = {
        [PARAM_MODE]: PurchaseOrderMode.UPDATE,
        [PARAM_LINE_MODE]: PurchaseOrderLineMode.UPDATE,
        [PARAM_COMPANY_CODE]: action.companyCode,
        [PARAM_SUPPLIER_UUID]: action.supplierUUID,
        [PARAM_PURCHASE_ORDER_LINE_UUID]: action.purchaseOrderLineUUID
    };
    yield put(pushLocation(updatePurchaseOrderLine.path, updatePurchaseOrderLine.title, pageParams));
}

function *handleSavePurchaseOrderLine(action: SavePurchaseOrderLineAction) {
    yield* runActionButton(ActionType.SAVE_PURCHASE_ORDER_LINE, function *() {
        const [result, err] = yield* callRequestHandler(
            RequestId.SAVE_PURCHASE_ORDER_LINE,
            createPurchaseOrderLineForm(action.data)
        );
        if (err || !result) {
            yield put(error({
                autoDismiss: 10,
                title: i18next.t('retail.procurement.orderLine.newPurchaseOrderLineError'),
                message: i18next.t('retail.procurement.orderLine.newPurchaseOrderLineError.message')
            }));
            throw err;
        } else {
            yield put(success({title: "Purchase order line has been saved successfully"}));

            //load and store purchase order lines
            let uniqueIdentifier : string = result.get('purchaseOrderUuid');
            const [purchaseOrder, err] = yield* callRequestHandler(RequestId.GET_PURCHASE_ORDER, { uniqueIdentifier });
            if (err) {
                throw err;
            }
            yield put(storePurchaseOrderLines(purchaseOrder.get('lines')));
        }
    });
}

function* handleDeletePurchaseOrderLine(action: DeletePurchaseOrderLineAction) {
    yield* runActionButton(ActionType.DELETE_PURCHASE_ORDER_LINE, function *() {
        const [result, err] = yield* callRequestHandler(
            RequestId.DELETE_PURCHASE_ORDER_LINE,
            action.purchaseOrderLineUuid
        );
        //update purchase order lines in store
        if (result) {
            try {
                // first fetch all the lines from the store
                const purchaseOrderLines = yield select(getPurchaseOrderLines);
                // then search for the record with the matching uniqueIdentifier/index
                const found = purchaseOrderLines.findEntry((value: Record<PurchaseOrderLineFormData>, index: number) => {
                    return (value.get('uniqueIdentifier') === action.purchaseOrderLineUuid) || (index === Number(action.purchaseOrderLineUuid));
                });

                const index = found[0]
                const purchaseOrderLine = found[1]

                if (purchaseOrderLine.get('id')) {
                    const toBeDeleted = purchaseOrderLine.set('toBeDeleted', true);
                    const updatedLines = purchaseOrderLines.set(index, toBeDeleted);
                    yield put(storePurchaseOrderLines(updatedLines));
                    yield put(success({ title: "Purchase order line has been deleted successfully." }));
                } else if (index != -1) {
                    const updatedLines = purchaseOrderLines.delete(index);
                    yield put(storePurchaseOrderLines(updatedLines));
                    yield put(success({ title: "Purchase order line has been deleted successfully." }));
                } else {
                    console.error("Couldn't find record with id: " + action.purchaseOrderLineUuid);
                }
            } catch (error) {
                console.error(error);
            }
        }
        else {
            throw err;
        }
    });
}
