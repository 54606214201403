import React, { useEffect, useState } from 'react'
import { Column } from '@pearlchain/component-lib-react-pg'
import { List, Record } from 'immutable';
import { Button } from 'react-bootstrap'
import { Option } from '../types/inventoryTypes';
import { ViewStockMovementsAction } from '../components/inventory/actions';
import { WebSortFields, WebSortType } from '../components/remote/requestTypes';

export function getStockEntryColumn(
        columns: Column[], 
        reasons: List<Record<Option>>, 
        correctionActions: List<Record<Option>>, 
        t: (value: string) => string, 
        viewStockMovements: (productNo: string, productDescription: string) => ViewStockMovementsAction,
        onValueChanged: (id: string, key: string, value: unknown, multiSelectMode?: boolean) => void,
        multiSelectMode: boolean,
        inputsRefs: any,
        changeFocus: (index:number) => void){
    return columns.map((column) => {
        if(column._key === "actions"){
            return { Header: column.title, accessor: column._key, width: column.width, Cell: (value: any) => {
                //value.row.original contains all the info of the row
                return (
                    <div className="cell-container">
                        <Button 
                            className="btn btn-secondary"
                            onClick={() => viewStockMovements(value.row.original.productNo, value.row.original.productDescription)}
                        >
                            <i className="fa fa-exchange-alt"></i>
                        </Button>
                    </div>
                )
            }}
        }
        if(column._key === "actionType"){
            return { Header: column.title, accessor: column._key, width: column.width, Cell: (value: any) => {
                //value.row.original contains all the info of the row
                const cellValue = value.row.original.actionType || ""
                const uuid = value.row.original.uniqueIdentifier

                return (
                    <div className="cell-container">
                        <select className="w-100" id={"actions"+uuid} name={"actions"+uuid} value={cellValue} onChange={(e) => onValueChanged(uuid, column._key, e.target.value, multiSelectMode)}>
                            <option key="empty" value="">---</option>
                            {correctionActions.map((correctionAction: Record<Option>) => {
                                return(
                                    <option key={correctionAction.get('value')} value={correctionAction.get('value')}>{t(correctionAction.get('label'))}</option>
                                )
                            })}
                        </select>
                    </div>
                )
            }}
        }
        if(column._key === "reason"){
            return { Header: column.title, accessor: column._key, width: column.width, Cell: (value: any) => {
                //value.row.original contains all the info of the row
                const cellValue = value.row.original.reason || ""
                const uuid = value.row.original.uniqueIdentifier
                var sorted = reasons.sortBy(
                    (f) => t(f.get('label')).toLowerCase()
                );
                
                return (
                    <div className="cell-container">
                        <select className="w-100" id={"reasons"+uuid} value={cellValue} name={"reasons"+uuid} onChange={(e) => onValueChanged(uuid, column._key, e.target.value, multiSelectMode)}>
                            <option key="empty" value="">---</option>
                            {sorted.map((reason: Record<Option>) => {
                                return(
                                    <option key={reason.get('value')} value={reason.get('value')}>{t(reason.get('label'))}</option>
                                )
                            })}
                        </select>
                    </div>
                )
            }}
        }
        if(column._key === "newQuantity"){
            return { Header: column.title, accessor: column._key, width: column.width, Cell: (value: any) => {
                const [cellValue, setValue] = useState(value.value)

                useEffect(() => {
                    if(cellValue !== ""){
                        if(!value.value && value.value !== 0){
                            setValue("")
                        }
                    }
                }, [value.value])


                //value.row.original contains all the info of the row
                const uuid = value.row.original.uniqueIdentifier
                return (
                    <div className="cell-container">
                        <input
                            className="w-100 border-0"
                            autoComplete="off" 
                            type="number" 
                            id={"newQuantity"+uuid} 
                            name="quantity"
                            value={cellValue}
                            ref={(element) => inputsRefs.current[value.row.id] = element}
                            onChange={e => setValue(e.target.value)}
                            onBlur={(e) => onValueChanged(uuid, column._key,e.target.value)}
                            onKeyPress={event => {
                                if (event.key === "Enter") {
                                  changeFocus(value.row.id);
                                }
                            }}
                        />
                    </div>
                )
            }}
        }
        if(column._key === "numLabels"){
            return { Header: column.title, accessor: column._key, width: column.width, Cell: (value: any) => {
                const [cellValue, setValue] = useState(value.value || "")

                useEffect(() => {
                    if(cellValue !== ""){
                        if(!value.value && value.value !== 0){
                            setValue("")
                        }
                    }
                }, [value.value])


                //value.row.original contains all the info of the row
                const uuid = value.row.original.uniqueIdentifier
                return (
                    <div className="cell-container">
                        <input
                            className="w-100 border-0"
                            autoComplete="off" 
                            type="number" 
                            id={"numLabels"+uuid} 
                            name="numLabels"
                            value={cellValue}
                            ref={(element) => inputsRefs.current[value.row.id] = element}
                            onChange={e => setValue(e.target.value)}
                            onBlur={(e) => onValueChanged(uuid, column._key,e.target.value)}
                            onKeyPress={event => {
                                if (event.key === "Enter") {
                                  changeFocus(value.row.id);
                                }
                            }}
                        />
                    </div>
                )
            }}
        }
        if(column._key === "quantity"){
            return { Header: column.title, accessor: column._key, width: column.width, Cell: (value: any) => {
                const cellValue = (value.value).toFixed(3).replace(".000", "");
                return (
                    <div className="cell-container">
                        <span className="cell-over">{cellValue}</span>
                    </div>
                )
            }}
        }
        if(column._key === "productSalesPriceIncludingVat"){
            return { Header: column.title, accessor: column._key, width: column.width, Cell: (value: any) => {
                const cellValue = value.value ? value.value + " " + value.row.original.productPriceCurrency : "0 "+value.row.original.productPriceCurrency
                return (
                    <div className="cell-container">
                        <span className="cell-over">{cellValue}</span>
                    </div>
                )
            }}
        }
        if(column._key === "remark"){
            return { Header: column.title, accessor: column._key, width: column.width, Cell: (value: any) => {
                const [cellValue, setValue] = useState(value.value || "")

                //value.row.original contains all the info of the row
                const uuid = value.row.original.uniqueIdentifier
                return (
                    <div className="cell-container">
                        <input
                            className="border-0"
                            autoComplete="off" 
                            type="text" 
                            id={"info"+uuid} 
                            name="remark"
                            value={cellValue}
                            onChange={e => setValue(e.target.value)}
                            onBlur={(e) => onValueChanged(uuid, column._key,e.target.value)}
                        />
                    </div>
                )
            }}
        }
        if(column._key === "productGroup"){
            return { Header: column.title, accessor: column._key, width: column.width, Cell: (value: any) => {
                return (
                    <div className="cell-container">
                        <span className="cell-over">{t(value.value)}</span>
                    </div>
                )
            }}
        }
        if(column._key === "uom"){
            return { Header: column.title, accessor: column._key, width: column.width, Cell: (value: any) => {
                return (
                    <div className="cell-container">
                        <span className="cell-over">{t(value.value)}</span>
                    </div>
                )
            }}
        }
        return { Header: column.title, accessor: column._key, width: column.width,  Cell: (value: any) => {
            //value.row.original contains all the info of the row
            return (
                <div className="cell-container">
                    <span className="cell-over">{value.value}</span>
                </div>
            )
        } }
    })
}

/**
 * The objective of this function is to convert the sortBy object that we get from react-table to our needed column type for our sort function
 * @param {id: string, desc: boolean} columns Columns in PG type
 */
export function getSortedColumns(sortBy: any): WebSortFields {
    let result: WebSortFields = {};
    if(STOCK_SORT_COLUMN_FIELDS.includes(sortBy.id)){
        const key = sortBy.id;
        const direction = sortBy.desc ? WebSortType.DESC  : WebSortType.ASC 
        result[key] = direction;
        return result;
    }
    return result
}

export const STOCK_SORT_COLUMN_FIELDS = ['uniqueIdentifier','company', 'productNo','productDescription', 'productGroup','quantity','remark'];
