import React from "react";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { isAnyActionRunning } from "./actionButtonSelectors";

export type ActionButtonProps = React.HTMLProps<HTMLButtonElement> & {
    variant?: string;
    children: React.ReactChild;
    onAction: (e: React.MouseEvent) => void;
}

/**
 * Blocks the user from proceeding until all ongoing actions are completed.
 * 
 * Use this component instead of AsyncActionButton for synchronous actions that do not need to track a "loading" state.
 */

export default function ActionButton({ children, variant, onAction, ...buttonProps }: ActionButtonProps) {
    const anyRunning = useSelector(isAnyActionRunning);
    const disabled = buttonProps.disabled || anyRunning;

    let className = "prl-action-btn";
    if (buttonProps.className) {
        className += ' ' + buttonProps.className;
    }

    return (
        <Button
            {...buttonProps as any}
            type="button"
            className={className}
            disabled={disabled}
            variant={variant as any}
            onClick={onAction}
        >
            {children}
        </Button>
    );
}
