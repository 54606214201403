import { createStore, compose, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { Store } from 'redux';
import { createBrowserHistory } from 'history';
import Immutable from 'immutable';
import { createLocationMiddleware, setHistory } from '@pearlchain/component-lib-common';

import reducers from './reducers';
import sagas from './sagas';
import initialState from './initialState';
import {ActionTypes} from "../components/userInfo/actions";

const sagaMiddleware = createSagaMiddleware();

export const history = createBrowserHistory({ basename: location.pathname + '#' });
setHistory(history);
const locationMiddleware = createLocationMiddleware(history);

const store = createStore(
  reducers as any,
  initialState,
  compose(
    applyMiddleware(sagaMiddleware),
    applyMiddleware(locationMiddleware),
    (window as any).__REDUX_DEVTOOLS_EXTENSION__
      ? (window as any).__REDUX_DEVTOOLS_EXTENSION__({
        serialize: {
          // Provide a formatter for immutable parts of the store.
          // This makes it much easier to see which objects are immutable, and which are not.
          immutable: Immutable
        }
      })
      : (x: any) => x
  )
) as Store;

store.dispatch({ type: ActionTypes.LOAD_USER_INFO_FROM_LOCAL_STORAGE });
sagaMiddleware.run(sagas());

if (module.hot) {
  module.hot.accept('./reducers.ts', () => {
    const nextRootReducer = require('./reducers.ts');
    store.replaceReducer(nextRootReducer.default);
  });
}

export default store;
