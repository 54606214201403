import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router, Route, Switch } from 'react-router';
import '@pearlchain/powergrid';

import store, { history } from './store/store';
import App from './App';
import Home from './components/home/Home';
import LoginPage from './components/login/LoginPage';
import { pages } from './components/page/pages';

import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead-bs4.css';

require('@fortawesome/fontawesome-free/css/all.css');
require('./styles/main.scss');
require('@pearlchain/component-lib-ui/src/styles/_pearlchain-web.scss');

function createPageRoutes() {
    return pages.map((page, index) => {
        return page.component ?
            <Route
                key={index}
                exact
                path={page.path}
                component={page.component}/>
            : undefined;
    })
}

ReactDOM.render(
    <Provider store={store}>
        <Router history={history}>
            <Switch>
                <Route path="/login" component={LoginPage}/>
                <Route path="/">
                    <App history={history}>
                        <Switch>        
                            <Route exact path="/" component={Home}/>
                            { createPageRoutes() }
                        </Switch>
                    </App>
                </Route>
            </Switch>
        </Router>
    </Provider>, 
    document.getElementById('root')
);
