import React, {Fragment, useEffect, useMemo, useCallback, useState} from 'react';
import {ButtonToolbar, Form} from 'react-bootstrap';
import {InjectedFormProps, reduxForm} from 'redux-form';
import { Record, List } from 'immutable';
import {
    FormFieldsLayout,
    TextBinder
} from '@pearlchain/component-lib-ui';
import { CompanyBinder } from '@pearlchain/stackbase-common';

import {RequestId} from '../remote/requests';
import {StoreState} from '../../types/storeTypes';
import ActionTypes, {listProducts, newProduct, updateProduct, viewProduct} from './actions';
import {ActionButtonEvent} from '../../types/gridTypes';
import {
    NewProductAction,
    ProductFilterFormData,
    UpdateProductAction,
    ViewProductAction,
    ProductGridData
} from "../../types/productTypes";
import ProductGrid from "./ProductGrid";
import {getProductsFromCommonStore, getVatInfo} from "./selectors";
import {fetchOptions} from "../combobox/actions";
import {mapOptions} from "../combobox/utils";
import {FetchOptionsAction, OptionsProps} from "../combobox/types";
import {getOptions} from "../combobox/selectors";
import {Node} from "../combobox/node";
import {connect} from "react-redux";
import Secured from "../auth/Secured";
import {Auth} from "../auth/functionalityCodes";
import { isRequestBusy, DEFAULT_PAGE_SIZE } from '../util/helpers';
import Loader from '../common/Loader';
import { formFieldConfig, FormFieldConfig } from "../../form/types";
import {FindAllCompaniesAction} from '../../types/procurementTypes';
import {findAllCompanies} from './actions';
import SelectBinder from "../common/binders/SelectBinder";
import { useTranslation } from "react-i18next";
import { createWebParams, WebSortFields } from '../remote/requestTypes';
import {WebParams} from '../remote/requestTypes';
import { RETAIL_PRODUCT_PACK } from "../../types/inventoryTypes";
import { getAllCompanyCodes , getAllCompanies } from '../procurement/selectors';
import AsyncActionButton from '../actionButton/AsyncActionButton';
import ActionButton from '../actionButton/ActionButton';
import { Company } from '@pearlchain/stackbase-common/lib/components/CompanyBinder';
import { validateCompanyBinder } from '../common/binders/ValidateCompanyBinder';

interface Props extends DispatchProps {
    products: List<Record<ProductGridData>> | undefined,
    companies: Company[] | undefined;
    isBusy: boolean,
    vatCountryCode: string | null
}

interface DispatchProps extends OptionsProps {
    listProducts: (formData: ProductFilterFormData, webParams: WebParams) => void
    viewProduct: (event: ActionButtonEvent, vatCountryCode: string|null) => ViewProductAction,
    updateProduct: (event: ActionButtonEvent, vatCountryCode: string|null) => UpdateProductAction,
    newProduct: () => NewProductAction,
    fetchOptions: (sources: string[]) => FetchOptionsAction,
    findAllCompanies: () => FindAllCompaniesAction
}

const comboboxSources = [Node.PRODUCT_SOURCE, Node.PRODUCT_STATUS, Node.PRODUCT_GROUP, Node.GIFT_PRODUCT_GROUP2, Node.GIFT_PRODUCT_GROUP3];

export function ProductOverview(props: InjectedFormProps<ProductFilterFormData, Props> & Props) {
    const [sortFields, setSortFields] = useState({} as WebSortFields);

    const {t} = useTranslation();

    useEffect(() => {
        props.fetchOptions(comboboxSources);
        props.findAllCompanies();
    }, []);

    const opt = useCallback((source: string) => mapOptions(props.options, source), [props.options]);

    function createProductOverviewFormFields() {
        const formFields: { [key: string]: FormFieldConfig } = {
            companies: formFieldConfig({ label: 'retail.product.overview.formFields.company', translateLabel: true, binder: CompanyBinder, multi: true, validate: validateCompanyBinder(props.companies) }),
            productNo: formFieldConfig({label: 'retail.product.overview.formFields.productNo', translateLabel: true, binder: TextBinder}),
            description: formFieldConfig({label: 'retail.product.overview.formFields.description', translateLabel: true, binder: TextBinder}),
            productGroup2: formFieldConfig({label: 'retail.product.overview.formFields.productGroup2', translateLabel: true, binder: SelectBinder, options: opt(Node.GIFT_PRODUCT_GROUP2)}),
            productGroup3: formFieldConfig({label: 'retail.product.overview.formFields.productGroup3', translateLabel: true, binder: SelectBinder, options: opt(Node.GIFT_PRODUCT_GROUP3)})
        };
        return formFields;
    }

    const formFields = useMemo(() => {
        return createProductOverviewFormFields();
    }, [opt, props.companies]);

    return (
        <div className="page-container">
            <Loader busy={props.isBusy}/>
            <Secured code={Auth.PRODUCT_SEARCH_VIEW}>
            <Fragment>
                <Form>
                    <ButtonToolbar className="mt-2 mb-3">
                        <AsyncActionButton
                            actionId={ActionTypes.LIST_PRODUCTS}
                            variant="primary"
                            type="submit"
                            onAction={props.handleSubmit((formData: ProductFilterFormData) => {
                                    const pageParams = createWebParams(0, DEFAULT_PAGE_SIZE, sortFields);
                                    props.listProducts(formData, pageParams);
                                })}
                        >
                            {t('retail.product.button.search')}
                        </AsyncActionButton>
                        <ActionButton
                            variant="secondary"
                            onAction={props.reset}
                        >
                            {t('retail.product.button.clear')}
                        </ActionButton>
                        <Secured code={Auth.NEW_PRODUCT}>
                            <ActionButton
                                variant="secondary"
                                onAction={() => props.newProduct()}
                            >
                                {t('retail.product.button.new')}
                            </ActionButton>
                        </Secured>
                    </ButtonToolbar>
                    <FormFieldsLayout fields={formFields} numCols={3}/>
                </Form>
                <ProductGrid products={props.products}
                            sortFields={sortFields}
                            setSortFields={setSortFields}
                            viewProductAction={(event: ActionButtonEvent) => props.viewProduct(event, props.vatCountryCode)}
                            updateProductAction={(event: ActionButtonEvent) => props.updateProduct(event, props.vatCountryCode)}/>
            </Fragment>
            </Secured>
        </div>
    );
}

const ProductOverviewForm = reduxForm<ProductFilterFormData, Props>({
    form: 'product-overview-form',
    enableReinitialize: true,
    keepDirtyOnReinitialize: true
})(ProductOverview);

export default connect(
    (state: StoreState) => {
        const isBusy = isRequestBusy(state, [RequestId.LIST_PRODUCTS]);
        const companyCodes = getAllCompanyCodes(state);
        return {
            options: getOptions(state),
            products: getProductsFromCommonStore(state),
            companies: getAllCompanies(state),
            initialValues: {
                companies: companyCodes,
                productGroup: "ProductGroup.Gift",
                source: RETAIL_PRODUCT_PACK,
                companyCode: state.userInfo.get('selectedRealmNo')
            },
            isBusy,
            vatCountryCode: getVatInfo(state)
        }
    },
    {listProducts, viewProduct, updateProduct, newProduct, fetchOptions, findAllCompanies}
)(ProductOverviewForm);
