import React from 'react';
import { MenuItem } from 'react-contextmenu';
import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Row } from "reactstrap";

interface Props {
    disabled: boolean,
    action: () => void,
    icon: IconDefinition,
    label: string,
    t: (label: string) => void
}

export function ContextMenuItem(props: Props) {
    return (
        <MenuItem disabled={props.disabled} onClick={props.action}>
            <Row>
                <Col col={2}>
                    <FontAwesomeIcon icon={props.icon} size="sm"/>
                </Col>
                <Col col={10}>
                    {props.t(props.label)}
                </Col>
            </Row>
        </MenuItem>
    )
}

