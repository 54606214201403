import React from 'react'
import { List, Record } from 'immutable'
import { WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form'
import { useTranslation } from 'react-i18next'
import { SelectBinder } from '@pearlchain/component-lib-ui'
import { RequestIdFetcher } from '@pearlchain/component-lib-common'

import { RequestId } from '../../remote/requests';
import { Option } from '../../common/binders/SelectBinder';

interface Props {
    input: WrappedFieldInputProps;
    meta: WrappedFieldMetaProps;
    dependsOn?: {};
    disabled: boolean;
}

export default function SalesOrderStatusBinder(props: Props) {
    const {t} = useTranslation();

    return (
        <RequestIdFetcher
            requestId={RequestId.GET_ORDER_STATUSES}
            render={(data: List<Record<Option<string>>> | undefined) => {
                const opts = dataToOptions(data, t);
                return <SelectBinder options={opts} {...props}/>
            }}
        />
    )
}

export function dataToOptions(data: List<Record<Option<string>>> | undefined, t: (input: string) => string): Array<Option<string>> {
    const options : Array<Option<string>> = [];

    options.push({value: '', label: ''} as Option<string>);

    if (data) {
        data.map((d) => options.push({
            value: d.get('value'),
            label: t(d.get('label'))
        }));
    }

    return options;
}
