import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ButtonToolbar, Form, Col } from 'react-bootstrap';
import {formValueSelector, InjectedFormProps, reduxForm} from 'redux-form';
import {List, Record} from 'immutable';
import { getLocationParam, RequestBuilder, useFetcher } from '@pearlchain/component-lib-common';
import { round } from '../util/helpers';

import {
    AddProductComponentAction,
    createProductForm,
    DeleteProductComponentAction,
    GetDefaultCompanyCurrencyAction,
    ProductComponentFormData,
    ProductFormData,
    ProductMode,
    SaveAndNewProductAction,
    UpdateProductComponentAction
} from '../../types/productTypes';
import {StoreState} from '../../types/storeTypes';
import ActionTypes, {
    addProductComponent,
    deleteProductComponent,
    getDefaultCompanyCurrency,
    saveAndNewProduct,
    saveProduct,
    updateProductComponent,
    generateProductNo, processExportToExcel
} from './actions';
import {getCompanyCurrency, getDefaultCompany, getDefaultProductPackVatLevel, getProduct, getProductComponents} from './selectors';
import ProductComponentModal from "./ProductComponentModal";
import {ActionButtonEvent} from "../../types/gridTypes";
import ProductComponentGrid from "./ProductComponentGrid";
import {fetchOptions} from "../combobox/actions";
import {getOptions} from "../combobox/selectors";
import {FetchOptionsAction, OptionsProps} from "../combobox/types";
import {mapOptions} from "../combobox/utils";
import {Node} from "../combobox/node";
import FormFieldsLayout from "../../form/FormFieldsLayout";
import {confirm} from "../confirmation/confirm";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {createProductFormFields} from "./formFieldsCreator";
import Secured from "../auth/Secured";
import {Auth} from "../auth/functionalityCodes";
import { isRequestBusy } from '../util/helpers';
import { RequestId } from '../remote/requests';
import Loader from '../common/Loader';
import { Prompt } from 'react-router';
import { RETAIL_PRODUCT_PACK } from "../../types/inventoryTypes";
import ProductStatuses from "./statuses";
import { Company } from '@pearlchain/stackbase-common/lib/components/CompanyBinder';
import { getAllCompanies } from '../procurement/selectors';
import AsyncActionButton from '../actionButton/AsyncActionButton';

import ActionButton from '../actionButton/ActionButton';
import { getSystemParam } from '../remote/api';


export interface Props extends DispatchProps {
    onSave: () => void,
    mode: ProductMode,
    productComponents?: List<Record<ProductComponentFormData>>,
    formSelectors: FormInputSelectors,
    companies: Company[] | undefined;
    defaultCompany?: string,
    defaultCompanyCurrency?: string,
    isBusy: boolean,
    product?: Record<ProductFormData>,
    processExportToExcel: typeof processExportToExcel
}

interface DispatchProps extends OptionsProps {
    saveProduct: typeof saveProduct,
    saveAndNewProduct: (data: Record<ProductFormData>, mode: ProductMode) => SaveAndNewProductAction,
    addProductComponent: (openModalFn: () => void) => AddProductComponentAction,
    updateProductComponent: (event: ActionButtonEvent, openModalFn: () => void, mode: ProductMode) => UpdateProductComponentAction,
    deleteProductComponent: (event: ActionButtonEvent, mode: ProductMode) => DeleteProductComponentAction,
    fetchOptions: (sources: string[]) => FetchOptionsAction,
    getDefaultCompanyCurrency: (companyCode: string) => GetDefaultCompanyCurrencyAction
    generateProductNo: typeof generateProductNo
}

export interface FormInputSelectors {
    source?: string,
    productNo?: string,
    companyCode?: string,
    alternativeUom2?: string,
    alternativeUom3?: string,
    alternativeUom4?: string
}

export interface SystemParam {
    parameterValue?:any
}

const comboboxSources = [Node.PRODUCT_GROUP, Node.VAT_CODE, Node.PRODUCT_STATUS, Node.UOM, Node.UOM2, Node.UOM3, Node.UOM4, Node.PRODUCT_SOURCE, Node.COMPONENT_AWARE, Node.GIFT_PRODUCT_GROUP2, Node.GIFT_PRODUCT_GROUP3];

export function Product(props: InjectedFormProps<ProductFormData, Props> & Props) {

    const {t} = useTranslation();

    const [isModalShown, toggleModal] = useState(false);
    const opt = (source: string) => mapOptions(props.options, source);
    const readonly = props.mode === ProductMode.VIEW;

    const { reset } = props;

    const handleCompanyChange = (value: any) => {
        props.getDefaultCompanyCurrency(value);
    };

    useEffect(() => {
        props.fetchOptions(comboboxSources);
    }, []);

    const product = props.product;
    const productComponents = props.productComponents;
    const productCompany = product && product.get('companyCode');
    const defaultCompany = props.defaultCompany;
    const defaultCompanyCurrency = props.defaultCompanyCurrency;

    // fetch the default company currency
    useEffect(() => {
        if (defaultCompany) {
            console.info("Getting default company currency");
            props.getDefaultCompanyCurrency(defaultCompany);
        }
    }, [defaultCompany]);

    // change the currency field to the default currency
    useEffect(() => {
        if (defaultCompanyCurrency) {
            props.change('currency', defaultCompanyCurrency);
        }
    }, [defaultCompanyCurrency]);

    // change the company field to the default company
    useEffect(() => {
        if (defaultCompany && !productCompany) {
            props.change('companyCode', defaultCompany);
        }
    }, [defaultCompany, productCompany]);

    // generate a new product number when the page is loaded
    useEffect(() => {
        if (props.mode === ProductMode.NEW && props.formSelectors.productNo == null) {
            props.generateProductNo('product-form', 'productNo');
        }
    }, [props.mode, props.formSelectors.productNo]);


    // change the default company-currency whenever the companyCode changed
    const companyCode = props.formSelectors.companyCode;
    useEffect(() => {
        if (companyCode) {
            props.getDefaultCompanyCurrency(companyCode);
        }
    }, [companyCode]);

    const formFields = useMemo(() => {
        return createProductFormFields(readonly, (value: any) => handleCompanyChange(value), opt, props.companies);
    }, [props.options, props.companies]);

    //Check the EnableEditableProductPackPrices system parameter to see if product pakc prices are editable
     const { data: customProductPackSalesPriceParam } = useFetcher(useCallback(() => {
         return getSystemParam(new RequestBuilder(), 'CustomProductPackSalesPrice', props.defaultCompany)
     }, []));

    const { data: productpackVatLevelParam } = useFetcher(useCallback(() => {
        return getSystemParam(new RequestBuilder(), 'ProductPackVatCode', companyCode)
    }, [companyCode]));

    if (productpackVatLevelParam !== undefined) {
        props.options.set('defaultProductPackVatLevel', productpackVatLevelParam.parameterValue);
    }

    // calculate whether any product components were changed
    const hasUnsavedComponentChanges = useMemo(() => {
        if (productComponents) {
            if (product) {
                return product.get('components') !== productComponents;
             } else {
                 return productComponents.size > 0;
             }
        } else {
            return false;
        }
    }, [productComponents, product]);

    const calcProductPriceExcludingVatValue = useMemo(() => {
        if (!customProductPackSalesPriceParam?.parameterValue) {
            if(!productComponents)  return '';
            const price = productComponents.reduce((r: number, p: Record<ProductComponentFormData>) =>
                r + parseFloat(p.get('quantity')) * (p.get('salesPrice') || 0.0),
                0.0);
            return round(price, -2).toFixed(2) + ' ' + defaultCompanyCurrency;
        }
    }, [productComponents, defaultCompanyCurrency]);

    const calcProductPriceIncludingVatValue = useMemo(() => {
        if (!customProductPackSalesPriceParam?.parameterValue) {
            if(!productComponents)  return '';
            const price = productComponents.reduce((r: number, p: Record<ProductComponentFormData>) =>
                r + round(parseFloat(p.get('quantity')) * (round(p.get('salesPriceIncludingVatValue') || 0.0, -2)), -2), 0.0)

            return round(price, -2).toFixed(2) + ' ' + defaultCompanyCurrency;
        }
    }, [productComponents, defaultCompanyCurrency]);

    // disable the add button if we're in readonly mode
    const isAddDisabled = readonly;

    // disable the save button if neither the form nor the components were changed
    const isSaveDisabled = readonly || !props.anyTouched && !hasUnsavedComponentChanges;

    return (
        <div className="page-container">
            <Loader busy={props.isBusy}/>
            <Form>
                <ButtonToolbar className="mt-2 mb-3">
                    <AsyncActionButton
                        actionId={ActionTypes.SAVE_PRODUCT}
                        variant="primary"
                        type="submit"
                        onAction={props.handleSubmit((data: ProductFormData) => {
                                props.saveProduct(createProductForm(data), props.mode, ActionTypes.SAVE_PRODUCT, (uuid: string) => props.change("uniqueIdentifier", uuid));
                                // props.initialize has an un-typed second parameter: "dirty"
                                (props.initialize as any)(props.initialValues, true); 
                            })}
                            disabled={isSaveDisabled}>
                        {t('retail.product.button.save')}
                    </AsyncActionButton>
                    {props.mode === ProductMode.NEW ? (
                        <>
                            <AsyncActionButton
                                actionId={ActionTypes.SAVE_AND_NEW_PRODUCT}
                                variant="secondary"
                                onAction={props.handleSubmit((data: ProductFormData) => {
                                    props.saveProduct(createProductForm(data), props.mode, ActionTypes.SAVE_PRODUCT);
                                    reset();
                                    props.saveAndNewProduct(createProductForm(data), props.mode);
                                })}
                                disabled={isSaveDisabled}
                            >
                                {t('retail.product.button.saveAndNew')}
                            </AsyncActionButton>
                        </>
                    ) : (
                        ''
                    )}
                    <ActionButton
                        variant="secondary"
                        onAction={() => confirm({ translationFunction:t }).then(props.reset)}
                        disabled={readonly}
                    >
                        {t('retail.product.button.clear')}
                    </ActionButton>
                </ButtonToolbar>
                <FormFieldsLayout fields={formFields[0]} numCols={3}/>
                {
                    customProductPackSalesPriceParam?.parameterValue ?
                    <FormFieldsLayout fields={formFields[3]} numCols={2}/> :
                    <Form.Row>
                        <Form.Group as={Col} xs="4">
                            <Form.Label>{ t('retail.product.formFields.salesPriceIncludingVat') }</Form.Label>
                            <Form.Control value={calcProductPriceIncludingVatValue} disabled={true}/>
                        </Form.Group>
                        <Form.Group as={Col} xs="4">
                            <Form.Label>{ t('retail.product.formFields.salesPriceExcludingVat') }</Form.Label>
                            <Form.Control value={calcProductPriceExcludingVatValue} disabled={true}/>
                        </Form.Group>
                    </Form.Row>
                }
                <FormFieldsLayout fields={formFields[1]} numCols={2}/>
                <br/>
                <FormFieldsLayout fields={formFields[2]} numCols={2}/>
                <ButtonToolbar className="mt-2 mb-2">
                    <Secured code={Auth.ADD_COMPONENT}>
                        <ActionButton
                            variant="secondary"
                            onAction={() => props.addProductComponent(() => toggleModal(true))}
                            disabled={isAddDisabled}
                        >
                            {t('retail.product.button.addComponent')}
                        </ActionButton>
                    </Secured>
                </ButtonToolbar>
            </Form>
            <ProductComponentGrid productComponents={props.productComponents}
                                mode={props.mode}
                                productNo={props.initialValues.productNo}
                                productDescription={props.initialValues.description}
                                updateProductComponentAction={(event: ActionButtonEvent) => props.updateProductComponent(event, () => toggleModal(true), props.mode)}
                                deleteProductComponentAction={(event: ActionButtonEvent) => props.deleteProductComponent(event, props.mode)}
                                processExportToExcel={props.processExportToExcel}
                                productPriceIncludingVatValue={calcProductPriceIncludingVatValue}
                                productPriceExcludingVatValue={calcProductPriceExcludingVatValue}

            />
            <ProductComponentModal show={isModalShown} onHide={() => toggleModal(false)}
                                mode={props.mode}/>
            
            <Prompt
            when={!isSaveDisabled}
            message={t('retail.product.unsaved.components.warning')}
            />
        </div>
    )
}

const buildInitialValues = (product: Record<ProductFormData>, state: StoreState) => {
    return {
        id: product.get('id'),
        uniqueIdentifier: product.get('uniqueIdentifier'),
        companyCode: product.get('companyCode'),
        source: product.get('source'),
        productNo: product.get('productNo'),
        description: product.get('description'),
        productGroup: product.get('productGroup'),
        productGroup2: product.get('productGroup2'),
        productGroup3: product.get('productGroup3'),
        salesPrice: product.get('salesPrice'),
        salesPriceIncludingVat: product.get('salesPriceIncludingVat'),
        vatLevel: product.get('vatLevel'),
        status: product.get('status'),
        serviceProduct: product.get('serviceProduct'),
        remark: product.get('remark'),
        remark2: product.get('remark2'),
        stockUom: product.get('stockUom'),
        eanNo: product.get('eanNo'),
        priceUom: product.get('priceUom'),
        alternativeUom2: product.get('alternativeUom2'),
        quantity2: product.get('quantity2'),
        equalsInStockUom2: product.get('equalsInStockUom2'),
        eanNo2: product.get('eanNo2'),
        alternativeUom3: product.get('alternativeUom3'),
        quantity3: product.get('quantity3'),
        equalsInStockUom3: product.get('equalsInStockUom3'),
        eanNo3: product.get('eanNo3'),
        alternativeUom4: product.get('alternativeUom4'),
        quantity4: product.get('quantity4'),
        equalsInStockUom4: product.get('equalsInStockUom4'),
        eanNo4: product.get('eanNo4'),
        currency: getCompanyCurrency(state),
        productPriceIncludingVatValue: product.get('salesPriceIncludingVat'),

    };
};

const ProductForm = reduxForm<ProductFormData, Props>({
    form: 'product-form'
})(Product);

const selector = formValueSelector("product-form");

const getProductMode = (state: StoreState) => {
    return getLocationParam<ProductMode>('mode', ProductMode.VIEW);
};

export default connect((state: StoreState) => {
    const product = getProduct(state);
    const defaultCompany = getDefaultCompany(state);
    const defaultCompanyCurrency = getCompanyCurrency(state);
    const isBusy = isRequestBusy(state, [RequestId.SAVE_PRODUCT]);
    const defaultProductPackVatLevel = getDefaultProductPackVatLevel(state);


    let initialValues = {
        companyCode: defaultCompany,
        currency: defaultCompanyCurrency,
        serviceProduct: false,
        source: RETAIL_PRODUCT_PACK,
        productGroup: "ProductGroup.Gift",
        vatLevel:defaultProductPackVatLevel,
        status: ProductStatuses.ANNOUNCED,
        stockUom: "uom.pc",
        priceUom: "uom.pc"
    };

    if (product) {
        initialValues = buildInitialValues(product, state);
    }
    return {
        options: getOptions(state),
        defaultCompany,
        defaultCompanyCurrency,
        initialValues,
        mode: getProductMode(state),
        companies: getAllCompanies(state),
        formSelectors: ({
            source: selector(state, 'source'),
            productNo: selector(state, 'productNo'),
            alternativeUom2: selector(state, 'alternativeUom2'),
            alternativeUom3: selector(state, 'alternativeUom3'),
            alternativeUom4: selector(state, 'alternativeUom4'),
            companyCode: selector(state, 'companyCode')
        }),
        productComponents: getProductComponents(state),
        isBusy,
        product
    }
}, {
    saveProduct,
    saveAndNewProduct,
    addProductComponent,
    updateProductComponent,
    deleteProductComponent,
    fetchOptions,
    getDefaultCompanyCurrency,
    generateProductNo,
    processExportToExcel
})(ProductForm);