export enum WebSortType {
    DESC='DESC',
    ASC='ASC'
}

export type WebSortFields = { [key: string]: WebSortType };

export interface WebParams {
    pageNumber: number;
    pageSize: number;
    sortFields: WebSortFields;
}

export function createWebParams(
    pageNumber: number,
    pageSize: number,
    sortFields: WebSortFields
): WebParams {
    return {
        pageNumber,
        pageSize,
        sortFields
    };
}
