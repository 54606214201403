import {Action} from "redux";
import {List, Record} from "immutable";

export interface FetchOptionsAction extends Action {
    sources: string[]
}

export interface StoreOptionsAction extends Action {
    data: Map<string, List<Option>>
}

export interface OptionsState {
    options: Map<string, List<Option>>
}

export const createOptionsState = Record<OptionsState>({
    options: new Map<string, List<Option>>()
});


export interface Option {
    label: string,
    value: any
}

export interface OptionsProps {
    options: Map<string, List<Option>>
}

export interface ShapeItOptions {
    options: List<string>
}