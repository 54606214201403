import {StoreState} from "../../types/storeTypes";
import {ProductComponentFormData, ProductFormData, ProductGridData, } from "../../types/productTypes";
import {List, Record} from "immutable";
import {getCachedRequest} from "@pearlchain/component-lib-common";

export function getProduct(state: StoreState): Record<ProductFormData> | undefined {
    return state.product.get('product');
}
export function getProductComponent(state: StoreState): Record<ProductComponentFormData> | undefined {
    return state.product.get('productComponent');
}


export function getProductComponents(state: StoreState): List<Record<ProductComponentFormData>> | undefined {
    return state.product.get('productComponents');
}

export function getProductComponentIdx(state: StoreState): string | undefined {
    return state.product.get('productComponentIdx');
}

export function getVatInfo(state: StoreState): string | null {
    return state.product.get('vatInfo') as string | null;
}

export function getProductsFromCommonStore(state: StoreState): List<Record<ProductGridData>> | undefined {
    const productsRequest = getCachedRequest(state, 'list-products');
    return productsRequest && (productsRequest.get('data') as any);
}

export function getProductFromCommonStore(state: StoreState) {
    return state.common.requests.get('requests').get('find-product');
}

export function getCompanyCurrency(state: StoreState) : string | undefined {
    return state.product.get('defaultCompanyCurrency');
}

export function getDefaultProductPackVatLevel(state: StoreState) : string | undefined {
    return state.options.get('defaultProductPackVatLevel', 'VatCodeOption.None');
}

export function getDefaultCompany(state: StoreState) : string | undefined {
    const companiesRequest = getCachedRequest(state, 'companies');
    const data = companiesRequest && (companiesRequest.get('data') as any);
    if (data && data.length >= 0) {
        return data[0].code;
    }
    return undefined;
}
