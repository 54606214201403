import React, { useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { formValueSelector, InjectedFormProps, reduxForm } from 'redux-form'
import { ButtonToolbar, Form } from 'react-bootstrap'
import { Record, List } from 'immutable'

import { FormFieldsLayout } from '@pearlchain/component-lib-ui'
import { getRealmNo } from '@pearlchain/component-lib-common'

import Secured from '../../../auth/Secured'
import { StoreState } from '../../../../types/storeTypes'
import { Auth } from '../../../auth/functionalityCodes'

import OutgoingStockOrdersOverviewGrid from './OutgoingStockOrdersOverviewGrid'
import { outgoingStockOrdersOverviewFormFieldsCreator } from './outgoingStockOrdersOverviewFormFieldsCreator'
import { OutgoingStockOrdersFilterForm, OutgoingSalesOrderItem } from '../../../../types/inventoryTypes'
import ActionTypes, { fetchOutgoingOrders, viewOutgoingOrder } from '../../actions'
import { getOutgoingOrdersFromState } from '../../selectors'
import { DEFAULT_PAGE_SIZE } from '../../../util/helpers';
import { useTranslation } from "react-i18next";
import { createWebParams, WebSortFields } from '../../../remote/requestTypes';
import { FindAllCompaniesAction } from "../../../../types/procurementTypes";
import { findAllCompanies } from "../../../product/actions";
import { Company } from '@pearlchain/stackbase-common/lib/components/CompanyBinder';
import { getAllCompanies } from '../../../procurement/selectors';
import AsyncActionButton from '../../../actionButton/AsyncActionButton'
import ActionButton from '../../../actionButton/ActionButton'

interface Props {
    company: string,
    companies: Company[] | undefined,
    searchResult: List<Record<OutgoingSalesOrderItem>> | undefined,
    fetchOutgoingOrders: typeof fetchOutgoingOrders,
    viewOutgoingOrder: (uuid: string, update: boolean, company: string) => void,
    findAllCompanies: () => FindAllCompaniesAction
}

function OutgoingStockOrdersOverviewPage(props: InjectedFormProps<OutgoingStockOrdersFilterForm, Props> & Props) {

    const [sortFields, setSortFields] = useState({} as WebSortFields)
    const {t} = useTranslation();

    useEffect(() => {
        props.findAllCompanies();
    }, []);

    const formFields = useMemo(() => {
        return outgoingStockOrdersOverviewFormFieldsCreator(props.companies);
    }, [props.companies]);

    return (
        <Secured code={Auth.INVENTORY_VIEW_ORDERS}>
             <Form className="page-container">
                 <ButtonToolbar className="mt-2 mb-3">
                     <AsyncActionButton
                         actionId={ActionTypes.FETCH_OUTGOING_ORDERS}
                        variant="primary"
                        type="submit"
                        className="mr-2"
                        onAction={props.handleSubmit((data: OutgoingStockOrdersFilterForm) => {
                                const params = createWebParams(0, DEFAULT_PAGE_SIZE, sortFields);
                                props.fetchOutgoingOrders(data, params);
                        })}
                    >
                        {t('retail.inventory.button.search')}
                    </AsyncActionButton>
                    <ActionButton
                        variant="primary"
                        className="mr-2"
                        onAction={() => props.initialize({company: getRealmNo()})}>
                        {t('retail.inventory.button.clear')}
                    </ActionButton>
                </ButtonToolbar>
                <FormFieldsLayout fields={formFields} numCols={{ xs: 1, sm: 2, md: 3, lg: 3 }}/>
                <OutgoingStockOrdersOverviewGrid
                    searchResult={props.searchResult}
                    sortFields={sortFields}
                    setSortFields={setSortFields}
                    onClickOrder={(uuid: string, update: boolean) => props.viewOutgoingOrder(uuid, update, props.company)}
                />
            </Form>
        </Secured>
    );
}

const OutgoingStockOrdersOverviewForm = reduxForm<OutgoingStockOrdersFilterForm, Props>({
    form: 'outgoing-orders-overview-form'
})(OutgoingStockOrdersOverviewPage);


export default connect((state: StoreState) => {
    const selector =  formValueSelector('outgoing-orders-overview-form');
    return {
        company: selector(state, 'company') || getRealmNo(),
        companies: getAllCompanies(state),
        initialValues: {
          company: getRealmNo()
        },
        searchResult: getOutgoingOrdersFromState(state)
    }
}, { fetchOutgoingOrders, viewOutgoingOrder, findAllCompanies })(OutgoingStockOrdersOverviewForm);
