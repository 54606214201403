import React from 'react'
import { Record, List } from 'immutable'
import { connect } from 'react-redux'
import { getFormValues } from 'redux-form'
import { useTranslation } from 'react-i18next'

import { GridReactive, Column, ApplyCallbackDatastore, Row } from '@pearlchain/component-lib-react-pg'
import { LocationItem, RequestBuilder } from '@pearlchain/component-lib-common'

import { mapAuthorizationProps, isAuthorized } from '../../auth/AuthUtils'
import { StoreState } from '../../../types/storeTypes'
import { Auth } from '../../auth/functionalityCodes'
import { ActionButtonEvent } from '../../../types/gridTypes'

import {
    PurchaseOrderFormData, ViewPurchaseOrderAction, UpdatePurchaseOrderAction,
    ReceivePurchaseOrderAction, DEFAULT_PAGE_SIZE, PurchaseOrderStatus
} from '../../../types/procurementTypes'
import { findPurchaseOrders } from '../../remote/api'
import { INITIAL_STATUS, showError } from '../actions'
import { createExtensions } from '../../../powergrid/extensions/contextmenu/powergridHelpers'
import StepThroughPager from '../../common/pagination/StepThroughPager';
import { WebSortFields } from '../../remote/requestTypes';
import { gridColumnsToSortFields } from '../../remote/requestHelpers';
import buildPurchaseOrderColumns from "./columns";

export interface PurchaseOrderGridProps {
    searchResult: List<Record<PurchaseOrderFormData>>,
    sortFields: WebSortFields,
    setSortFields: (sortFields: WebSortFields) => void,
    viewPurchaseOrderAction: (event: ActionButtonEvent, location: LocationItem) => ViewPurchaseOrderAction,
    updatePurchaseOrderAction: (event: ActionButtonEvent, location: LocationItem) => UpdatePurchaseOrderAction,
    receivePurchaseOrderAction: (event: ActionButtonEvent, location: LocationItem) => ReceivePurchaseOrderAction,
    functionalityCodes: List<string>,
    isCorporateAdmin: boolean,
    showError: (message: string) => void,
    formValues: any
}

const SORT_COLUMN_FIELDS_MAPPING = new Map([
    ['companyCode', 'companyCode'],
    ['no', 'no'],
    ['description', 'description'],
    ['supplierName', 'supplierName'],
    ['orderDate', 'orderDate'],
    ['requestedDeliveryDate', 'requestedDeliveryDate'],
    ['status', 'status'],
]);

export function dataToRows(data: Iterable<Record<PurchaseOrderFormData>>, columns: Column[]): Row[] {
    const rows: Row[] = [];

    for (let item of data) {
        const row = columns.map((column) => (item as any).get(column._key)) as Row;
        row.id = (item as any).get('uniqueIdentifier');
        row[5] = (item as any).getIn(['supplier', 'name']);
        rows.push(row);
    }

    return rows;
}

export function PurchaseOrderGrid(props: PurchaseOrderGridProps) {

    const {t} = useTranslation();

    const purchaseOrderColumns: Column[] = buildPurchaseOrderColumns(t);

    const viewPurchaseOrderEvent = { key: 'view-purchase-order', action: props.viewPurchaseOrderAction };
    const updatePurchaseOrderEvent = { key: 'update-purchase-order', action: (event: ActionButtonEvent, location: LocationItem) => {
            const status: string  = event && event.row ? event.row.rowData[event.row.id][8] : "";

            if (status === INITIAL_STATUS) {
                props.updatePurchaseOrderAction(event, location);
            } else {
                props.showError(t('retail.procurement.overview.validation.initial'));
            }
        }
    };
    const receivePurchaseOrderEvent = { key: 'receive-purchase-order', action: props.receivePurchaseOrderAction };

    function resolveGlobalAttribute(row: Row, property: string, attribute: string) {
        if (property === 'view-purchase-order' && attribute === 'actionHidden') {
            if (isAuthorized(props.functionalityCodes, props.isCorporateAdmin, Auth.VIEW_PURCHASE_ORDER)) {
                return false;
            }
        }
        if (property === 'update-purchase-order' && attribute === 'actionHidden') {
            if (row[8] !== INITIAL_STATUS) {
                return true;
            }

            if (isAuthorized(props.functionalityCodes, props.isCorporateAdmin, Auth.UPDATE_PURCHASE_ORDER)) {
                return false;
            }
        }
        if (property === 'receive-purchase-order' && attribute === 'actionHidden') {

            const rowStatus: string = row[8];

            if (rowStatus === PurchaseOrderStatus.INITIAL || rowStatus === PurchaseOrderStatus.DELIVERED) {
                return true;
            }

            if (isAuthorized(props.functionalityCodes, props.isCorporateAdmin, Auth.RECEIVE_PURCHASE_ORDER)) {
                return false;
            }
        }
    }

    return (
        <StepThroughPager
            alwaysShowPager={true}
            data={props.searchResult || List()}
            pageSize={DEFAULT_PAGE_SIZE}
            sortFields={props.sortFields}
            fetchNextData={(params) => {
                return findPurchaseOrders(new RequestBuilder(), props.formValues, params);
            }}
            render={(data: List<Record<PurchaseOrderFormData>>) => {
                return (
                    <GridReactive
                    gridId="purchase-order-grid"
                    classNameOuter="wrap-grid"
                    data={data ? dataToRows(data, purchaseOrderColumns) : []}
                    columns={purchaseOrderColumns}
                    onSortColumnsChanged={(sortColumns) => {
                        if (sortColumns) {
                            const sortFields = gridColumnsToSortFields(sortColumns, purchaseOrderColumns, SORT_COLUMN_FIELDS_MAPPING);
                            props.setSortFields(sortFields);
                        }
                    }}
                    extensions={
                        createExtensions({"formatting": true}, undefined, 'procurement-overview-form-id', {translate: t})
                    }
                    events={[viewPurchaseOrderEvent, updatePurchaseOrderEvent, receivePurchaseOrderEvent]}
                    createDatastore={(data) =>
                        new ApplyCallbackDatastore(['view-purchase-order','update-purchase-order','receive-purchase-order'], ['actionHidden'], resolveGlobalAttribute, data)
                    }/>
                )             
            }}
        />
    );
}

export default connect((state: StoreState) => {
    const formValues = getFormValues('procurement-overview-form')(state);
    const authorizationProps = mapAuthorizationProps(state);
    return {
        functionalityCodes: authorizationProps.functionalityCodes,
        isCorporateAdmin: authorizationProps.isCorporateAdmin,
        formValues
    }
},{ showError } )(PurchaseOrderGrid)
