import { DateBinder, FormFieldConfig, TextBinder } from '@pearlchain/component-lib-ui'
import { getRealmNo } from '@pearlchain/component-lib-common'
import { CompanyBinder } from '@pearlchain/stackbase-common';
import { Company } from '@pearlchain/stackbase-common/lib/components/CompanyBinder';

import ProductBinder from '../../../common/binders/ProductBinder'
import SalesOrderStatusBinder from '../../binders/SalesOrderStatusBinder'
import { validateCompanyBinder } from '../../../common/binders/ValidateCompanyBinder'
import PartnerBinder from "../../../common/binders/partner/PartnerBinder"
import { formFieldConfig } from '../../../../form/types'

export function outgoingStockOrdersOverviewFormFieldsCreator(companies: Company[] | undefined) {
    const formFields: { [key: string]: FormFieldConfig } = {
        company: formFieldConfig({ label: 'retail.inventory.orders.overview.company', translateLabel: true, binder: CompanyBinder, validate: validateCompanyBinder(companies) }),
        no: formFieldConfig({ label: 'retail.inventory.orders.overview.no', translateLabel: true, binder: TextBinder}),
        status: formFieldConfig({ label: 'retail.inventory.orders.overview.status', translateLabel: true, binder: SalesOrderStatusBinder}),
        from: formFieldConfig({ label: 'retail.inventory.orders.overview.dateFrom', translateLabel: true, binder: DateBinder }),
        until: formFieldConfig({ label: 'retail.inventory.orders.overview.dateTo', translateLabel: true, binder: DateBinder }),
        partner: formFieldConfig({ label: 'retail.inventory.orders.overview.partner', translateLabel: true, binder: PartnerBinder, initialValues: {companyCodes: [getRealmNo()]}, modalRoot: "modal-root", modalTitle: 'common.binder.supplier' }),
        productNo: formFieldConfig({ label: 'retail.inventory.orders.overview.productNo', translateLabel: true, binder: ProductBinder, companyCode: getRealmNo() })
    };
    return formFields;
}
