import React, { useEffect } from 'react';
import {Button, ButtonToolbar, Modal} from "react-bootstrap";
import {confirmable} from 'react-confirm';

export interface WarningProps {
    translationFunction: any,
    okLabel?: string,
    title?: string,
    message?: string
}

interface InternalProps extends WarningProps {
    show: boolean,
    proceed: any,
    cancel: any,
    dismiss: any
}

const CONFIRM_YES_ID = "react-confirm-yes-id";

export const WarningModal: React.FunctionComponent<InternalProps> = props => {

    const t = props.translationFunction;

    const {
        okLabel,
        title,
        message,
        show,
        proceed,
        dismiss
    } = props;

    useEffect(() => {
        const reactConfirmYesId: any = document.getElementById(CONFIRM_YES_ID);

        if (reactConfirmYesId) {
            reactConfirmYesId.focus();
        }
    }, []);

    return (
        <React.Fragment>
            <Modal show={show}
                   onHide={dismiss}
                   enforceFocus={true} centered={true}>
                <Modal.Header><h5>{t(title)}</h5></Modal.Header>
                <Modal.Body>
                    <h6>{t(message)}</h6>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonToolbar className="mt-2 mb-2">
                        <Button autoFocus id={CONFIRM_YES_ID} variant="primary" className="mr-2"
                                onClick={proceed}>{t(okLabel)}</Button>
                    </ButtonToolbar>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    );
};

WarningModal.defaultProps = {
    okLabel: "retail.confirmation.button.yes",
    message: "retail.confirmation.message.default",
    title: "retail.confirmation.title.warning"
};


export default confirmable(WarningModal);
