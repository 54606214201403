import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {Form, ButtonToolbar, Button} from 'react-bootstrap';
import {formValueSelector, reduxForm, InjectedFormProps} from 'redux-form';
import {List, Record} from 'immutable';
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {SelectBinder} from '@pearlchain/component-lib-ui';
import { ProductFormData } from "../../types/productTypes";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core';
library.add(faSpinner);

import {
    ProductMode,
    ProductComponentFormData,
    SaveProductComponentAction,
    SaveAndNewProductComponentAction
} from '../../types/productTypes';
import {StoreState} from '../../types/storeTypes';
import {
    saveProductComponent,
    saveAndNewProductComponent
} from './actions';
import { getProductComponent, getDefaultCompany, getProductComponents } from './selectors';

import {
    integerStockUomValidatorWithMandatoryPositiveNumberWithThreeDecimalFrom0To1000,
    required,
} from "../util/validators";
import {Node} from "../combobox/node";
import {mapOptions} from "../combobox/utils";
import {OptionsProps} from "../combobox/types";
import {getOptions} from "../combobox/selectors";
import {formFieldConfig} from "../../form/types";
import FormFieldsLayout from "../../form/FormFieldsLayout";
import {confirm} from "../confirmation/confirm";

import ProductBinder from "../common/binders/ProductBinder";
import { useBasicProductInfo } from '../productInfo/useProductInfo';
import TextBinder from '../common/binders/TextBinder';
import {SubmissionError} from 'redux-form';
import ConfirmAlert, { ConfirmableAlertOpts } from '../common/ConfirmAlert';
import { validateGiftForUnique } from '../procurement/purchaseOrderLine/validateProductNoForUnique';
export interface OwnProps {
    onSave: () => void;
    mode: ProductMode;
}

interface StateProps extends OptionsProps {
    defaultCompany: string | undefined;
    productNo: string;
    initialValues: any;
    newProductComponent: boolean;
    productComponents?: List<Record<ProductComponentFormData>>,
}

interface DispatchProps {
    saveProductComponent: (data: Record<ProductComponentFormData>, mode: ProductMode, newProductComponent: boolean) => SaveProductComponentAction,
    saveAndNewProductComponent: (data: Record<ProductComponentFormData>, mode: ProductMode, reset: () => void, newProductComponent: boolean) => SaveAndNewProductComponentAction
}

type Props = OwnProps & StateProps & DispatchProps;

function mergeFormDataWithProductInfo(formData: Record<ProductComponentFormData>, productInfo: ProductFormData) {
    return Record(formData)()
        .set('productNo', productInfo.productNo);
}

export function ProductComponent(props: InjectedFormProps<ProductComponentFormData, Props> & Props) {
    const productNo = props.productNo;
    const companyCode = props.defaultCompany!;
    const {t} = useTranslation();
    const { data: productInfo, fetching } = useBasicProductInfo(productNo, companyCode);
    const [ confirmAlertOpts, setConfirmAlert] = useState<ConfirmableAlertOpts>();

    console.log(props.productComponents)
    
    // change the form fields with the updated product info
    useEffect(() => {
        props.change('description', productInfo ? productInfo.description : '');
        props.change('uom', productInfo ? productInfo.stockUom : '');
        props.change('salesPriceIncludingVat', productInfo ? productInfo.salesPriceIncludingVat : '');
        props.change('salesPriceIncludingVatValue', productInfo ? productInfo.salesPriceIncludingVatValue : '');
        props.change('currency', productInfo ? productInfo.currency : '');
    }, [productInfo]);

    // disable the "Add" button until product info is available and the request is not fetching
    const isFetching = fetching;
    const isDisabled = productNo == null;

    const onSubmitAdd = useCallback((formData: Record<ProductComponentFormData>) => {
        const component = mergeFormDataWithProductInfo(formData, productInfo!);

        //Throw an error when the product has a status that is not allowed for sale
        if(productInfo && !productInfo.availableForSale){
            throw new SubmissionError({
                productNo: t('retail.procurement.orderLine.productNotInSaleAllowedStatus'),
                _error: t('retail.procurement.orderLine.productNotInSaleAllowedStatus.error')
            });
        }

        const message = validateGiftForUnique(productInfo, props.productComponents, props.mode, t);
        if (message) {
            // validation failed. Show a confirmation warning before allowing the user to save.
            setConfirmAlert({
                title: t('retail.purchase.newpurchaseorder.save.warning'),
                message,
                variant: 'warning',
                onConfirm: () => {
                    // the alert was confirmed - "save anyway"
                    props.saveProductComponent(component, props.mode, props.newProductComponent);
                    props.onSave();
                }
            });
        }
        else {
            props.saveProductComponent(component, props.mode, props.newProductComponent);
            props.onSave();
        }
    }, [productInfo]);

    const onSubmitAddAndNew = useCallback((formData: Record<ProductComponentFormData>) => {
        const component = mergeFormDataWithProductInfo(formData, productInfo!);

        //Throw an error when the product has a status that is not allowed for sale
        if(productInfo && !productInfo.availableForSale){
            throw new SubmissionError({
                productNo: t('retail.procurement.orderLine.productNotInSaleAllowedStatus'),
                _error: t('retail.procurement.orderLine.productNotInSaleAllowedStatus.error')
            });
        }

        const message = validateGiftForUnique(productInfo, props.productComponents, props.mode, t);
        if (message) {
            // validation failed. Show a confirmation warning before allowing the user to save.
            setConfirmAlert({
                title: t('retail.purchase.newpurchaseorder.save.warning'),
                message,
                variant: 'warning',
                onConfirm: () => {
                    // the alert was confirmed - "save anyway"
                    props.saveAndNewProductComponent(component, props.mode, () => props.reset(), props.newProductComponent)
                }
            });
        }
        else {
            props.saveAndNewProductComponent(component, props.mode, () => props.reset(), props.newProductComponent)
        }
    }, [productInfo, props.newProductComponent]);

    const formFields = useMemo(() => {
        return {
            productNo: formFieldConfig({
                label: 'retail.productComponent.formFields.productNo',
                translateLabel: true,
                binder: ProductBinder,
                validate: required,
                companyCode,
                excludeGifts: true,
                focus: true,
                availableForPurchase: false,
                availableForSale: true,
                extraSelector: { availableForSale: true }
            }),
            quantity: formFieldConfig({
                label: 'retail.productComponent.formFields.quantity',
                translateLabel: true,
                binder: TextBinder,
                validate: integerStockUomValidatorWithMandatoryPositiveNumberWithThreeDecimalFrom0To1000(productInfo && productInfo.stockUomIsInteger),
                focus: !!productNo
            }),
            description: formFieldConfig({label: 'retail.productComponent.formFields.productDescription', translateLabel: true, binder: TextBinder, disabled: true}),
            uom: formFieldConfig({label: 'retail.productComponent.formFields.uom', translateLabel: true, binder: SelectBinder, options: mapOptions(props.options, Node.UOM), disabled: true}),
        }
    }, [productInfo]);

    return (
        <Form>
            <ConfirmAlert alert={confirmAlertOpts}/>
            <ButtonToolbar className="mt-2 mb-3">
                <Button variant="primary" type="submit" className="mr-2" disabled={isDisabled}
                        onClick={props.handleSubmit(onSubmitAdd)}>
                        {t('retail.product.button.add')}
                </Button>
                <Button variant="secondary" className="mr-2" disabled={isDisabled}
                        onClick={props.handleSubmit(onSubmitAddAndNew)}>
                    {t('retail.product.button.addAndNew')}
                </Button>
                <Button variant="secondary" className="mr-2"
                        onClick={() =>confirm({translationFunction : t, 
                        title: t('retail.product.button.clear.title'),
                        message: t('retail.product.button.clear.message'),
                        okLabel: t('retail.product.button.clear.okLabel'),
                        cancelLabel: t('retail.product.button.clear.cancelLabel')}
                        ).then(props.initialize)}>
                    {t('retail.product.button.clear')}
                </Button>
                { isFetching && <FontAwesomeIcon icon="spinner" spin size="lg" className="mt-2"/> }
            </ButtonToolbar>
            <FormFieldsLayout fields={formFields} numCols={2}/>
        </Form>
    )
}

const selector = formValueSelector("product-component-form");

const buildInitialValues = (productComponent: Record<ProductComponentFormData>) => {
    return {
        uniqueIdentifier: productComponent.get('uniqueIdentifier'),
        productNo: productComponent.get('productNo'),
        description: productComponent.get('description'),
        uom: productComponent.get('uom'),
        quantity: productComponent.get('quantity'),
        salesPrice: productComponent.get('salesPrice'),
        currency: productComponent.get('currency')
    };
};

const ProductComponentForm = reduxForm<ProductComponentFormData, Props>({
    form: 'product-component-form'
})(ProductComponent);

export default connect((state: StoreState): StateProps => {
    const productComponent = getProductComponent(state);
    let initialValues = {
        quantity: "1"
    };
    if (productComponent) {
        initialValues = buildInitialValues(productComponent);
    }

    const newProductComponent = productComponent == null;

    return {
        options: getOptions(state),
        initialValues,
        defaultCompany: getDefaultCompany(state),
        productNo: selector(state, 'productNo'),
        newProductComponent,
        productComponents: getProductComponents(state),
    }
}, {saveProductComponent, saveAndNewProductComponent})
(ProductComponentForm);
