import XLSX from "xlsx";

export function exportPdf(blob: any, fileName: string) {
    // https://blog.jayway.com/2017/07/13/open-pdf-downloaded-api-javascript/
    const newBlob = new Blob([blob], { type: "application/pdf" })
    const data = window.URL.createObjectURL(newBlob);
    const link = document.createElement('a');

    link.href = data;
    link.target = "_blank";
    link.download = fileName;

    link.click();

    setTimeout(() =>
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(data)
        , 100);
}

export function exportXLSX(title: string, data: any, t: (values: string) => string) {
    const wb = XLSX.utils.book_new();
    const toExport  = data.toJS();

    toExport.forEach((row) => {
        delete row.uniqueIdentifier;
        delete row.no;
        delete row.integerUom;
        delete row.companyCode;
        delete row.productPriceCurrency;
        delete row.productGroup;
        delete row.productSource;
        delete row.printLabel;

        row.uom = t(row.uom);
        row.correctedQuantity = '';
    });

    const worksheet = XLSX.utils.json_to_sheet(toExport, {
        header : [
            "productNo",
            "productDescription",
            "quantity",
            "correctedQuantity",
            "uom",
            "productSalesPriceIncludingVat"
        ]
    });

    const range = XLSX.utils.decode_range(worksheet['!ref']);

    for(let C = range.s.c; C <= range.e.c; ++C) {
        let address = XLSX.utils.encode_col(C) + "1";
        if (!worksheet[address]) continue;

        switch (worksheet[address].v) {
            case "companyCode":  worksheet[address].v = t("retail.inventory.export.header.company"); break;
            case "quantity": worksheet[address].v = t("retail.inventory.export.header.quantity"); break;
            case "correctedQuantity": worksheet[address].v = t("retail.inventory.export.header.correctedQuantity"); break;
            case "uom": worksheet[address].v = t("retail.inventory.export.header.uom"); break;
            case "productSalesPriceIncludingVat": worksheet[address].v = t("retail.inventory.export.header.price"); break;
            case "productPriceCurrency": worksheet[address].v = t("retail.inventory.export.header.currency"); break;
            case "productNo":	worksheet[address].v = t("retail.inventory.export.header.productNo"); break;
            case "productDescription": worksheet[address].v = t("retail.inventory.export.header.description"); break;
            case "productGroup": worksheet[address].v = t("retail.inventory.export.header.productGroup"); break;
        }
    }

    worksheet['!cols'] = [
        {wch:15},
        {wch:10},
        {wch:10},
        {wch:10},
        {wch:15},
        {wch:10},
        {wch:15},
        {wch:50},
        {wch:50}
    ];

    XLSX.utils.book_append_sheet(wb,  worksheet, t("retail.inventory.export.contextMenu.label"));
    XLSX.writeFile(wb, title);
}
