import React from 'react'
import { GridReactive, Column, Row, ApplyCallbackDatastore } from '@pearlchain/component-lib-react-pg'
import { Record, List } from 'immutable'

import { ActionButtonEvent } from '../../types/gridTypes'
import {
    ProductGridData,
    UpdateProductAction,
    ViewProductAction
} from '../../types/productTypes';
import { useTranslation } from "react-i18next"
import { connect } from 'react-redux'
import { StoreState } from '../../types/storeTypes'
import { isAuthorizedByProps } from '../auth/AuthUtils'
import { AuthorizationProps } from '../auth/authTypes'
import { Auth} from '../auth/functionalityCodes'
import { productsToRows } from './helpers'
import { getAllCompanyCodes } from '../procurement/selectors'
import { listProducts } from '../remote/api'
import { getRealmNo, RequestBuilder } from '@pearlchain/component-lib-common'
import { getFormValues } from 'redux-form'
import StepThroughPager from '../common/pagination/StepThroughPager';
import { gridColumnsToSortFields } from '../remote/requestHelpers';
import { DEFAULT_PAGE_SIZE } from '../util/helpers';
import { WebSortFields } from '../remote/requestTypes';

export interface ProductGridProps extends AuthorizationProps {
    products: List<Record<ProductGridData>> | undefined,
    sortFields: WebSortFields,
    setSortFields: (sortFields: WebSortFields) => void,
    viewProductAction: (event: ActionButtonEvent, action: () => void) => ViewProductAction,
    updateProductAction: (event: ActionButtonEvent, action: () => void) => UpdateProductAction,
    companies: string[],
    formValues: any
}

function getProductId(product: Record<ProductGridData>) {
    return product.get('uniqueIdentifier');
}

const SORT_COLUMN_FIELDS_MAPPING = new Map<string, string>([
    ['companyName', 'companyName'],
    ['productNo', 'productNo'],
    ['description', 'description'],
    ['productGroup2', 'productGroup2'],
    ['productGroup3', 'productGroup3']
]);

export function ProductGrid(props: ProductGridProps) {
    const productList: List<Record<ProductGridData>> = props.products || List();
    const {t} = useTranslation();
    const company: string = getRealmNo();

    const productColumns: Column[] = [
        {_key: 'uniqueIdentifier', width: 0, title: '', idField: true},
        {
            _key: 'actions', width: 100, sortable: false, title: t('retail.product.grid.actions'), actions: [
                {
                    cssClass: 'btn btn-secondary fa',
                    icon: 'fa-eye',
                    eventName: 'view-product',
                    hidden: true
                },
                {
                    cssClass: 'btn btn-secondary fa',
                    icon: 'fa-pencil-alt',
                    eventName: 'update-product',
                    hidden: true
                }
            ]
        },
        // a hidden column. We use this to compare against the current company, to determine whether to show/hide the "edit" button
        {_key: 'companyCode', width: 0, title: '', formatter: 'translate'},
        {_key: 'hasEntriesInStock', width: 0, title: ''},
        {_key: 'companyName', width: 150, title: t('retail.product.grid.company'), formatter: 'translate'},
        {_key: 'productNo', width: 150, title: t('retail.product.grid.no')},
        {_key: 'description', width: 250, title: t('retail.product.grid.description')},
        {_key: 'productGroup2', width: 150, title: t('retail.product.grid.group2'), formatter: 'translate', translateValue: true},
        {_key: 'productGroup3', width: 150, title: t('retail.product.grid.group3'), formatter: 'translate', translateValue: true},
        {_key: 'salesPriceIncludingVatWithUOM', width: 200, title: t('retail.product.grid.salesPriceIncludingVat') },
        {_key: 'salesPriceExcludingVatWithUOM', width: 200, title: t('retail.product.grid.salesPriceExcludingVat') }
    ];

    const companyCodeIdx = productColumns.findIndex(column => column._key == 'companyCode');
    const availableCompanies = props.companies;

    const viewProductEvent = {key: 'view-product', action: props.viewProductAction};
    const updateProductEvent = {key: 'update-product', action: props.updateProductAction};

    function resolveGlobalAttribute(row: Row, property: string, attribute: string) {

        if (property === 'view-product' && attribute === 'actionHidden') {
            if (isAuthorizedByProps(props, Auth.VIEW_PRODUCT)) {
                return false;
            }
        }

        if (property === 'update-product' && attribute === 'actionHidden') {

            if (row[2] !== company) {
                return true;
            }

            if (row[3] === true) {
                return true;
            }

            if (isAuthorizedByProps(props, Auth.UPDATE_PRODUCT) &&
                availableCompanies.indexOf(row[companyCodeIdx]) != -1) {
                return false;
            }
        }
    }

    if (!availableCompanies) {
        return null;
    }

    return (
        <StepThroughPager
            data={productList}
            pageSize={DEFAULT_PAGE_SIZE}
            sortFields={props.sortFields}
            fetchNextData={(params) => {
                const formData = props.formValues;
                return listProducts(new RequestBuilder(), formData, params);
            }}
            render={(data) => {
                const rows = productsToRows(data, getProductId, productColumns, t)
                return (
                    <GridReactive
                        gridId="product-grid"
                        classNameOuter="wrap-grid"
                        data={rows}
                        columns={productColumns}
                        onSortColumnsChanged={(sortColumns) => {
                            if (sortColumns) {
                                const sortFields = gridColumnsToSortFields(sortColumns, productColumns, SORT_COLUMN_FIELDS_MAPPING);
                                props.setSortFields(sortFields);
                            }
                        }}
                        extensions={{
                            selection: true,
                            directinput: true
                        }}
                        events={[viewProductEvent, updateProductEvent]}
                        createDatastore={(data) =>
                            new ApplyCallbackDatastore(['view-product','update-product'], ['actionHidden'], resolveGlobalAttribute, data)
                        }
                    />
                );
            }}
        />
    );
}

export default connect((state: StoreState) => {
    const formValues = getFormValues('product-overview-form')(state);
    return {
        functionalityCodes: state.userInfo.get('functionalityCodes'),
        isCorporateAdmin: state.userInfo.get('isCorporateAdmin'),
        companies: getAllCompanyCodes(state)!,
        formValues
    }
})(ProductGrid)
