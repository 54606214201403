import { StoreState } from '../../types/storeTypes'
import { PurchaseOrderLineFormData, PurchaseOrderFormData, ValidationMessage, PurchaseOrderMode,
    SupplierIdentifier } from '../../types/procurementTypes'
import { getCachedRequest, getLocationParam } from '@pearlchain/component-lib-common'
import { List, Record } from 'immutable'
import { Company } from '@pearlchain/stackbase-common/lib/components/CompanyBinder';

export function getPurchaseOrder(state: StoreState) : Record<PurchaseOrderFormData> | undefined {
    return state.procurement.get('purchaseOrder');
}

export function getPurchaseOrderLines(state: StoreState) : List<Record<PurchaseOrderLineFormData>> | undefined {
    return state.procurement.get('purchaseOrderLines');
}

export function getCompanyCurrency(state: StoreState) : string | undefined {
    return state.procurement.get('companyCurrency');
}

export function getDefaultSupplier(state: StoreState) : Record<SupplierIdentifier> | undefined {
    let defaultCompanySupplier: Record<SupplierIdentifier> | undefined = state.procurement.get('defaultCompanySupplier');
    return defaultCompanySupplier;
}

export function getValidationMessages(state: StoreState) : List<Record<ValidationMessage>> {
    return state.procurement.get('validationMessages');
}

// when we only have one company, that's the default one, and we use it to prefill the binder
export function getDefaultCompany(state: StoreState) : string | undefined {
    const companiesRequest = getCachedRequest(state, 'companies');
    const data = companiesRequest && (companiesRequest.get('data') as any[]);
    if (data && data.length === 1) {
        return data[0].code;
    }
    return undefined;
}

export function getAllCompanies(state: StoreState) : Company[] | undefined {
    const companiesRequest = getCachedRequest(state, 'companies');
    return companiesRequest && (companiesRequest.get('data') as Company[] | undefined);
}

export function getAllCompanyCodes(state: StoreState) : string[] | undefined {
    const companies = getAllCompanies(state);
    return companies ? companies.map(company => company.code) : undefined;
}

export function getPurchaseOrderMode(state: StoreState) {
    return getLocationParam<PurchaseOrderMode>('mode', PurchaseOrderMode.VIEW);
}
