import moment, { Moment } from 'moment';
import _ from 'lodash';

import { getLanguage } from '../components/auth/AuthUtils';
import { formatPrice } from '@pearlchain/component-lib-common';
import i18next from "i18next";

export interface Formatters {
    date: (v: string) => string,
    number: (v: number | undefined) => string
}

export function formatDate(v: string) : string {
    return stringToDateString(v);
}

/**
 * Formats a date based on the locale
 */
export function formatMoment(m: Moment): string {
    setLocale(m)
    return m.format('L');
}

export function stringToDateString(v: string): string {
    let m;
    if(v == null) {
        return '';
    } else if (v.includes('T')) {
        m = moment(v);
    } else {
        m = moment(v, 'DD-MM-YYYY');
    }

    return formatMoment(m);
}

export function formatNumber(v: number | undefined) : string {
    const languageCode = getLanguage()
    return (!v || v == null) ? '' : Number(v).toFixed(2).toLocaleString(languageCode);
}

/**
 * Gets the language code from localstorage and sets the locale
 */
function setLocale(m: Moment) {
    const languageCode = getLanguage()
    m.locale(languageCode);
}

/**
 * Returns the date format depending on the locale
 */
export function getFormat() : string {
    const languageCode = getLanguage()
    return moment.localeData(languageCode).longDateFormat('L');
}

export function formatQuantityWithUom(t: (key: string) => string, quantity: string | number, uom: string) {
    return formatThreeDecimal(quantity) +  ' ' + t(uom);
}

export function formatPriceWithUom(t: (key: string) => string, price: string | number, currency: string, uom: string) {
    return formatPrice(price) +  ' ' + currency  + ' / ' + t(uom);
}

function toNumber(input: unknown): number {
    if (typeof input === 'number') {
        return input;
    } else {
        return +(input as any);
    }
}

export function formatThreeDecimal(input: unknown, opts?: unknown) {
    const value = toNumber(input);
    if (Number.isNaN(value)) {
        return '';
    }

    const locale = i18next.language;
    const formatter = new Intl.NumberFormat(locale, Object.assign({
        minimumFractionDigits: 0,
        maximumFractionDigits: 3,
    }, opts));

    return formatter.format(value);
}
