import { List, Record } from "immutable";
import {PurchaseOrderLineFormData, PurchaseOrderLineMode} from "../../../types/procurementTypes";
import { TranslationFunction } from "i18next";
import {ProductComponentFormData, ProductFormData, ProductMode} from "../../../types/productTypes";

export function validateProductNoForUnique(product: ProductFormData, lines: List<Record<PurchaseOrderLineFormData>>, lineMode: PurchaseOrderLineMode,  t: TranslationFunction): string | undefined {
    for (const line of lines) {
        let productNo: string = product.productNo;
        if (line.get('productNo') === productNo && lineMode === PurchaseOrderLineMode.NEW) {
            // product no is not unique
            return t('retail.purchase.newpurchaseorder.warn.duplicateProductNo', { productNo });
        }
    }
}

export function validateGiftForUnique(data: ProductFormData | undefined , productComponents: List<Record<ProductComponentFormData>> | undefined, mode: ProductMode,  t: TranslationFunction): string | undefined {
    if(productComponents){
        for (const component of productComponents) {
            let productNo: string = data?.productNo+"";
            if (component.get('productNo') === productNo && (mode === ProductMode.UPDATE || mode === ProductMode.NEW)) {
                // product no is not unique
                return t('retail.purchase.newpurchaseorder.warn.duplicateProductNo', { productNo });
            }
        }
    }
    else {
        return ""
    }
}