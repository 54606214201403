import {
    FormFieldConfig,
    formFieldConfig,
    TextBinder
} from '@pearlchain/component-lib-ui'
import NumberBinder from '../../binders/NumberBinder'

export function detailedOrderFormFieldsCreator(readOnly: boolean) {
    const formFields: { [key: string]: FormFieldConfig } = {
        company: formFieldConfig({ label: 'retail.inventory.orders.detailed.company', translateLabel: true, binder: TextBinder, disabled: readOnly}),
        no: formFieldConfig({ label: 'retail.inventory.orders.detailed.no', translateLabel: true,binder: TextBinder, disabled: readOnly}),
        status: formFieldConfig({ label: 'retail.inventory.orders.detailed.status', translateLabel: true,binder: TextBinder, disabled: readOnly}),
        partner: formFieldConfig({ label: 'retail.inventory.orders.detailed.partner', translateLabel: true,binder: TextBinder, disabled: readOnly}),
        requestedDeliveryDate: formFieldConfig({ label: 'retail.inventory.orders.detailed.requestedDeliveryDate', translateLabel: true,binder: TextBinder, disabled: readOnly}),
        totalPrice: formFieldConfig({ label: 'retail.inventory.orders.detailed.totalPrice', translateLabel: true,binder: NumberBinder, disabled: readOnly, id: "number-binder-id"})
    };
    return formFields;
}
