import React from 'react';
import { List, Record } from 'immutable';
import { WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form';
import { getRealmNo, RequestIdFetcher } from '@pearlchain/component-lib-common';

import { RequestId } from '../../remote/requests';
import SelectBinder, { Option } from './SelectBinder';
import { dataToOptions } from '../../util/helpers';

interface Props {
    input: WrappedFieldInputProps,
    meta: WrappedFieldMetaProps,
    dependsOn?: {},
    disabled: boolean,
    multi?: boolean,
    translateOptions?: boolean,
    productNo: string
}

export default function ProductUomBinder(props: Props) {
    return (
        <RequestIdFetcher requestId={RequestId.LIST_PRODUCT_UOMS}
                          cacheKey={props.productNo}
                          params={{productNo: props.productNo, company: getRealmNo()}}
            render={(data: List<Record<Option<string>>> | undefined) => {
                    const opts = dataToOptions(data);
                    return <SelectBinder translateOptions={props.translateOptions} options={opts} { ...props }/>
                }
            }
        />
    )
}
