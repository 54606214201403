import { StoreState } from "../../types/storeTypes";
import { ActionButtonState } from "./actionButtonTypes";

/**
 * Selects the action-button state from the store
 */
export function selectActionButtonState(actionId: string) {
    return (state: StoreState): ActionButtonState | undefined => state.actionButtons.actions.get(actionId);
}

/**
 * Returns true when any action is running
 */
export function isAnyActionRunning(state: StoreState): boolean {
    return state.actionButtons.runningCount > 0;
}
