import React, { Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

interface Props {
    busy: boolean
}

/**
 * Shows a spinner as loading icon
 * Used to be shown as long as a request is busy
 * Works with an overlay, so the user is blocked from further interaction
 */
export default function Loader(props: Props) {
    if(props.busy) {
        return <div className="overlay">
            <FontAwesomeIcon icon={faSpinner} size="lg" className="fa-spin"/>
        </div>;
    } else {
        return <Fragment/>;
    }
}
