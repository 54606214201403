import { Action } from "redux";
import { ActionButtonsStoreState, createActionButtonsStoreState, createActionButtonState } from "./actionButtonTypes";
import { ActionType, ActionStartAction, ActionSuccessAction, ActionErrorAction } from './actionButtonActions';

function handleActionStart(s: ActionButtonsStoreState, { actionId }: ActionStartAction): ActionButtonsStoreState {
    let actions = s.actions;
    let action = actions.get(actionId);
    if (!action) {
        action = createActionButtonState();
    }
    
    // update the total number of running actions
    if (!action.running) {
        s = s.update('runningCount', c => c + 1);
    }

    // update the current action
    action = action.set('running', true);

    return s.set('actions', actions.set(actionId, action));
}

function handleActionSuccess(s: ActionButtonsStoreState, { actionId }: ActionSuccessAction): ActionButtonsStoreState {
    let actions = s.actions;
    let action = actions.get(actionId);
    if (!action) return s; 

    // update the total number of running actions
    if (action.running) {
        s = s.update('runningCount', c => c - 1);
    }

    // update the current action
    action = action
        .set('running', false)
        .delete('error')
        .delete('errorMsg');

    return s.set('actions', actions.set(actionId, action));
}

function handleActionError(s: ActionButtonsStoreState, { actionId, errorMsg }: ActionErrorAction): ActionButtonsStoreState {
    let actions = s.actions;
    let action = actions.get(actionId);
    if (!action) return s; 

    // update the total number of running actions
    if (action.running) {
        s = s.update('runningCount', c => c - 1);
    }

    // update the current action
    action = action
        .set('running', false)
        .set('error', true)
        .set('errorMsg', errorMsg);

    return s.set('actions', actions.set(actionId, action));
}

function handleIncrementRunningCount(s: ActionButtonsStoreState) {
    return s.update('runningCount', c => c + 1);
}

function handleDecrementRunningCount(s: ActionButtonsStoreState) {
    return s.update('runningCount', c => c - 1);
}

export default function (state: ActionButtonsStoreState | undefined, action: Action) {
    if (!state) {
        state = createActionButtonsStoreState();
    }

    switch (action.type) {
        case ActionType.ACTION_START:
            return handleActionStart(state, action as ActionStartAction);
        case ActionType.ACTION_SUCCESS:
            return handleActionSuccess(state, action as ActionSuccessAction);
        case ActionType.ACTION_ERROR:
            return handleActionError(state, action as ActionErrorAction);
        case ActionType.INCREMENT_RUNNING_ACTIONS:
            return handleIncrementRunningCount(state);
        case ActionType.DECREMENT_RUNNING_ACTIONS:
            return handleDecrementRunningCount(state);
        default:
            return state;
    }
}
