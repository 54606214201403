import React, { useMemo } from 'react'
import { connect } from 'react-redux'
import { InjectedFormProps, reduxForm } from 'redux-form'
import { ButtonToolbar, Form } from 'react-bootstrap'
import { Record } from 'immutable'

import { FormFieldConfig, FormFieldsLayout } from '@pearlchain/component-lib-ui'

import { Auth } from '../../../auth/functionalityCodes'
import { StoreState } from '../../../../types/storeTypes'
import Secured from '../../../auth/Secured'
import TextBinder from '../../../common/binders/TextBinder'
import NumberBinder from '../../binders/NumberBinder'
import UomBinder from '../../../common/binders/UomBinder'

import { SalesOrderLine } from '../../../../types/inventoryTypes'
import {
    integerStockUomValidatorWithMandatoryPositiveNumberWithThreeDecimalFrom0To1000,
    positiveNumber
} from '../../../util/validators'
import { useTranslation } from "react-i18next";
import { getLocationParam, popLocation } from '@pearlchain/component-lib-common'
import { updateOrderLineInOrder } from "../../actions";
import { formFieldConfig } from '../../../../form/types';
import ActionButton from '../../../actionButton/ActionButton'

interface Props {
    line: SalesOrderLine | undefined,
    updateOrderLineInOrder: (line: SalesOrderLine) => void,
    popLocation: (n?: number) => void
}

function formFieldsCreator(line: any) {
    const formFields: { [key: string]: FormFieldConfig } = {
        productNo: formFieldConfig({ label: 'retail.inventory.orders.update.productNo', translateLabel: true, binder: TextBinder, disabled: true }),
        productDescription: formFieldConfig({ label: 'retail.inventory.orders.update.productDescription', translateLabel: true, binder: TextBinder, disabled: true}),
        quantityOrdered: formFieldConfig({ label: 'retail.inventory.orders.update.quantityOrdered', translateLabel: true, binder: NumberBinder, validate: positiveNumber, disabled: true}),
        quantityShipped: formFieldConfig({ label: 'retail.inventory.orders.update.quantityShipped', translateLabel: true, binder: NumberBinder,
            validate: integerStockUomValidatorWithMandatoryPositiveNumberWithThreeDecimalFrom0To1000(isStockUomIsInteger(line))}),
        uom: formFieldConfig({ label: 'retail.inventory.orders.update.uom', translateLabel: true, binder: UomBinder}),
        orderQuantityUom: formFieldConfig({ label: 'retail.inventory.orders.update.priceUom', translateLabel: true, binder: UomBinder}),
        unitPrice: formFieldConfig({ label: 'retail.inventory.orders.update.price', translateLabel: true, binder: NumberBinder, validate: positiveNumber})
    };
    return formFields;
}

function isStockUomIsInteger(line: any) : boolean {
    if (line) {
        return line.get('stockUomIsInteger');
    }
    return false;
}


function UpdateOrderLinePage(props: InjectedFormProps<SalesOrderLine, Props> & Props) {

    const {t} = useTranslation();
    const line = props.line;

    const formFields = useMemo(() => {
        return formFieldsCreator(line);
    }, []);

    return (
        <Secured code={Auth.INVENTORY_UPDATE_ORDER_LINE}>
            <Form>
                <ButtonToolbar className="mt-2 mb-3">
                    <ActionButton
                        variant="primary"
                        type="submit"
                        disabled={props.invalid}
                        onAction={props.handleSubmit((line: SalesOrderLine) => {
                                if (line) {
                                    line.updated = true;
                                    props.updateOrderLineInOrder(line);
                                    props.popLocation(1);
                                }
                            })}
                    >
                        {t('retail.inventory.button.save')}
                    </ActionButton>
                </ButtonToolbar>
                <FormFieldsLayout fields={formFields} numCols={{ xs: 1, sm: 2, md: 2, lg: 2 }}/>
            </Form>
        </Secured>
    )
}

const UpdateOrderLineForm = reduxForm<SalesOrderLine, Props>({
    form: 'update-order-line-form'
})(UpdateOrderLinePage);


export default connect((state: StoreState) => {
    const line = getLocationParam<Record<SalesOrderLine>>('line');

    return {
        initialValues: line && line.toJS(), line
    }
}, {updateOrderLineInOrder, popLocation})(UpdateOrderLineForm);
