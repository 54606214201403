import React, { useMemo } from 'react';
import { Button, ButtonToolbar, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { connectDialog, DialogProps } from '../../../dialog/connectDialog';
import { InjectedFormProps, reduxForm } from "redux-form";
import { Option, PrintShelfLabelsForm, PrintLabelInfo } from "../../../../types/inventoryTypes";
import { formFieldConfig, FormFieldConfig, FormFieldsLayout } from "@pearlchain/component-lib-ui";
import NumberBinder from "../../binders/NumberBinder";
import { ModalBody, ModalFooter } from "reactstrap";
import { mandatoryPositiveNumber, requiredValidator } from "../../../util/validators";
import ProductUomBinder from "../../../common/binders/ProductUomBinder";
import { Map, Record, List } from "immutable";

export const PRINT_LABEL_SIMPLE_DIALOG_ID = 'print-label-simple';

interface PrintLabelDialogProps extends DialogProps {
    initialValues: any,
    onConfirm: (labelsToPrint: List<Record<PrintLabelInfo>>, form: PrintShelfLabelsForm) => void
}

function formFieldsCreator(productNo: string) {
    const formFields: { [key: string]: FormFieldConfig } = {
        //numberOfCopies: formFieldConfig({ id: "number-binder-id-1", label: 'retail.inventory.printLabels.numLabels', translateLabel: true, binder: NumberBinder, validate: mandatoryPositiveNumber}),
        shelfQuantity: formFieldConfig({ id: "number-binder-id-2", label: 'retail.inventory.printLabels.shelfQuantity', translateLabel: true, binder: NumberBinder, validate: mandatoryPositiveNumber}),
        uom: formFieldConfig({ label: 'retail.inventory.printLabels.uom', translateLabel: true, binder: ProductUomBinder, productNo, validate: requiredValidator()})
    };
    return formFields;
}

function PrintLabelForSimpleModal(props: InjectedFormProps<PrintShelfLabelsForm, PrintLabelDialogProps> & PrintLabelDialogProps) {
    const { t } = useTranslation();

    const product: Option = props.params && props.params.get(0) || Map();

    const formFields = useMemo(() => {
        return formFieldsCreator(product.value);
    }, [product]);

    return (
        <Modal show={props.show} onHide={props.onHide} onExited={props.onClear}>
            <Modal.Header closeButton>
                {t('retail.inventory.dialog.printLabels.simple.title', {
                    productNo: product.label
                })}
            </Modal.Header>
                <ModalBody>
                    <FormFieldsLayout fields={formFields} numCols={{ xs: 1, sm: 2, md: 2, lg: 2 }}/>
                </ModalBody>
                <ModalFooter>
                    <ButtonToolbar className="mt-2 mb-3">
                        <Button variant="primary" type="submit" className="mr-2" disabled={props.invalid}
                                onClick={props.handleSubmit((form: PrintShelfLabelsForm) => {
                                    props.onConfirm(props.params, form);
                                    props.reset();
                                    props.onHide();
                                })}>
                            {t('retail.inventory.dialog.printWeightLabels.confirm')}
                        </Button>
                    </ButtonToolbar>
                </ModalFooter>
        </Modal>
    );
}

const PrintLabelsForSimpleModalForm = reduxForm<PrintShelfLabelsForm, PrintLabelDialogProps>({
    form: PRINT_LABEL_SIMPLE_DIALOG_ID
})(PrintLabelForSimpleModal);

export default connectDialog(PRINT_LABEL_SIMPLE_DIALOG_ID)(PrintLabelsForSimpleModalForm);
