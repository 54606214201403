import React from 'react';
import { GridReactive, Column, Row } from '@pearlchain/component-lib-react-pg';
import { List, Record } from 'immutable';

import { StockMovementOverviewItem } from "../../../types/inventoryTypes";
import { dataToRows } from "../../../powergrid/helpers";
import { useTranslation } from "react-i18next";
import { createExtensions } from "../../../powergrid/extensions/contextmenu/powergridHelpers";

export interface Props {
    stockMovementEntries?: List<Record<StockMovementOverviewItem>>
}

export function convertStockMovementsToRows<T>(data: Iterable<T>, idFn: (item: T, index: number) => string, columns: Column[]): Row[] {
    const rows: Row[] = [];
    const {t} = useTranslation();

    let i = 0;
    for (let item of data) {
        const row = columns.map((column) => {
            if(column._key == 'type'){
                return t((item as any).get(column._key));
            }
            if(column._key == 'quantity' && (item as any).get('type') == 'SalesOrderLineDelivery'){
                return ((item as any).get(column._key)*(-1)).toFixed(3);
            }
            if(column._key == 'quantity'){
                return (item as any).get(column._key).toFixed(3);
            }
            if(column._key == 'reason'){
                return t((item as any).get(column._key));
            }
            return (item as any).get(column._key)
        }) as Row;
        row.id = idFn(item, i++);
        rows.push(row);
    }

    return rows;
}

export default function StockMovementsOverviewGrid(props: Props) {

    const {t} = useTranslation();
    
    const columns: Column[] = [
        { _key: 'uniqueIdentifier', width: 0, title: '', idField: true },
        { _key: 'type', width: 160, title: t('retail.inventory.movements.type')},
        { _key: 'date', width: 160, title: t('retail.inventory.movements.date'), formatter: 'datetime'},
        { _key: 'remark', width: 160, title: t('retail.inventory.movements.remark')},
        { _key: 'modificationUser', width: 120, title: t('retail.inventory.movements.modificationUser')},
        { _key: 'quantity', width: 80, title: t('retail.inventory.movements.quantity')},
        { _key: 'uom', width: 80, title: t('retail.inventory.movements.uom'), formatter: 'translate'},
        { _key: 'fromCompanyName', width: 200, title: t('retail.inventory.movements.from')},
        { _key: 'toCompanyName', width:200, title: t('retail.inventory.movements.to')},
        { _key: 'reason', width: 200, title: t('retail.inventory.movements.reason')}
    ];

    let rows: Row[] = [];

    if (props.stockMovementEntries) {
        rows = convertStockMovementsToRows<Record<StockMovementOverviewItem>>(props.stockMovementEntries,
            (x: Record<StockMovementOverviewItem>, index) => index + "", columns);
    }

    return(
        <GridReactive gridId="stock-movements-overview-grid"
            classNameOuter="wrap-grid"
            data={rows}
            columns={columns}
            extensions={createExtensions({"formatting": true, "resizing": true}, undefined, 'procurement-overview-form-id', {translate: t})}
            events={[]}/>
    )
}