import { List, Record} from 'immutable'
import { Moment } from 'moment'
import { SupplierIdentifier } from "./procurementTypes";

export const RETAIL_PRODUCT_PACK: string = 'Retail.ProductPack';
export const RETAIL_PRODUCT: string = 'Retail.Product';

export interface StoreStateInventory {
    order: Record<OutgoingSalesOrderItem> | undefined,
    printLabels: List<Record<PrintLabelInfo>>,
    corrections: List<StockCorrection>,
    conversionValidationResponse: Record<ConversionValidationResponse> | undefined
}

export interface NoAndCompanySelector {
    no: string,
    company: string
}

export interface OutgoingSalesOrderItem {
    uniqueIdentifier: string,
    company: string,
    no: string,
    partner: string,
    orderDate: string,
    requestedDeliveryDate: string,
    orderType: string,
    lines: List<Record<SalesOrderLine>>,
    status: string
}

export interface UpdateSalesOrderForm {
    company: string,
    no: string,
    status: string,
    partner: string,
    requestedDeliveryDate: string,
    totalPrice: number,
    lines: List<SalesOrderLine>
}

export interface SalesOrderLine {
    uniqueIdentifier: string,
    productNo: string,
    productDescription: string,
    quantityOrdered: number,
    unitPrice: number,
    totalPrice: number,
    currency: string,
    quantityShipped: number,
    orderQuantityUom: string,
    uom: string,
    updated: boolean
    stockUomIsInteger: boolean
}

export interface PrintShelfLabelsForm {
    numberOfCopies: number;
    shelfQuantity: number;
    uom: string;
}

export interface OutgoingStockOrdersFilterForm {
    company: string,
    no?: string,
    status?: string,
    supplier?: SupplierIdentifier,
    from?: string,
    until?: string,
    productNo?: string,
    pageNumber?: number,
    pageSize?: number,
    sortField?: string,
    sortType?: string
}

export interface ProductInfo {
    productNo: string,
    productDescription: string,
    stockEntryNo: string,
    uom: string,
    quantity: number,
    products: List<Record<CompositeProductInfo>>
}

export interface CompositeProductInfo {
    productNo: string,
    productDescription: string,
    uom: string,
    stockEntryNo: string,
    quantity: number,
    stockEntryQuantity: number
}

export interface StockCreationFormData {
    company: string,
    productDescription: string,
    quantity: number,
    productNo: string,
    uom: string
}

export interface ConversionFormData {
    realmNo: string,
    fromProductNo?: string,
    fromProductDescription?: string,
    fromProductQuantity?: string,
    fromProductUom?: string,
    toProductNo?: string,
    toProductDescription?: string,
    toProductQuantity?: string,
    toProductUom?: string,
    toQuantity?:number
}

export interface ConversionValidationRequest {
    fromProductNo: string | undefined,
    toProductNo: string | undefined,
    fromQuantity: number | undefined,
    realmNo: string | undefined,
    resetForm: boolean
}

export interface ConversionValidationResponse {
    fromProductNo?: string,
    toProductNo?: string,
    fromProductDescription?: string,
    fromStockEntryNo?: string,
    fromQuantity?: string,
    fromTotalQuantity?: string,
    fromUom?: string,
    toProductDescription?: string,
    toStockEntryNo?: string,
    toQuantity?: string,
    toUom?: string,
    uomConversionIsPossible?: false,
    resetForm?: boolean
}

export interface InventoryOverviewFilterFormData {
    company: string,
    productGroup?: string,
    productDescription?: string,
    productNo?: string,
    excludeEmptyStock?: boolean,
    notForSale?: boolean,
    pageNumber?: number,
    pageSize?: number,
    sortField?: string,
    sortType?: string
}

export interface PrintLabelInfo {
    productNo: string,
    productSource: string,
    productDescription: string,
    numLabels: number
}

export interface InventoryOverviewItem {
    uniqueIdentifier: string,
    companyCode: string,
    no: string,
    productNo: string,
    productDescription: string,
    productGroup: string,
    productSource: string,
    quantity: string,
    actionType?: string,
    newQuantity?: string,
    uom: string,
    reason?: string,
    remark?: string,
    printLabel: boolean,
    numLabels: number,
    integerUom: boolean,
    productSalesPriceIncludingVat: string|null,
    productPriceCurrency: string,
    currentQuantity: number
}

export const createInventoryState = Record<StoreStateInventory>({
    order: undefined,
    printLabels: List(),
    corrections: List(),
    conversionValidationResponse: undefined
});

export interface StockMovementOverviewFilterFormData {
    company: string,
    productNo: string,
    productDescription: string,
    periodFrom: string | Moment,
    periodTo: string | Moment
}

export interface StockMovementOverviewItem {
    uniqueIdentifier: string;
    type: string;
    date: string;
    modificationUser: string;
    quantity: string;
    uom: string;
    from: string;
    fromWarehouse: string;
    fromParty: string;
    to: string;
    toWarehouse: string;
    toParty: string;
    reason: string;
    remark: string;
    fromCompanyName: string;
    toCompanyName: string;
}

export interface GridRowEvent {
    row: GridRow
}

export interface GridRow {
    id: number,
    rowData: string[]
}

export interface Option {
    value: string,
    label: string,
    key: string
}

export interface StockCorrection {
    uuid: string,
    stockEntryNo?: string,
    productNo: string,
    newQuantity?: number,
    reason?: string,
    remark?: string,
    integerUom: boolean,
    actionType?: string,
    currentQuantity?: string
}

export const createPrintLabelInfo = Record<PrintLabelInfo>({
    productNo: "",
    productSource: "",
    productDescription: "",
    numLabels: 0
});
