import { Record, List } from "immutable";
import {ActionTypes} from "./actions";
import {createUserInfoState, StoreStateUserInfo} from "./types";

export default function(state : Record<StoreStateUserInfo> = createUserInfoState(), action: any) {
    switch(action.type) {
        case ActionTypes.RECEIVE_USER_INFO:
            return handleReceiveUserInfo(state, action);
        case ActionTypes.LOAD_USER_INFO_FROM_LOCAL_STORAGE:
            return loadUserInfoFromLocalStorage(state, action);
        case ActionTypes.PERSIST_USER_INFO_TO_LOCAL_STORAGE:
            return persistUserInfoToLocalStorage(state, action);
        case ActionTypes.CLEAR_USER_INFO_FROM_LOCAL_STORAGE:
            return clearUserInfoFromLocalStorage(state, action);
    }
    return state;
}

function handleReceiveUserInfo(state: Record<StoreStateUserInfo>, action: any) {
    return state.withMutations(s => {
        s.set('isCorporateAdmin', action.data.get('corporateAdmin'));
        s.set('functionalityCodes', action.data.get('functionalityCodes'));
        s.set('selectedRealmNo', action.data.get('selectedRealmNo'));
    });
}

function loadUserInfoFromLocalStorage(state: Record<StoreStateUserInfo>, action: any) {
    return state.withMutations(s => {
        const storedString = localStorage.getItem('userInfo');
        if (storedString) {
            const storedInfo = JSON.parse(storedString);
            s.set('isCorporateAdmin', storedInfo.corporateAdmin);
            s.set('functionalityCodes', List.of(storedInfo.functionalityCodes));
            s.set('selectedRealmNo', storedInfo.selectedRealmNo);
        }
    });
}

function persistUserInfoToLocalStorage(state: Record<StoreStateUserInfo>, action: any) {
    localStorage.setItem('userInfo', JSON.stringify(action.data));
    return state;
}

function clearUserInfoFromLocalStorage(state: Record<StoreStateUserInfo>, action: any) {
    localStorage.removeItem('userInfo');
    return state;
}