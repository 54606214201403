import { List, Record, Map } from "immutable";
import {
    createInventoryState, createPrintLabelInfo,
    PrintLabelInfo,
    SalesOrderLine,
    StockCorrection,
    StoreStateInventory
} from "../../types/inventoryTypes";
import ActionTypes, {
    PutOrderAction,
    StockCorrectionAction,
    UpdateOrderLineAction,
    MarkProductToPrintAction
} from "./actions";

export default function(state : Record<StoreStateInventory> = createInventoryState(), action: any) {
    switch(action.type) {
        case ActionTypes.PROCESS_NEW_CORRECTION:
            return processNewCorrection(state, action as StockCorrectionAction);
        case ActionTypes.CLEAR_CORRECTIONS:
            return handleClearCorrections(state);
        case ActionTypes.PUT_ORDER:
            return handlePutOrder(state, action as PutOrderAction);
        case ActionTypes.UPDATE_ORDER_LINE:
            return handleUpdateOrderLine(state, action as UpdateOrderLineAction);
        case ActionTypes.MARK_ENTRY_TO_PRINT_LABEL:
            return handleSetProductToPrint(state, action as MarkProductToPrintAction);
        case ActionTypes.REMOVE_PRINT_LABELS_FROM_ENTRIES:
            return handleRemovePrintLabelFromEntries(state);
    }
    return state;
}

function handleRemovePrintLabelFromEntries(state: Record<StoreStateInventory>) {
    return state.set('printLabels', List<Record<PrintLabelInfo>>());
}

function handleUpdateOrderLine(state: Record<StoreStateInventory>, action: UpdateOrderLineAction) {
    return state.updateIn(['order', 'lines'], (lines: List<Record<SalesOrderLine>>) => lines.map((line: Record<SalesOrderLine>) => {
        if (line.get('uniqueIdentifier') === action.line.uniqueIdentifier) {
            return Map(action.line);
        } else {
            return line;
        }
    }));
}

function handlePutOrder(state: Record<StoreStateInventory>, action: PutOrderAction) {
    return state.setIn(['order'], action.order);
}

function handleClearCorrections(state: Record<StoreStateInventory>) {
    return state.updateIn(['corrections'], () => List());
}

function processNewCorrection(state: Record<StoreStateInventory>, action: StockCorrectionAction) {
    return state.updateIn(['corrections'], (corrections: List<StockCorrection>) => {

        let exist: boolean = false;

        let updated = corrections.map(correction => {
            if (correction.uuid === action.correction.uuid) {
                exist = true;

                let merged = { ...correction, ...action.correction };

                if (merged.reason === "") {
                    delete merged.reason;
                }

                if (merged.actionType === "") {
                    delete merged.actionType;
                }

                if (merged.newQuantity === null) {
                    delete merged.newQuantity;
                }

                return merged;
            } else {
                return correction;
            }
        });

        if (!exist) {
            updated = updated.push(action.correction);
        }

        return updated.filter(correction => correction.actionType || correction.reason || correction.remark || (correction.newQuantity !== undefined && correction.newQuantity >= 0));
    });
}

function handleSetProductToPrint(state: Record<StoreStateInventory>, { productNo, productSource, productDescription, numLabels, marked }: MarkProductToPrintAction) {

    let oldPrintLabels: List<Record<PrintLabelInfo>> = state.getIn(['printLabels']);
    let newPrintLabels: List<Record<PrintLabelInfo>> = List();

    let includes = false
    let index = -1

    oldPrintLabels.forEach((item, i) => {
        if(item.get("productNo") === productNo){
            includes =  true
            index = i
        }
    })


    if (numLabels == 0) {
        newPrintLabels = oldPrintLabels.filter(label =>
            productNo !== label.get('productNo') || productSource !== label.get('productSource')
        );
    } else {
        if(!includes){
            newPrintLabels = oldPrintLabels.insert(oldPrintLabels.size, createPrintLabelInfo({productNo, productSource, productDescription, numLabels}))
        }
        else {
            newPrintLabels = oldPrintLabels.set(index, createPrintLabelInfo({productNo, productSource, productDescription, numLabels}))
        }
        
    }

    return state.setIn(['printLabels'], newPrintLabels);
}