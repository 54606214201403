import {List, Record} from "immutable";

export interface StoreStateUserInfo {
    functionalityCodes: List<string>,
    isCorporateAdmin: boolean,
    selectedRealmNo: string
}

export const createUserInfoState = Record<StoreStateUserInfo>({
    functionalityCodes: List<string>(),
    isCorporateAdmin: false,
    selectedRealmNo: ''
});