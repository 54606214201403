
import React, { useMemo, useRef, useEffect } from 'react';
import { GridReactive, Column, ApplyCallbackDatastore, Row } from "@pearlchain/component-lib-react-pg";
import { List, Record } from 'immutable';
import { useTranslation } from 'react-i18next';

import { PurchaseOrderLineFormData, PurchaseOrderMode, DeletePurchaseOrderLineAction, UpdatePurchaseOrderLineAction } from '../../../types/procurementTypes';
import { purchaseOrderLinesToRows, isPurchaseOrderLineUnsaved, sortPurchaseOrderLines } from '../helpers';
import { ActionButtonEvent } from '../../../types/gridTypes';
import { isAuthorized, mapAuthorizationProps } from '../../auth/AuthUtils';
import { Auth } from '../../auth/functionalityCodes';
import { connect } from 'react-redux';
import { StoreState } from '../../../types/storeTypes';

export interface PurchaseOrderLineGridProps {
    purchaseOrderLines: List<Record<PurchaseOrderLineFormData>> | undefined,
    updatePurchaseOrderLineAction: (event: ActionButtonEvent, mode: PurchaseOrderMode, fn: () => void) => UpdatePurchaseOrderLineAction,
    deletePurchaseOrderLineAction: (event: ActionButtonEvent, mode: PurchaseOrderMode) => DeletePurchaseOrderLineAction,
    mode: PurchaseOrderMode,
    functionalityCodes: List<string>,
    isCorporateAdmin: boolean,
    purchaseOrderLineCurrency?: string,
    interCompanyTransaction: boolean | undefined
}

const buildColumns = (interCompanyTransaction: boolean | undefined) : Column[] => {

    let totalPrice: string = 'retail.procurement.creation.grid.totalPrice';

    if (interCompanyTransaction !== undefined) {
        totalPrice = interCompanyTransaction ? 'retail.procurement.creation.grid.purchasePrice' :
                'retail.procurement.creation.grid.salesPrice';
    }

    return [
        { _key: 'uniqueIdentifier', width: 0, title: '', idField: true },
        { _key: 'actions', width: 100, title: 'retail.procurement.creation.grid.actions',  actions: [
                {
                    cssClass: 'btn btn-secondary fa',
                    icon: 'fa-pencil-alt',
                    eventName: 'update-purchase-order-line',
                    hidden: true
                },
                {
                    cssClass: 'btn btn-secondary fa',
                    icon: 'fa-times',
                    eventName: 'delete-purchase-order-line',
                    hidden: true
                }
            ]},
        { _key: 'productNo', width: 150, title: 'retail.procurement.creation.grid.productNo' },
        { _key: 'description', width: 150, title: 'retail.procurement.creation.grid.description' },
        { _key: 'quantityWithUom', width: 150, title: 'retail.procurement.creation.grid.quantityWithUom' },
        { _key: 'priceWithUom', width: 150, title: 'retail.procurement.creation.grid.priceWithUom' },
        { _key: 'totalPrice', width: 150, title: totalPrice },
        { _key: 'quantityDelivered', width: 150, title: 'retail.procurement.creation.grid.quantityDelivered', formatter: 'decimal' }
    ];
};

function useStylingExtension(lookupByRowId: Map<string, Record<PurchaseOrderLineFormData>>) {
    const ref = useRef(lookupByRowId);

    useEffect(() => {
        // keep the ref updated with the most recent value.
        // This is so that the applyClasses callback can access the correct value.
        ref.current = lookupByRowId;
    });

    // styling extension config
    return {
        applyClasses: (record: Row, column: Column, callback: (style: string) => void) => {
            const item = ref.current.get(record.id);

            if (item && isPurchaseOrderLineUnsaved(item)) {
                callback('cell-unsaved');
            }
        }
    }
}

export function PurchaseOrderLineGrid(props: PurchaseOrderLineGridProps) {
    const { purchaseOrderLines } = props;
    const {t} = useTranslation();
    
    const updatePurchaseOrderLineEvent = { key: 'update-purchase-order-line', action: props.updatePurchaseOrderLineAction }
    const deletePurchaseOrderLineEvent = { key: 'delete-purchase-order-line', action: props.deletePurchaseOrderLineAction }
    const columns: Column[] = buildColumns(props.interCompanyTransaction);

    // sort the lines
    const linesSorted = purchaseOrderLines && sortPurchaseOrderLines(purchaseOrderLines);

    // convert lines to grid rows
    const [rows, lookupByRowId] = useMemo(() => {
        return purchaseOrderLinesToRows(linesSorted, columns, props.purchaseOrderLineCurrency, t);
    }, [linesSorted]);

    function resolveGlobalAttribute(row: Row, property: string, attribute: string) {
        if (property === 'update-purchase-order-line' && attribute === 'actionHidden') {
            if (isAuthorized(props.functionalityCodes, props.isCorporateAdmin, Auth.UPDATE_PURCHASE_ORDER_LINE) && PurchaseOrderMode.VIEW != props.mode) {
                return false;
            }
        }
        if (property === 'delete-purchase-order-line' && attribute === 'actionHidden') {
            if (isAuthorized(props.functionalityCodes, props.isCorporateAdmin, Auth.DELETE_PURCHASE_ORDER_LINE) && PurchaseOrderMode.VIEW != props.mode) {
                return false;
            }
        }
        return true;
    }

    // create the powergrid styling extension
    const styling = useStylingExtension(lookupByRowId);

    return (
        <GridReactive
            gridId="purchase-order-line-grid"
            classNameOuter="wrap-grid"
            data={rows}
            columns={columns}
            extensions={{ 
                selection: true,
                columnsizing: true,
                styling
            }}
            events={[updatePurchaseOrderLineEvent, deletePurchaseOrderLineEvent]}
            createDatastore={(data) =>
                new ApplyCallbackDatastore(['update-purchase-order-line','delete-purchase-order-line'], ['actionHidden'], resolveGlobalAttribute, data)
            }
        />
    );
}

export default connect((state: StoreState) => {
    return mapAuthorizationProps(state)
})(PurchaseOrderLineGrid)
