import React, { useEffect, useState } from "react";
import { Location, History } from "history";
import { useAuthTokenRefresher } from "@pearlchain/component-lib-common";
import { pages } from './components/page/pages';
import Main from "./Main";

interface Props {
    history: History,
    children: React.ReactNode;
}

function getCurrentPage(location: Location) {
    return pages.find((page) => page.path === location.pathname);
}

// listen to the history for the current page to change
function useCurrentPage(history: History) {
    const [currentPage, setCurrentPage] = useState(() => getCurrentPage(history.location));
    useEffect(() => {
        return history.listen((location) => {
            const page = getCurrentPage(location);
            if (page) setCurrentPage(page);
        })
    }, [history, location]);
    return currentPage;
}

export default React.memo(
    function App(props: Props) {
        const token = useAuthTokenRefresher();

        useEffect(() => {
            if (!token) {
                // redirect to the login page if the token is missing or invalid
                props.history.push('/login');
            }
        }, [token]);

        const currentPage = useCurrentPage(props.history);
        
        if (token && currentPage) {
            return (
                <Main page={currentPage}>
                    { React.Children.only(props.children) }
                </Main>
            );

        } else {
            // fallback: show nothing this render. After the effect triggers, the
            // user will be redirected to the login page
            return null;
        }
    }
);
