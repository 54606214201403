import { Record, List } from 'immutable'
import { Action } from 'redux'
import {ValueData} from './procurementTypes'
import {WebParams} from '../components/remote/requestTypes'

export interface ProductFilterFormData {
    companyCode: string,
    productNo?: string,
    description?: string,
    source?: string,
    productGroup?: string,
    productGroup2?: string,
    productGroup3?: string,
    productGroup4?: string,
    status?: string,
    serviceProduct?: boolean,
    exactNo?: boolean,
    exactDescription?: boolean,
    companies?: string[],
    sortField?: string
}

export interface ProductFormData {
    id: string,
    uniqueIdentifier: string,
    companyCode: string,
    source: string,
    productNo: string,
    description: string,
    productGroup: string,
    productGroup2: string,
    productGroup3: string,
    productGroup4: string,
    salesPrice: number | null,
    salesPriceIncludingVat: number|null,
    currency: string,
    vatLevel: string,
    status: string,
    serviceProduct: boolean,
    remark: string,
    remark2: string,
    stockUom: string,
    eanNo: string,
    priceUom: string,
    alternativeUom2: string,
    quantity2: string,
    equalsInStockUom2: string,
    eanNo2: string,
    alternativeUom3: string,
    quantity3: string,
    equalsInStockUom3: string,
    eanNo3: string,
    alternativeUom4: string,
    quantity4: string,
    equalsInStockUom4: string,
    eanNo4: string,
    components: List<Record<ProductComponentFormData>>,
    purchaseOrderMultipleValue: number | null,
    hasEntriesInStock: boolean,
    stockUomIsInteger: boolean | null,
    availableForPurchase: boolean | null,
    availableForSale: boolean | null,
    productIsSaving: boolean
}


export const createProductForm = Record<ProductFormData>({
    id:"",
    uniqueIdentifier: "",
    companyCode: "",
    source: "",
    productNo: "",
    description: "",
    productGroup: "",
    productGroup2: "",
    productGroup3: "",
    productGroup4: "",
    salesPrice: null,
    salesPriceIncludingVat: null,
    currency: "",
    vatLevel: "",
    status: "",
    serviceProduct: false,
    remark: "",
    remark2: "",
    stockUom: "",
    eanNo: "",
    priceUom: "",
    alternativeUom2: "",
    quantity2: "",
    equalsInStockUom2: "",
    eanNo2: "",
    alternativeUom3: "",
    quantity3: "",
    equalsInStockUom3: "",
    equalsInStockUom4:"",
    eanNo3: "",
    alternativeUom4: "",
    quantity4: "",
    eanNo4: "",
    components: List<Record<ProductComponentFormData>>(),
    purchaseOrderMultipleValue: null,
    hasEntriesInStock: false,
    stockUomIsInteger: false,
    availableForPurchase: false,
    availableForSale: false,
    productIsSaving: false
});

export interface ProductComponentFormData {
    uniqueIdentifier: string,
    productNo: string,
    description: string,
    uom: string,
    quantity: string,
    salesPrice: number|null,
    salesPriceIncludingVat: string|null,
    salesPriceIncludingVatValue: number|null,
    currency: string
}

export interface ProductGridData {
    uniqueIdentifier: string,
    companyCode: string,
    companyName: string,
    productNo: string,
    description: string,
    source: string,
    productGroup: string,
    productGroup2: string,
    productGroup3: string,
    productGroup4: string,
    status: string,
    serviceProduct: boolean,
    salesPrice: number|null,
    salesPriceIncludingVat: string|null,
    vatCountryCode: string|null,
    purchasePrice: string,
    currency: string,
    priceUom: string
}

export interface ProcessExportToExcelAction extends Action {
    no: string,
    description: string,
    components: List<Record<ProductComponentFormData>>
    productPriceIncludingVatValue: string
    productPriceExcludingVatValue: string
}

export interface SaveProductAction extends Action {
    data: Record<ProductFormData>,
    mode: ProductMode,
    actionButtonId: string,
    setUUID?: (uuid: string) => void
}

export interface UpdateProductAction extends Action {
    productUUID: string,
    vatCountryCode: string|null
}

export interface ListProductsAction extends Action {
    formData: ProductFilterFormData, 
    webParams: WebParams
}

export interface StoreVatInfoAction extends Action {
    vatCountryCode: string|null
}

export interface NewProductAction extends Action {
}

export interface ViewProductAction extends Action {
    productUUID: string,
    vatCountryCode: string|null
}

export interface SaveAndNewProductAction extends Action {
    data: Record<ProductFormData>,
    mode: ProductMode
}

export interface StoreProductAction extends Action {
    data: Record<ProductFormData>
}

export interface StoreProductComponentAction extends Action {
    data: Record<ProductComponentFormData>
}

export interface StoreProductComponentIdxAction extends Action {
    index: string
}

export interface AddNewProductComponentAction extends Action {
    data: Record<ProductComponentFormData>
}

export interface StoreProductComponentsAction extends Action {
    data: List<Record<ProductComponentFormData>>
}

export interface StoreStateProduct {
    product?: Record<ProductFormData> | undefined,
    productComponents: List<Record<ProductComponentFormData>>
    productComponent?: Record<ProductComponentFormData> | undefined,
    productComponentIdx?: string,
    defaultCompanyCurrency?: string,
    vatInfo?: string|null,
}

export const createProductState = Record<StoreStateProduct>({
    product: undefined,
    productComponents: List<Record<ProductComponentFormData>>(),
    productComponent: undefined,
    productComponentIdx: undefined,
    defaultCompanyCurrency: undefined,
    vatInfo: undefined
});

export interface AddProductComponentAction extends Action {
    openModalFn: () => void
}

export interface SaveProductComponentAction extends Action {
    data: Record<ProductComponentFormData>,
    mode: ProductMode,
    newProductComponent: boolean
}

export interface SaveAndNewProductComponentAction extends Action {
    data: Record<ProductComponentFormData>,
    mode: ProductMode,
    reset: () => void,
    newProductComponent: boolean
}

export interface GenerateProductNoAction extends Action {
    form: string;
    field: string;   
}

export interface UpdateProductComponentAction extends Action {
    productComponentIdx: string,
    mode: ProductMode,
    openModalFn: () => void
}

export interface DeleteProductComponentAction extends Action {
    productComponentIdx: string,
    mode: ProductMode
}

export enum ProductMode {
    VIEW = 'view-product',
    NEW = 'new-product',
    UPDATE = 'update-product'
}

export interface GetDefaultCompanyCurrencyAction extends Action {
    companyCode: string
}

export interface StoreDefaultCompanyCurrencyAction extends Action {
    currency: Record<ValueData>
}

export interface ValueData {
    value: string
}

export interface FindAllCompaniesAction extends Action {
}
