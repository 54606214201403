export enum Auth {
    SAVE_PURCHASE_ORDER = 'web-savePurchaseOrderCommand',
    PURCHASE_ORDER_SEARCH_VIEW = 'web-purchaseOrderSearchView',
    VIEW_PURCHASE_ORDER = 'web-viewPurchaseOrderCommand',
    NEW_PURCHASE_ORDER = 'web-newPurchaseOrderCommand',
    UPDATE_PURCHASE_ORDER = 'web-updatePurchaseOrderCommand',
    NEW_PURCHASE_ORDER_LINE = 'web-newPurchaseOrderLineCommand',
    SAVE_PURCHASE_ORDER_LINE = 'web-savePurchaseOrderLineCommand',
    UPDATE_PURCHASE_ORDER_LINE = 'web-updatePurchaseOrderLineCommand',
    DELETE_PURCHASE_ORDER_LINE = 'web-deletePurchaseOrderLineCommand',
    SEND_PURCHASE_ORDER = 'web-sendPurchaseOrderCommand',
    CANCEL_PURCHASE_ORDER = 'web-cancelPurchaseOrderCommand',
    RECEIVE_PURCHASE_ORDER = 'web-receivePurchaseOrderCommand',
    REGISTER_PURCHASE_ORDER_LINE = 'web-registerPurchaseOrderLineCommand',
    RECEIVE_ALL = 'web-receiveallCommand',
    PRODUCT_SEARCH_VIEW = 'web-productSearchView',
    VIEW_PRODUCT = 'web-viewProductCommand',
    UPDATE_PRODUCT = 'web-updateProductCommand',
    NEW_PRODUCT = 'web-newProductCommand',
    ADD_COMPONENT = 'web-addComponentCommand',
    DELETE_COMPONENT = 'web-deleteComponentCommand',
    UPDATE_COMPONENT = 'web-updateComponentCommand',
    INVENTORY_SEARCH_VIEW = 'web-inventorySearchView',
    INVENTORY_VIEW_PRODUCT_FLOW = 'web-viewProductFlowCommand',
    INVENTORY_SAVE_STOCK_CORRECTION = 'web-saveStockCorrectionCommand',
    INVENTORY_STOCK_CONVERSION = 'web-stockConversionCommand',
    INVENTORY_NEW_STOCK_ENTRY ='web-NewStockEntryCommand',
    INVENTORY_PRINT_LABEL ='web-PrintLabelCommand',
    INVENTORY_EXPORT_TO_EXCEL ='web-ExportToExcelCommand',
    INVENTORY_VIEW_ORDERS = 'web-outgoingStockOrdersSearchView',
    INVENTORY_VIEW_ORDER = 'web-viewOutgoingStockOrderCommand',
    INVENTORY_UPDATE_ORDER = 'web-updateOutgoingStockOrderCommand',
    INVENTORY_UPDATE_ORDER_LINE = 'web-updateOutgoingStockOrderLineCommand',
    INVENTORY_CONFIRM_ORDER = 'web-confirmOutgoingStockOrderCommand'
}