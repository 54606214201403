import {
    InventoryOverviewFilterFormData,
    ConversionFormData,
    StockCorrection,
    StockMovementOverviewFilterFormData,
    ConversionValidationResponse,
    StockCreationFormData,
    OutgoingStockOrdersFilterForm,
    OutgoingSalesOrderItem,
    SalesOrderLine,
    UpdateSalesOrderForm,
    InventoryOverviewItem,
    PrintShelfLabelsForm, PrintLabelInfo
} from "../../types/inventoryTypes";
import { List, Record } from 'immutable'
import { Action } from "redux";
import { ProductInfo } from "../productInfo/productInfoTypes";
import { WebParams, WebSortFields } from "../remote/requestTypes";

export const SALES_ORDER_CONFIRMED_STATUS = "SalesOrderStatus.Confirmed";

const ActionTypes = {
    FIND_INVENTORY_ENTRIES: 'inventory.FIND_INVENTORY_ENTRIES',
    CLEAR_INVENTORY_ENTRIES: 'inventory.CLEAR_INVENTORY_ENTRIES',
    VIEW_STOCK_MOVEMENTS: 'inventory.VIEW_STOCK_MOVEMENTS',
    FIND_STOCK_MOVEMENTS: 'inventory.FIND_STOCK_MOVEMENTS',
    FETCH_CORRECTION_REASONS: 'inventory.FETCH_CORRECTION_REASONS',
    FETCH_CORRECTION_ACTIONS: 'inventory.FETCH_CORRECTION_ACTIONS',
    FETCH_UOMS: 'inventory.FETCH_UOMS',
    PROCESS_NEW_CORRECTION: 'inventory.PROCESS_NEW_CORRECTION',
    SAVE_CORRECTIONS: 'inventory.SAVE_CORRECTIONS',
    CLEAR_CORRECTIONS: 'inventory.CLEAR_CORRECTIONS',
    CONVERT_STOCKS: 'inventory.CONVERT_STOCKS',
    CONVERT_STOCKS_AND_NEW: 'inventory.CONVERT_STOCKS_AND_NEW',
    CHANGE_CONVERSION_PRODUCT: 'inventory.CHANGE_CONVERSION_PRODUCT',
    SAVE_STOCK: 'inventory.SAVE_STOCK',
    SAVE_STOCK_AND_NEW: 'inventory.SAVE_STOCK_AND_NEW',
    FETCH_OUTGOING_ORDERS: 'inventory.FETCH_OUTGOING_ORDERS',
    VIEW_OUTGOING_ORDER: 'inventory.VIEW_OUTGOING_ORDER',
    PUT_ORDER: 'inventory.PUT_ORDER',
    CONFIRM_ORDER: 'inventory.CONFIRM_ORDER',
    UPDATE_ORDER_LINE: 'inventory.UPDATE_ORDER_LINE',
    UPDATE_ORDER: 'inventory.UPDATE_ORDER',
    PROCESS_EXPORT_TO_EXCEL: 'inventory.PROCESS_EXPORT_TO_EXCEL',
    CLEAR_CHANGES: 'inventory.CLEAR_CHANGES',
    OPEN_ORDER_LINE_FOR_EDIT: 'inventory.OPEN_ORDER_LINE_FOR_EDIT',
    VALIDATE_CONVERSION_RESPONSE: 'inventory.conversion.VALIDATE_CONVERSION_RESPONSE',
    GET_PRODUCT_LABELS_DOC: 'inventory.GET_PRODUCT_LABESL_DOC',
    MARK_ENTRY_TO_PRINT_LABEL: 'inventory.MARK_ENTRY_TO_PRINT_LABEL',
    SHOW_PRINT_LABELS_DIALOG: 'inventory.SHOW_PRINT_LABELS_DIALOG',
    SHOW_PRINT_WEIGHT_LABELS_DIALOG: 'inventory.SHOW_PRINT_WEIGHT_LABELS_DIALOG',
    REMOVE_PRINT_LABELS_FROM_ENTRIES: 'inventory.REMOVE_PRINT_LABELS_FROM_ENTRIES'
};

export function removePrintLabelsFromEntries() : Action {
    return {
        type: ActionTypes.REMOVE_PRINT_LABELS_FROM_ENTRIES
    }
}

export function printShelfLabels(printLabels: List<Record<PrintLabelInfo>>, form: PrintShelfLabelsForm, fileName: string) : PrintShelfLabelsAction{
    return {
        type: ActionTypes.GET_PRODUCT_LABELS_DOC,
        printLabels,
        form,
        fileName
    }
}

export function showPrintLabelsDialog() : Action {
    return {
        type: ActionTypes.SHOW_PRINT_LABELS_DIALOG
    }
}

export function showPrintWeightLabelsDialog() : Action {
    return {
        type: ActionTypes.SHOW_PRINT_WEIGHT_LABELS_DIALOG
    }
}

export function markEntryToPrintLabel(productNo: string, productSource: string, productDescription: string, numLabels: number, marked: boolean): MarkProductToPrintAction {
    return {
        type: ActionTypes.MARK_ENTRY_TO_PRINT_LABEL,
        productNo,
        productSource,
        productDescription,
        numLabels,
        marked
    };
}

export function openOrderLineForEdit(line: SalesOrderLine) : OpenOrderLineForEditAction {
    return {
        type: ActionTypes.OPEN_ORDER_LINE_FOR_EDIT,
        line
    }
}

export function validateConversionResponse(validation: Record<ConversionValidationResponse>) : ValidateConversionResponseAction {
    return {
        type: ActionTypes.VALIDATE_CONVERSION_RESPONSE,
        validation
    }
}

export function processExportToExcel(filter: Record<InventoryOverviewFilterFormData>, currentPage: List<Record<InventoryOverviewItem>>) : ExportToExcelAction {
    return {
        type: ActionTypes.PROCESS_EXPORT_TO_EXCEL,
        filter,
        currentPage
    }
}

export function updateOrder(order: UpdateSalesOrderForm) : UpdateOrderAction {
    return {
        type: ActionTypes.UPDATE_ORDER,
        order
    }
}

export function updateOrderLineInOrder(line: SalesOrderLine) : UpdateOrderLineAction {
    return {
        type: ActionTypes.UPDATE_ORDER_LINE,
        line
    }
}

export function putOrder(order: Record<OutgoingSalesOrderItem>) : PutOrderAction {
    return {
        type: ActionTypes.PUT_ORDER,
        order
    }
}

export function confirmOrder(order: UpdateSalesOrderForm) : ConfirmOrderAction {
    return {
        type: ActionTypes.CONFIRM_ORDER,
        order
    }
}

export function viewOutgoingOrder(orderNo: string, update: boolean, company: string) : OutgoingOrderAction {
    return {
        type: ActionTypes.VIEW_OUTGOING_ORDER,
        company,
        orderNo,
        update
    }
}

export function fetchOutgoingOrders(formData: OutgoingStockOrdersFilterForm, params: WebParams) : FetchOutgoingOrdersAction {
    return {
        type: ActionTypes.FETCH_OUTGOING_ORDERS,
        formData,
        params
    }
}

export function saveStock(formData: Record<StockCreationFormData>, productInfo: ProductInfo, finish: boolean, actionId: string) : SaveStockAction {
    return {
        type: ActionTypes.SAVE_STOCK,
        formData,
        productInfo,
        finish,
        actionId
    }
}

export function validateConversion(fromProductNo: string | undefined, toProductNo: string | undefined, fromQuantity: number | undefined, resetForm: boolean) : ChangeConversionProductAction {
    return {
        type: ActionTypes.CHANGE_CONVERSION_PRODUCT,
        fromProductNo,
        toProductNo,
        fromQuantity,
        resetForm
    }
}

export function convertStocks(formData: ConversionFormData, validation: Record<ConversionValidationResponse>, finishConversion: boolean, actionId: string) : ConvertStockAction {
    return {
        type: ActionTypes.CONVERT_STOCKS,
        formData,
        validation,
        finishConversion,
        actionId
    }
}

export function clearCorrections() : Action {
    return {
        type: ActionTypes.CLEAR_CORRECTIONS
    }
}

export function saveCorrections(corrections: List<StockCorrection>, sortFields: WebSortFields) : SaveCorrectionsAction {
    return {
        type: ActionTypes.SAVE_CORRECTIONS,
        corrections,
        sortFields
    }
}

export function fetchReasons() : Action {
    return {
        type: ActionTypes.FETCH_CORRECTION_REASONS
    }
}

export function fetchCorrectionActions() : Action {
    return {
        type: ActionTypes.FETCH_CORRECTION_ACTIONS
    }
}

export function findInventoryEntries(formData: InventoryOverviewFilterFormData, webParams: WebParams, clearCorrections: boolean) : FindInventoryEntriesAction {
    return { type: ActionTypes.FIND_INVENTORY_ENTRIES, formData, webParams, clearCorrections };
}

export function viewStockMovements(productNo: string, productDescription: string) : ViewStockMovementsAction {
    return { type: ActionTypes.VIEW_STOCK_MOVEMENTS, productNo, productDescription};
}

export function findStockMovements(data: StockMovementOverviewFilterFormData) : FindStockMovementsAction {
    return { type: ActionTypes.FIND_STOCK_MOVEMENTS, data };
}

export function processNewCorrection(correction: StockCorrection) : StockCorrectionAction {
    return {
        type: ActionTypes.PROCESS_NEW_CORRECTION,
        correction
    }
}

export function clearChanges(): ClearChangesAction {
    return {
        type: ActionTypes.CLEAR_CHANGES
    }
}

export function getProductLabelsDocument(productNo: string, companyCode: string, numLabels: number, fileName?: string): GetProductLabelsDocAction {
    return {
        type: ActionTypes.GET_PRODUCT_LABELS_DOC,
        productNo,
        companyCode,
        numLabels,
        fileName
    }
}

export interface OpenOrderLineForEditAction extends Action {
    line: SalesOrderLine
}

export interface ValidateConversionResponseAction extends Action {
    validation: Record<ConversionValidationResponse>
}

export interface SetConversionValidationResponseAction extends Action {
    response: Record<ConversionValidationResponse>
}

export interface ExportToExcelAction extends Action {
    filter: Record<InventoryOverviewFilterFormData>,
    currentPage: List<Record<InventoryOverviewItem>>
}

export interface UpdateOrderAction extends Action {
    order: UpdateSalesOrderForm
}

export interface UpdateOrderLineAction extends Action {
    line: SalesOrderLine
}

export interface ConfirmOrderAction extends Action {
    order: UpdateSalesOrderForm
}

export interface PutOrderAction extends Action {
    order: Record<OutgoingSalesOrderItem>
}

export interface OutgoingOrderAction extends Action {
    company: string,
    orderNo: string,
    update: boolean
}

export interface FetchOutgoingOrdersAction extends Action {
    formData: OutgoingStockOrdersFilterForm,
    params: WebParams
}

export interface SaveStockAction extends Action{
    formData: Record<StockCreationFormData>,
    productInfo: ProductInfo,
    finish: boolean,
    actionId: string
}

export interface ChangeConversionProductAction extends Action {
    fromProductNo: string | undefined,
    toProductNo: string | undefined,
    fromQuantity: number | undefined,
    resetForm: boolean
}

export interface ConvertStockAction extends Action {
    formData: ConversionFormData,
    validation: Record<ConversionValidationResponse>,
    finishConversion: boolean,
    actionId: string
}

export interface SaveCorrectionsAction extends Action {
    corrections: List<StockCorrection>,
    sortFields: WebSortFields
}

export interface StockCorrectionAction extends Action {
    correction: StockCorrection
}

export interface SetInventoryEntriesAction extends Action {
    entries: List<Record<InventoryOverviewItem>>
}

export interface FindInventoryEntriesAction extends Action {
    formData: InventoryOverviewFilterFormData,
    webParams: WebParams,
    clearCorrections:  boolean
}

export interface ViewStockMovementsAction extends Action {
    productNo: string,
    productDescription: string
}

export interface FindStockMovementsAction extends Action {
    data: StockMovementOverviewFilterFormData
}

export interface ClearChangesAction extends Action { }

export interface GetProductLabelsDocAction extends Action { 
    productNo: string;
    companyCode: string;
    numLabels: number;
    fileName?: string;
}

export interface MarkProductToPrintAction extends Action {
    productNo: string;
    productSource: string;
    productDescription: string;
    numLabels: number;
    marked: boolean;
}

export interface PrintShelfLabelsAction extends Action {
    printLabels: List<Record<PrintLabelInfo>>,
    form: PrintShelfLabelsForm,
    fileName: string
}

export default ActionTypes;