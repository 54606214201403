import {isImmutable} from "immutable";
import * as i18next from 'i18next'

export function requiredValidator() {
    return required;
}

export const required = (input: any) => {
    if (!input || input.length === 0 || (isImmutable(input) && input.isEmpty())) return i18next.t('retail.validation.required');
};

export const mandatoryPositiveNumber = (input: any) => {
    if (!input || input.length === 0 || (isImmutable(input) && input.isEmpty())) return i18next.t('retail.validation.required');
    return positiveNumber(input);
};

export const positiveNumber = (input: any) => {
    if (input) {
        const parsedInput = parseFloat(input);
        if (isNaN(parsedInput) || parsedInput < 0) return i18next.t('retail.validation.notPositiveNumber');
    }
};

export const positiveInteger = (input: any) => {
    if (input) {
        const parsedInput = parseFloat(input);
        if (isNaN(parsedInput) || parsedInput < 0 || !Number.isInteger(parsedInput)) return i18next.t('retail.validation.notPositiveNumber');
    }
};

export const mandatoryPositiveIntegerNumberFrom0To1000 = (input: any) => {
    if (required(input) || positiveInteger(input) || smallerNumber(input)) {
        return i18next.t('retail.validation.notIntegerValue');
    }
};

export const mandatoryPositiveNumberWithTwoDecimalFrom0To1000 = (input: any) => {
    if (required(input) || positiveNumber(input) || twoDecimalNumber(input) || smallerNumber(input)) {
        return i18next.t('retail.validation.twoDecimalAndRange');
    }
};

export const mandatoryPositiveNumberWithThreeDecimalFrom0To1000 = (input: any) => {
    if (required(input) || positiveNumber(input) || threeDecimalNumber(input) || smallerNumber(input)) {
        return i18next.t('retail.validation.threeDecimalAndRange');
    }
};


export const mandatoryPositiveIntegerNumber = (input: any) => {
    if (required(input) || positiveInteger(input)) {
        return i18next.t('retail.validation.notIntegerValue1');
    }
};

export const mandatoryPositiveNumberWithTwoDecimal = (input: any) => {
    if (required(input) || positiveNumber(input) || twoDecimalNumber(input)) {
        return i18next.t('retail.validation.twoDecimalAndRange1');
    }
};

export const mandatoryPositiveNumberWithThreeDecimal = (input: any) => {
    if (required(input) || positiveNumber(input) || threeDecimalNumber(input)) {
        return i18next.t('retail.validation.threeDecimalAndRange1');
    }
};

export function integerStockUomValidatorWithMandatoryPositiveNumberWithTwoDecimalFrom0To1000(stockUomIsInteger: boolean | null | undefined) {
    if (stockUomIsInteger === true) {
        return mandatoryPositiveIntegerNumberFrom0To1000;
    } else {
        return mandatoryPositiveNumberWithTwoDecimalFrom0To1000;
    }
}

export function integerStockUomValidatorWithMandatoryPositiveNumberWithThreeDecimalFrom0To1000(stockUomIsInteger: boolean | null | undefined) {
    if (stockUomIsInteger === true) {
        return mandatoryPositiveIntegerNumberFrom0To1000;
    } else {
        return mandatoryPositiveNumberWithThreeDecimalFrom0To1000;
    }
}

export function integerStockUomValidatorWithMandatoryPositiveNumberWithTwoDecimal(stockUomIsInteger: boolean | null | undefined) {
    if (stockUomIsInteger === true) {
        return mandatoryPositiveIntegerNumber;
    } else {
        return mandatoryPositiveNumberWithTwoDecimal;
    }
}

export function integerStockUomValidatorWithMandatoryPositiveNumberWithThreeDecimal(stockUomIsInteger: boolean | null | undefined) {
    if (stockUomIsInteger === true) {
        return mandatoryPositiveIntegerNumber;
    } else {
        return mandatoryPositiveNumberWithThreeDecimal;
    }
}


export const twoDecimalNumber = function (input: any) {
    if (input && Math.round(input * 100) / 100 != input)
        return i18next.t('retail.validation.twoDecimal')
};

export const threeDecimalNumber = function (input: any) {
    if (input && Math.round(input * 1000) / 1000 != input)
        return i18next.t('retail.validation.threeDecimal')
};

export const smallerNumber = function (input: any) {
    if (input > 1000)
        return i18next.t('retail.validation.smallerNumberWarning')
};