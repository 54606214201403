import React, { Reducer, useReducer, useEffect, useCallback } from "react";
import { List } from "immutable";

import PagerButtongroup from "./StepThroughPagerButtons";
import { WebParams, createWebParams, WebSortFields } from "../../remote/requestTypes";

/**
 * Pager component that "steps" through pages, by incrementing the page "offset" by
 * the number of items in the data-set
 */

type Props<T> = {
    data: T;
    size?: (data: T) => number;
    pageSize: number;
    alwaysShowPager?: boolean;
    sortFields?: WebSortFields;
    fetchNextData: (params: WebParams) => Promise<T>;
    render: (data: T) => React.ReactElement;
    reactTable?: boolean;
}

type State<T> = {
    data: T;
    countData: number;
    firstResult: number;
    isFetching: boolean;
};

enum ActionType {
    FETCH_BEGIN,
    RECEIVE_DATA,
    INIT
}

type Action<T> =
    | { type: ActionType.FETCH_BEGIN }
    | { type: ActionType.RECEIVE_DATA, data: T, countData: number, firstResult: number }
    | { type: ActionType.INIT; params: InitParams<T> };

type InitParams<T> = {
    data: T;
    countData: number;
}

function init<T>(params: InitParams<T>): State<T> {
    return {
        data: params.data,
        countData: params.countData,
        firstResult: 0,
        isFetching: false
    };
}

function reducer<T>(state: State<T>, action: Action<T>): State<T> {
    switch (action.type) {
        case ActionType.INIT:
            return init(action.params);

        case ActionType.FETCH_BEGIN:
            return { ...state, isFetching: true };

        case ActionType.RECEIVE_DATA:
            return {
                isFetching: false,
                data: action.data,
                countData: action.countData,
                firstResult: action.firstResult
            };
    }
}

function getCountData<T>(size: ((data: T) => number) | undefined, data: T): number {
    return size ? size(data) : (data as unknown as List<unknown>).size;
}

export default React.memo(function StepThroughPager<T>(props: Props<T>) {
    const { data, size, pageSize, fetchNextData, sortFields } = props;

    const countData = getCountData(size, data);
    const initParams: InitParams<T> = { data, countData };
    const [state, dispatch] = useReducer<Reducer<State<T>, Action<T>>, InitParams<T>>(reducer, initParams, init);

    useEffect(() => {
        // reset the state when the initial data changed
        if (state.data !== initParams.data || state.countData !== initParams.countData) {
            dispatch({ type: ActionType.INIT, params: initParams });
        }
    }, [data, countData]);

    // handle fetch page given by an "offset"
    const fetchPage = useCallback((pageNo: number) => {
        const webSortFields = sortFields || {};
        const pageParams: WebParams = createWebParams(pageNo, pageSize, webSortFields);
        dispatch({ type: ActionType.FETCH_BEGIN });
        fetchNextData(pageParams)
            .then((data) => {
                const countData = getCountData(size, data);
                dispatch({
                    type: ActionType.RECEIVE_DATA,
                    data,
                    countData,
                    firstResult: pageNo
                });
            });
    }, [state.firstResult, pageSize, sortFields, fetchNextData]);

    // handle move to the next page
    const onMoveNext = useCallback(() => {
        const firstResult = state.firstResult + 1;
        fetchPage(firstResult);
    }, [fetchPage, state.firstResult, state.countData]);

    // handle move to the previous page
    const onMovePrev = useCallback(() => {
        const firstResult = Math.max(0, state.firstResult - 1);
        fetchPage(firstResult);
    }, [fetchPage, state.firstResult]);

    // handle move to the first page
    const onMoveFirst = useCallback(() => {
        fetchPage(0);
    }, [fetchPage]);

    if(props.reactTable){
        return(
            <div className="d-flex flex-column h-100" style={{overflowY: "auto"}}>
            <div className="h-100" style={{overflowY: "auto"}}>
                { props.render(state.data) }
            </div>
            <PagerButtongroup
                onMoveFirst={onMoveFirst}
                onMovePrev={onMovePrev}
                onMoveNext={onMoveNext}
                canMovePrev={!state.isFetching && state.firstResult > 0}
                canMoveNext={!state.isFetching && state.countData >= pageSize}
            />
        </div>
        )
    }

    return (
        <div className="pager-container">
            <div className="pager-content">
                { props.render(state.data) }
            </div>
            <PagerButtongroup
                onMoveFirst={onMoveFirst}
                onMovePrev={onMovePrev}
                onMoveNext={onMoveNext}
                canMovePrev={!state.isFetching && state.firstResult > 0}
                canMoveNext={!state.isFetching && state.countData >= pageSize}
            />
        </div>
    );
}) as <T>(props: Props<T>) => React.ReactElement;
