import React, {useEffect, useMemo, useCallback, useState} from 'react';
import {InjectedFormProps, formValueSelector, reduxForm, SubmissionError} from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ButtonToolbar, Form } from 'react-bootstrap';
import { useFetcher, popLocation } from '@pearlchain/component-lib-common';

import { findProductUnitPrice, findProductUoms, findProductByNoAndCompanyCode } from '../../remote/api';
import {PurchaseOrderLineFormData, PurchaseOrderLineMode, PurchaseOrderMode} from '../../../types/procurementTypes';
import { createPurchaseOrderLineFormFields } from '../formFieldsCreator';
import Secured from '../../auth/Secured';
import FormFieldsLayout from '../../../form/FormFieldsLayout';
import { Auth } from '../../auth/functionalityCodes';
import { savePurchaseOrderLine, newPurchaseOrderLine } from './purchaseOrderLineActions';
import { RetailPriceResponse } from '../../remote/responseTypes';
import { Option } from '../../common/binders/SelectBinder';
import { warning } from "../../confirmation/warning";
import ActionButton from '../../actionButton/ActionButton';
import {getPurchaseOrder, getPurchaseOrderLines} from "../selectors";
import ConfirmAlert, {ConfirmableAlertOpts} from "../../common/ConfirmAlert";
import Alerts from "../../common/Alerts";
import { validateProductNoForUnique} from "./validateProductNoForUnique";

type Props = {
    mode: PurchaseOrderMode;
    lineMode: PurchaseOrderLineMode;
    companyCode: string;
    supplierUUID: string | undefined;
}

type WatchedFormFields = {
    productNo: string;
    quantity: number;
    purchaseOrderFromState: any;
    purchaseOrderLinesFromState: any;
}

export const NEW_PURCHASE_ORDER_LINE_FORM_ID = 'new-purchase-order-line-form';
export const CENTRALSUPPLIER = "COF001"

const selector = formValueSelector(NEW_PURCHASE_ORDER_LINE_FORM_ID);

function selectFormFields(state: any): WatchedFormFields {
    const productNo = selector(state, 'productNo');
    const quantity = selector(state, 'quantity');
    const purchaseOrderFromState = getPurchaseOrder(state);
    const purchaseOrderLinesFromState = getPurchaseOrderLines(state);
    return { productNo, quantity, purchaseOrderFromState, purchaseOrderLinesFromState };
}

function getPriceUoms(priceAndUom: RetailPriceResponse | undefined): Option<string>[] {
    const priceUoms: Option<string>[] = [];
    if (priceAndUom) {
        priceUoms.push({
            label: priceAndUom.uom,
            value: priceAndUom.uom
        });
    }
    return priceUoms;
}

function NewPurchaseOrderLine(props: Props & InjectedFormProps<Partial<PurchaseOrderLineFormData>, Props>) {

    const { companyCode, supplierUUID } = props;
    const { productNo, quantity, purchaseOrderFromState, purchaseOrderLinesFromState } = useSelector(selectFormFields);
    const [ confirmAlertOpts, setConfirmAlert] = useState<ConfirmableAlertOpts>();

    // fetch the product
    const { data: product } = useFetcher(useCallback(() => {
        return productNo ? findProductByNoAndCompanyCode(productNo, companyCode, false) : Promise.resolve(undefined)
    }, [productNo, companyCode]));

    // fetch the price and unit of measurement
    const { data: priceAndUom } = useFetcher(useCallback(() => {
        return product ? findProductUnitPrice(productNo, companyCode, quantity, supplierUUID) : Promise.resolve(undefined)
    }, [productNo, companyCode, quantity, supplierUUID, product]));

    // fetch the product unit of measurement
    const { data: productUoms } = useFetcher(useCallback(() => {
        return product ? findProductUoms(productNo, companyCode) : Promise.resolve(undefined);
    }, [productNo, companyCode, product]));

    // set the description from the API response
    useEffect(() => {
        if (product) {
            props.change('description', product.description);
            // set the purchaseOrderMultipleValue from the API response
            if(product.purchaseOrderMultipleValue){
                props.change('purchaseOrderMultipleValue', product.purchaseOrderMultipleValue);
            }
        }
    }, [product]);

    // set the price and price-uom from the API response
    useEffect(() => {
        if (productNo && priceAndUom) {
            props.change('price', priceAndUom.price);
            props.change('priceUom', priceAndUom.uom);

            if (!quantity) {
                setFocusOnField(NEW_PURCHASE_ORDER_LINE_FORM_ID, 'quantity', true)
            }
        }
    }, [productNo && priceAndUom, quantity]);

    // set the quantity-uom from the API response
    useEffect(() => {
        if (productUoms && productUoms.length) {
            props.change('quantityUom', productUoms[0].value);
        }
    }, [productUoms]);

    // resolve the available price uoms
    const priceUoms = useMemo(() => {
        return getPriceUoms(priceAndUom);
    }, [priceAndUom]);

    // construct the form-field config
    const { t } = useTranslation();
    const uomsEnabled = productNo.length > 0;
    const formFields = useMemo(() => {
        return createPurchaseOrderLineFormFields(t, productUoms || [], priceUoms,  uomsEnabled, props.lineMode, product, supplierUUID)
    }, [t, productUoms, priceUoms, uomsEnabled, product]);

    // save callback
    const dispatch = useDispatch();

    const doSubmit = (data: Partial<PurchaseOrderLineFormData>, isNewForm: boolean) => {
        data.purchaseOrderUuid = purchaseOrderFromState.get('uniqueIdentifier');
        if (product && product.purchaseOrderMultipleValue && supplierUUID === CENTRALSUPPLIER &&
            data.quantity &&  !isMultiple(data.quantity, product.purchaseOrderMultipleValue)) {

            if (isNewForm) {
                setFocusOnField(NEW_PURCHASE_ORDER_LINE_FORM_ID, 'quantity', false);
            }

            warning({translationFunction : t,
                title: t('retail.procurement.orderLine.purchaseOrderMultipleWarning.title'),
                message: t('retail.procurement.orderLine.purchaseOrderMultipleWarningQuantity.message', {
                    purchaseOrderMultipleValue: product.purchaseOrderMultipleValue
                }),
                okLabel: t('ok'),
            }).then(() => {
                console.log("Dismissing modal")
            })
        } else {
            dispatch(savePurchaseOrderLine(data));
            if (isNewForm) {
                dispatch(newPurchaseOrderLine(companyCode, supplierUUID, true));
                setTimeout(() => {
                    setFocusOnField(NEW_PURCHASE_ORDER_LINE_FORM_ID, 'productNo', true);
                }, 500);
            }
            else {
                dispatch(popLocation(1));
            }
        }
    };

    const handleSave = useCallback((data: Partial<PurchaseOrderLineFormData>) => {
        //Throw an error when the product has a status that is not allowed for purchase
        if(!product || !product.availableForPurchase){
            throw new SubmissionError({
                productNo: t('retail.procurement.orderLine.productNotInPurchaseAllowedStatus'),
                _error: t('retail.procurement.orderLine.productNotInPurchaseAllowedStatus.error')
            });
        }

        if (props.lineMode === PurchaseOrderLineMode.UPDATE) {
            doSubmit(data, false);
        } else {
            const message = validateProductNoForUnique(product, purchaseOrderLinesFromState, props.lineMode, t);
            if (message) {
                // validation failed. Show a confirmation warning before allowing the user to save.
                setConfirmAlert({
                    title: t('retail.purchase.newpurchaseorder.save.warning'),
                    message,
                    variant: 'warning',
                    onConfirm: () => {
                        // the alert was confirmed - "save anyway"
                        doSubmit(data, false);
                    }
                });
            } else {
                // validation passed without warnings, call save directly
                doSubmit(data, false);
            }
        }
    }, [dispatch, product]);

    // save and new callback
    const handleSaveAndNew = useCallback((data: Partial<PurchaseOrderLineFormData>) => {
        //Throw an error when the product has a status that is not allowed for purchase
        if(!product || !product.availableForPurchase){
            throw new SubmissionError({
                productNo: t('retail.procurement.orderLine.productNotInPurchaseAllowedStatus'),
                _error: t('retail.procurement.orderLine.productNotInPurchaseAllowedStatus.error')
            });
        }

        const message = validateProductNoForUnique(product, purchaseOrderLinesFromState, props.lineMode, t);
        if (message) {
            // validation failed. Show a confirmation warning before allowing the user to save.
            setConfirmAlert({
                title: t('retail.purchase.newpurchaseorder.save.warning'),
                message,
                variant: 'warning',
                onConfirm: () => {
                    // the alert was confirmed - "save anyway"
                    doSubmit(data, true);
                }
            });
        } else {
            // validation passed without warnings, call save directly
            doSubmit(data, true);
        }
    }, [dispatch, product, companyCode, supplierUUID]);


    //add event listener for enter and shift + enter
    useEffect(() => {
        function handleKeyPress(event: KeyboardEvent) {
            if (productNo && quantity && event.key === 'Enter' && isFormControlEvent(event)) {
                if (event.shiftKey) {
                    props.handleSubmit(handleSave)(event)
                } else {
                    props.handleSubmit(handleSaveAndNew)(event);
                }
            }
        }

        document.addEventListener('keydown', handleKeyPress);
        return function cleanup() {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, [handleSave, handleSaveAndNew, productNo, quantity]);

    return (
        <Form className="page-container">
            <ButtonToolbar className="mt-2 mb-3">
                <Secured code={Auth.SAVE_PURCHASE_ORDER_LINE}>
                    <ActionButton
                        variant="primary"
                        onAction={props.handleSubmit(handleSave)}
                    >
                        {t('retail.procurement.button.save')}
                    </ActionButton>
                </Secured>
                <Secured code={Auth.SAVE_PURCHASE_ORDER_LINE}>
                    <ActionButton
                        variant="secondary"
                        onAction={props.handleSubmit(handleSaveAndNew)}>
                        {t('retail.procurement.button.saveAndNew')}
                    </ActionButton>
                </Secured>                    
                <ActionButton
                    variant="secondary"
                    onAction={props.reset}>
                    {t('retail.procurement.button.clear')}
                </ActionButton>
            </ButtonToolbar>
            <FormFieldsLayout fields={formFields} numCols={{ xs: 1, sm: 2, md: 2, lg: 2 }}/>
            <Alerts/>
            <ConfirmAlert alert={confirmAlertOpts}/>
        </Form>
    )
}

export default reduxForm<Partial<PurchaseOrderLineFormData>, Props>({
    enableReinitialize: true,
    form: NEW_PURCHASE_ORDER_LINE_FORM_ID
})(NewPurchaseOrderLine);

function isFormControlEvent(event: KeyboardEvent) : boolean {
    // @ts-ignore
    return event && event.target && event.target.className === "form-control"
}

function setFocusOnField(formId: string, fieldName: string, focused: boolean) {
    const input: any = document.getElementById(`${formId}-${fieldName}`);

    if (input) {
        if (focused)
            input.focus();
        else
            input.blur();
    }
}

function isMultiple(quantity:number, multiple:number) {
    const quant = Number(quantity);
    const mult = Number(multiple);

    if(mult > quant) return false;

    const result = (quant / mult).toFixed(8);

	if((result as any) % 1 === 0){
    	return true;
    }
    else {
    	return false;
    }
}
