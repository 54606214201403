import { contextMenuExtension } from './contextMenuExtension'
import { createFormattingExtension } from './formattingExtension'

export const EXTS = {
    STYLING: 'styling',
    RESIZING: 'resizing',
    EDITING: 'editing',
    SORTING: 'sorting',
    SELECTION: 'selection',
    COLUMN_SIZING: 'column-sizing',
    DIRECT_INPUT: 'direct-input',
    FORMATTING: 'formatting',
    ACTION_BUTTONS: 'actionbuttons',
    DROPDOWN: 'dropDownList',
    NESTED_HEADERS: 'nested-headers',
    CONTEXT_MENU: 'dummy'
};

export function createExtensions(exts, callbacks, formId, context) {
    const extensions = {};

    if (exts) {
        for (let key in exts) {
            if (exts.hasOwnProperty(key)) {
                extensionBuilders[key](extensions, callbacks, exts[key], formId, context);
            }
        }
    }

    return extensions;
}


const extensionBuilders = {
    [EXTS.STYLING](extensions, callbacks) {
        extensions.styling = {
            applyClasses: callbacks.onApplyClasses
        };
    },

    [EXTS.RESIZING](extensions) {
        extensions.columnsizing = true
    },

    [EXTS.EDITING](extensions) {
        extensions.editing = {
            commitOnBlur: true
        };
    },

    [EXTS.SORTING](extensions, callbacks) {
        extensions.sorting = {
            multiSort: true,
            onSortColumnsChanged: callbacks.onSortColumnsChanged
        };
    },

    [EXTS.FORMATTING](extensions, callbacks, current_extension, formId, context) {
        createFormattingExtension(extensions, context)
    },

    [EXTS.SELECTION](extensions, callbacks) {
        extensions.selection = {
            onrowselected: callbacks.onRowSelected
        };
    },

    [EXTS.COLUMN_SIZING](extensions) {
        extensions.columnsizing = true;
    },

    [EXTS.DIRECT_INPUT](extensions) {
        extensions.directinput = true;
    },

    [EXTS.ACTION_BUTTONS](extensions) {
        extensions.actionbuttons = true;
    },

    [EXTS.DROPDOWN](extensions, dataList, ext) {
        extensions.dropDownList = ext
    },

    [EXTS.CONTEXT_MENU](extensions) {
        extensions.dummy = function() {
            contextMenuExtension('ctx-menu-id').apply(this, arguments);
        }
    },

    [EXTS.NESTED_HEADERS](extensions) {
        extensions.templating = true;
        extensions.nestedheaders = true;
    }
};
