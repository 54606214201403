import React, { useMemo } from 'react';
import { Form, ButtonToolbar } from 'react-bootstrap';
import { connect } from 'react-redux';
import { pushLocation } from '@pearlchain/component-lib-common';
import { FormFieldsLayout } from '@pearlchain/component-lib-ui';
import { reduxForm, InjectedFormProps } from 'redux-form';
import { Record, List } from 'immutable';

import { PurchaseOrderFormData, PurchaseOrderLineFormData } from '../../../types/procurementTypes';
import { StoreState } from '../../../types/storeTypes';
import ReceivePurchaseOrderLineGrid from './ReceivePurchaseOrderLineGrid';
import { getPurchaseOrder, getPurchaseOrderLines } from '../selectors';
import { createReceivePurchaseOrderFormFields } from '../formFieldsCreator';
import ActionTypes, { storeReceivePurchaseOrderLineChange, registerPurchaseOrderLines, registerAllPurchaseOrderLines, markAllPurchaseOrderLinesRegistered } from '../actions';
import Alerts from '../../common/Alerts';
import { Auth } from '../../auth/functionalityCodes';
import Secured from '../../auth/Secured';
import { determinePurchaseOrderLineCurrency, hasReceiptMasterLines } from '../helpers';
import { useTranslation } from "react-i18next";
import AsyncActionButton from '../../actionButton/AsyncActionButton';

export interface ReceivePurchaseOrderProps extends DispatchProps {
    purchaseOrderLines?: List<Record<PurchaseOrderLineFormData>>,
    purchaseOrder?: Record<PurchaseOrderFormData>,
    storeReceivePurchaseOrderLineChange: typeof storeReceivePurchaseOrderLineChange,
    markAllPurchaseOrderLinesRegistered: typeof markAllPurchaseOrderLinesRegistered,
    registerPurchaseOrderLines: () => void
}

interface DispatchProps {
    pushLocation: typeof pushLocation,
}

export function ReceivePurchaseOrder(props: InjectedFormProps<PurchaseOrderFormData, ReceivePurchaseOrderProps> & ReceivePurchaseOrderProps) {

        const {t} = useTranslation();

        const formFields = useMemo(() => {
            return createReceivePurchaseOrderFormFields();
        }, []);

        return (
            <Form className="page-container">
                <ButtonToolbar className="mt-2 mb-3">
                    <Secured code={Auth.REGISTER_PURCHASE_ORDER_LINE}>
                        <AsyncActionButton
                            variant="primary" 
                            actionId={ActionTypes.REGISTER_PURCHASE_ORDER_LINES} 
                            type="submit"
                            onAction={props.handleSubmit(props.registerPurchaseOrderLines)} 
                            disabled={hasReceiptMasterLines(props.purchaseOrder?.toJS() as PurchaseOrderFormData)}
                        >
                            {t('retail.procurement.button.register')}
                        </AsyncActionButton>
                    </Secured>
                </ButtonToolbar>
                <FormFieldsLayout fields={formFields} numCols={4}/>
                <Alerts/>
                <ReceivePurchaseOrderLineGrid
                    purchaseOrderLines={props.purchaseOrderLines}
                    purchaseOrderLineCurrency={determinePurchaseOrderLineCurrency(props)}
                    onValueChanged={props.storeReceivePurchaseOrderLineChange}
                />
            </Form>
        );
}

const initializeQuantityDelivered = (lines: List<Record<PurchaseOrderLineFormData>>) : List<Record<PurchaseOrderLineFormData>> => {
    return lines.map((value: Record<PurchaseOrderLineFormData>) => {
        return value.withMutations((pol: Record<PurchaseOrderLineFormData>) => {
            if (pol.get('quantityDelivered') == null) {
                if (pol.get('receiptMasterLines').size > 0) {
                    // according to the domain model, we can have multiple master lines per po line, for now we take the first one
                    const receiptMasterLine = pol.get('receiptMasterLines').get(0)!;
                    pol.set('quantityDelivered', receiptMasterLine.get('quantity'))
                } else {
                    pol.set('quantityDelivered', 0)
                }
            }
        })
    }); 
}

const mapStateToProps = (state: StoreState) => {
    const purchaseOrder = getPurchaseOrder(state);
    const purchaseOrderLines = getPurchaseOrderLines(state);
    let lines;
    let initialValues = {};
    if (purchaseOrder && purchaseOrderLines) {
        initialValues = {
            no: purchaseOrder.get('no')
        }
        lines = initializeQuantityDelivered(purchaseOrderLines);
    }
    return {
        initialValues,
        purchaseOrderLines: lines,
        purchaseOrder
    }            
}

const ReceivePurchaseOrderForm = reduxForm<PurchaseOrderFormData, ReceivePurchaseOrderProps>({
    form: 'receive-purchaseorder-form'
})(ReceivePurchaseOrder)

export default connect((state: StoreState) => {
    return mapStateToProps(state);
}, {
    pushLocation,
    storeReceivePurchaseOrderLineChange,
    markAllPurchaseOrderLinesRegistered,
    registerPurchaseOrderLines,
    registerAllPurchaseOrderLines,
})(ReceivePurchaseOrderForm);
