import React, { useState, useEffect, useMemo } from 'react';
import { Input } from 'reactstrap';
import { BaseBinderProps } from "../../common/binders/types";

type Props = {
    onChange?: (input: number) => void,
    formatter?: (value: string) => string
} & BaseBinderProps;

export default function NumberBinder({ id, disabled, datacy, formatter, input, onChange }: Props) {

    // apply the formatter to the input
    const valueFormatted = useMemo(() =>  formatter ? formatter(input.value) : input.value, [formatter, input.value]);

    // keep track of the current input state
    const [value, setValue] = useState(valueFormatted);

    // reset the state when a new input value was passed in
    useEffect(() => {
        if (value !== valueFormatted) setValue(valueFormatted);
    }, [valueFormatted]);

    function handleBlur() {
        input.onChange(value);
        input.onBlur(value);
        if (onChange) onChange(value);
    }

    function handleChange(e: any) {
        setValue(e.target.value);
    }

    function handleKeyPress(e: React.KeyboardEvent<any>) {
        if (e.key === 'Enter') {
            input.onChange(value);
            if (onChange) onChange(value);
        }
    }

    return <Input id={id} data-cy={datacy}
                  type={"number"}
                  disabled={!!disabled}
                  {...input}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  onKeyPress={handleKeyPress}
                  value={value}/>
}
