import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ActionButton, { ActionButtonProps } from "./ActionButton";

export type AsyncActionButtonProps = ActionButtonProps & {
    busy?: boolean;
    children: React.ReactChild;
}

/**
 * An Action-Button that displays a loading spinner while an action is in progress.
 */

export default function AsyncActionButtonControlled({ children, busy, ...buttonProps }: AsyncActionButtonProps) {
    let disabled = busy || buttonProps.disabled || false;
    return (
        <ActionButton
            {...buttonProps}
            disabled={disabled}
        >
            <>
                <span className={busy ? 'hidden' : ''}>
                    {children}
                </span>
                {busy && (
                    <div className="prl-action-btn-spinner">
                        <FontAwesomeIcon icon="spinner" spin/>
                    </div>
                )}
            </>
        </ActionButton>
    );
}
