import {List, Record} from "immutable";
//import {ProductFilterFormData} from "../../types/productTypes";
import {WebParams} from '../remote/requestTypes';
import {ActionButtonEvent} from "../../types/gridTypes";
import {
    AddNewProductComponentAction,
    AddProductComponentAction, DeleteProductComponentAction, GetDefaultCompanyCurrencyAction,
    NewProductAction, ProductComponentFormData,
    ProductFormData,
    ProductMode, SaveAndNewProductAction, SaveAndNewProductComponentAction,
    SaveProductAction, SaveProductComponentAction, StoreDefaultCompanyCurrencyAction,
    StoreProductAction, StoreProductComponentAction, StoreProductComponentIdxAction, StoreProductComponentsAction,
    UpdateProductAction, UpdateProductComponentAction, ValueData,
    ViewProductAction, FindAllCompaniesAction, GenerateProductNoAction,
    ProductFilterFormData, ListProductsAction, StoreVatInfoAction, ProcessExportToExcelAction
} from "../../types/productTypes";

const ActionTypes = {
    NEW_PRODUCT: 'product.NEW_PRODUCT',
    LIST_PRODUCTS: 'product.LIST_PRODUCTS',
    STORE_VAT_INFO: 'product.STORE_VAT_INFO',
    CLEAR_PRODUCT_DATA: 'product.CLEAR_PRODUCT_DATA',
    CLEAR_PRODUCT_COMPONENT_DATA: 'product.CLEAR_PRODUCT_COMPONENT_DATA',
    CLEAR_PRODUCT_COMPONENT_IDX_DATA: 'product.CLEAR_PRODUCT_COMPONENT_IDX_DATA',
    CLEAR_PRODUCT_COMPONENTS_DATA: 'product.CLEAR_PRODUCT_COMPONENTS_DATA',
    SAVE_PRODUCT: 'product.SAVE_PRODUCT',
    UPDATE_PRODUCT: 'product.UPDATE_PRODUCT',
    VIEW_PRODUCT: 'product.VIEW_PRODUCT',
    STORE_PRODUCT: 'product.STORE_PRODUCT',
    STORE_PRODUCT_COMPONENT: 'product.STORE_PRODUCT_COMPONENT',
    STORE_PRODUCT_COMPONENT_IDX: 'product.STORE_PRODUCT_COMPONENT_IDX',
    STORE_PRODUCT_COMPONENTS: 'product.STORE_PRODUCT_COMPONENTS',
    FIND_PRODUCT_DESCRIPTION: 'product.FIND_PRODUCT_DESCRIPTION',
    STORE_PRODUCT_DESCRIPTION: 'product.STORE_PRODUCT_DESCRIPTION',
    STORE_PRODUCT_UOM: 'product.STORE_PRODUCT_UOM',
    STORE_PRODUCT_SALES_PRICE: 'product.STORE_PRODUCT_SALES_PRICE',
    STORE_PRODUCT_CURRENCY: 'product.STORE_PRODUCT_CURRENCY',
    SAVE_AND_NEW_PRODUCT: 'product.SAVE_AND_NEW_PRODUCT',
    SAVE_PRODUCT_COMPONENT: 'product.SAVE_PRODUCT_COMPONENT',
    SAVE_AND_NEW_PRODUCT_COMPONENT: 'product.SAVE_AND_NEW_PRODUCT_COMPONENT',
    GENERATE_PRODUCT_NO: 'product.GENERATE_PRODUCT_NO',
    ADD_PRODUCT_COMPONENT: 'product.ADD_PRODUCT_COMPONENT',
    UPDATE_PRODUCT_COMPONENT: 'product.UPDATE_PRODUCT_COMPONENT',
    DELETE_PRODUCT_COMPONENT: 'product.DELETE_PRODUCT_COMPONENT',
    ADD_NEW_PRODUCT_COMPONENT: 'product.ADD_NEW_PRODUCT_COMPONENT',
    VIEW_PRODUCT_COMPONENT: 'product.VIEW_PRODUCT_COMPONENT',
    GET_DEFAULT_COMPANY_CURRENCY: 'product.GET_DEFAULT_COMPANY_CURRENCY',
    STORE_DEFAULT_COMPANY_CURRENCY: 'product.STORE_DEFAULT_COMPANY_CURRENCY',
    FIND_ALL_COMPANIES: 'procurement.FIND_ALL_COMPANIES',
    PROCESS_EXPORT_EXCEL: 'procurement.PROCESS_EXPORT_EXCEL'
};

export function processExportToExcel(no: string, description: string, components: List<Record<ProductComponentFormData>>,productPriceIncludingVatValue: string, productPriceExcludingVatValue: string) : ProcessExportToExcelAction {
    return {
        type: ActionTypes.PROCESS_EXPORT_EXCEL,
        no,
        description,
        components,
        productPriceIncludingVatValue,
        productPriceExcludingVatValue
    }
}

export function listProducts(formData: ProductFilterFormData, webParams: WebParams): ListProductsAction {
    return {type: ActionTypes.LIST_PRODUCTS, formData, webParams};
}

export function storeVatInfo(vatCountryCode: string|null): StoreVatInfoAction {
    return {type: ActionTypes.STORE_VAT_INFO, vatCountryCode};
}

export function newProduct(): NewProductAction {
    return {type: ActionTypes.NEW_PRODUCT};
}

export function clearProductData() {
    return {type: ActionTypes.CLEAR_PRODUCT_DATA};
}

export function clearProductComponentData() {
    return {type: ActionTypes.CLEAR_PRODUCT_COMPONENT_DATA};
}

export function clearProductComponentIdxData() {
    return {type: ActionTypes.CLEAR_PRODUCT_COMPONENT_IDX_DATA};
}

export function clearProductComponentsData() {
    return {type: ActionTypes.CLEAR_PRODUCT_COMPONENTS_DATA};
}

export function saveProduct(data: Record<ProductFormData>, mode: ProductMode, actionButtonId: string, setUUID?: (uuid: string) => void): SaveProductAction {
    return {type: ActionTypes.SAVE_PRODUCT, data, mode, actionButtonId, setUUID};
}

export function updateProduct(event: ActionButtonEvent, vatCountryCode: string|null): UpdateProductAction {
    const productUUID = event.row.id;
    return {type: ActionTypes.UPDATE_PRODUCT, productUUID, vatCountryCode};
}

export function viewProduct(event: ActionButtonEvent, vatCountryCode: string|null): ViewProductAction {
    const productUUID = event.row.id;
    return {type: ActionTypes.VIEW_PRODUCT, productUUID, vatCountryCode};
}

export function saveAndNewProduct(data: Record<ProductFormData>, mode: ProductMode): SaveAndNewProductAction {
    return {type: ActionTypes.SAVE_AND_NEW_PRODUCT, data, mode};
}

export function storeProduct(data: Record<ProductFormData>): StoreProductAction {
    return {type: ActionTypes.STORE_PRODUCT, data};
}

export function storeProductComponent(data: Record<ProductComponentFormData>): StoreProductComponentAction {
    return {type: ActionTypes.STORE_PRODUCT_COMPONENT, data};
}

export function storeProductComponentIdx(index: string): StoreProductComponentIdxAction {
    return {type: ActionTypes.STORE_PRODUCT_COMPONENT_IDX, index};
}

export function addNewProductComponent(data: Record<ProductComponentFormData>): AddNewProductComponentAction {
    return {type: ActionTypes.ADD_NEW_PRODUCT_COMPONENT, data};
}

export function storeProductComponents(data: List<Record<ProductComponentFormData>>): StoreProductComponentsAction {
    return {type: ActionTypes.STORE_PRODUCT_COMPONENTS, data};
}

export function addProductComponent(openModalFn: () => void): AddProductComponentAction {
    return {type: ActionTypes.ADD_PRODUCT_COMPONENT, openModalFn};
}

export function saveProductComponent(data: Record<ProductComponentFormData>, mode: ProductMode, newProductComponent: boolean): SaveProductComponentAction {
    return {type: ActionTypes.SAVE_PRODUCT_COMPONENT, data, mode, newProductComponent};
}

export function saveAndNewProductComponent(data: Record<ProductComponentFormData>, mode: ProductMode, reset: () => void, newProductComponent: boolean): SaveAndNewProductComponentAction {
    return {type: ActionTypes.SAVE_AND_NEW_PRODUCT_COMPONENT, data, mode, reset, newProductComponent};
}

export function generateProductNo(form: string, field: string): GenerateProductNoAction {
    return { type: ActionTypes.GENERATE_PRODUCT_NO, form, field };
}

export function updateProductComponent(event: ActionButtonEvent, openModalFn: () => void, mode: ProductMode): UpdateProductComponentAction {
    const productComponentIdx = event.row.id;
    return {type: ActionTypes.UPDATE_PRODUCT_COMPONENT, productComponentIdx, openModalFn, mode};
}

export function deleteProductComponent(event: ActionButtonEvent, mode: ProductMode): DeleteProductComponentAction {
    const productComponentIdx = event.row.id;
    return {type: ActionTypes.DELETE_PRODUCT_COMPONENT, productComponentIdx, mode};
}

export function getDefaultCompanyCurrency(companyCode: string) : GetDefaultCompanyCurrencyAction {
    return { type: ActionTypes.GET_DEFAULT_COMPANY_CURRENCY, companyCode }
}

export function storeDefaultCompanyCurrency(currency: Record<ValueData>) : StoreDefaultCompanyCurrencyAction {
    return { type: ActionTypes.STORE_DEFAULT_COMPANY_CURRENCY, currency };
}

export function findAllCompanies() : FindAllCompaniesAction {
    return { type: ActionTypes.FIND_ALL_COMPANIES }    
}

export default ActionTypes;
