import React, { useMemo } from 'react';
import { Button, ButtonToolbar, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { connectDialog, DialogProps } from '../../../dialog/connectDialog';
import { InjectedFormProps, reduxForm } from "redux-form";
import { PrintShelfLabelsForm, Option } from "../../../../types/inventoryTypes";
import { FormFieldConfig, FormFieldsLayout } from "@pearlchain/component-lib-ui";
import NumberBinder from "../../binders/NumberBinder";
import { ModalBody, ModalFooter } from "reactstrap";
import { List } from "immutable";
import { mandatoryPositiveNumber } from "../../../util/validators";
import { formFieldConfig } from '../../../../form/types';

export const PRINT_LABEL_GIFT_DIALOG_ID = 'print-label-gift';

interface PrintLabelDialogProps extends DialogProps {
    initialValues: any,
    onConfirm: (nos: List<String>, form: PrintShelfLabelsForm) => void
}

function formFieldsCreator() {
    const formFields: { [key: string]: FormFieldConfig } = {
        numberOfCopies: formFieldConfig({ label: 'retail.inventory.printLabels.numLabels', translateLabel: true, binder: NumberBinder, validate: mandatoryPositiveNumber})
    };
    return formFields;
}

function PrintLabelForGiftModal(props: InjectedFormProps<PrintShelfLabelsForm, PrintLabelDialogProps> & PrintLabelDialogProps) {
    const { t } = useTranslation();

    const products: List<Option> = props.params && props.params.toJS() || List();

    const formFields = useMemo(() => {
        return formFieldsCreator();
    }, []);

    return (
        <Modal show={props.show} onHide={props.onHide} onExited={props.onClear}>
            <Modal.Header closeButton>
                {t('retail.inventory.dialog.printLabels.gift.title', {
                    productNos: products.map(product => product.label).toString()
                })}
            </Modal.Header>
            <ModalBody>
                <FormFieldsLayout fields={formFields} numCols={{ xs: 1, sm: 2, md: 2, lg: 2 }}/>
            </ModalBody>
            <ModalFooter>
                <ButtonToolbar className="mt-2 mb-3">
                    <Button variant="primary" type="submit" className="mr-2" disabled={props.invalid}
                            onClick={props.handleSubmit((form: PrintShelfLabelsForm) => {
                                props.onConfirm(products.map(product => product.value), form);
                                props.reset();
                                props.onHide();
                            })}>
                        {t('retail.inventory.dialog.printLabels.confirm')}
                    </Button>
                </ButtonToolbar>
            </ModalFooter>
        </Modal>
    );
}

const PrintLabelsForGiftModalForm = reduxForm<PrintShelfLabelsForm, PrintLabelDialogProps>({
    form: PRINT_LABEL_GIFT_DIALOG_ID
})(PrintLabelForGiftModal);

export default connectDialog(PRINT_LABEL_GIFT_DIALOG_ID)(PrintLabelsForGiftModalForm);
