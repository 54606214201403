import React, { useEffect } from "react";
import { connect } from "react-redux";
import Notifications, { error } from 'react-notification-system-redux';
import {createNotifyOnErrorResponseHandler, RequestBuilder} from "@pearlchain/component-lib-common";
import { StoreState } from "./types/storeTypes";

type Props = {
    notifications: any;
    error: typeof error;
}

function Nots(props: Props) {
    useEffect(() => {
        const opts = RequestBuilder.Opts;
        opts.responseHandlers.push(createNotifyOnErrorResponseHandler(props.error));
    }, [props.error]);

    return <Notifications notifications={props.notifications}/>
}

export default connect((state: StoreState) => ({
    notifications: state.notifications
}), { error })(Nots);
