import { procurementOverview, newPurchaseOrder, receivePurchaseOrder, newPurchaseOrderLine } from '../procurement/procurementPage';
import { inventoryOverview, stockCreation, stockConversion, stockMovementsOverview, outgoingStockOrdersOverview, detailedOutgoingOrder, updateOrderLine } from "../inventory/inventoryPages";
import { Page } from '../../types/pageTypes';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import {productPage, productOverview} from "../product/productPage";

library.add(faHome)


export const home: Page = {
    title: 'home.title',
    icon: 'home',
    path: '/'
};

export const pages: Page[] = [
    home,
    procurementOverview,
    inventoryOverview,
    outgoingStockOrdersOverview,
    detailedOutgoingOrder,
    updateOrderLine,
    stockMovementsOverview,
    stockConversion,
    stockCreation,
    newPurchaseOrder,
    newPurchaseOrderLine,
    receivePurchaseOrder,
    productOverview,
    productPage
];

export const pagesByPathname = new Map();
for(let page of pages) {
    pagesByPathname.set(page.path, page);
}
