import { Record, List } from 'immutable'
import { Action } from 'redux'
import { Moment } from 'moment'
import { LocationItem } from '@pearlchain/component-lib-common'
import { WebSortFields } from '../components/remote/requestTypes';
import { Option } from "./inventoryTypes";

export const DEFAULT_PAGE_SIZE: number = 20;

export interface PurchaseOrderFilterFormData {
    companies?: List<string>,
    purchaseNumber?: string,
    statuses?: List<string>,
    beginDate?: string | Moment,
    endDate?: string | Moment,
    productNo?: string,
    pageNumber?: number,
    pageSize?: number,
    sortField?: string,
    sortType?: string
}

export interface PurchaseOrderFormData {
    uniqueIdentifier: string | undefined,
    version: number,
    companyCode: string,
    no: string,
    description: string,
    status: string,
    supplier: Record<SupplierIdentifier>,
    requestedDeliveryDate: string,
    cancellationReason: string,
    currency: string,
    interCompanyTransaction?: boolean,
    lines: List<Record<PurchaseOrderLineFormData>>
}

export interface PurchaseOrderLineFormData {
    id: number | undefined,
    uniqueIdentifier: string | undefined,
    version: number | undefined,
    no: string | undefined,
    productNo: string,
    description: string,
    quantity: number | undefined,
    quantityUom: string,
    quantityWithUom: string,
    price: number | undefined,
    priceUom: string,
    priceWithUom: string,
    totalPrice: number | undefined,
    totalPriceWithUom: string,
    quantityDelivered: number | undefined,
    toBeRegistered: boolean,
    toBeDeleted: boolean,
    receiptMasterLines: List<Record<ReceiptMasterLine>>
    internalId?: string, // the index of the record in the grid (only filled in for unsaved records)
    stockUomIsInteger: boolean | undefined,
    purchaseOrderUuid: string
}

export interface ReceiptMasterLine {
    uniqueIdentifier: string,
    quantity: number
    registered: boolean
}

export interface ValueData {
    value: string
}

export enum PurchaseOrderLineProperty {
    UNIQUE_IDENTIFIER = 'uniqueIdentifier',
    PRODUCT_NO = 'productNo',
    DESCRIPTION = 'description',
    QUANTITY = 'quantity',
    UNIT_PRICE = 'unitPrice',
    PRICE = 'price',
    QUANTITY_DELIVERED = 'quantityDelivered',
    TO_BE_REGISTERED = 'toBeRegistered'    
}

export interface CancelPurchaseOrderFormData {
    uniqueIdentifier: string,
    companyCode: string,
    reason: string
}

export interface RegisterPurchaseOrderLineData {
    supplierUniqueIdentifier: string,
    companyCode: string,
    lines: List<Record<PurchaseOrderLineFormData>>
}

export interface CalculatePurchaseOrderLinePriceData {
    productNo: string,
    companyCode: string,
    quantity: number,
    quantityUom: string,
    unitPrice: number,
    unitPriceUom: string
}

export interface NotificationAction extends Action {
    message: string
}

export interface NewPurchaseOrderAction extends Action {
    location: LocationItem
}

export interface SavePurchaseOrderAction extends Action {
    data: PurchaseOrderFormData,
    mode: PurchaseOrderMode,
    location: LocationItem
}

export interface SavePurchaseOrderIntermediaryAction extends Action {
    data: Record<PurchaseOrderFormData>
}

export interface FindPurchaseOrdersAction extends Action {
    data: PurchaseOrderFilterFormData,
    sortFields: WebSortFields
}

export interface SetPurchaseOrdersAction extends Action {
    orders: List<Record<PurchaseOrderFormData>>
}

export interface UpdatePurchaseOrderInGridAction extends Action {
    order: Record<PurchaseOrderFormData>
}

export interface UpdatePurchaseOrderAction extends Action {
    purchaseOrderId: string,
    location: LocationItem
}

export interface NewPurchaseOrderLineAction extends Action {
    mode: PurchaseOrderMode,
    supplierUUID: string,
    validPOHeader: boolean,
    newPurchaseOrderLineLocation: LocationItem
}

export interface UpdatePurchaseOrderLineAction extends Action {
    purchaseOrderLineUuid: string,
    mode: PurchaseOrderMode,
    newPurchaseOrderLineLocation: LocationItem
}

export interface DeletePurchaseOrderLineAction extends Action {
    purchaseOrderLineUuid: string,
    mode: PurchaseOrderMode
}

export interface ViewPurchaseOrderAction extends Action {
    purchaseOrderUuid: string,
    location: LocationItem
}

export interface ReceivePurchaseOrderAction extends Action {
    purchaseOrderUuid: string,
    location: LocationItem
}

export interface CancelPurchaseOrderAction extends Action {
    data: Record<CancelPurchaseOrderFormData>,
    location: LocationItem
}

export interface StoreReceivePurchaseOrderLineChangeAction extends Action {
    purchaseOrderLineUuid: string,
    columnIndex: number,
    value: string
}

export interface MarkAllPurchaseOrderLinesRegisteredAction extends Action {
    registered: boolean;
}

export interface SendPurchaseOrderAction extends Action {
    data: Record<PurchaseOrderFormData>
}

export interface SavePurchaseOrderLineAction extends Action {
    data: Record<PurchaseOrderLineFormData>,
    mode: PurchaseOrderMode,
    newPurchaseOrderLineFlag?: boolean,
    newLocation: LocationItem 
}

export interface SaveAndNewPurchaseOrderLineAction extends Action {
    data: Record<PurchaseOrderLineFormData>
}

export interface StorePurchaseOrderAction extends Action {
    data: Record<PurchaseOrderFormData>
}

export interface StoreCompanyCurrencyAction extends Action {
    currency: Record<ValueData>
}

export interface StoreDefaultCompanySupplierAction extends Action {
    supplier: Record<Option>
}

export interface StoreValidationMessageAction extends Action {
    message: Record<ValidationMessage>
}

export interface ClearValidationMessagesAction extends Action {
}

export interface StorePurchaseOrderLinesAction extends Action {
    data: List<Record<PurchaseOrderLineFormData>>
}

export interface FindAllCompaniesAction extends Action {
}

export interface GetDefaultCompanyCurrencyAction extends Action {
    companyCode: string
}

export interface GetDefaultCompanySupplierAction extends Action {
}

export interface ValidationMessage {
    message: string,
    type: ValidationMessageType
}

export interface SupplierIdentifier {
    uniqueIdentifier: string,
    name: string,
    no: string
}

export interface StoreStateProcurement {
    searchResult: List<Record<PurchaseOrderFormData>>,
    purchaseOrder?: Record<PurchaseOrderFormData>,
    purchaseOrderLines: List<Record<PurchaseOrderLineFormData>>,
    purchaseOrderLineIndex?: number,
    companyCurrency?: string,
    defaultCompanySupplier?: Record<SupplierIdentifier>,
    validationMessages: List<Record<ValidationMessage>>
}

export const createProcurementState = Record<StoreStateProcurement>({
    searchResult: List<Record<PurchaseOrderFormData>>(),
    purchaseOrder: undefined,
    purchaseOrderLines: List<Record<PurchaseOrderLineFormData>>(),
    purchaseOrderLineIndex: -1, // the result index the purchase-order-line will be saved to
    companyCurrency: undefined,
    defaultCompanySupplier: undefined,
    validationMessages: List<Record<ValidationMessage>>()
})

export const createSupplierIdentifier = Record<SupplierIdentifier>({
   uniqueIdentifier: "",
   name: "",
   no: ""
});

// make a PurchaseOrderForm Record with default values
export const createPurchaseOrderForm = Record<PurchaseOrderFormData>({
    uniqueIdentifier: undefined,
    version: 0,
    companyCode: "",
    no: "",
    description: "",
    status: "",
    supplier: createSupplierIdentifier({}),
    requestedDeliveryDate: "",
    currency: "",
    lines: List<Record<PurchaseOrderLineFormData>>(),
    cancellationReason: ''
});

export const createPurchaseOrderLineForm = Record<PurchaseOrderLineFormData>({
    id: undefined,
    uniqueIdentifier: undefined,
    version: undefined,
    no: undefined,
    productNo: "",
    description: "",
    quantity: undefined,
    quantityUom: "",
    quantityWithUom: "",
    price: undefined,
    priceUom: "",
    priceWithUom: "",
    totalPrice: undefined,
    totalPriceWithUom: "",
    quantityDelivered: undefined,
    toBeRegistered: true,
    toBeDeleted: false,
    receiptMasterLines: List<Record<ReceiptMasterLine>>(),
    internalId: undefined,
    stockUomIsInteger: undefined,
    purchaseOrderUuid: ""
})

export const createCancelPurchaseOrderForm = Record<CancelPurchaseOrderFormData>({
    uniqueIdentifier: "",
    companyCode: "",
    reason: ""
})

export const createRegisterPurchaseOrderLineData = Record<RegisterPurchaseOrderLineData>({
    supplierUniqueIdentifier: "",
    companyCode: "",
    lines: List<Record<PurchaseOrderLineFormData>>()
})

export const createCalculatePurchaseOrderLinePriceData = Record<CalculatePurchaseOrderLinePriceData>({
    productNo: "",
    companyCode: "",
    quantity: 0,
    quantityUom: "",
    unitPrice: 0,
    unitPriceUom: ""
})

export enum PurchaseOrderMode {
    // only for viewing data
    VIEW = 'view-purchaseorder',
    // when we're working with unsaved data
    NEW = 'new-purchaseorder',
    // when we're working with data that already exists in the db
    UPDATE = 'update-purchaseorder'
}

export enum PurchaseOrderLineMode {
    VIEW = 'view-purchase-order-line',
    NEW = 'new-purchase-order-line',
    UPDATE = 'update-purchase-order-line'
}

export enum ValidationMessageType {
    ERROR = 'error-message',
    WARNING = 'warning-message'
}

export const PURCHASEORDER_HEADER_INVALID = Record<ValidationMessage>({ message: "retail.validation.purchaseorderheader.required", type: ValidationMessageType.ERROR })();
export const NO_PURCHASEORDER_LINES = Record<ValidationMessage>({ message: "retail.validation.purchasorderline.required", type: ValidationMessageType.ERROR })();
export const CANNOT_BE_SENT = Record<ValidationMessage>({ message: "retail.validation.cannot.be.sent", type: ValidationMessageType.ERROR })();
export const CANNOT_BE_CANCELLED = Record<ValidationMessage>({ message: "retail.validation.cannot.be.cancelled", type: ValidationMessageType.ERROR })();
export const NO_QUANTITIES_CHANGED = Record<ValidationMessage>({ message: "retail.validation.no.quantity.updated", type: ValidationMessageType.WARNING })();

export enum PurchaseOrderStatus {
    INITIAL = 'PurchaseOrderStatus.Initial',
    ORDERED = 'PurchaseOrderStatus.Ordered',
    DELIVERED = 'PurchaseOrderStatus.Delivered'
}
