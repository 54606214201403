import {Record} from "immutable";
import {Action} from "redux";

import ActionTypes from "./actions";
import {
    StoreStateProduct,
    createProductState,
    StoreProductAction,
    StoreProductComponentAction,
    StoreProductComponentsAction,
    AddNewProductComponentAction,
    StoreProductComponentIdxAction,
    StoreDefaultCompanyCurrencyAction,
    StoreVatInfoAction
} from "../../types/productTypes";

export default function (state: Record<StoreStateProduct> = createProductState(), action: Action) {
    switch (action.type) {
        case ActionTypes.STORE_PRODUCT:
            return storeProduct(state, action as StoreProductAction);
        case ActionTypes.CLEAR_PRODUCT_DATA:
            return clearProductData(state);
        case ActionTypes.CLEAR_PRODUCT_COMPONENT_DATA:
            return clearProductComponentData(state);
        case ActionTypes.CLEAR_PRODUCT_COMPONENT_IDX_DATA:
            return clearProductComponentIdxData(state);
        case ActionTypes.CLEAR_PRODUCT_COMPONENTS_DATA:
            return clearProductComponentsData(state);
        case ActionTypes.STORE_PRODUCT_COMPONENT:
            return storeProductComponent(state, action as StoreProductComponentAction);
        case ActionTypes.STORE_PRODUCT_COMPONENT_IDX:
            return storeProductComponentIdx(state, action as StoreProductComponentIdxAction);
        case ActionTypes.ADD_NEW_PRODUCT_COMPONENT:
            return addNewProductComponent(state, action as AddNewProductComponentAction);
        case ActionTypes.STORE_PRODUCT_COMPONENTS:
            return storeProductComponents(state, action as StoreProductComponentsAction);
            case ActionTypes.STORE_DEFAULT_COMPANY_CURRENCY:
            return storeDefaultCompanyCurrency(state, action as StoreDefaultCompanyCurrencyAction);
        case ActionTypes.STORE_VAT_INFO:
            return storeVatInfo(state, action as StoreVatInfoAction);
    }
    return state;
}

function storeVatInfo(state: Record<StoreStateProduct>, action: StoreVatInfoAction) {
    return state.set('vatInfo', action.vatCountryCode);
}

function storeProduct(state: Record<StoreStateProduct>, action: StoreProductAction) {
    return state.set('product', action.data);
}

function clearProductData(state: Record<StoreStateProduct>) {
    return state.delete('product');
}

function clearProductComponentData(state: Record<StoreStateProduct>) {
    return state.delete('productComponent');
}

function clearProductComponentIdxData(state: Record<StoreStateProduct>) {
    return state.delete('productComponentIdx');
}

function clearProductComponentsData(state: Record<StoreStateProduct>) {
    return state.delete('productComponents');
}

function storeProductComponent(state: Record<StoreStateProduct>, action: StoreProductComponentAction) {
    return state.set('productComponent', action.data);
}

function storeProductComponentIdx(state: Record<StoreStateProduct>, action: StoreProductComponentIdxAction) {
    return state.set('productComponentIdx', action.index);
}

function addNewProductComponent(state: Record<StoreStateProduct>, action: AddNewProductComponentAction) {
    return state.update('productComponents', (value) => {
        return value.push(action.data);
    });
}

function storeProductComponents(state: Record<StoreStateProduct>, action: StoreProductComponentsAction) {
    return state.set('productComponents', action.data);
}

function storeDefaultCompanyCurrency(state: Record<StoreStateProduct>, action: StoreDefaultCompanyCurrencyAction) {
    return state.set('defaultCompanyCurrency', action.currency.get('value'));
}
