
export enum ActionType {
    LOAD_PROPERTIES='common.LOAD_PROPERTIES'
}

export function loadProperties(properties: { [key: string]: unknown }): LoadPropertiesAction {
    return {
        type: ActionType.LOAD_PROPERTIES,
        properties
    };
}

export interface LoadPropertiesAction {
    type: ActionType.LOAD_PROPERTIES,
    properties: { [key: string]: unknown }
}

