import React, { useEffect, useMemo } from 'react';
import { FormFieldConfig } from "./types";
import { WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { FormGroup, FormLabel } from 'react-bootstrap';

type Props = {
    /**
     * Binder configuration
     */
    config: FormFieldConfig,
    /**
     * Redux-form field input prop
     */
    input: WrappedFieldInputProps,
    /**
     * Redux-form field meta prop
     */
    meta: WrappedFieldMetaProps,
    inline: boolean
}

export default function FormField(props: Props) {
    let { binder, label, translateLabel, labelWidth, fieldWidth, focus, ...binderProps } = props.config;

    // translate the label
    const { t } = useTranslation();
    if (translateLabel !== false) {
        label = t(label);
    }

    const id = props.meta.form + '-' + props.input.name;

    // the properties to pass through to the "binder" component
    binderProps = useMemo(() => {
        const props = Object.assign({}, binderProps);
        props.id = id;
        return props;
    }, [binderProps, id]);
    
    // focus on the input element
    useEffect(() => {
        if (focus) {
            const el = document.getElementById(id);
            if (el) el.focus();
        }
    }, [id, focus]);

    return (
        <FormGroup>
            <FormLabel>{ label }</FormLabel>
            { React.createElement(binder, {
                input: props.input,
                meta: props.meta,
                ...binderProps
            })}
            { props.meta.touched && props.meta.error && <div className="form-text text-danger">{ props.meta.error }</div> }
        </FormGroup>
    )
}
