import React from 'react';
import { Modal } from 'react-bootstrap';
import { ModalBody } from 'react-bootstrap';
import CancelPurchaseOrder from './CancelPurchaseOrder';
import { useTranslation } from "react-i18next";

export interface Props {
    show: boolean,
    onHide: () => void
}

export default function CancelPurchaseOrderModal(props: Props) {

    const {t} = useTranslation();

    return(
        <Modal show={props.show} size="sm" onHide={props.onHide}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {t('retail.procurement.cancelOrder.cancel')}
                </Modal.Title>
            </Modal.Header>
            <ModalBody>
                <CancelPurchaseOrder onSave={props.onHide}/>
            </ModalBody>
        </Modal>
    )
}
