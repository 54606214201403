import { put, select } from "redux-saga/effects";
import { warning } from "react-notification-system-redux";
import { actionStart, actionSuccess, actionError, incrementRunningActions, decrementRunningActions } from "../actionButton/actionButtonActions";
import { isAnyActionRunning } from "../actionButton/actionButtonSelectors";

/*
* Runs a saga, seting the action-button to be in status "running" until the saga is terminated
*/
export function* runActionButton<T>(actionId: string, fn: () => Generator<any, T, any>): Generator<any, T | undefined, any> {
    const anyActionRunning = (yield select(isAnyActionRunning)) as boolean;
    if (anyActionRunning) {
        yield put(warning({
            title: 'Another action is in progress',
            message: 'Cannot process action with id: "' + actionId + '".'
        }));
        return;
    }

    yield put(actionStart(actionId));
    try {
        const result: T = yield* fn();
        yield put(actionSuccess(actionId));
        return result;

    } catch(e) {
        console.error(e);
        yield put(actionError(actionId, '' + e));
    } 
}

/**
 * Runs a saga, disabling all action-buttons on the screen until the saga is terminated
 */
export function *lockActionButtons<T>(fn: () => Generator<any, T, any>): Generator<any, T | undefined, any> {
    yield put(incrementRunningActions());
        try {
            const result: T = yield* fn();
            return result;
        } catch(e) {
            console.error(e);
        } finally {
            yield put(decrementRunningActions());
        }
}
