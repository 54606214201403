import React, { useReducer, useCallback } from 'react';
import { AsyncTypeahead, AllTypeaheadOwnAndInjectedProps, TypeaheadMenuProps, Highlighter } from 'react-bootstrap-typeahead';
import { BasicProductResponse } from '../../remote/responseTypes';
import { findProductSuggestions } from '../../remote/api';
import { useTranslation } from 'react-i18next';

/**
 * Input field for the product-binder. Displays a list of suggestions as the user types
 */

type Props = {
    id: string;
    productNo: string;
    companyCode: string;
    excludeGifts?: boolean;
    maxResults?: number;
    availableForPurchase?: boolean;
    onChange: (input: string) => void;
    availableForSale?: boolean;
}

type State = {
    isLoading: boolean,
    query: string;
    suggestions: BasicProductResponse[];
}

enum ActionType {
    BEGIN_FETCH,
    RECEIVE_SUGGESTIONS,
    SET_QUERY
}

type Action =
    | { type: ActionType.BEGIN_FETCH }
    | { type: ActionType.RECEIVE_SUGGESTIONS, suggestions: BasicProductResponse[] }
    | { type: ActionType.SET_QUERY, query: string };

function init(query: string): State {
    return {
        isLoading: false,
        query,
        suggestions: []
    };
}

function reducer(state: State, action: Action): State {
    switch (action.type) {
        case ActionType.BEGIN_FETCH:
            return {
                ...state,
                suggestions: state.suggestions,
                isLoading: true
            };

        case ActionType.SET_QUERY:
            return {
                ...state,
                query: action.query
            };

        case ActionType.RECEIVE_SUGGESTIONS:
            return {
                ...state,
                suggestions: action.suggestions,
                isLoading: false
            };

        default:
            return state;
    }
}

function startsWithIgnoreCase(left: string, right: string) {
    return left.toUpperCase().startsWith(right.toUpperCase());
}

function filterProduct(option: BasicProductResponse, props: AllTypeaheadOwnAndInjectedProps<BasicProductResponse>) {
    let text = props.text;
    if (!text) return false;
    return startsWithIgnoreCase(option.no, text)
        || (option.eanNo ? startsWithIgnoreCase(option.eanNo, text) : false);
}

function renderMenuItemChildren(option: BasicProductResponse, props: TypeaheadMenuProps<BasicProductResponse>): React.ReactNode {
    return [
        <div key="desc">{ option.description }</div>,
        <small key="no">
            {'No: '}
            <Highlighter search={props.text}>{ option.no }</Highlighter>
            { option.eanNo && <span>
            {' Ean: '}
            <Highlighter search={props.text}>{ option.eanNo }</Highlighter>
            </span> }
        </small>
    ];
}

const MAX_RESULTS = 10;

export default React.memo(function ProductBinderInput(props: Props) {
    const [state, dispatch] = useReducer(reducer, props.productNo, init);

    const { t } = useTranslation();

    let maxResults = props.maxResults;
    if (maxResults == null) maxResults = MAX_RESULTS;

    // callback to fetch next suggestions
    const onSearch = useCallback((query: string) => {
        dispatch({ type: ActionType.BEGIN_FETCH });
        findProductSuggestions(query + "%", props.companyCode, maxResults!, props.availableForPurchase, props.availableForSale, props.excludeGifts)
            .then(products => {
                dispatch({
                    type: ActionType.RECEIVE_SUGGESTIONS,
                    suggestions: products
                });
            });
    }, [dispatch, props.companyCode, maxResults]);

    // when the input text is changed
    const onInputChange = useCallback((query: string) => {
        dispatch({ type: ActionType.SET_QUERY, query });
    }, [dispatch]);

    // update the form-state when the input loses focus
    const onBlur = useCallback(() => {
        props.onChange(state.query);
    }, [state.query]);

    // handle an option was selected
    const onChange = useCallback((sugg: BasicProductResponse[]) => {
        if (sugg.length) {
            const query = sugg[0].no;
            dispatch({ type: ActionType.SET_QUERY, query })
            props.onChange(query);
        }
    }, [dispatch]);

    return (
        <AsyncTypeahead
            id={props.id + '-input'}
            inputProps={{
                id: props.id
            }}
            defaultInputValue={props.productNo}
            labelKey="no"
            filterBy={filterProduct}
            options={state.suggestions}
            isLoading={state.isLoading}
            renderMenuItemChildren={renderMenuItemChildren}
            onSearch={onSearch}
            onInputChange={onInputChange}
            onBlur={onBlur}
            onChange={onChange}
            emptyLabel={t('product.binder.input.emptyLabel')}
        />
    );
});
