import {FetchOptionsAction, Option, StoreOptionsAction} from "./types";
import {List} from "immutable";

export  const ActionTypes = {
    FETCH_OPTIONS: 'FETCH_OPTIONS',
    STORE_OPTIONS: 'STORE_OPTIONS'
};

export function fetchOptions(sources: string[]): FetchOptionsAction {
    return {type: ActionTypes.FETCH_OPTIONS, sources: sources};
}

export function storeOptions(data: Map<string, List<Option>>): StoreOptionsAction {
    return {type: ActionTypes.STORE_OPTIONS, data};
}