
import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { GridReactive, Column, Row } from '@pearlchain/component-lib-react-pg';
import { FormFieldConfig } from '@pearlchain/component-lib-ui';
import { error } from 'react-notification-system-redux';
import { useTranslation } from 'react-i18next';
import { Modal, ModalBody, ModalFooter, Button } from 'react-bootstrap';

import SearchBinderForm from './SearchBinderForm';

type Props = {
    formId: string,
    show: boolean,
    columns: Column[],
    initialValues: { [key: string]: unknown },
    formFields: { [key: string]: FormFieldConfig },
    title: string,
    extraSelector?: { [key: string]: unknown },
    onHide: () => void,
    onExit?: () => void,
    onComplete: (value: string) => void,
    onSearch: (formValues: any) => Promise<Row[]>,
}

export default React.memo(function SearchBinderModal(props: Props) {
    const {
        formId, show, columns, initialValues, formFields, title,
        extraSelector, onHide, onExit, onComplete, onSearch } = props;

    const [selectedValue, setSelectedValue] = useState<string |undefined>(undefined);
    const [data, setData] = useState<Row[]>([]);

    // handle fetching the next data for the form
    const dispatch = useDispatch();
    const fetchData = useCallback((formValues: { [key: string]: unknown }) => {
        if (extraSelector) {
            // to pass extra selector fields, we don't show as an input field
            formValues = Object.assign({}, formValues, extraSelector);
        }

        return onSearch(formValues)
            .then(setData)
            .catch(err => {
                // show an error notification
                dispatch(error({
                    title: 'API Error',
                    message: err.message
                }));
            });
    }, [dispatch, onSearch, extraSelector]);

    // handle "select" button pressed
    const handleComplete = useCallback(() => {
        if (selectedValue) onComplete(selectedValue);
    }, [selectedValue, onComplete]);

    const { t } = useTranslation();
    return (
        <Modal show={show} size="lg" onHide={onHide} onExited={onExit}>
            <Modal.Header onHide={onHide} closeButton>{t(title)}</Modal.Header>
            <ModalBody>
                <SearchBinderForm
                    form={formId}
                    fields={formFields}
                    initialValues={initialValues}
                    onFetchData={fetchData}/>
                <GridReactive
                    gridId={formId}
                    style={{ height: 450 }}
                    columns={columns}
                    data={data}
                    extensions={{
                        columnsizing: true
                    }}
                    onRowSelected={(value: string) => setSelectedValue(value)}/>
            </ModalBody>
            <ModalFooter>
                <Button variant="secondary" onClick={onHide}>{t('binder.cancel')}</Button>
                <Button disabled={!selectedValue} onClick={handleComplete}>{t('binder.select')}</Button>
            </ModalFooter>
        </Modal>
    );
});
