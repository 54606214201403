import { TextBinder, RequestIdSelectBinder, DateBinder } from '@pearlchain/component-lib-ui'
import { getRealmNo, formatDecimal } from '@pearlchain/component-lib-common'
import { CompanyBinder } from '@pearlchain/stackbase-common';

import PurchaseOrderStatusBinder from './binders/PurchaseOrderStatusBinder'
import ProductBinder from '../common/binders/ProductBinder'
import { validateCompanyBinder } from '../common/binders/ValidateCompanyBinder'
import SelectBinder, { Option } from '../common/binders/SelectBinder'

import { RequestId } from '../remote/requests'
import {
    requiredValidator,
    integerStockUomValidatorWithMandatoryPositiveNumberWithThreeDecimal
} from '../util/validators'
import { PurchaseOrderLineMode, PurchaseOrderMode } from '../../types/procurementTypes'
import { formFieldConfig, FormFieldConfig } from '../../form/types'
import NumberBinder from "../inventory/binders/NumberBinder";
import PartnerBinder from "../common/binders/partner/PartnerBinder";
import { Company } from '@pearlchain/stackbase-common/lib/components/CompanyBinder';
import { CENTRALSUPPLIER } from './purchaseOrderLine/PurchaseOrderLine';
import DescriptionBinder from '../common/binders/DescriptionBinder';

export function createPurchaseOrderFormFields(initialValues: any, mode: PurchaseOrderMode, companies: Company[] | undefined, companyChangeCallBack: (value: any) => void) {

    let companyCodeDisabled: boolean, descriptionDisabled: boolean, supplierNoDisabled: boolean, requestedDeliveryDateDisabled: boolean, currencyDisabled : boolean, productNoDisabled : boolean;

    companyCodeDisabled = descriptionDisabled = supplierNoDisabled = requestedDeliveryDateDisabled = currencyDisabled = false, productNoDisabled = false;

    if (PurchaseOrderMode.VIEW == mode) {
        companyCodeDisabled = descriptionDisabled = supplierNoDisabled = requestedDeliveryDateDisabled = currencyDisabled = true;
    } else if (PurchaseOrderMode.UPDATE == mode) {
        companyCodeDisabled = currencyDisabled = true;
        productNoDisabled = true;
    }

    let formFields: { [key: string]: FormFieldConfig } = {};

    if (initialValues.status === 'PurchaseOrderStatus.Canceled') {
        formFields = {
            companyCode: formFieldConfig({ label: 'retail.procurement.formFields.companyCode', translateLabel: true, binder: CompanyBinder, validate: validateCompanyBinder(companies), disabled: companyCodeDisabled, onChange: companyChangeCallBack }),
            no: formFieldConfig({ label: 'retail.procurement.fromFields.no', translateLabel: true, binder: TextBinder, disabled: true }),
            description: formFieldConfig({ label: 'retail.procurement.fromFields.description', translateLabel: true, binder: DescriptionBinder, disabled: descriptionDisabled }),
            status: formFieldConfig({ label: 'retail.procurement.fromFields.status', translateLabel: true, binder: TextBinder, disabled: true }),
            cancellationReason: formFieldConfig({ label: 'retail.procurement.fromFields.reason', translateLabel: true, binder: TextBinder, disabled: true }),
            supplier: formFieldConfig({ label: 'retail.procurement.fromFields.supplierNo', translateLabel: true, binder: PartnerBinder, initialValues: {companyCodes: [getRealmNo()], limit: 50}, supplier: true, validate: requiredValidator(),
            disabled: supplierNoDisabled, modalRoot: "modal-root", modalTitle: 'common.binder.supplier.title' }),
            requestedDeliveryDate: formFieldConfig({ label: 'retail.procurement.fromFields.deliveryDate', translateLabel: true, binder: DateBinder, disabled: requestedDeliveryDateDisabled, validate: requiredValidator() }),
            currency: formFieldConfig({ label: 'retail.procurement.fromFields.currency', translateLabel: true, binder: RequestIdSelectBinder, requestId: RequestId.LIST_CURRENCIES, validate: requiredValidator(), disabled: currencyDisabled }),
            totalPrice: formFieldConfig({ label: 'retail.procurement.fromFields.totalPrice', translateLabel: true, binder: TextBinder, disabled: true })
        };
    } else {
        formFields = {
            companyCode: formFieldConfig({ label: 'retail.procurement.formFields.companyCode', translateLabel: true, binder: CompanyBinder, validate: validateCompanyBinder(companies), disabled: companyCodeDisabled, onChange: companyChangeCallBack }),
            no: formFieldConfig({ label: 'retail.procurement.fromFields.no', translateLabel: true, binder: TextBinder, disabled: true }),
            description: formFieldConfig({ label: 'retail.procurement.fromFields.description', translateLabel: true, binder: DescriptionBinder, disabled: descriptionDisabled }),
            status: formFieldConfig({ label: 'retail.procurement.fromFields.status', translateLabel: true, binder: TextBinder, disabled: true }),
            supplier: formFieldConfig({ label: 'retail.procurement.fromFields.supplierNo', translateLabel: true, binder: PartnerBinder, initialValues: {companyCodes: [getRealmNo()], limit: 50}, supplier: true, validate: requiredValidator(),
            disabled: supplierNoDisabled, modalRoot: "modal-root", modalTitle: 'common.binder.supplier.title' }),
            requestedDeliveryDate: formFieldConfig({ label: 'retail.procurement.fromFields.deliveryDate', translateLabel: true, binder: DateBinder, disabled: requestedDeliveryDateDisabled, validate: requiredValidator() }),
            currency: formFieldConfig({ label: 'retail.procurement.fromFields.currency', translateLabel: true, binder: RequestIdSelectBinder, requestId: RequestId.LIST_CURRENCIES, validate: requiredValidator(), disabled: currencyDisabled }),
            totalPrice: formFieldConfig({ label: 'retail.procurement.fromFields.totalPrice', translateLabel: true, binder: TextBinder, disabled: true })
        };
    }

    return formFields;
}

export function createReceivePurchaseOrderFormFields() {
    const formFields: { [key: string]: FormFieldConfig } = {
        no: formFieldConfig({ label: 'Purchase order No', binder: TextBinder, disabled: true })
    };
    return formFields;    
}

export function createPurchaseOrderOverviewFormFields(companies: Company[] | undefined) {
    const companyCode = getRealmNo();
    const formFields: { [key: string]: FormFieldConfig } = {
        companies: formFieldConfig({ label: 'common.selector.company', binder: CompanyBinder, multi: true, validate: validateCompanyBinder(companies) }),
        purchaseNumber: formFieldConfig({ label: 'retail.inventory.orders.overview.no', binder: TextBinder }),
        statuses: formFieldConfig({ label: 'retail.inventory.orders.overview.status', binder: PurchaseOrderStatusBinder, translateOptions: true, multi: true }),
        beginDate: formFieldConfig({ label: 'retail.inventory.orders.overview.dateFrom', binder: DateBinder }),
        endDate: formFieldConfig({ label: 'retail.inventory.orders.overview.dateTo', binder: DateBinder }),
        productNo: formFieldConfig({ label: 'retail.inventory.orders.overview.productNo', binder: ProductBinder, companyCode, extraSelector: { availableForPurchase: true } })
    };
    return formFields;
}

export function createPurchaseOrderLineFormFields(
    t: (key: string) => string,
    quantityUoms: Option<string>[],
    priceUoms: Option<string>[],
    uomsEnabled: boolean,
    mode: PurchaseOrderLineMode,
    product: any,
    supplierUUID: string | undefined
) {
    const productStockUomIsInteger = product && product.stockUomIsInteger;
    const fmtDecimal = formatDecimal(t);
    let formFields: { [key: string]: FormFieldConfig } = {};

    formFields={
        productNo: (PurchaseOrderLineMode.UPDATE == mode) 
            ? formFieldConfig({ label: 'retail.inventory.overview.productDescription', binder: TextBinder , disabled: true})
            : formFieldConfig({ label: 'retail.inventory.orders.overview.productNo', binder: ProductBinder, availableForPurchase: true, validate: requiredValidator(), companyCode: getRealmNo(), extraSelector: { availableForPurchase: true }}),
        description: (PurchaseOrderLineMode.UPDATE == mode)
            ? formFieldConfig({ label: 'retail.inventory.overview.productDescription', binder: TextBinder , disabled: true})
            : formFieldConfig({ label: 'retail.inventory.overview.productDescription', binder: TextBinder}),
            quantity: formFieldConfig({ label: 'retail.inventory.orders.detailed.quantityOrdered', binder: NumberBinder, formatter: fmtDecimal,
            validate: integerStockUomValidatorWithMandatoryPositiveNumberWithThreeDecimal(productStockUomIsInteger) }),
            quantityUom: formFieldConfig({ label: 'retail.inventory.orders.update.uom', binder: SelectBinder, options: quantityUoms, validate: requiredValidator(), disabled: !uomsEnabled }),
            price: formFieldConfig({ label: 'retail.inventory.orders.update.price', formatter: fmtDecimal, binder: TextBinder }),
            priceUom: formFieldConfig({ label: 'retail.inventory.orders.update.priceUom', binder: SelectBinder, options: priceUoms, validate: requiredValidator(), disabled: !uomsEnabled })    
    }

    if(product && product.purchaseOrderMultipleValue && supplierUUID === CENTRALSUPPLIER) {
        formFields["purchaseOrderMultipleValue"] = formFieldConfig({ label: 'retail.inventory.orders.overview.purchaseOrderMultipleValue', binder: TextBinder})
    }

    return formFields;
}

function fromQuantityValidator() {
    return (input: any) => {
        if (!input || input.length >5 )
            return 'nadal';
    }
}
