import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { pushLocation } from '@pearlchain/component-lib-common';
import { LoginForm } from "@pearlchain/stackbase-common"
import { findUserInfo } from '../userInfo/actions';
import { loginClearState } from './actions';


interface Props {
    pushLocation: typeof pushLocation,
    findUserInfo: typeof findUserInfo,
    loginClearState: typeof loginClearState
}

function LoginPage(props: Props) {
    useEffect(() => {
        props.loginClearState();
    }, []);

    return <LoginForm storeKey="auth" onLoginSuccess={() => {
        // login complete. Redirect to home page
        props.findUserInfo();
        props.pushLocation('/', 'Home', {})
    }}/>
}

export default connect(undefined, { pushLocation, findUserInfo, loginClearState })(LoginPage);