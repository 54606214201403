import React from "react";
import { useSelector } from "react-redux";

import { selectActionButtonState } from "./actionButtonSelectors";
import AsyncActionButtonControlled, { AsyncActionButtonProps } from "./AsyncActionButtonControlled";

type Props = AsyncActionButtonProps & {
    actionId: string;
    disableOnError?: boolean;
    children: React.ReactChild;
    onAction: () => void;
}

/**
 * Connected async action button - selects the "running" status from the store.
 */

export default function AsyncActionButton({ actionId, children, onAction, disableOnError, ...buttonProps }: Props) {
    // select the button state from the store
    const buttonState = useSelector(selectActionButtonState(actionId));
    let busy = buttonProps.busy;
    let disabled = buttonProps.disabled;

    if (buttonState) {
        // disable the button if the action already threw an error
        if (disableOnError && buttonState.error) disabled = true;

        if (buttonState.running) busy = true;
    }

    return (
        <AsyncActionButtonControlled
            {...buttonProps}
            onAction={onAction}
            busy={busy}
            disabled={disabled}
        >
            {children}
        </AsyncActionButtonControlled>
    );
}
