import ActionTypes, {
    putOrder,
    FindInventoryEntriesAction,
    FindStockMovementsAction,
    SaveCorrectionsAction,
    ViewStockMovementsAction,
    clearCorrections,
    findInventoryEntries,
    ConvertStockAction,
    SaveStockAction,
    FetchOutgoingOrdersAction,
    OutgoingOrderAction,
    UpdateOrderAction,
    ConfirmOrderAction,
    ExportToExcelAction,
    ValidateConversionResponseAction,
    OpenOrderLineForEditAction,
    PrintShelfLabelsAction,
    removePrintLabelsFromEntries,
    printShelfLabels
} from './actions';
import i18next from 'i18next'
import { all, takeEvery, takeLatest, put, select } from 'redux-saga/effects';
import {
    getRealmNo,
    pushLocation,
    invokeRequestHandler,
    callRequestHandler,
    receiveCachedData,
    callFetcher,
    RequestBuilder,
    popLocation
} from '@pearlchain/component-lib-common';
import { RequestId } from '../remote/requests';
import { inventoryOverview, stockMovementsOverview, detailedOutgoingOrder, updateOrderLine } from './inventoryPages';
import moment, { isMoment } from 'moment';
import {
    InventoryOverviewFilterFormData,
    StockCorrection,
    InventoryOverviewItem, SalesOrderLine, RETAIL_PRODUCT_PACK, RETAIL_PRODUCT, Option, PrintLabelInfo
} from '../../types/inventoryTypes';
import { List, Record, Map } from 'immutable';
import { error, success } from 'react-notification-system-redux';
import { exportPdf, exportXLSX } from './exportUtils';
import { INVENTORY_OVERVIEW_FORM } from "./overview/InventoryOverview";
import { createWebParams } from '../remote/requestTypes';
import {DEFAULT_PAGE_SIZE} from '../util/helpers';
import { getOutgoingOrders } from '../remote/api';
import { isNumber } from "util";
import { PRINT_LABEL_GIFT_DIALOG_ID } from "./overview/labels/PrintLabelForGiftModal";
import { showDialog } from "../dialog/actions";
import { PRINT_LABEL_SIMPLE_DIALOG_ID } from "./overview/labels/PrintLabelForSimpleModal";
import { runActionButton } from '../util/sagaUtils';

export default function() {
    return all([
        takeEvery(ActionTypes.FIND_INVENTORY_ENTRIES, handleFindInventoryEntries),
        takeEvery(ActionTypes.VIEW_STOCK_MOVEMENTS, handleViewStockMovements),
        takeEvery(ActionTypes.FIND_STOCK_MOVEMENTS, handleFindStockMovements),
        takeEvery(ActionTypes.FETCH_CORRECTION_REASONS, handleFetchCorrectionReasons),
        takeEvery(ActionTypes.FETCH_CORRECTION_ACTIONS, handleFetchCorrectionActions),
        takeEvery(ActionTypes.FETCH_UOMS, handleFetchUoms),
        takeEvery(ActionTypes.SAVE_CORRECTIONS, handleSaveCorrections),
        takeEvery(ActionTypes.CONVERT_STOCKS, handleConvertStocks),
        takeLatest(ActionTypes.SAVE_STOCK, handleSaveStock),
        takeLatest(ActionTypes.FETCH_OUTGOING_ORDERS, handleFetchOutgoingOrders),
        takeLatest(ActionTypes.VIEW_OUTGOING_ORDER, handleFindOrder),
        takeLatest(ActionTypes.UPDATE_ORDER, handleUpdateOrder),
        takeLatest(ActionTypes.CONFIRM_ORDER, handleConfirmOrder),
        takeLatest(ActionTypes.PROCESS_EXPORT_TO_EXCEL, handleProcessExportToExcel),
        takeLatest(ActionTypes.VALIDATE_CONVERSION_RESPONSE, handleValidateConversion),
        takeLatest(ActionTypes.OPEN_ORDER_LINE_FOR_EDIT, handleOpenOrderLineForEdit),
        takeLatest(ActionTypes.SHOW_PRINT_LABELS_DIALOG, handleShowPrintLabelsDialog),
        takeLatest(ActionTypes.SHOW_PRINT_WEIGHT_LABELS_DIALOG, handleShowPrintWeightLabelsDialog),
        takeLatest(ActionTypes.GET_PRODUCT_LABELS_DOC, handleGetProductLabelsDoc)
    ]);
}

function *handleShowPrintLabelsDialog() {
    const productsToPrint: List<Record<PrintLabelInfo>> = yield select(state => state.inventory.get('printLabels'));

    if (productsToPrint &&  productsToPrint.size > 0) {

        const filter = (source: string, items: List<Record<PrintLabelInfo>>) : List<Record<PrintLabelInfo>> => {
            return items.filter(item => source === item.get('productSource') && item.get('numLabels')>0).map(item => {
                console.log(item.get('productNo') + ": "+item.get('numLabels'));
                return item;
            });
        };

        let productPackNos: List<Record<PrintLabelInfo>> = filter(RETAIL_PRODUCT_PACK, productsToPrint);
        let productNos: List<Record<PrintLabelInfo>> = filter(RETAIL_PRODUCT, productsToPrint);

        if (productPackNos.size > 0 && productNos.size > 0) {
            yield put(error({
                autoDismiss: 10,
                title: i18next.t('retail.inventory.error'),
                message: i18next.t('retail.inventory.labels.cant.print.different.sources.error')
            }));
        } else {
            if (productPackNos.size > 0) {
                yield put(printShelfLabels(productPackNos, undefined, 'labels-gifts.pdf'));
            } else if (productNos.size > 0) {
                yield put(printShelfLabels(productNos, undefined, 'labels-simple.pdf'));
            }
        }
    }
}

function *handleShowPrintWeightLabelsDialog() {
    const productsToPrint: List<Record<PrintLabelInfo>> = yield select(state => state.inventory.get('printLabels'));

    if (productsToPrint &&  productsToPrint.size > 0) {

        const filter = (source: string, items: List<Record<PrintLabelInfo>>) : List<Record<PrintLabelInfo>> => {
            return items.filter(item => source === item.get('productSource') && item.get('numLabels')>0).map(item => {
                console.log(item.get('productNo') + ": "+item.get('numLabels'));
                return item;
            });
        };

        let productPackNos: List<Record<PrintLabelInfo>> = filter(RETAIL_PRODUCT_PACK, productsToPrint);
        let productNos: List<Record<PrintLabelInfo>> = filter(RETAIL_PRODUCT, productsToPrint);

        if (productPackNos.size > 0 && productNos.size > 0) {
            yield put(error({
                autoDismiss: 10,
                title: i18next.t('retail.inventory.error'),
                message: i18next.t('retail.inventory.labels.cant.print.different.sources.error')
            }));
        } else {
            if (productPackNos.size > 0) {
                yield put(showDialog(PRINT_LABEL_GIFT_DIALOG_ID, productPackNos));
            } else if (productNos.size > 0) {
                yield put(showDialog(PRINT_LABEL_SIMPLE_DIALOG_ID, productNos));
            }
        }
    }
}

function *handleOpenOrderLineForEdit(action: OpenOrderLineForEditAction) {
    let line: SalesOrderLine = action.line;

    yield put(
        pushLocation(
            updateOrderLine.path,
            i18next.t(updateOrderLine.title) ,
            {
                line
            }
        )
    );
}

function* handleGetProductLabelsDoc(action: PrintShelfLabelsAction) {
    yield* runActionButton(ActionTypes.GET_PRODUCT_LABELS_DOC, function *() {
        var data: any;
        if(action.form !== undefined) {
            data = {
                productNosAndNumLabels: Map(action.printLabels.map((item, index) => ([item.get('productNo'), item.get('numLabels')]))),
                companyCode: getRealmNo(),
                shelfQuantity: action.form.shelfQuantity,
                uom: action.form.uom,
                language: i18next.language
            };
        } else {
            data = {
                productNosAndNumLabels: Map(action.printLabels.map((item, index) => ([item.get('productNo'), item.get('numLabels')]))),
                companyCode: getRealmNo(),
                language: i18next.language
            };
        }
    
        const [blob, error] = yield* callRequestHandler(RequestId.GET_PRODUCT_LABELS_DOC, data);
    
        if (error) {
            const errorMsg = 'retail.document.create.error';
            yield put(error({
                title: i18next.t('retail.inventory.error'),
                message: i18next.t(errorMsg)
            }));
            throw errorMsg;

        } else {
            exportPdf(blob, action.fileName);
            yield put(removePrintLabelsFromEntries());
        }
    });
}

function *handleProcessExportToExcel(action: ExportToExcelAction) {
    yield* runActionButton(ActionTypes.PROCESS_EXPORT_TO_EXCEL, function *() {
        const title: string = `Inventory Overview (${moment()}).xlsx`;
        const currentPage: List<Record<InventoryOverviewItem>> = action.currentPage;
    
        yield put(success({
            title: i18next.t('retail.inventory.message'),
            message: i18next.t('retail.inventory.export.started.message')
        }));
    
        const t = i18next.t.bind(i18next);
        if (DEFAULT_PAGE_SIZE > currentPage.size) {
            exportXLSX(title, currentPage, t);
    
            yield put(success({
                title: i18next.t('retail.inventory.message'),
                message: i18next.t('retail.inventory.export.finished.message')
            }));
        } else {
            action.filter.maxResults = 1000;
            const [result, error] = yield* callRequestHandler(RequestId.GET_STOCK_ENTRIES_FOR_EXPORT, action.filter);
    
            if (result) {
                exportXLSX(title, result, t);
                yield put(success({
                    title: i18next.t('retail.inventory.message'),
                    message: i18next.t('retail.inventory.export.finished.message')
                }));

            } else if (error) {
                const errorMsg = 'retail.inventory.error.on.export.data';
                yield put(error({
                    title: i18next.t('retail.inventory.error'),
                    message: i18next.t(errorMsg)
                }));
                throw errorMsg;
            }
        }
    });
}

function* handleConfirmOrder(action: ConfirmOrderAction) {
    yield* runActionButton(ActionTypes.CONFIRM_ORDER, function *() {
        let [result, error] = yield* callRequestHandler(RequestId.CONFIRM_ORDER, action.order);

        if (result) {
            const [order, error] = yield* callRequestHandler(RequestId.GET_OUTGOING_ORDER, {
                no: action.order.no,
                company: action.order.company
            });
    
            if (order) {
                yield put(putOrder(order));
            } else if (error) {
                const errorMsg = 'retail.inventory.error.on.fetch.additional.info';
                yield put(error({
                    title: i18next.t('retail.inventory.error'),
                    message: i18next.t(errorMsg)
                }));
                throw errorMsg;
            }
    
            yield put(success({
                title: i18next.t('retail.inventory.message'),
                message: i18next.t('retail.inventory.confirm.success.message')
            }));
        } else if (error) {
            const errorMsg = 'retail.inventory.confirm.error.message';
            yield put(error({
                title: i18next.t('retail.inventory.error'),
                message: i18next.t(errorMsg)
            }));
            throw errorMsg;
        }
    });
}

function* handleUpdateOrder(action: UpdateOrderAction) {
    yield *runActionButton(ActionTypes.UPDATE_ORDER, function *() {
        let [result, error] = yield* callRequestHandler(RequestId.UPDATE_ORDER, action.order);

        if (result) {
            const [order, error] = yield* callRequestHandler(RequestId.GET_OUTGOING_ORDER, {
                no: action.order.no,
                company: action.order.company
            });
    
            if (order) {
                yield put(putOrder(order));
            } else if (error) {
                yield put(error({
                    title: i18next.t('retail.inventory.error'),
                    message: i18next.t('retail.inventory.error.on.fetch.additional.info')
                }));
            }
    
            yield put(success({
                title: i18next.t('retail.inventory.message'),
                message: i18next.t('retail.inventory.update.order.success.message')
            }));
        } else if (error) {
            yield put(error({
                title: i18next.t('retail.inventory.error'),
                message: i18next.t('retail.inventory.update.order.error.message')
            }));
            throw error;
        }
    });
}

function *handleFetchOutgoingOrders(action: FetchOutgoingOrdersAction) {
    yield* runActionButton(ActionTypes.FETCH_OUTGOING_ORDERS, function *() {
        let form = action.formData;

        if (form.from && isMoment(form.from)) {
            form.from = form.from.utc().format();
        }

        if (form.until && isMoment(form.until)) {
            form.until = form.until.utc().format();
        }

        const [result, err] = yield* callFetcher(getOutgoingOrders, new RequestBuilder(), form, action.params);

        if (result) {
            yield put(receiveCachedData(RequestId.GET_OUTGOING_ORDERS, result));
        } else {
            throw err;
        }
    });
}

function *handleSaveStock(action: SaveStockAction) {
    let response: [] = [];

    yield *runActionButton(action.actionId, function *() {
        response = yield* callRequestHandler(RequestId.SAVE_STOCK, action.formData);

        if (response[0]) {
            yield put(success({
                title: i18next.t('retail.inventory.message'),
                message: i18next.t('retail.inventory.create.entry.success.message')
            }));
        } else if (response[1]) {
            const errorMsg = 'retail.inventory.create.entry.error.message';
            yield put(error({
                title: i18next.t('retail.inventory.error'),
                message: i18next.t(errorMsg)
            }));
            throw errorMsg;
        }
    });

    if (action.finish && response[0]) {
        yield put(popLocation(2));
        yield put(pushLocation(inventoryOverview.path, i18next.t(inventoryOverview.title) ,{}));
        const webParams = createWebParams(0, DEFAULT_PAGE_SIZE, {});
        const form: InventoryOverviewFilterFormData = yield select(state => state.form[INVENTORY_OVERVIEW_FORM].values);
        yield put(findInventoryEntries(form, webParams, false));
    }
}

function *handleValidateConversion(action: ValidateConversionResponseAction) {
    const validation = action.validation;

    if (validation) {
        const fromProductNo: string | undefined = validation.get('fromProductNo');
        const toProductNo: string | undefined = validation.get('toProductNo');
        const fromStockEntryNo: string | undefined = validation.get('fromStockEntryNo');
        const toStockEntryNo: string | undefined = validation.get('toStockEntryNo');
        const uomConversionIsPossible: boolean | undefined = validation.get('uomConversionIsPossible');
        const fromQuantity: string | undefined = validation.get('fromQuantity');
        const fromTotalQuantity: string | undefined = validation.get('fromTotalQuantity');

        if (fromProductNo && toProductNo && !uomConversionIsPossible) {
            yield put(error({
                title: i18next.t('retail.inventory.error'),
                message: i18next.t('retail.inventory.conversion.uom.not.possible')
            }));
        } else {
            if (fromProductNo && toProductNo && fromProductNo === toProductNo) {
                yield put(error({
                    title: i18next.t('retail.inventory.error'),
                    message: i18next.t('retail.inventory.conversion.the.same.product.warning')
                }));
            } else if (toProductNo && !toStockEntryNo) {
                yield put(success({
                    title: i18next.t('retail.inventory.message'),
                    message: i18next.t('retail.inventory.conversion.to.stock.entry.warning')
                }));
            } else if (fromProductNo && !fromStockEntryNo) {
                yield put(error({
                    title: i18next.t('retail.inventory.error'),
                    message: i18next.t('retail.inventory.conversion.from.stock.entry.warning')
                }));
            } else if (isNumber(fromQuantity) && isNumber(fromTotalQuantity) && fromQuantity > fromTotalQuantity) {
                yield put(error({
                    title: i18next.t('retail.inventory.error'),
                    message: i18next.t('retail.conversion.stock.level.is.not.sufficient', {
                        productNo: toProductNo,
                        quantity: fromTotalQuantity
                    })
                }));
            }
        }
    }
}

function *handleConvertStocks(action: ConvertStockAction) {

    let response: [] = [];

    yield* runActionButton(action.actionId, function *() {
        const { formData, validation } = action;
        formData.realmNo = getRealmNo();
    
        if (validation) {
            const uomConversionIsPossible : boolean | undefined = validation.get('uomConversionIsPossible');
    
            if (!uomConversionIsPossible) {
                yield put(error({
                    title: i18next.t('retail.inventory.error'),
                    message: i18next.t('retail.inventory.conversion.uom.not.possible')
                }));
            } else if (formData.fromProductNo === formData.toProductNo) {
                yield put(error({
                    title: i18next.t('retail.inventory.error'),
                    message: i18next.t('retail.inventory.conversion.the.same.product.warning')
                }));
            } else {
                try {
                    response = yield* callRequestHandler(RequestId.SAVE_CONVERSION, formData);
    
                    if (response[0]) {
                        yield put(success({
                            title: i18next.t('retail.inventory.message'),
                            message: i18next.t('retail.inventory.conversion.finished.success.message')
                        }));
                    } else if (response[1]) {
                        const errorMsg = 'retail.inventory.conversion.finished.error.message';
                        yield put(error({
                            title: i18next.t('retail.inventory.error'),
                            message: i18next.t('retail.inventory.conversion.finished.error.message')
                        }));
                        throw errorMsg;
                    }
                } catch (error) {
                    const errorMsg = 'retail.inventory.conversion.finished.error.message';
                    yield put(error({
                        title: i18next.t('retail.inventory.error'),
                        message: i18next.t(errorMsg)
                    }));
                    throw errorMsg;
                }
            }
        } else {
            const errorMsg = 'retail.inventory.conversion.cant.check.products.message';
            yield put(error({
                title: i18next.t('retail.inventory.error'),
                message: i18next.t(errorMsg)
            }));
            return errorMsg;
        }
    });

    if (action.finishConversion && response[0]) {
        yield put(pushLocation(inventoryOverview.path, i18next.t(inventoryOverview.title) ,{}));
        const form: InventoryOverviewFilterFormData = yield select(state => state.form[INVENTORY_OVERVIEW_FORM].values);
        const webParams = createWebParams(0, DEFAULT_PAGE_SIZE, {});
        yield put(findInventoryEntries(form, webParams, false));
    }
}

function *handleSaveCorrections(action: SaveCorrectionsAction) {
    yield* runActionButton(ActionTypes.SAVE_CORRECTIONS, function *() {
        const invalid = yield validate(action.corrections);

        if (!invalid) {
            let [result, err] = yield* callRequestHandler(RequestId.SAVE_CORRECTIONS, {
                company: getRealmNo(),
                corrections: action.corrections
            });

            if (result) {
                yield put(success({
                    title: i18next.t('retail.inventory.message'),
                    message: i18next.t('retail.inventory.correction.success.message')
                }));

                yield put(clearCorrections());
            } else if (err) {
                const errorMsg = 'retail.inventory.correction.error.message';
                yield put(error({
                    title: i18next.t('retail.inventory.error'),
                    message: errorMsg
                }));
                throw errorMsg;
            }
        }
    });

    const corrections: List<StockCorrection> = yield select(state => state.inventory.get("corrections"));

    if (!corrections || corrections.size === 0) {
        const form: InventoryOverviewFilterFormData = yield select(state => state.form[INVENTORY_OVERVIEW_FORM].values);
        const webParams = createWebParams(0, DEFAULT_PAGE_SIZE, action.sortFields);
        yield put(findInventoryEntries(form, webParams, false));
    }
}

function *handleFetchCorrectionReasons() {
    yield put(invokeRequestHandler(RequestId.GET_CORRECTION_REASONS));
}

function *handleFetchCorrectionActions() {
    yield put(invokeRequestHandler(RequestId.GET_CORRECTION_ACTION));
}

function *handleFetchUoms() {
    yield put(invokeRequestHandler(RequestId.LIST_UOMS));
}

function* handleFindStockMovements(action: FindStockMovementsAction) {
    yield *runActionButton(ActionTypes.FIND_STOCK_MOVEMENTS, function *() {
        let data =  {...action.data};

        data.company = getRealmNo();

        if (data.periodFrom && isMoment(data.periodFrom)) {
           data.periodFrom = data.periodFrom.startOf("day").format();
        }

        if (data.periodTo && isMoment(data.periodTo)) {
            data.periodTo = data.periodTo.endOf("day").format();
        }

        const [response, error] = yield* callRequestHandler(RequestId.GET_MOVEMENTS, data);
        if (error) throw error;
        yield put(receiveCachedData(RequestId.GET_MOVEMENTS, response));
    });
}

function *handleFindOrder(action: OutgoingOrderAction) {
    yield *runActionButton(ActionTypes.VIEW_OUTGOING_ORDER, function *() {
        const [result, error] = yield* callRequestHandler(RequestId.GET_OUTGOING_ORDER, {
            no: action.orderNo,
            company: action.company
        });
    
        if (result) {
            yield put(putOrder(result));
            yield put(pushLocation(detailedOutgoingOrder.path, i18next.t(detailedOutgoingOrder.titleDetailed) ,{ orderNo: action.orderNo, update: action.update }));

        } else if (error) {
            const errorMsg = 'retail.inventory.error.on.fetch.additional.info';
            yield put(error({
                title: i18next.t('retail.inventory.error'),
                message: i18next.t(errorMsg)
            }));
            throw errorMsg;
        }
    });
}

function* handleViewStockMovements(action: ViewStockMovementsAction) {
    yield put(pushLocation(stockMovementsOverview.path, i18next.t(stockMovementsOverview.titleView) ,{ productNo: action.productNo, productDescription: action.productDescription }));
}

function* handleFindInventoryEntries(action: FindInventoryEntriesAction) {
    yield* runActionButton(ActionTypes.FIND_INVENTORY_ENTRIES, function *() {
        const [result, error] = yield* callRequestHandler(RequestId.GET_STOCK_ENTRIES, action.formData, action.webParams);

        if (action.clearCorrections) {
            yield put(clearCorrections());
        }

        if (error) {
            yield put(error({
                title: i18next.t('retail.inventory.error'),
                message: '' + error
            }));
            throw error;
        }
        yield put(receiveCachedData(RequestId.GET_STOCK_ENTRIES, result));
    });
}

function *validate(correction: List<StockCorrection>) : any {

    let quantityIntegerReasonProductNo: string | undefined= undefined;
    let quantityReasonProductNo: string | undefined= undefined;
    let remarkProductNo: string | undefined = undefined;
    let actionTypeProductNo: string | undefined = undefined;
    let reasonProductNo: string | undefined = undefined;

    correction.map((correction) => {

        if (correction.newQuantity === undefined || correction.newQuantity < 0) {
            quantityReasonProductNo = correction.productNo;
        } else if (correction.integerUom === true && !Number.isInteger(correction.newQuantity)) {
            quantityIntegerReasonProductNo = correction.productNo;
        }

        if (correction.remark && correction.remark.length > 60) {
            remarkProductNo = correction.productNo;
        }

        if (!correction.actionType) {
            actionTypeProductNo = correction.productNo;
        }

        if (!correction.reason) {
            reasonProductNo = correction.productNo;
        }
    });

    if (actionTypeProductNo) {
        yield put(error({
            title: i18next.t('retail.inventory.error'),
            message: `${i18next.t('retail.inventory.correction.validate.actionType.message')} ${actionTypeProductNo}`
        }));
    }

    if (quantityReasonProductNo) {
        yield put(error({
            title: i18next.t('retail.inventory.error'),
            message: `${i18next.t('retail.inventory.correction.validate.quantity.positive.message')} ${quantityReasonProductNo}`
        }));
    }

    if (quantityIntegerReasonProductNo) {
        yield put(error({
            title: i18next.t('retail.inventory.error'),
            message: `${i18next.t('retail.inventory.correction.validate.quantity.integer.message')} ${quantityIntegerReasonProductNo}`
        }));
    }

    if (remarkProductNo) {
        yield put(error({
            title: i18next.t('retail.inventory.error'),
            message: `${i18next.t('retail.inventory.correction.validate.remark.message')} ${remarkProductNo}`
        }));
    }

    if (reasonProductNo) {
        yield put(error({
            title: i18next.t('retail.inventory.error'),
            message: `${i18next.t('retail.inventory.correction.validate.reason.message')} ${reasonProductNo}`
        }));
    }

    return quantityReasonProductNo || quantityIntegerReasonProductNo || remarkProductNo || actionTypeProductNo || reasonProductNo;
}
