import { ContextMenu } from "react-contextmenu";
import { ContextMenuItem } from "./ContextMenuItem";
import React from "react";
import { ContextMenuCommand } from "./contextMenuTypes";

export interface Props {
    commands: ContextMenuCommand[],
    t: (label: string) => string
}

export function CustomContextMenu(props: Props) {

    if (!props.commands)
        return;

    return (
        <ContextMenu id={'ctx-menu-id'}>
            { props.commands.map((command: any, idx: number) =>
                <ContextMenuItem key={idx} { ...command } t={props.t}/>
            )}
        </ContextMenu>);
}