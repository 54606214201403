import React from 'react';
import { Label, Input } from 'reactstrap';
import { BaseBinderProps } from '@pearlchain/component-lib-ui/lib/components/binders/types';

type Props = {
    checkboxLabel?: string
} & BaseBinderProps;

export default function CheckboxBinder(props: Props) {
    const checked = props.input.value || false;
    return (
        <Label check>
            <Input type="checkbox" checked={checked} disabled={!!props.disabled} onClick={() => props.input.onChange(!checked)} data-cy={props.datacy}/>
            { props.checkboxLabel }
        </Label>
    );
}
