import React from 'react';
import { Field } from 'redux-form';


import FormField from './FormField';
import { FormFieldConfig } from './types';
import { Col, Row } from 'react-bootstrap';

/**
 * Layout form-fields in a "grid"
 */

type Dims = { xs?: number, sm?: number, md?: number, lg?: number };

type Props = {
    /**
     * Fields to show. Note: wrap each config object through "formFieldConfig" to ensure full type-safety
     */
    fields: { [name: string]: FormFieldConfig },
    /**
     * Number of columns to arrange the fields into. Allows to specify a different value per media size.
     */
    numCols?: number|Dims
}

const DEFAULT_DIMS: Dims = { xs: 6 };

export default function FormFieldsLayout(props: Props) {
    const fields = props.fields;

    let dims: Dims = DEFAULT_DIMS;
    if (props.numCols != null) {
         if ( typeof props.numCols === 'number') {
            dims = { xs: 12 / props.numCols };
        } else if (props.numCols) {
            dims = { xs: 12 / (props.numCols.xs ? props.numCols.xs : 1), 
                sm: 12 / (props.numCols.sm ? props.numCols.sm : 2), 
                md: 12 / (props.numCols.md ? props.numCols.md : 3), 
                lg: 12 / (props.numCols.lg ? props.numCols.lg : 4)  };
        }
    }

    const fieldsArr = Object.keys(fields).map((key) => {
        const config = fields[key];
        return (
            <Col xs={dims.xs} sm={dims.sm} md={dims.md} lg={dims.lg}>
                <Field key={key}
                    onChange={config.onChange}
                    name={key}
                    component={FormField}
                    config={config} 
                    validate={config.validate}/>
            </Col>);
    });

    return <Row>
        { fieldsArr }
    </Row>
}
