import React from 'react';
import {ApplyCallbackDatastore, Column, ColumnAction, GridReactive, Row} from '@pearlchain/component-lib-react-pg';
import {List, Record} from 'immutable';
import {ActionButtonEvent} from '../../types/gridTypes';
import {
    DeleteProductComponentAction,
    ProductComponentFormData,
    ProductMode,
    UpdateProductComponentAction
} from "../../types/productTypes";
import {AuthorizationProps} from "../auth/authTypes";
import {connect} from "react-redux";
import {StoreState} from "../../types/storeTypes";
import {isAuthorizedByProps, mapAuthorizationProps} from "../auth/AuthUtils";
import {Auth} from "../auth/functionalityCodes";
import { useTranslation } from "react-i18next"
import { productComponentsToRows } from './helpers'
import { createExtensions, EXTS } from "../../powergrid/extensions/contextmenu/powergridHelpers";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { CustomContextMenu } from "../../powergrid/extensions/contextmenu/CustomContextMenu";
import { processExportToExcel } from "./actions";

export interface ProductGridProps extends AuthorizationProps {
    productComponents?: List<Record<ProductComponentFormData>>,
    mode: ProductMode,
    productNo: string,
    productDescription: string,
    updateProductComponentAction: (event: ActionButtonEvent, action: () => void) => UpdateProductComponentAction
    deleteProductComponentAction: (event: ActionButtonEvent) => DeleteProductComponentAction,
    processExportToExcel: (no: string, description: string, components: List<Record<ProductComponentFormData>> | undefined, productPriceIncludingVatValue: string, productPriceExcludingVatValue: string) => typeof     processExportToExcel,
    productPriceIncludingVatValue: string,
    productPriceExcludingVatValue: string
}

export function ProductComponentGrid(props: ProductGridProps) {
    const {t} = useTranslation();

    const actions: ColumnAction[] =
        [
            {
                cssClass: 'btn btn-secondary fa',
                icon: 'fa-pencil-alt',
                eventName: 'update-product-component',
                hidden: true
            },
            {
                cssClass: 'btn btn-secondary fa',
                icon: 'fa-trash-alt',
                eventName: 'delete-product-component',
                hidden: true
            }
        ];

    const disabledActions: ColumnAction[] =
        [
            {
                cssClass: 'btn btn-secondary fa disabled',
                icon: 'fa-pencil-alt',
                eventName: '',
                hidden: true
            },
            {
                cssClass: 'btn btn-secondary fa disabled',
                icon: 'fa-trash-alt',
                eventName: '',
                hidden: true
            }
        ];

    const productColumns: Column[] = [
        {_key: 'uniqueIdentifier', width: 0, title: '', idField: true},
        {
            _key: 'actions', width: 100, title: t('retail.productComponent.columns.actions'), actions: props.mode == ProductMode.VIEW ? disabledActions : actions
        },
        {_key: 'productNo', width: 150, title: t('retail.productComponent.columns.productNo')},
        {_key: 'description', width: 300, title: t('retail.productComponent.columns.description')},
        {_key: 'quantity', width: 100, title: t('retail.productComponent.columns.quantity')},
        {_key: 'uom', width: 150, title: t('retail.productComponent.columns.uom'), translateValue: true},
        {_key: 'salesPriceIncludingVatWithUOM', width: 200, title: t('retail.productComponent.columns.salesPriceIncludingVat') },
        {_key: 'salesPriceExcludingVatWithUOM', width: 200, title: t('retail.productComponent.columns.salesPriceExcludingVat') }
    ];

    const updateProductComponentEvent = {key: 'update-product-component', action: props.updateProductComponentAction};
    const deleteProductComponentEvent = {key: 'delete-product-component', action: props.deleteProductComponentAction}

    let rows: Row[] = [];
    if (props.productComponents) {
            rows = productComponentsToRows(props.productComponents,
                (x: Record<ProductComponentFormData>, index) => index + "", productColumns,t);
    }

    function resolveGlobalAttribute(row: Row, property: string, attribute: string) {
        if (property === 'update-product-component' && attribute === 'actionHidden') {
            if (isAuthorizedByProps(props, Auth.UPDATE_COMPONENT)) {
                return false;
            }
        }
        if (property === 'delete-product-component' && attribute === 'actionHidden') {
            if (isAuthorizedByProps(props, Auth.DELETE_COMPONENT)) {
                return false;
            }
        }
    }
    const extensions = buildExtensions(t, props.productComponents, () => props.processExportToExcel(props.productNo, props.productDescription, props.productComponents, props.productPriceIncludingVatValue, props.productPriceExcludingVatValue));

    return (
        <>
            <GridReactive
                gridId="product-component-grid"
                classNameOuter="wrap-grid"
                data={rows}
                columns={productColumns}
                extensions={createExtensions(extensions, undefined, `product-component-grid-id`, {translate: t})}
                events={[updateProductComponentEvent, deleteProductComponentEvent]}
                createDatastore={(data) =>
                    new ApplyCallbackDatastore(['update-product-component','delete-product-component'], ['actionHidden'], resolveGlobalAttribute, data)
                }
            />
            <CustomContextMenu commands={extensions[EXTS.CONTEXT_MENU].commands} t={t}/>
        </>
    )
}

export default connect((state: StoreState) => {
    return mapAuthorizationProps(state)
})(ProductComponentGrid)

export function buildExtensions(t: (value: string) => string, productComponents?: List<Record<ProductComponentFormData>>, action?: () => void) : any {
    return {
        [EXTS.RESIZING] : true,
        [EXTS.EDITING]: true,
        [EXTS.FORMATTING] : true,
        [EXTS.DIRECT_INPUT]: true,
        [EXTS.CONTEXT_MENU]: {
            commands: [
                {label: t('stocks.overview.export'),  disabled: !productComponents || productComponents.size === 0, action, icon: faFileExcel}
            ]
        }
    }
}