import React from 'react';
import {connect} from 'react-redux';
import {isAuthorized, mapAuthorizationProps} from "./AuthUtils";
import {Auth} from "./functionalityCodes";
import {AuthorizationProps} from "./authTypes";

export interface Props extends AuthorizationProps {
    children: React.ReactElement,
    code?: Auth,
}

export function SecuredComponent(props: Props) {
    return isAuthorized(props.functionalityCodes, props.isCorporateAdmin, props.code) ? props.children : null
}

export default connect(mapAuthorizationProps)(SecuredComponent)
