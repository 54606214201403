import {
    FormFieldConfig,
    formFieldConfig,
    TextBinder,
    SelectBinder
} from "@pearlchain/component-lib-ui";
import { CompanyBinder } from '@pearlchain/stackbase-common';

import {
    required, requiredValidator
} from "../util/validators";
import { validateCompanyBinder } from "../common/binders/ValidateCompanyBinder";
import {Node} from "../combobox/node";
import {Option} from "../combobox/types";
import { Company } from "@pearlchain/stackbase-common/lib/components/CompanyBinder";

export function createProductFormFields(readonly: boolean, companyChangeCallBack: (value: any) => void, opt : (source: string) => Option[], companies: Company[] | undefined) {
    const groupThreeLenght = opt(Node.GIFT_PRODUCT_GROUP3).length;

    const formFields: { [key: string]: FormFieldConfig }[] = [
        {
            companyCode: formFieldConfig({
                id: "company-binder-id",
                label: 'retail.product.formFields.company',
                translateLabel: true,
                binder: CompanyBinder,
                validate: validateCompanyBinder(companies),
                onChange: companyChangeCallBack,
                disabled: readonly
            }),
            productNo: formFieldConfig({label: 'retail.product.formFields.no', translateLabel: true, binder: TextBinder, disabled:true}),
            description: formFieldConfig({label: 'retail.product.formFields.description', translateLabel: true, binder: TextBinder, validate: required, disabled: readonly}),
            productGroup2: formFieldConfig({label: 'retail.product.formFields.group2', translateLabel: true, binder: SelectBinder, options: opt( Node.GIFT_PRODUCT_GROUP2), disabled: readonly, validate: requiredValidator()  }),
            productGroup3: formFieldConfig({label: 'retail.product.formFields.group3', translateLabel: true, binder: SelectBinder, options: opt( Node.GIFT_PRODUCT_GROUP3), 
                disabled: readonly, validate: groupThreeLenght > 0 ? requiredValidator() :  undefined})
        },
        {
            remark: formFieldConfig({label: 'retail.product.formFields.remark', translateLabel: true, binder: TextBinder, disabled: readonly}),
            remark2: formFieldConfig({label: 'retail.product.formFields.remark2', translateLabel: true, binder: TextBinder, disabled: readonly})
        },
        {
            eanNo: formFieldConfig({label: 'retail.product.formFields.eanNo', translateLabel: true, binder: TextBinder, disabled: true}),
        },
        {
            salesPrice: formFieldConfig({label: 'retail.product.formFields.salesPriceExcludingVat', translateLabel: true, binder: TextBinder, disabled: false}),
            salesPriceIncludingVat: formFieldConfig({label: 'retail.product.formFields.salesPriceIncludingVat', translateLabel: true, binder: TextBinder, disabled: true}),
        },
        {

        }];
    return formFields;
}

