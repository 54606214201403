import React, { Fragment } from 'react';
import { connect } from "react-redux";
import { Alert } from "react-bootstrap";
import { List, Record } from 'immutable';
import { useTranslation } from 'react-i18next';

import { StoreState } from "../../types/storeTypes";
import { getValidationMessages } from '../procurement/selectors';
import { ValidationMessage, ValidationMessageType } from '../../types/procurementTypes';

interface Props {
    messages?: List<Record<ValidationMessage>>
}

function Alerts(props: Props) {
    const { t } = useTranslation()
    if (props.messages && props.messages.size > 0) {
        return (
        <Fragment>
            {props.messages.map((message: Record<ValidationMessage>, index) => {

                let variant;
                if (ValidationMessageType.WARNING === message.get('type')) {
                    variant = 'warning';
                } else {
                    variant = 'danger';
                }

                return <Alert key={index} color={variant}>{t(message.get('message'))}</Alert>;
            })}
        </Fragment>)
    } else {
        return <Fragment/>
    } 
}

export default connect((state: StoreState) => {
    return {
        messages: getValidationMessages(state)
    }
}, {})(Alerts)
