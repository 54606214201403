import {List} from "immutable";
import {Auth} from "./functionalityCodes";
import {Page} from "../../types/pageTypes";
import {StoreState} from "../../types/storeTypes";
import {AuthorizationProps} from "./authTypes";

const KEY_LANGUAGE = 'language';

export function isAuthorizedByProps(props : AuthorizationProps, requestedFunctionalityCode?: Auth) {
    return isAuthorized(props.functionalityCodes, props.isCorporateAdmin, requestedFunctionalityCode);
}

export function isAuthorized(functionalityCodes: List<string>, isCorporateAdmin: boolean, requestedFunctionalityCode?: Auth) {
    return !requestedFunctionalityCode || isCorporateAdmin || functionalityCodes && functionalityCodes.contains(requestedFunctionalityCode);
}

export function isPageAuthorized(functionalityCodes: List<string>, isCorporateAdmin: boolean, page: Page) {
    return isAuthorized(functionalityCodes, isCorporateAdmin, page.functionalityCode);
}


export function mapAuthorizationProps(state: StoreState) {
    return {
        functionalityCodes: state.userInfo.get('functionalityCodes'),
        isCorporateAdmin: state.userInfo.get('isCorporateAdmin')
    }
}

export function getLanguage() {
    return window.localStorage.getItem(KEY_LANGUAGE)
}
