import {Column} from "@pearlchain/component-lib-react-pg";

export default function purchaseOrderColumns(t: (value: string) => string) : Column[] {
    return [
        { _key: 'uniqueIdentifier', width: 0, title: '', idField: true },
        { _key: 'actions', width: 120, title: t('retail.procurement.overview.grid.actions'), actions: [
                {
                    cssClass: 'btn btn-secondary fa',
                    icon: 'fa-eye',
                    eventName: 'view-purchase-order',
                    hidden: true
                },
                {
                    cssClass: 'btn btn-secondary fa',
                    icon: 'fa-pencil-alt',
                    eventName: 'update-purchase-order',
                    hidden: true
                },
                {
                    cssClass: 'btn btn-secondary fa',
                    icon: 'fa-receipt',
                    eventName: 'receive-purchase-order',
                    hidden: true
                }
            ]},
        { _key: 'companyCode', width: 150, title: t('retail.procurement.overview.grid.companyCode') },
        { _key: 'no', width: 150, title: t('retail.procurement.overview.grid.no') },
        { _key: 'description', width: 150, title: t('retail.procurement.overview.grid.description') },
        { _key: 'supplierName', width: 150, title: t('retail.procurement.overview.grid.supplierName') },
        { _key: 'orderDate', width: 150, title: t('retail.procurement.overview.grid.orderDate'), formatter: 'date' },
        { _key: 'requestedDeliveryDate', width: 150, title: t('retail.procurement.overview.grid.requestedDeliveryDate'), formatter: 'date' },
        { _key: 'status', width: 150, title: t('retail.procurement.overview.grid.status'), formatter: 'translate' }
    ];
}