import {all, put, select, takeEvery} from "redux-saga/effects";
import {RequestBuilder, callFetcher, callRequestHandler, pushLocation, receiveCachedData, receiveCachedError} from '@pearlchain/component-lib-common';
import { change } from 'redux-form';

import ActionTypes, {
    addNewProductComponent,
    clearProductComponentData,
    clearProductComponentIdxData,
    clearProductComponentsData,
    clearProductData, newProduct,
    saveProduct,
    saveProductComponent, storeDefaultCompanyCurrency,
    storeProduct,
    storeProductComponent,
    storeProductComponentIdx,
    storeProductComponents,
    storeVatInfo
} from "./actions";

import {getProductComponentIdx, getProductComponents} from "./selectors";
import {RequestId} from "../remote/requests";
import {
    AddProductComponentAction,
    DeleteProductComponentAction, GetDefaultCompanyCurrencyAction,
    NewProductAction,
    ProductMode,
    SaveAndNewProductAction,
    SaveAndNewProductComponentAction,
    SaveProductAction,
    SaveProductComponentAction,
    UpdateProductAction,
    UpdateProductComponentAction,
    ViewProductAction,
    GenerateProductNoAction,
    ListProductsAction,
    ProcessExportToExcelAction
} from "../../types/productTypes";
import {productPage} from "./productPage";
import {success, error} from "react-notification-system-redux";
import { generateProductNo, findProductSameDescription } from "../remote/api";
import { createWebParams } from "../remote/requestTypes";
import XLSX from "xlsx";
import * as i18next from "i18next";
import { runActionButton, lockActionButtons } from "../util/sagaUtils";

export default function () {
    return all([
        takeEvery(ActionTypes.LIST_PRODUCTS, handleListProducts),
        takeEvery(ActionTypes.NEW_PRODUCT, handleNewProduct),
        takeEvery(ActionTypes.SAVE_PRODUCT, handleSaveProduct),
        takeEvery(ActionTypes.SAVE_AND_NEW_PRODUCT, handleSaveAndNewProduct),
        takeEvery(ActionTypes.VIEW_PRODUCT, handleViewProduct),
        takeEvery(ActionTypes.UPDATE_PRODUCT, handleUpdateProduct),
        takeEvery(ActionTypes.ADD_PRODUCT_COMPONENT, handleAddProductComponent),
        takeEvery(ActionTypes.SAVE_PRODUCT_COMPONENT, handleSaveProductComponent),
        takeEvery(ActionTypes.SAVE_AND_NEW_PRODUCT_COMPONENT, handleSaveAndNewProductComponent),
        takeEvery(ActionTypes.GENERATE_PRODUCT_NO, handleGenerateProductNo),
        takeEvery(ActionTypes.UPDATE_PRODUCT_COMPONENT, handleUpdateProductComponent),
        takeEvery(ActionTypes.DELETE_PRODUCT_COMPONENT, handleDeleteProductComponent),
        takeEvery(ActionTypes.GET_DEFAULT_COMPANY_CURRENCY, handleGetDefaultCompanyCurrency),
        takeEvery(ActionTypes.PROCESS_EXPORT_EXCEL, handleProcessExportToExcel)
    ]);
}

function *handleProcessExportToExcel(action: ProcessExportToExcelAction) {

    yield put(success({
        title: i18next.t('retail.inventory.message'),
        message: i18next.t('retail.inventory.export.started.message')
    }));

    const toExport  = action.components.toJS();

    toExport.forEach((row) => {
        delete row.denominator;
        delete row.denominatorUOM;
        delete row.efUnitOfMeasurement;
        delete row.numerator;
        delete row.productId;
        delete row.purchasePrice;
        delete row.uniqueIdentifier;
        delete row.companyCode;
        delete row.purchasePriceValue;

        row.uom = i18next.t(row.uom);
        row.roundedSalesPriceInclVat = row.salesPriceIncludingVatValue.toFixed(2);
        row.roundedSalesPriceExclVat = row.salesPriceValue.toFixed(2);
        row.finalPriceUom = row.currency + '/' + i18next.t(row.priceUom);
        row.salesPriceTimesQuantityValue = (row.quantity * row.salesPriceValue).toFixed(2);
        row.salesPriceIncludingVatTimesQuantityValue = (row.quantity * row.salesPriceIncludingVatValue).toFixed(2);


        delete row.priceUom;
        delete row.salesPriceValue;
        delete row.salesPriceIncludingVatValue;
        delete row.salesPrice;
        delete row.salesPriceIncludingVat;
    });

    toExport.push({});

    toExport.push({
        'salesPriceTimesQuantityValue' : i18next.t("retail.gift.export.totalPrice.label"),
        'salesPriceIncludingVatTimesQuantityValue': i18next.t("retail.gift.export.totalPriceInclVat.label"),
    });

    toExport.push({
        'salesPriceTimesQuantityValue'  : action.productPriceExcludingVatValue,
        'salesPriceIncludingVatTimesQuantityValue' : action.productPriceIncludingVatValue,
    });

    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(toExport, {
        header : [
            "productNo",
            "description",
            "quantity",
            "uom",
            "roundedSalesPriceInclVat",
            "roundedSalesPriceExclVat",
            "finalPriceUom",
            "salesPriceTimesQuantityValue",
            "salesPriceIncludingVatTimesQuantityValue",
            "currency"
        ]
    });

    const rangeString: string | undefined =  worksheet['!ref'];

    if (rangeString) {
        const range: XLSX.Range = XLSX.utils.decode_range(rangeString);

        for (let C = 0; C <= range.e.c; ++C) {
            let address = XLSX.utils.encode_col(C) + "1";

            if (!worksheet[address]) continue;

            switch (worksheet[address].v) {
                case "productNo": worksheet[address].v = i18next.t("retail.gift.export.header.productNo"); break;
                case "description": worksheet[address].v = i18next.t("retail.gift.export.header.description"); break;
                case "quantity": worksheet[address].v = i18next.t("retail.gift.export.header.quantity"); break;
                case "uom": worksheet[address].v = i18next.t("retail.gift.export.header.uom"); break;
                case "roundedSalesPriceInclVat": worksheet[address].v = i18next.t("retail.gift.export.header.salesPriceIncludingVatValue"); break;
                case "roundedSalesPriceExclVat": worksheet[address].v = i18next.t("retail.gift.export.header.salesPriceValue"); break;
                case "finalPriceUom": worksheet[address].v = i18next.t("retail.gift.export.header.finalPriceUom"); break;
                case "salesPriceTimesQuantityValue": worksheet[address].v = i18next.t("retail.gift.export.header.salesPriceTimesQuantityValue"); break;
                case "salesPriceIncludingVatTimesQuantityValue": worksheet[address].v = i18next.t("retail.gift.export.header.salesPriceIncludingVatTimesQuantityValue"); break;
                case "currency": worksheet[address].v = i18next.t("retail.gift.export.header.currency"); break;
            }
        }

        worksheet['!cols'] = [
            {wch:25},
            {wch:50},
            {wch:10},
            {wch:10},
            {wch:20},
            {wch:20},
            {wch:20},
            {wch:20},
            {wch:20}
        ];

        console.log(worksheet)

        XLSX.utils.book_append_sheet(wb,  worksheet, "");
        XLSX.writeFile(wb, `${action.no}.xlsx`);

        yield put(success({
            title: i18next.t('retail.inventory.message'),
            message: i18next.t('retail.inventory.export.finished.message')
        }));
    } else {
        yield put(error({
            title: i18next.t('retail.inventory.error'),
            message: i18next.t('retail.inventory.export.error.message')
        }));
    }
}

function* handleListProducts(action: ListProductsAction) {
    yield* runActionButton(ActionTypes.LIST_PRODUCTS, function *() {
        const [result, err] = yield* callRequestHandler(RequestId.LIST_PRODUCTS, action.formData, action.webParams);
        if(result) {
            yield put(receiveCachedData(RequestId.LIST_PRODUCTS, result));

            if (result.get(0)) {
                const code = result.get(0).get('vatCountryCode');
                yield put(!!code ? storeVatInfo(code) : error({ title: 'VAT', message: "Country for VAT calculation could not be determined" }));
            }
        } else {
            yield put(receiveCachedError(RequestId.LIST_PRODUCTS, err));
            throw err;
        }
    });
}

function* handleNewProduct(action: NewProductAction) {
    yield put(clearProductData());
    yield put(clearProductComponentData());
    yield put(clearProductComponentsData());
    yield put(clearProductComponentIdxData());
    yield put(pushLocation(productPage.path, i18next.t('product.title.new'), { mode: ProductMode.NEW }));
}

function* handleAddProductComponent(action: AddProductComponentAction) {
    yield put(clearProductComponentData());
    action.openModalFn();
}

function* handleSaveProduct(action: SaveProductAction) {
    yield* runActionButton(action.actionButtonId, function *() {
        const productComponents = yield select(getProductComponents);
        const product = action.data.set('components', productComponents);
        yield put(storeProduct(product));

        if (action.mode == ProductMode.NEW && !product.get("uniqueIdentifier")) {
            const pagingParams = createWebParams(0, 1, {});
            const [foundProducts] = yield* callFetcher(findProductSameDescription, new RequestBuilder(), {
                description: product.get('description'),
                companyCode: product.get('companyCode')
            }, pagingParams);

            if (foundProducts) {
                // check if any products exist matching this criteria
                if (!foundProducts.isEmpty()) {
                    const foundProductNo = foundProducts.get(0).get('productNo');
                    let message = i18next.t('retail.product.same.description', {foundProductNo: foundProductNo});
                    let title = i18next.t('retail.product.same.description.title');
                    yield put(error({ title, message }));
                    return;
                }

            } else {
                // API request returned an error
                return;
            }
        }

        const [uuid] = yield* callRequestHandler(RequestId.SAVE_PRODUCT, product);
        if (uuid) {
            action.setUUID && action.setUUID(uuid.get("result"));
            yield put(success({title: i18next.t('retail.product.gift.creation.title'), message : i18next.t('retail.product.gift.creation.message')}));
        }
    });
}

function* handleSaveAndNewProduct(action: SaveAndNewProductAction) {
    yield put(clearProductData());
    yield put(clearProductComponentData());
    yield put(clearProductComponentsData());
    yield put(clearProductComponentIdxData());
}

function *handleGenerateProductNo(action: GenerateProductNoAction) {
    yield *lockActionButtons(function *() {
        const [response] = yield* callFetcher(generateProductNo);
        if (response) {
            const productNo = response.productNo;
            yield put(change(action.form, action.field, productNo));
            //Temporal while michael is back prodcutNo and eanNo neeed to be the same
            yield put(change("product-form", "eanNo", productNo));
        }
    });
}

function* handleUpdateProduct(action: UpdateProductAction) {
    yield *runActionButton(ActionTypes.UPDATE_PRODUCT, function *() {
        const [product, error] = yield* callRequestHandler(RequestId.FIND_PRODUCT, {productUUID: action.productUUID, vatCountryCode: action.vatCountryCode});
        if (error) {
            yield put(error({
                title: 'Product',
                message: 'Product with uuid = ' + action.productUUID + ' doesn\'t exist'
            }));
            throw error;
        }

        yield put(storeProduct(product));
        const components = product.get("components");
        if (components) {
            yield put(storeProductComponents(components));
        }
        yield put(pushLocation(productPage.path, i18next.t('product.title.udpate'), { mode: ProductMode.UPDATE }));
    });
}

function* handleViewProduct(action: ViewProductAction) {
    yield* runActionButton(ActionTypes.VIEW_PRODUCT, function *() {
        const [product, error] = yield* callRequestHandler(RequestId.FIND_PRODUCT, { productUUID: action.productUUID, vatCountryCode: action.vatCountryCode });
        if (error) {
            yield put(error({
                title: 'Product',
                message: 'Product with uuid = ' + action.productUUID + ' doesn\'t exist'
            }));
            throw error;
        }

        yield put(storeProduct(product));
        const components = product.get("components");
        if (components) {
            yield put(storeProductComponents(components));
        }
        yield put(pushLocation(productPage.path, i18next.t('product.title.view'), { mode: ProductMode.VIEW }));
    });
}

function* handleSaveProductComponent(action: SaveProductComponentAction) {
    try {
        if (action.newProductComponent) {
            yield put(addNewProductComponent(action.data));
        } else {
            const productComponents = yield select(getProductComponents);
            const index = yield select(getProductComponentIdx);
            const updatedLines = productComponents.set(index, action.data);
            yield put(storeProductComponents(updatedLines));
        }
        yield put(clearProductComponentData());
        yield put(clearProductComponentIdxData());
    } catch (error) {
        console.error(error);
    }
}

function* handleUpdateProductComponent(action: UpdateProductComponentAction) {
    try {
        const productComponents = yield select(getProductComponents);
        const productComponent = productComponents.get(action.productComponentIdx);
        yield put(storeProductComponent(productComponent));
        yield put(storeProductComponentIdx(action.productComponentIdx));
        action.openModalFn();
    } catch (error) {
        console.error(error);
    }
}

function* handleDeleteProductComponent(action: DeleteProductComponentAction) {
    try {
        const productComponents = yield select(getProductComponents);
        const updatedLines = productComponents.remove(action.productComponentIdx);
        yield put(storeProductComponents(updatedLines));
    } catch (error) {
        console.error(error);
    }
}

function* handleSaveAndNewProductComponent(action: SaveAndNewProductComponentAction) {
    yield put(saveProductComponent(action.data, action.mode, action.newProductComponent));
    action.reset();
}

function* handleGetDefaultCompanyCurrency(action: GetDefaultCompanyCurrencyAction) {
    yield* lockActionButtons(function *() {
        const [currency] = yield* callRequestHandler(RequestId.GET_DEFAULT_COMPANY_CURRENCY, { companyCode: action.companyCode });
        yield put(storeDefaultCompanyCurrency(currency));
    });
}
