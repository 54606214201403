import {
    findPurchaseOrders, getPurchaseOrder, listPurchaseOrderStatuses, savePurchaseOrder, sendPurchaseOrder,
    cancelPurchaseOrder, listUoms, listCurrencies, getUserInfo, registerPurchaseOrderLines, getDefaultCompanyCurrency,
    getDefaultCompanySupplier, listProducts, findProduct, listAllCompanies, saveProduct, findProductComponent, fetchOptions,
    getProductGroups, getStockEntries, getMovements, saveCorrections, validateConversion, saveConversion, saveStock,
    getProductInfo, getCorrectionReasons, getCorrectionActions, getOrderStatuses, getOutgoingOrders, getOutgoingOrder,
    updateOrder, postConfirmOrder, getProductLabelsDoc, listProductUoms, getSystemParam,
    updateOrder, postConfirmOrder, getProductLabelsDoc, listProductUoms, savePurchaseOrderLine, deletePurchaseOrderLine
} from './api';
import { RequestHandler } from '@pearlchain/component-lib-common';

// the actual keys of the store where you can find the fetched data
export enum RequestId {
    COMPANIES = 'companies',
    FIND_PURCHASE_ORDERS = 'find-purchase-orders',
    GET_PURCHASE_ORDER = 'get-purchase-order',
    LIST_PURCHASE_ORDER_STATUSES = 'list-purchase-order-statuses',
    SAVE_PURCHASE_ORDER = 'save-purchase-order',
    SEND_PURCHASE_ORDER = 'send-purchase-order',
    CANCEL_PURCHASE_ORDER = 'cancel-purchase-order',
    SAVE_PURCHASE_ORDER_LINE = 'save-purchase-order-line',
    DELETE_PURCHASE_ORDER_LINE = 'delete-purchase-order-line',
    LIST_UOMS = 'list-uoms',
    LIST_PRODUCT_UOMS = 'list-product-uoms',
    LIST_CURRENCIES = 'list-currencies',
    GET_USER_INFO = 'get-user-info',
    GET_DEFAULT_COMPANY_CURRENCY = 'get-default-company-currency',
    GET_DEFAULT_COMPANY_SUPPLIER = 'get-default-company-supplier',
    LIST_ALL_COMPANIES = 'get-all-companies',
    REGISTER_PURCHASE_ORDER_LINES = 'register-purchase-order-lines',
    LIST_PRODUCTS = 'list-products',
    FIND_PRODUCT = 'find-product',
    FIND_PRODUCT_COMPONENT = 'find-product-component',
    SAVE_PRODUCT = 'save-product',
    FETCH_OPTIONS = 'fetch-options',
    GET_PRODUCT_GROUPS = 'get-product-groups',
    GET_STOCK_ENTRIES = 'get-stock-entries',
    GET_STOCK_ENTRIES_FOR_EXPORT = 'get-stock-entries-for-export',
    GET_MOVEMENTS = 'get-movements',
    GET_CORRECTION_REASONS = 'get-correction-reasons',
    GET_CORRECTION_ACTION = 'get-correction-actions',
    SAVE_CORRECTIONS = 'save-corrections',
    VALIDATE_CONVERSION = 'validate-conversion',
    SAVE_CONVERSION = 'save-conversion',
    SAVE_STOCK = 'save-stock',
    GET_PRODUCT_INFO = 'get-product-info',
    GET_ORDER_STATUSES = 'get-order-statuses',
    GET_OUTGOING_ORDERS = 'get-outgoing-orders',
    GET_OUTGOING_ORDER = 'get-outgoing-order',
    UPDATE_ORDER = 'update-order',
    CONFIRM_ORDER = 'post-confirm-order',
    GET_PRODUCT_LABELS_DOC = 'get-product-labels-doc',
    GET_SYSTEM_PARAMETER = 'get-system-parameter'
}

export const RequestHandlers: { [key: string]: RequestHandler } = {
    [RequestId.FIND_PURCHASE_ORDERS]: findPurchaseOrders,
    [RequestId.GET_PURCHASE_ORDER]: getPurchaseOrder,
    [RequestId.LIST_PURCHASE_ORDER_STATUSES]: listPurchaseOrderStatuses,
    [RequestId.SAVE_PURCHASE_ORDER]: savePurchaseOrder,
    [RequestId.SEND_PURCHASE_ORDER]: sendPurchaseOrder,
    [RequestId.CANCEL_PURCHASE_ORDER]: cancelPurchaseOrder,
    [RequestId.SAVE_PURCHASE_ORDER_LINE]: savePurchaseOrderLine,
    [RequestId.DELETE_PURCHASE_ORDER_LINE]: deletePurchaseOrderLine,
    [RequestId.LIST_UOMS]: listUoms,
    [RequestId.LIST_PRODUCT_UOMS]: listProductUoms,
    [RequestId.LIST_CURRENCIES]: listCurrencies,
    [RequestId.GET_USER_INFO]: getUserInfo,
    [RequestId.GET_DEFAULT_COMPANY_CURRENCY]: getDefaultCompanyCurrency,
    [RequestId.GET_DEFAULT_COMPANY_SUPPLIER]: getDefaultCompanySupplier,
    [RequestId.LIST_ALL_COMPANIES]: listAllCompanies,
    [RequestId.REGISTER_PURCHASE_ORDER_LINES]: registerPurchaseOrderLines,
    [RequestId.LIST_CURRENCIES]: listCurrencies,
    [RequestId.FIND_PRODUCT]: findProduct,
    [RequestId.FIND_PRODUCT_COMPONENT]: findProductComponent,
    [RequestId.LIST_PRODUCTS]: listProducts,
    [RequestId.SAVE_PRODUCT]: saveProduct,
    [RequestId.FETCH_OPTIONS]: fetchOptions,
    [RequestId.FETCH_OPTIONS]: fetchOptions,
    [RequestId.GET_PRODUCT_GROUPS]: getProductGroups,
    [RequestId.GET_STOCK_ENTRIES]: getStockEntries,
    [RequestId.GET_STOCK_ENTRIES_FOR_EXPORT]: getStockEntries,
    [RequestId.GET_MOVEMENTS]: getMovements,
    [RequestId.GET_CORRECTION_REASONS]: getCorrectionReasons,
    [RequestId.GET_CORRECTION_ACTION]: getCorrectionActions,
    [RequestId.SAVE_CORRECTIONS]: saveCorrections,
    [RequestId.VALIDATE_CONVERSION]: validateConversion,
    [RequestId.SAVE_CONVERSION]: saveConversion,
    [RequestId.SAVE_STOCK]: saveStock,
    [RequestId.GET_PRODUCT_INFO]: getProductInfo,
    [RequestId.GET_ORDER_STATUSES]: getOrderStatuses,
    [RequestId.GET_OUTGOING_ORDERS]: getOutgoingOrders,
    [RequestId.GET_OUTGOING_ORDER]: getOutgoingOrder,
    [RequestId.UPDATE_ORDER]: updateOrder,
    [RequestId.CONFIRM_ORDER]: postConfirmOrder,
    [RequestId.GET_PRODUCT_LABELS_DOC]: getProductLabelsDoc,
};
