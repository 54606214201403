import { StoreState } from '../../types/storeTypes'
import { List, Record, Map } from 'immutable'
import {
    Option,
    StockCorrection,
    StockMovementOverviewItem,
    ConversionValidationResponse,
    OutgoingSalesOrderItem,
    InventoryOverviewItem
} from '../../types/inventoryTypes'
import { RequestId } from '../remote/requests'
import { getCachedRequest } from '@pearlchain/component-lib-common';
import * as i18next from "i18next";

export function getConversionValidationResponse(state: StoreState) : Record<ConversionValidationResponse> {
    return  state.inventory.get('conversionValidationResponse') || Map();
}

export function getInventorySearchResultFromState(state: StoreState): List<Record<InventoryOverviewItem>> {
    const entries = state.common.requests.getIn(['requests', RequestId.GET_STOCK_ENTRIES]);
    return entries ? entries.data : List();
}

export function getOutgoingOrdersFromState(state: StoreState): List<Record<OutgoingSalesOrderItem>> | undefined {
    const request = getCachedRequest(state, RequestId.GET_OUTGOING_ORDERS);
    return request ? request.get('data') as (List<Record<OutgoingSalesOrderItem>> | undefined) : undefined;
}

export function getInventoryCorrectionsFromState(state: StoreState)  : List<StockCorrection> {
    return state.inventory.corrections || List();
}

export function getStockMovementEntriesFromState(state: StoreState)  : List<Record<StockMovementOverviewItem>> {
    const entries = state.common.requests.getIn(['requests', RequestId.GET_MOVEMENTS]);
    return entries ? entries.data : List();
}

export function getReasonsFromState(state: StoreState)  : List<Record<Option>> {
    const entries = state.common.requests.getIn(['requests', RequestId.GET_CORRECTION_REASONS]);
    return entries ? entries.data : List();
}

export function getCorrectionActionsFromState(state: StoreState)  : List<Record<Option>> {
    const entries = state.common.requests.getIn(['requests', RequestId.GET_CORRECTION_ACTION]);
    if (entries) {
        return entries.data.map((record : Record<Option>) => Map({'label' : i18next.t(record.get('key') + '.' + record.get('label')), 'value' : record.get('value')}));
    }
    return List();
}

