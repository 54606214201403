import React from 'react';
import { List } from 'immutable';
import { WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form';
import { RequestIdFetcher } from '@pearlchain/component-lib-common';

import { RequestId } from '../../remote/requests';
import { Option } from "../../common/binders/SelectBinder";
import SelectBinder from '../../common/binders/SelectBinder';

interface Props {
    input: WrappedFieldInputProps;
    meta: WrappedFieldMetaProps;
    dependsOn?: {};
    disabled: boolean;
}

export default function ProductGroupBinder(props: Props) {
    return <RequestIdFetcher
        requestId={RequestId.GET_PRODUCT_GROUPS}
        render={(data: List<string> | undefined) => {
            const opts = dataToOptions(data);
            return <SelectBinder translateOptions={true} options={opts} { ...props }/>
        }}
    />
}

export function dataToOptions(data: List<string> | undefined): Array<Option<string>> {
    return data ? data.map((d) => ({ value: d, label: d})).toArray() : [];
}
