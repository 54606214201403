import { List, Record } from "immutable";
import { Option } from "../common/binders/SelectBinder";
import { StoreState } from "../../types/storeTypes";
import { getCachedRequest } from "@pearlchain/component-lib-common";

export const DEFAULT_PAGE_SIZE = 30;

export function dataToOptions(data: List<Record<Option<string>>> | undefined): Array<Option<string>> {
    return data ? data.map((d) => ({ value: d.get('value'), label: d.get('label') })).toArray() : [];
}

/**
 * Checks if any of the provided request ids is fetching
 */
export function isRequestBusy(state: StoreState, requests: string[]) : boolean {
    for (let request of requests) {
        const req = getCachedRequest(state, request);
        if (req && req.isFetching) {
            return true;
        }
    }
    return false;
}

/**
 * Rounds a number to the specified degree avoiding issues caused by floating point accuracy loss. Example: round(1.005, -2) gives 1.01
 * value: number to round
 * exp: degree of 10 to round to. 
 */
 export function round(value: number|string, exp: number|string): number {
    value = +value;
    exp = +exp;
    if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
      return NaN;
    }
    let parts = value.toString().split('e');
    value = Math.round(+(parts[0] + 'e' + (parts[1] ? (+parts[1] - exp) : -exp)));
    parts = value.toString().split('e');
    return +(parts[0] + 'e' + (parts[1] ? (+parts[1] + exp) : exp));
}
