import React, { useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import { ButtonToolbar, Form } from 'react-bootstrap'
import { InjectedFormProps, reduxForm } from 'redux-form'
import { List, Record } from 'immutable'
import * as i18next from 'i18next'
import { pushLocation, popLocation, getLocationParam, replaceLocation } from '@pearlchain/component-lib-common'
import { FormFieldsLayout } from '@pearlchain/component-lib-ui'

import { StoreState } from '../../../../types/storeTypes'
import { Auth } from '../../../auth/functionalityCodes'
import Secured from '../../../auth/Secured'

import DetailedOutgoingOrderLinesGrid from './DetailedOutgoingOrderLinesGrid'
import { detailedOrderFormFieldsCreator } from './detailedOrderFormFieldsCreator'
import { OutgoingSalesOrderItem, SalesOrderLine, UpdateSalesOrderForm } from '../../../../types/inventoryTypes'
import ActionTypes, { updateOrderLineInOrder, updateOrder, confirmOrder } from '../../actions'
import { useTranslation } from "react-i18next";
import AsyncActionButton from '../../../actionButton/AsyncActionButton'

interface Props {
    router: any,
    orderNo: string,
    update: boolean,
    initialValues: any,
    order: Record<OutgoingSalesOrderItem> | undefined,
    lines: List<Record<SalesOrderLine>> | undefined,
    pushLocation: (path: string, title: string, params: any) => void,
    replaceLocation: (path: string, title: string, params: any) => void,
    popLocation: (steps: number) => void,
    updateOrderLineInOrder: (line: SalesOrderLine) => void,
    updateOrder: (order: UpdateSalesOrderForm) => void,
    confirmOrder: (order: UpdateSalesOrderForm) => void
}

function DetailedOutgoingOrderPage(props: InjectedFormProps<UpdateSalesOrderForm, Props> & Props) {

    const {t} = useTranslation();

    const formFields = useMemo(() => {
        return detailedOrderFormFieldsCreator(true);
    }, []);

    useEffect(() => {
        props.initialize(props.initialValues)
        return function cleanup() {
            props.reset();
        }
    }, []);

    return (
        <Secured code={Auth.INVENTORY_VIEW_ORDER}>
            <Form className="page-container">
                {props.update &&
                    <ButtonToolbar className="mt-2 mb-3">
                        <Secured code={Auth.INVENTORY_UPDATE_ORDER}>
                            <AsyncActionButton
                                actionId={ActionTypes.UPDATE_ORDER}
                                variant="primary"
                                type="submit"
                                disabled={nothingToSave(props.lines)}
                                onAction={props.handleSubmit(props.updateOrder)}
                            >
                                {t('retail.inventory.button.save')}
                            </AsyncActionButton>
                        </Secured>
                        <Secured code={Auth.INVENTORY_CONFIRM_ORDER}>
                            <AsyncActionButton
                                actionId={ActionTypes.CONFIRM_ORDER}
                                variant="primary"
                                className="mr-2"
                                disabled={props.order && props.order.get('status') === 'SalesOrderStatus.Confirmed'}
                                onAction={props.handleSubmit(props.confirmOrder)}
                            >
                                {t('retail.inventory.button.confirm')}
                            </AsyncActionButton>
                        </Secured>
                    </ButtonToolbar>}
                <FormFieldsLayout fields={formFields} numCols={{ xs: 1, sm: 2, md: 3, lg: 3 }}/>
                {props.lines &&
                    <DetailedOutgoingOrderLinesGrid update={props.update} lines={props.lines} updateOrderLineInOrder={props.updateOrderLineInOrder}/>
                }
            </Form>
        </Secured>
    )
}

function nothingToSave(lines: List<Record<SalesOrderLine>>) : boolean {
    return lines && !(lines.filter((line: Record<SalesOrderLine>) => line.get('updated')).size > 0);
}

const DetailedOutgoingOrderPageForm = reduxForm<UpdateSalesOrderForm, Props>({
    form: 'outgoing-orders-overview-form'
})(DetailedOutgoingOrderPage);

export default connect((state: StoreState) => {
    const update = getLocationParam<boolean>('update', false);
    const order: Record<OutgoingSalesOrderItem> | undefined = state.inventory.get('order');

    if (order) {
        const initialValues = order.toJS();

        let totalPrice = 0;

        order.get('lines').map((line: Record<SalesOrderLine>) =>
            totalPrice = totalPrice + line.get('totalPrice')
        );

        initialValues.totalPrice = totalPrice;
        initialValues.status = i18next.t(initialValues.status);

        return  {
            order,
            initialValues,
            lines: order.get('lines'),
            update
        }
    } else {
        return {
            update
        }
    }
}, { pushLocation, popLocation, updateOrderLineInOrder, updateOrder, confirmOrder, replaceLocation })(DetailedOutgoingOrderPageForm);
