import ProductOverview from "./ProductOverview";
import Product from "./Product";
import {Auth} from "../auth/functionalityCodes";
import { Page } from "../../types/pageTypes";

export const productOverview: Page = {
    title: 'product.overview.title',
    path: '/gifts',
    component: ProductOverview,
    hide: false,
    functionalityCode: Auth.PRODUCT_SEARCH_VIEW
};

export const productPage = {
    title: 'product.title',
    path: '/gifts/product',
    component: Product,
    hide: true,
};