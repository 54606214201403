import {Option} from "./types";
import {List} from "immutable";


export function mapOptions(optionsMap: Map<string, List<Option>>, sourceId: string): Option[] {
    if (!optionsMap) {
        return [];
    }
    const options = optionsMap.get(sourceId);
    return options ? options.toArray() : [];
}