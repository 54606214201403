import { combineReducers, Action } from 'redux';
import { reducers as commonReducers } from '@pearlchain/component-lib-common';
import { reducer as formReducer } from 'redux-form';
import { reducer as notifications } from 'react-notification-system-redux';
import { stackbaseReducers } from '@pearlchain/stackbase-common';

import { StoreState } from '../types/storeTypes';
import procurementReducers from '../components/procurement/reducers';
import productReducers from '../components/product/reducers';
import inventoryReducers from '../components/inventory/reducers';
import userInfoReducers from '../components/userInfo/reducers';
import optionsReducers from '../components/combobox/reducers';
import retailCommonReducers from '../components/common/reducers';
import dialogReducers from '../components/dialog/reducers';
import loginReducers from '../components/login/reducers';
import actionButtonsReducer from '../components/actionButton/actionButtonReducers';

const reducers = combineReducers<StoreState>({
    procurement: procurementReducers,
    inventory: inventoryReducers,
    product: productReducers,
    userInfo: userInfoReducers,
    common: commonReducers,
    stackbase: stackbaseReducers,
    form: formReducer,
    options: optionsReducers,
    notifications: notifications as any /** TODO: why doesn't TS allow this? */,
    retailCommon: retailCommonReducers,
    dialog: dialogReducers,
    actionButtons: actionButtonsReducer
});

export default function(state: StoreState | undefined, action: Action) {
    state = loginReducers(state, action);
    state = reducers(state, action);
    return state;
}
