import { Company } from '@pearlchain/stackbase-common/lib/components/CompanyBinder';
/**
 * Validates at least 1 company is given, and that this company is a valid member of the
 * available companies.
 */
export function validateCompanyBinder(companies: Company[] | undefined) {
    return (value: string | string[] | undefined) => {
        if (!value || (typeof value !== 'string' && !value.length)) {
            return 'At least 1 company must be provided';

        } else if (!companies) {
            return 'No companies loaded';

        } else {
            if (typeof value === 'string') {
                if (!companies.some(c => c.code === value)) {
                    return 'Unknown company: ' + value;
                }

            } else {
                if (!companies.some(c => value.indexOf(c.code) >= 0)) {
                    return 'Unknown company: ' + value;
                }
            }
        }
    }
}

