import React, { useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { formValueSelector, InjectedFormProps, reduxForm } from 'redux-form'
import { ButtonToolbar, Form } from 'react-bootstrap'
import { Record, List } from 'immutable'

import { getRealmNo } from '@pearlchain/component-lib-common'
import { FormFieldsLayout } from '@pearlchain/component-lib-ui'

import Secured from '../../auth/Secured'
import { Auth } from '../../auth/functionalityCodes'
import { StoreState } from '../../../types/storeTypes'

import { StockCreationFormData, CompositeProductInfo } from '../../../types/inventoryTypes'
import { stockCreationFormFieldsCreator } from './stockCreationFormFieldsCreator'
import ActionTypes, { saveStock } from '../actions'
import CompositeProductGrid from './CompositeProductGrid'
import { useTranslation } from "react-i18next";
import { FindAllCompaniesAction } from "../../../types/procurementTypes";
import { findAllCompanies } from "../../product/actions";
import { useProductInfo } from '../../productInfo/useProductInfo';
import { Company } from '@pearlchain/stackbase-common/lib/components/CompanyBinder';
import { getAllCompanies } from '../../procurement/selectors'
import AsyncActionButton from '../../actionButton/AsyncActionButton'
import ActionButton from '../../actionButton/ActionButton'
import ErrorAlert from '../../common/ErrorAlert'

interface Props {
    productNo: string,
    parentQuantity: number | undefined,
    companies: Company[] | undefined,
    saveStock: typeof saveStock,
    findAllCompanies: () => FindAllCompaniesAction
}

function validateStockEntries(entries?: List<CompositeProductInfo>): boolean {
    var valid = false;

    if (entries) {
        for(const entry of entries) {
            if(entry.get('stockEntryQuantity') === null || entry.get('stockEntryQuantity') === undefined ){
                valid = true
                break
            }
        }
    }

    return valid
}

function StockCreationPage(props: InjectedFormProps<StockCreationFormData, Props> & Props) {
    const { productNo, parentQuantity, companies, change, reset } = props;
    const [showStockError, setShowStockError] = useState(false)
    
    const {t} = useTranslation();
    const { data: productInfo } = useProductInfo(productNo, getRealmNo());
    const products = productInfo && productInfo.get('products');

    useEffect(() => {
        props.findAllCompanies();
    }, []);

    useEffect(() => {
        const stockEntryInvalid = validateStockEntries(products)
        if(stockEntryInvalid) {
                setShowStockError(true)
            }
    }, [products]);

    useEffect(() => {
        if (productNo && productInfo && productInfo.get('productNo') === productNo) {
            change('productDescription', productInfo.get('productDescription'));
            change('uom', productInfo.get('uom'));
        } else {
            change('productDescription', '');
            change('uom', '');
            change('quantity', 0);
        }
    }, [productInfo])

    const formFields = useMemo(() => {
        return stockCreationFormFieldsCreator(productInfo, parentQuantity, companies, t);
    }, [productNo, productInfo, parentQuantity, companies]);

    return (
        <Secured code={Auth.INVENTORY_NEW_STOCK_ENTRY}>
            <Form className="page-container">
                <ErrorAlert show={showStockError} message={"retail.alerts.noStockEntry"} handleAlertClose={() =>setShowStockError(false)}/>
                <ButtonToolbar className="mt-2 mb-3">
                    <AsyncActionButton
                        actionId={ActionTypes.SAVE_STOCK}
                        variant="primary"
                        type="submit"
                        disabled={props.invalid}
                        onAction={props.handleSubmit((form: Record<StockCreationFormData>) => {
                            if (productInfo) {
                                props.saveStock(form, productInfo, true, ActionTypes.SAVE_STOCK);
                                reset();
                            }
                        })}
                    >
                        {t('retail.inventory.button.save')}
                    </AsyncActionButton>
                    <AsyncActionButton
                        actionId={ActionTypes.SAVE_STOCK_AND_NEW}
                        variant="primary"
                        type="submit"
                        disabled={props.invalid}
                        onAction={props.handleSubmit((form: Record<StockCreationFormData>) => {
                            if (productInfo) {
                                props.saveStock(form, productInfo, false, ActionTypes.SAVE_STOCK_AND_NEW);
                                reset();
                            }
                        })}>
                        {t('retail.inventory.button.saveAndNew')}
                    </AsyncActionButton>
                    <ActionButton variant="primary" className="mr-2" onAction={props.reset}>
                        {t('retail.inventory.button.clear')}
                    </ActionButton>
                </ButtonToolbar>
                <FormFieldsLayout fields={formFields} numCols={{ xs: 1, sm: 2, md: 2, lg: 2 }}/>
                {products && products.size > 0 && <CompositeProductGrid products={products}/>}
            </Form>
        </Secured>
    )
}

const SimpleStockCreationForm = reduxForm<StockCreationFormData, Props>({
    form: 'stock-creation-form'
})(StockCreationPage);

export default connect((state: StoreState) => {
    const selector = formValueSelector('stock-creation-form');
    const productNo = selector(state, 'productNo');
    const parentQuantity = selector(state, 'quantity');

    return {
        initialValues: {company: getRealmNo()},
        productNo,
        parentQuantity,
        companies: getAllCompanies(state)
    }
}, {
    saveStock, findAllCompanies
})(SimpleStockCreationForm);
