import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getLocationParam } from '@pearlchain/component-lib-common';
import { Record } from 'immutable';

import {PurchaseOrderMode, PurchaseOrderLineFormData, PurchaseOrderLineMode} from '../../../types/procurementTypes';
import PurchaseOrderLine from './PurchaseOrderLine';
import {
    PARAM_MODE,
    PARAM_LINE_MODE,
    PARAM_SUPPLIER_UUID,
    PARAM_COMPANY_CODE
} from './purchaseOrderLineParams';
import { StoreState } from '../../../types/storeTypes';

function resolveMode(mode: string | undefined) {
    if (mode === PurchaseOrderMode.NEW) {
        return PurchaseOrderMode.NEW;
    } else if (mode === PurchaseOrderMode.UPDATE) {
        return PurchaseOrderMode.UPDATE;
    } else if (mode === PurchaseOrderMode.VIEW) {
        return PurchaseOrderMode.VIEW;
    } else {
        return null;
    }
}

function resolveLineMode(lineMode: string | undefined) {
    if (lineMode === PurchaseOrderLineMode.NEW) {
        return PurchaseOrderLineMode.NEW;
    } else if (lineMode === PurchaseOrderLineMode.UPDATE) {
        return PurchaseOrderLineMode.UPDATE;
    } else if (lineMode === PurchaseOrderLineMode.VIEW) {
        return PurchaseOrderLineMode.VIEW;
    } else {
        return null;
    }
}

function buildInitialFormValues(purchaseOrderLine: Record<PurchaseOrderLineFormData>) {
    return {
        uniqueIdentifier: purchaseOrderLine.get('uniqueIdentifier'),
        productNo: purchaseOrderLine.get('productNo'),
        description: purchaseOrderLine.get('description'),
        quantity: purchaseOrderLine.get('quantity'),
        quantityUom: purchaseOrderLine.get('quantityUom'),
        price: purchaseOrderLine.get('price'),
        priceUom: purchaseOrderLine.get('priceUom'),
        version: purchaseOrderLine.get('version'),
        no: purchaseOrderLine.get('no'),
    };
};

/**
 * Fetches initial data for the purchase-order line component
 */

export default function PurchaseOrderLineContainer() {
    // resolve location parameters
    const mode = resolveMode(getLocationParam<string>(PARAM_MODE));
    const lineMode = resolveLineMode(getLocationParam<string>(PARAM_LINE_MODE));
    const supplierUuid = getLocationParam<string>(PARAM_SUPPLIER_UUID);
    const companyCode = getLocationParam<string>(PARAM_COMPANY_CODE);
    if (!mode || !lineMode || !companyCode) {
        throw new Error('Purchase-order line. Required page params not provided');
    }

    // select the purchase order line from the store
    const purchaseOrderLine = useSelector((state: StoreState) => {
        const resultIndex = state.procurement.get('purchaseOrderLineIndex');
        if (resultIndex != null && resultIndex >= 0) {
            return state.procurement.get('purchaseOrderLines').get(resultIndex);
        }
    });

    // construct the initial form values
    const initialFormValues = useMemo((): Partial<PurchaseOrderLineFormData> => {
        if (purchaseOrderLine) {
            return buildInitialFormValues(purchaseOrderLine);
        } else {
            return {
                productNo: ''
            };
        }
    }, [purchaseOrderLine]);

    return (
        <PurchaseOrderLine
            mode={mode}
            lineMode={lineMode}
            companyCode={companyCode}
            supplierUUID={supplierUuid}
            initialValues={initialFormValues}
        />
    );
}
