import React, { useRef, Suspense } from "react";
import { I18nextProvider } from "react-i18next";
import { i18n } from "i18next";

import Notifications from './Notifications';
import Navigation from "./components/page/Navigation";
import { Page } from "./types/pageTypes";
import i18nSetup from "./components/i18n/i18n";

type Props = {
    page: Page;
    children: React.ReactNode;
}

export default function Main(props: Props) {
    const i18n = useRef<i18n>();
    if (!i18n.current) {
        i18n.current = i18nSetup();
    }

    return (
        <div id="app">
            <Suspense fallback={<div>Loading Translations</div>}>
                <I18nextProvider i18n={i18n.current}>
                    <Notifications/>
                    <Navigation currentPage={props.page}/>
                    { React.Children.only(props.children) }
                    <div className="footer"></div>
                </I18nextProvider>
            </Suspense>
        </div>
    );
}
