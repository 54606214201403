import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { List, Record } from 'immutable'
import { getFormValues } from 'redux-form'

import { RequestBuilder } from '@pearlchain/component-lib-common'

import { mapAuthorizationProps } from '../../auth/AuthUtils'
import { AuthorizationProps } from '../../auth/authTypes'
import { StoreState } from '../../../types/storeTypes'

import {
    GridRow,
    InventoryOverviewItem,
    Option,
    PrintLabelInfo,
    StockCorrection
} from '../../../types/inventoryTypes'
import { listIsEmpty } from './gridUtils'
import { getStockEntries } from '../../remote/api'
import {
    fetchReasons,
    processExportToExcel,
    markEntryToPrintLabel,
    fetchCorrectionActions
} from '../actions'
import {
    getReasonsFromState,
    getCorrectionActionsFromState,
    getInventorySearchResultFromState,
    getInventoryCorrectionsFromState
} from '../selectors'
import StepThroughPager from '../../common/pagination/StepThroughPager';
import { WebSortFields } from '../../remote/requestTypes';
import { DEFAULT_PAGE_SIZE } from '../../util/helpers';
import { showDialog } from '../../dialog/actions';
import InventoryOverviewPage from "./InventoryOverviewPage";
import { INVENTORY_OVERVIEW_FORM } from './InventoryOverview'


export interface Props extends AuthorizationProps {
    multiSelectMode: boolean,
    reasons: List<Record<Option>>,
    correctionActions: List<Record<Option>>,
    entries: List<Record<InventoryOverviewItem>>,
    sortFields: WebSortFields,
    setSortFields: (sortFields: WebSortFields) => void,
    processExportToExcel: typeof processExportToExcel,
    markEntryToPrintLabel: typeof markEntryToPrintLabel,
    printLabels: List<Record<PrintLabelInfo>>,
    fetchReasons: () => void,
    fetchCorrectionActions: () => void,
    formValues: any,
    showDialog: (id: string, row: GridRow) => void,
    corrections: List<StockCorrection>
}

function InventoryOverviewGrid(props: Props) {

    useEffect(() => {
        props.fetchReasons();
        props.fetchCorrectionActions();
    }, []);

    if (listIsEmpty(props.reasons) || listIsEmpty(props.correctionActions))
        return <React.Fragment/>;
    return (
        <StepThroughPager 
            data={props.entries} 
            pageSize={DEFAULT_PAGE_SIZE} 
            sortFields={props.sortFields}
            fetchNextData={(params) => getStockEntries(new RequestBuilder(), props.formValues, params)}
            reactTable={true}
            render={(data: List<Record<InventoryOverviewItem>>) => {

                let correctedData: List<Record<InventoryOverviewItem>> = data;

                // NOTE! Need this, bcs we are loosing corrected values in the grid by using pagination
                if (props.corrections && props.corrections.size > 0) {

                    let quickMap: Map<string, StockCorrection>  = props.corrections.reduce(
                        (acc: Map<string, StockCorrection>, correction: StockCorrection) => {
                            acc.set(correction.uuid, correction);
                            return acc;
                        },
                        new Map()
                    );

                    correctedData = data.map((row: Record<InventoryOverviewItem>) => {

                        const correction: StockCorrection | undefined = quickMap.get(row.get('uniqueIdentifier'));

                        if (correction !== undefined) {
                            return row.set('actionType', correction.actionType)
                                .set('newQuantity', correction.newQuantity as string | undefined)
                                .set('reason', correction.reason)
                                .set('remark', correction.remark);
                        } else {
                            return row;
                        }
                    });
                }

                return <InventoryOverviewPage page={correctedData} sortFields={props.sortFields} setSortFields={props.setSortFields}
                                       multiSelectMode={props.multiSelectMode}/>
                }
            }
        />
    );
}

export default connect(
    (state: StoreState) => {
        const formValues = getFormValues(INVENTORY_OVERVIEW_FORM)(state);
        const authorizationProps = mapAuthorizationProps(state);
        return {
            functionalityCodes: authorizationProps.functionalityCodes,
            isCorporateAdmin: authorizationProps.isCorporateAdmin,
            printLabels: state.inventory.get('printLabels') || List(),
            reasons: getReasonsFromState(state),
            correctionActions: getCorrectionActionsFromState(state),
            entries: getInventorySearchResultFromState(state),
            corrections: getInventoryCorrectionsFromState(state),
            formValues
        }
    },
    {processExportToExcel, markEntryToPrintLabel, fetchReasons, fetchCorrectionActions, showDialog}
)(InventoryOverviewGrid);
