import ProcurementOverview, {FCProcurementOverview} from './overview/ProcurementOverview';
import {FCNewPurchaseOrder} from './creation/NewPurchaseOrder';
import NewPurchaseOrder from './creation/NewPurchaseOrder';
import ReceivePurchaseOrder from './receive/ReceivePurchaseOrder';
import PurchaseOrderLine from './purchaseOrderLine/PurchaseOrderLineContainer';

export const procurementOverview = {
    title: 'purchase.title',
    path: '/procurement',
    component: ProcurementOverview,
    functionalityCode: FCProcurementOverview,
    hide: false
};

export const newPurchaseOrder = {
    title: 'purchase.newpurchaseorder.title',
    path: '/procurement/po',
    component: NewPurchaseOrder,
    functionalityCode: FCNewPurchaseOrder,
    hide: true
};

export const newPurchaseOrderLine = {
    title: 'purchase.newpurchaseorderline.title',
    path: '/procurement/pol',
    component: PurchaseOrderLine,
    functionalityCode: FCNewPurchaseOrder,
    hide: true
};

export const updatePurchaseOrderLine = {
    title: 'purchase.updatepurchaseorderline.title',
    path: '/procurement/pol',
    component: PurchaseOrderLine,
    functionalityCode: FCNewPurchaseOrder,
    hide: true
};

export const updatePurchaseOrder = {
    title: 'purchase.updatepurchaseorder.title',
    path: '/procurement/po',
    component: NewPurchaseOrder,
    functionalityCode: FCNewPurchaseOrder,
    hide: true
};

export const viewPurchaseOrder = {
    title: 'purchase.viewpurchaseorder.title',
    path: '/procurement/po',
    component: NewPurchaseOrder,
    functionalityCode: FCNewPurchaseOrder,
    hide: true
};

export const receivePurchaseOrder = {
    title: 'purchase.receivegoods.title',
    path: '/procurement/po/receive',
    component: ReceivePurchaseOrder,
    hide: true
};
