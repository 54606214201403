import { all } from 'redux-saga/effects';
import { sagas as stackbaseSagas } from '@pearlchain/stackbase-common';
import { sagas as commonSagas, registerRequestHandlers } from '@pearlchain/component-lib-common';

import procurementSagas from '../components/procurement/sagas';
import inventorySagas from '../components/inventory/sagas';
import { RequestHandlers } from '../components/remote/requests';
import userInfoSaga from "../components/userInfo/userInfoSagas";
import productSaga from "../components/product/sagas";
import optionsSaga from "../components/combobox/sagas";

export default function() {
    registerRequestHandlers(RequestHandlers);

    return function *() {
        yield all([
            stackbaseSagas(),
            commonSagas(),
            procurementSagas(),
            userInfoSaga(),
            productSaga(),
            optionsSaga(),
            inventorySagas()
        ])    
    }
}
