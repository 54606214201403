import React, {useEffect, useMemo, useState} from 'react'
import {InjectedFormProps, reduxForm} from 'redux-form'
import {connect} from 'react-redux'
import {List, Record} from 'immutable'
import {ButtonToolbar, Form} from 'react-bootstrap'

import {FormFieldsLayout} from '@pearlchain/component-lib-ui'
import {getRealmNo, pushLocation} from '@pearlchain/component-lib-common'

import {Auth} from '../../auth/functionalityCodes'
import Secured from '../../auth/Secured'

import InventoryOverviewGrid from './InventoryOverviewGrid'
import {
    InventoryOverviewFilterFormData, Option, StockCorrection,
    PrintShelfLabelsForm, PrintLabelInfo
} from '../../../types/inventoryTypes'
import {inventoryOverviewFormFieldsCreator} from './inventoryOverviewFormFieldsCreator'
import ActionTypes, {
    findInventoryEntries, saveCorrections, getProductLabelsDocument,
    showPrintLabelsDialog, showPrintWeightLabelsDialog, printShelfLabels, clearCorrections
} from '../actions'
import {StoreState} from '../../../types/storeTypes'
import {getInventoryCorrectionsFromState, getReasonsFromState,} from '../selectors'
import {stockCreation, stockConversion} from '../inventoryPages'
import {useTranslation} from "react-i18next";
import PrintLabelForGiftModal from './labels/PrintLabelForGiftModal';
import PrintLabelForSimpleModal from './labels/PrintLabelForSimpleModal';
import {createWebParams, WebSortFields} from '../../remote/requestTypes';
import {DEFAULT_PAGE_SIZE} from '../../util/helpers';
import {FindAllCompaniesAction} from "../../../types/procurementTypes";
import {findAllCompanies} from "../../product/actions";
import {getAllCompanies} from '../../procurement/selectors'
import {confirm} from "../../confirmation/confirm";
import AsyncActionButton from '../../actionButton/AsyncActionButton'
import ActionButton from '../../actionButton/ActionButton'
import { isAnyActionRunning } from '../../actionButton/actionButtonSelectors'
import { Company } from '@pearlchain/stackbase-common/lib/components/CompanyBinder'

export const INVENTORY_OVERVIEW_FORM = "inventory-overview-form";

interface Props {
    printLabels: List<Record<PrintLabelInfo>>,
    reasons: List<Record<Option>>,
    corrections: List<StockCorrection>,
    companies: Company[] | undefined,
    anyActionRunning: boolean,
    findInventoryEntries: typeof findInventoryEntries,
    saveCorrections: typeof saveCorrections,
    pushLocation: (path: string, title: string, params: any) => void,
    findAllCompanies: () => FindAllCompaniesAction,
    getProductLabelsDocument: typeof getProductLabelsDocument,
    showPrintLabelsDialog: typeof showPrintLabelsDialog,
    showPrintWeightLabelsDialog: typeof showPrintWeightLabelsDialog,
    printShelfLabels: typeof printShelfLabels,
    clearCorrections: typeof clearCorrections
}

function InventoryOverview(props: InjectedFormProps<InventoryOverviewFilterFormData, Props> & Props) {

    const [initialized, setInitialized] = useState<boolean>(false);
    const [sortFields, setSortFields] = useState<WebSortFields>({});
    const [formData, setFormData] = useState<InventoryOverviewFilterFormData>(props.initialValues as InventoryOverviewFilterFormData);
    const [multiSelectMode, setMultiSelectMode] = useState<boolean>(false);
    const {t} = useTranslation();

    useEffect(() => {
        props.findAllCompanies();
    }, []);

    useEffect(() => {
        if (!props.anyActionRunning && initialized && sortFields) {
            const webParams = createWebParams(0, DEFAULT_PAGE_SIZE, sortFields);
            props.findInventoryEntries(formData, webParams, false);
        }

        if (sortFields) {
            setInitialized(true);
        }
    }, [sortFields]);

    const formFields = useMemo(() => {
        return inventoryOverviewFormFieldsCreator(props.companies);
    }, [props.companies]);

    return (
        <Secured code={Auth.INVENTORY_SEARCH_VIEW}>
            <div className="page-container">
                <Form>
                    <ButtonToolbar className="mt-2 mb-3">
                        <AsyncActionButton
                            actionId={ActionTypes.FIND_INVENTORY_ENTRIES}
                            variant="primary"
                            type="submit"
                            onAction={props.handleSubmit((data: InventoryOverviewFilterFormData) => {
                                setFormData(data);
                                const webParams = createWebParams(0, DEFAULT_PAGE_SIZE, sortFields);
                                props.findInventoryEntries(data, webParams, true);
                            })}
                        >
                            {t('retail.inventory.button.search')}
                        </AsyncActionButton>
                        <ActionButton
                            variant="primary"
                            className="mr-2"
                            onAction={props.reset}
                        >
                            {t('retail.inventory.button.clear')}
                        </ActionButton>
                        <Secured code={Auth.INVENTORY_SAVE_STOCK_CORRECTION}>
                            <AsyncActionButton
                                actionId={ActionTypes.SAVE_CORRECTIONS}
                                variant="primary"
                                disabled={props.corrections.size === 0}
                                onAction={() => props.saveCorrections(props.corrections, sortFields)}
                            >
                                {t('retail.inventory.button.save')}
                            </AsyncActionButton>
                        </Secured>
                        <ActionButton
                            variant="primary"
                            onAction={() => props.pushLocation(stockConversion.path, t('retail.inventory.button.stockConversion'), {})}
                        >
                            {t('retail.inventory.button.stockConversion')}
                        </ActionButton>
                        <ActionButton
                            variant="primary"
                            onAction={() => props.pushLocation(stockCreation.path, t('retail.inventory.button.new'), {})}
                        >
                            {t('retail.inventory.button.new')}
                        </ActionButton>
                        <ActionButton
                            variant="primary"
                            disabled={props.printLabels.size === 0}
                            onAction={() => {
                                    console.log(props)
                                    return props.showPrintLabelsDialog()
                                }
                            }
                        >
                            {t('retail.inventory.button.printLabels')}
                        </ActionButton>
                        <ActionButton
                            variant={ multiSelectMode ? "outline-primary" : "primary"}
                            onAction={() => {
                                if (props.corrections && props.corrections.size > 0) {
                                    confirm({translationFunction : t,
                                        title: t('retail.inventory.multiSelect.title'),
                                        message: t('retail.inventory.multiSelect.message'),
                                        okLabel: t('retail.inventory.multiSelect.ok.label'),
                                        cancelLabel: t('retail.inventory.multiSelect.cancel.label')}
                                    ).then(() => {
                                        props.clearCorrections();
                                        setMultiSelectMode(!multiSelectMode)
                                    })
                                } else {
                                    setMultiSelectMode(!multiSelectMode)
                                }
                            }}
                        >
                            {t('retail.inventory.button.multiSelect')}
                        </ActionButton>
                        <ActionButton
                            variant="primary"
                            disabled={props.printLabels.size === 0}
                            onAction={() => {
                                    return props.showPrintWeightLabelsDialog()
                                }
                            }
                        >
                            {t('retail.inventory.button.printWeightLabels')}
                        </ActionButton>
                    </ButtonToolbar>
                    <FormFieldsLayout fields={formFields} numCols={{xs: 1, sm: 2, md: 3, lg: 3}}/>
                </Form>

                <InventoryOverviewGrid sortFields={sortFields} setSortFields={setSortFields} multiSelectMode={multiSelectMode}/>

                <PrintLabelForGiftModal onConfirm={(nos: List<String>, form: PrintShelfLabelsForm) =>
                    props.printShelfLabels(props.printLabels, form, `labels-gifts.pdf`)
                }/>
                <PrintLabelForSimpleModal onConfirm={(no: string, form: PrintShelfLabelsForm) => {
                    return props.printShelfLabels(props.printLabels, form, `labels-simple.pdf`)
                }
                }/>
            </div>
        </Secured>
    )
}

const InventoryOverviewForm = reduxForm<InventoryOverviewFilterFormData, Props>({
    form: INVENTORY_OVERVIEW_FORM
})(InventoryOverview);

export default connect(
    (state: StoreState) => {
        return {
            initialValues: {
                company: getRealmNo(),
                excludeEmptyStock: true
            },
            anyActionRunning: isAnyActionRunning(state),
            companies: getAllCompanies(state),
            reasons: getReasonsFromState(state),
            printLabels: state.inventory.get('printLabels') || List(),
            corrections: getInventoryCorrectionsFromState(state)
        }
    },
    {
        findInventoryEntries, saveCorrections, pushLocation,
        showPrintLabelsDialog, showPrintWeightLabelsDialog, findAllCompanies, printShelfLabels, clearCorrections
    }
)(InventoryOverviewForm);
