import React, { Fragment, useMemo, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { reduxForm, InjectedFormProps } from 'redux-form'
import { Form, ButtonToolbar } from 'react-bootstrap'
import { Record, List } from 'immutable'

import { pushLocation, LocationItem, createLocationItem } from '@pearlchain/component-lib-common'
import { FormFieldsLayout } from '@pearlchain/component-lib-ui'

import Secured from '../../auth/Secured'
import {Auth} from '../../auth/functionalityCodes'
import { StoreState } from '../../../types/storeTypes'
import { ActionButtonEvent } from '../../../types/gridTypes'
import { isRequestBusy, DEFAULT_PAGE_SIZE } from '../../util/helpers'
import { RequestId } from '../../remote/requests'
import Loader from '../../common/Loader'

import PurchaseOrderGrid from './PurchaseOrderGrid'
import { PurchaseOrderFilterFormData, FindAllCompaniesAction, PurchaseOrderStatus, PurchaseOrderFormData } from '../../../types/procurementTypes'
import ActionTypes, { viewPurchaseOrder, updatePurchaseOrder, newPurchaseOrder, receivePurchaseOrder, findPurchaseOrders,
    findAllCompanies } from '../actions'
import { getAllCompanyCodes, getAllCompanies } from '../selectors'
import { createPurchaseOrderOverviewFormFields } from '../formFieldsCreator'
import { newPurchaseOrder as NewPurchaseOrderPage, updatePurchaseOrder as UpdatePurchaseOrderPage,
    viewPurchaseOrder as ViewPurchaseOrderPage, receivePurchaseOrder as ReceivePurchaseOrderPage } from '../procurementPage'
import { useTranslation } from "react-i18next";
import { WebSortFields } from '../../remote/requestTypes';
import { Company } from '@pearlchain/stackbase-common/lib/components/CompanyBinder'
import AsyncActionButton from '../../actionButton/AsyncActionButton'
import ActionButton from '../../actionButton/ActionButton'

interface Props extends DispatchProps {
    searchResult: List<Record<PurchaseOrderFormData>> | undefined,
    initialValues: any,
    isBusy: boolean,
    companies: Company[] | undefined
}

interface DispatchProps extends InjectedFormProps {
    pushLocation: typeof pushLocation,
    viewPurchaseOrder: typeof viewPurchaseOrder,
    updatePurchaseOrder: typeof updatePurchaseOrder,
    receivePurchaseOrder: typeof receivePurchaseOrder,
    newPurchaseOrder: typeof newPurchaseOrder,
    findPurchaseOrders: typeof findPurchaseOrders,
    findAllCompanies: () => FindAllCompaniesAction 
}

export const FCProcurementOverview = Auth.PURCHASE_ORDER_SEARCH_VIEW;

export function ProcurementOverview(props: InjectedFormProps<PurchaseOrderFilterFormData, Props> & Props) {

        const {t} = useTranslation();
        const [sortFields, setSortFields] = useState({} as WebSortFields);

        const formFields = useMemo(() => {
            return createPurchaseOrderOverviewFormFields(props.companies);
        }, [props.companies]);

        useEffect(() => {
            props.findAllCompanies();
        },[]);

        const numCols = { xs: 1, sm: 2, md: 3, lg: 3 };

        const newLocation: LocationItem = createLocationItem({
            title: NewPurchaseOrderPage.title,
            pathName: NewPurchaseOrderPage.path
        });

        const updateLocation: LocationItem = createLocationItem({
            title: UpdatePurchaseOrderPage.title,
            pathName: UpdatePurchaseOrderPage.path
        });

        const viewLocation: LocationItem = createLocationItem({
            title: ViewPurchaseOrderPage.title,
            pathName: ViewPurchaseOrderPage.path
        });

        const receiveLocation: LocationItem = createLocationItem({
            title: ReceivePurchaseOrderPage.title,
            pathName: ReceivePurchaseOrderPage.path
        });

        return (
            <div className="page-container">
                <Loader busy={props.isBusy}/>
                <Secured code={FCProcurementOverview}>
                    <Fragment>
                    <Form>
                        <ButtonToolbar className="mt-2 mb-3">
                            <AsyncActionButton
                                actionId={ActionTypes.FIND_PURCHASE_ORDERS}
                                variant="primary"
                                type="submit"
                                onAction={props.handleSubmit((data) => {
                                    data.pageNumber = 0;
                                    data.pageSize = DEFAULT_PAGE_SIZE;
                                    props.findPurchaseOrders(data, sortFields);
                                })}>
                                {t('retail.procurement.button.search')}
                            </AsyncActionButton>
                            <ActionButton
                                variant="secondary"
                                onAction={props.reset}
                            >
                                {t('retail.procurement.button.clear')}
                            </ActionButton>
                            <Secured code={Auth.NEW_PURCHASE_ORDER}>
                                <ActionButton
                                    variant="secondary"
                                    onAction={() => props.newPurchaseOrder(newLocation)}
                                >
                                    {t('retail.procurement.button.new')}
                                </ActionButton>
                            </Secured>
                        </ButtonToolbar>
                        <FormFieldsLayout fields={formFields} numCols={numCols}/>
                    </Form>
                    <PurchaseOrderGrid
                        searchResult={props.searchResult}
                        sortFields={sortFields}
                        setSortFields={setSortFields}
                        viewPurchaseOrderAction={(event: ActionButtonEvent, location: LocationItem) => props.viewPurchaseOrder(event, viewLocation)} 
                        updatePurchaseOrderAction={(event: ActionButtonEvent, location: LocationItem) => props.updatePurchaseOrder(event, updateLocation)}
                        receivePurchaseOrderAction={(event: ActionButtonEvent, location: LocationItem) => props.receivePurchaseOrder(event, receiveLocation)}/>
                    </Fragment>
                </Secured>
            </div>
        );
}

const ProcurementOverviewForm = reduxForm<PurchaseOrderFilterFormData, Props>({
    form: 'procurement-overview-form',
    enableReinitialize: true,
    keepDirtyOnReinitialize: true
})(ProcurementOverview);

export default connect(
    (state: StoreState) => {
        const isBusy = isRequestBusy(state, [RequestId.FIND_PURCHASE_ORDERS]);
        const companies = getAllCompanyCodes(state)!;
        return {
            initialValues: {
                companies: companies,
                statuses: [PurchaseOrderStatus.INITIAL, PurchaseOrderStatus.ORDERED]
            },
            companies: getAllCompanies(state),
            searchResult: state.procurement.get('searchResult'),
            isBusy
        }
    },
    { pushLocation, viewPurchaseOrder, updatePurchaseOrder, newPurchaseOrder, receivePurchaseOrder, findPurchaseOrders, findAllCompanies }
)(ProcurementOverviewForm);
