import {List, Record} from "immutable";
import { Action } from "redux";

import ActionTypes from "./actions";
import {
    StoreStateProcurement,
    createProcurementState,
    StorePurchaseOrderAction,
    StorePurchaseOrderLinesAction,
    StoreCompanyCurrencyAction,
    StoreValidationMessageAction,
    StoreDefaultCompanySupplierAction,
    MarkAllPurchaseOrderLinesRegisteredAction,
    SetPurchaseOrdersAction, UpdatePurchaseOrderInGridAction, PurchaseOrderFormData
} from "../../types/procurementTypes";

import purchaseOrderLineReducers from './purchaseOrderLine/purchaseOrderLineReducers';

export default function(state: Record<StoreStateProcurement> = createProcurementState(), action: Action) {
    switch(action.type) {
        case ActionTypes.STORE_PURCHASE_ORDER:
            return storePurchaseOrder(state, action as StorePurchaseOrderAction);
        case ActionTypes.STORE_PURCHASE_ORDER_LINES:
            return storePurchaseOrderLines(state, action as StorePurchaseOrderLinesAction);
        case ActionTypes.MARK_ALL_PURCHASE_ORDER_LINES_REGISTERED:
            return markAllPurchaseOrderLinesRegistered(state, action as MarkAllPurchaseOrderLinesRegisteredAction);
        case ActionTypes.CLEAR_DATA:
            return clearData(state);
        case ActionTypes.CLEAR_PURCHASE_ORDER_LINE_DATA:
            return clearPurchaseOrderLineData(state);
        case ActionTypes.STORE_DEFAULT_COMPANY_CURRENCY:
            return storeCompanyCurrency(state, action as StoreCompanyCurrencyAction);
        case ActionTypes.STORE_DEFAULT_COMPANY_SUPPLIER:
            return storeDefaultCompanySupplier(state, action as StoreDefaultCompanySupplierAction);    
        case ActionTypes.STORE_VALIDATION_MESSAGE:
            return storeValidationMessage(state, action as StoreValidationMessageAction);
        case ActionTypes.CLEAR_VALIDATION_MESSAGES:
            return clearValidationMessages(state);
        case ActionTypes.SET_PURCHASE_ORDERS:
            return setPurchaseOrders(state, action as SetPurchaseOrdersAction)
        case ActionTypes.UPDATE_PURCHASE_ORDER_IN_GRID:
            return updatePurchaseOrderInGrid(state, action as UpdatePurchaseOrderInGridAction);
    }

    state = purchaseOrderLineReducers(state, action);
    return state;
}

function setPurchaseOrders(state: Record<StoreStateProcurement>, action: SetPurchaseOrdersAction) {
    return state.set('searchResult', action.orders);
}

function updatePurchaseOrderInGrid(state: Record<StoreStateProcurement>, action: UpdatePurchaseOrderInGridAction) {
    return state.update('searchResult', (searchResult: List<Record<PurchaseOrderFormData>>) =>
        searchResult.map((order: Record<PurchaseOrderFormData>) => {
            if (order.get('uniqueIdentifier') === action.order.get('uniqueIdentifier')) {
                return action.order;
            } else {
                return order;
            }
        })
    );
}

function storePurchaseOrder(state: Record<StoreStateProcurement>, action: StorePurchaseOrderAction) {
    return state.set('purchaseOrder', action.data);
}

function storePurchaseOrderLines(state: Record<StoreStateProcurement>, action: StorePurchaseOrderLinesAction) {
    return state.set('purchaseOrderLines', action.data);
}

function markAllPurchaseOrderLinesRegistered(state: Record<StoreStateProcurement>, action: MarkAllPurchaseOrderLinesRegisteredAction) {
    return state.update('purchaseOrderLines', (lines) => lines.map(line => line.set('toBeRegistered', action.registered)));
}

function clearData(state: Record<StoreStateProcurement>) {
    return state.withMutations((state: Record<StoreStateProcurement>) => {
        state.delete('purchaseOrder')
        state.delete('purchaseOrderLines')
        state.delete('companyCurrency')
        state.delete('validationMessages')
        return state.delete('purchaseOrderLine');
    }) 
}

function clearPurchaseOrderLineData(state: Record<StoreStateProcurement>) {
    return state.delete('purchaseOrderLine');
}

function storeCompanyCurrency(state: Record<StoreStateProcurement>, action: StoreCompanyCurrencyAction) {
    return state.set('companyCurrency', action.currency.get('value'));
}

function storeDefaultCompanySupplier(state: Record<StoreStateProcurement>, action: StoreDefaultCompanySupplierAction) {
    return state.set('defaultCompanySupplier', action.supplier);
}

function storeValidationMessage(state: Record<StoreStateProcurement>, action: StoreValidationMessageAction) {
    const store = clearValidationMessages(state);
    return store.update('validationMessages', value => {
        return value.push(action.message);
    })
}

function clearValidationMessages(state: Record<StoreStateProcurement>) {
    return state.delete('validationMessages');
}
