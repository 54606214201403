import React from 'react';
import { List, Record } from 'immutable';
import { WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form';
import { RequestIdFetcher } from '@pearlchain/component-lib-common';

import { RequestId } from '../../remote/requests';
import SelectBinder, { Option } from './SelectBinder';

interface Props {
    input: WrappedFieldInputProps,
    meta: WrappedFieldMetaProps,
    dependsOn?: {},
    disabled: boolean,
    multi?: boolean,
    translateOptions?: boolean
}

export default function UomBinder(props: Props) {
    return <RequestIdFetcher
        requestId={RequestId.LIST_UOMS}
        render={(data: List<Record<Option<string>>> | undefined) => {
            const opts = dataToOptions(data);
            return <SelectBinder translateOptions={props.translateOptions} options={opts} { ...props }/>
        }}
    />
}

export function dataToOptions(data: List<Record<Option<string>>> | undefined): Array<Option<string>> {
    return data ? data.map((d) => ({ value: d.get('value'), label: d.get('label') })).toArray() : [];
}
