export const enum Node {
    PRODUCT_GROUP = "net.pearlchain.prl.interfaces.common.shapeit.Product.ProductGroup",
    GIFT_PRODUCT_GROUP2 = "GiftProductGroup2",
    GIFT_PRODUCT_GROUP3 = "GiftProductGroup3",
    PRODUCT_STATUS = "net.pearlchain.prl.interfaces.common.shapeit.ProductStatus",
    PRODUCT_SOURCE = "RetailProductSource",
    VAT_CODE = "VatCodeOption",
    UOM = "RetailProductUOM",
    UOM2 = "RetailProductUOM2",
    UOM3 = "RetailProductUOM3",
    UOM4 = "RetailProductUOM4",
    COMPONENT_AWARE = "ComponentAware"
}