import React from 'react'
import { Button } from 'react-bootstrap'

type Props = {
    ypos: string,
    xpos: string,
    handleExportExcel: () => void,
    t: any
}

export default function ExcelButtton({ypos, xpos, handleExportExcel, t}: Props) {
    return (
        <div style={{ top: ypos, left: xpos, position: "absolute", zIndex: 30}}>
            <Button onClick={handleExportExcel}>{t('stocks.overview.export')}
            <i className="ml-3 fa fa-file-excel"></i></Button>
        </div>
    )
}
