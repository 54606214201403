import { showMenu } from 'react-contextmenu';

/**
 * Powergrid extension. Shows a context menu if the grid was right-clicked.
 */

export function contextMenuExtension(id) {
    return function(override, $super, grid) {
        override(grid, function($super) {
            return {
                init() {
                    $super.init();

                    this.container.contextmenu((event) => {
                        event.preventDefault();
                        event.stopPropagation();

                        const x = event.clientX || (event.touches && event.touches[0].pageX);
                        const y = event.clientY || (event.touches && event.touches[0].pageY);

                        showMenu({
                            position: { x, y },
                            id,
                            data: { }
                        });
                    });
                }
            }
        });
    }
}
