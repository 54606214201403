import React, { useEffect, useMemo } from 'react';
import { List } from 'immutable';
import ReactSelect from 'react-select';

import { BaseBinderProps } from './types';
import { useTranslation } from 'react-i18next';

/**
 * Select field component. Choose an item from a fixed list of options
 */

export type Option<T> = {
    label: string,
    value: T
}

type Props<T> = {
    /**
     * Options to choose from
     */
    options: Option<T>[],
    value: Option<T> | undefined,
    /**
     * Allow multiple items to be selected
     */
    multi?: boolean,
    /**
     * Whether to translate the option labels (default = true)
     */
    translateOptions?: boolean,
    /**
     * Callback for an onChange event
     */
    onChange?: (value: any) => any,
} & BaseBinderProps;

const customStyles = {
    menu: (provided: any) => ({
        ...provided,
        zIndex: 999
    })
}

export default function SelectBinder<T>(props: Props<T>) {
    const { multi, options, translateOptions } = props;
    let value = props.input.value;

    const { t } = useTranslation();

    useEffect(() => {
        if (props.value) {
            const value = optionToValue(props.value as any);
            props.input.onChange(value);
            if (props.onChange) {
                props.onChange(value);
            }
        }
    }, []);

    // translate the options
    const optionsTranslated: Option<T>[] = useMemo(() => {
        if (translateOptions === false) return options;
        return options.map((opt) => ({
            label: t(opt.label),
            value: opt.value
        }));
    }, [translateOptions, options]);

    // values can be supplied as immutable List
    if(List.isList(value)) value = value.toArray();

    // the option corresponding to the current value
    const option = useMemo(() =>
        valueToOption(value, optionsTranslated), [value, optionsTranslated]);

    return (
        <ReactSelect
            styles={customStyles}
            isMulti={multi}
            isDisabled={props.disabled}
            options={optionsTranslated}
            value={option || props.value}
            onChange={option => {
                // React-select returns the option(s) selected, rather than the value itself.
                // Extract the value and pass to onChange callback. 
                const value = optionToValue(option as any);
                props.input.onChange(value);
                if (props.onChange) {
                    props.onChange(value);
                }
            }}
            // trigger redux-form "onBlur" action. Pass the current value in manually,
            // because react-select value is an "option" object. 
            onBlur={() => props.input.onBlur(value)}
        />
    );
}

function valueToOption<T>(value: T|T[], options: Option<T>[]) {
    let result;

    if (Array.isArray(value)) {
        result = options.filter((option) => value.indexOf(option.value) >= 0);
    } else {
        let found = options.find((option) => option.value == value);
        result = found ? found : {label: '', value: undefined};
    }

    return result;
}

function optionToValue<T>(option: Option<T>[]|Option<T>) {
    if (Array.isArray(option)) {
        return option.map((option) => option.value);
    } else {
        return option.value;
    }
}
