import React from 'react';
import { Form, ButtonToolbar } from 'react-bootstrap';
import { FormFieldConfig, FormFieldsLayout, formFieldConfig, TextBinder } from '@pearlchain/component-lib-ui';
import { connect } from 'react-redux';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { LocationItem, createLocationItem } from '@pearlchain/component-lib-common';
import { Record } from 'immutable';

import ActionTypes, { cancelPurchaseOrder } from '../../procurement/actions';
import { CancelPurchaseOrderFormData, createCancelPurchaseOrderForm } from '../../../types/procurementTypes';
import { procurementOverview as ProcurementOverviewPage } from "../procurementPage";
import { useTranslation } from "react-i18next";
import AsyncActionButton from '../../actionButton/AsyncActionButton';

export interface Props extends DispatchProps {
    // callback for when save button is clicked
    onSave: () => void
}

interface DispatchProps {
    cancelPurchaseOrder: typeof cancelPurchaseOrder
}

const formFields: { [key: string]: FormFieldConfig } = {
    reason: formFieldConfig({ label: 'Reason', binder: TextBinder, type: 'textarea' })
}

export function CancelPurchaseOrder(props: InjectedFormProps<CancelPurchaseOrderFormData, Props> & Props) {

    const overviewLocation: LocationItem = createLocationItem({
        title: ProcurementOverviewPage.title,
        pathName: ProcurementOverviewPage.path
    });

    const {t} = useTranslation();

    return (
        <Form>
            <FormFieldsLayout fields={formFields} numCols={1}/>
            <ButtonToolbar className="mt-2 mb-3">
                <AsyncActionButton
                    actionId={ActionTypes.CANCEL_PURCHASE_ORDER}
                    variant="primary"
                    type="submit"
                    onAction={props.handleSubmit((formValues) => {
                        const data = createCancelPurchaseOrderForm(formValues);
                        cancelPurchaseOrderAndCloseModal(data, props, overviewLocation);
                    })}
                >
                    {t('retail.procurement.cancelOrder.ok')}
                </AsyncActionButton>
            </ButtonToolbar>
        </Form>
    );
}

const cancelPurchaseOrderAndCloseModal = (data: Record<CancelPurchaseOrderFormData>, props: Props, location: LocationItem) => {
    props.cancelPurchaseOrder(data, location);
    props.onSave();
}

const CancelPurchaseOrderForm = reduxForm<CancelPurchaseOrderFormData, Props>({
    form: 'cancel-purchaseorder-form'
})(CancelPurchaseOrder);

export default connect(undefined, { cancelPurchaseOrder })(CancelPurchaseOrderForm)
