import React, { useState, useEffect, useMemo, useRef} from 'react';
import { Input } from 'reactstrap';

import { BaseBinderProps } from './types';

/**
 * Text-Binder that calls "onChange" when the input loses focus, or the enter key is pressed
 */

type Props = {
    onChange?: (input: any) => void,
    formatter?: (value: string) => string,
    type?: "text" | "textarea",
    labelFieldName?: string,
    focus?: boolean
} & BaseBinderProps;


export default function DescriptionBinder(props: Props) {
    const { disabled, datacy, formatter, input, type, onChange , focus, id, labelFieldName} = props;

    // apply the formatter to the input
    const valueFormatted = useMemo(() =>  formatter ? formatter(input.value) : input.value, [formatter, input.value]);

    // keep track of the current input state
    const [value, setValue] = useState(valueFormatted);

    const inputRef = useRef<HTMLInputElement>(null);
    
    // reset the state when a new input value was passed in
    useEffect(() => {
        if (onChange) {
            onChange(valueFormatted)
        }

        if (focus && inputRef.current) {
            inputRef.current.focus();
        }
        if (value !== valueFormatted) setValue(valueFormatted);
    }, [valueFormatted, focus]);

    function handleBlur() {
        input.onChange(value);
        input.onBlur(value);
    }

    function handleChange(e: any) {
        setValue(e.target.value);
    }

    function handleKeyPress(e: React.KeyboardEvent<any>) {
        if (e.key === 'Enter') input.onChange(value);
    }

    function handleKeyUp(e: React.KeyboardEvent<any>){
        if (onChange) onChange(value)
    }

    return <Input data-cy={datacy}
                  id={id}
                  type={type ? type : "text"}
                  disabled={!!disabled}
                  {...input}
                  innerRef={inputRef}
                  maxLength={150}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  onKeyPress={handleKeyPress}
                  onKeyUp={handleKeyUp}
                  value={labelFieldName ? value[labelFieldName] : value}/>
}
