import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { StoreState } from '../../types/storeTypes';
import { loadProperties } from './actions';

const PROPERTIES_LOCATION = 'api/properties.json';

type Props = {
    render: (properties: { [key: string]: unknown } | undefined) => React.ReactElement | null;
    loadProperties: typeof loadProperties;
    properties: { [key: string]: unknown } | undefined;
}

function PropertiesFileLoader(props: Props) {
    useEffect(() => {
        if (!props.properties) {
            fetch(PROPERTIES_LOCATION)
                .then(r => r.json())
                .then(props.loadProperties);
        }
    }, [props.properties, props.loadProperties]);
    return props.render(props.properties);
}

export default connect(
    (state: StoreState) => ({
        properties: state.retailCommon.properties
    }),
    { loadProperties }
)(PropertiesFileLoader);
