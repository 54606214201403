import _ from 'lodash';
import { getRealmNo, RequestBuilder } from "@pearlchain/component-lib-common";
import { PurchaseOrderFilterFormData, PurchaseOrderFormData, CancelPurchaseOrderFormData, PurchaseOrderLineFormData, CalculatePurchaseOrderLinePriceData } from '../../types/procurementTypes';
import { List, Record } from 'immutable';
import {ProductFilterFormData, ProductFormData } from "../../types/productTypes";
import { InventoryOverviewFilterFormData, ConversionValidationRequest, StockCorrection, StockMovementOverviewFilterFormData, ConversionFormData, StockCreationFormData, OutgoingStockOrdersFilterForm, UpdateSalesOrderForm, NoAndCompanySelector, InventoryOverviewItem, OutgoingSalesOrderItem } from "../../types/inventoryTypes";
import { BasicProductResponse, RetailPriceResponse, UomOption } from './responseTypes';
import { ProductInfo } from '../productInfo/productInfoTypes';
import { WebParams } from './requestTypes';
import { SystemParam } from '../product/Product';

enum HttpRequestMethod {
    GET='GET',
    POST='POST'
}

export const URL = {
    LIST_PRODUCT_UOMS: 'sec/api/retail/masterdata/product/uoms',
    FIND_PURCHASE_ORDERS: 'sec/api/retail/procurement/po/find',
    GET_PURCHASE_ORDER: 'sec/api/retail/procurement/po',
    FIND_PURCHASE_ORDER_LINE: 'sec/api/retail/procurement/pol',
    LIST_PURCHASE_ORDER_STATUSES: 'sec/api/retail/procurement/po/statuses',
    SAVE_PURCHASE_ORDER: 'sec/api/retail/procurement/po',
    SEND_PURCHASE_ORDER: 'sec/api/retail/procurement/po/send',
    SAVE_PURCHASE_ORDER_LINE: 'sec/api/retail/procurement/pol/save',
    DELETE_PURCHASE_ORDER_LINE: 'sec/api/retail/procurement/pol/delete',
    REGISTER_PURCHASE_ORDER_LINES: 'sec/api/retail/procurement/po/register',
    CANCEL_PURCHASE_ORDER: 'sec/api/retail/procurement/po/cancel',
    LIST_CURRENCIES: 'sec/api/masterdata/currencies',
    LIST_DEFAULT_COMPANY_CURRENCY: 'sec/api/masterdata/defaultcompanycurrency',
    LIST_DEFAULT_COMPANY_SUPPLIER: 'sec/api/masterdata/defaultcompanysupplier',
    LIST_ALL_COMPANIES: 'sec/api/masterdata/companies',
    LIST_UOMS: 'sec/api/masterdata/uoms',
    GENERATE_PRODUCT_NO: 'sec/api/retail/masterdata/product-no/generate',
    FIND_PRODUCT_UOMS: 'sec/api/product/uoms',
    FIND_PRODUCT_BY_NO_AND_COMPANYCODE: 'sec/api/retail/masterdata/product-by-no',
    FIND_PRODUCT_SUGGESTIONS: 'sec/api/retail/masterdata/product/suggestions',
    GET_USER_INFO: 'sec/api/user',
    FIND_PRODUCT_BY_UUID: 'sec/api/retail/masterdata/product-by-uuid',
    FIND_PRODUCT_COMPONENT: 'NOT IMPLEMENTED',
    LIST_PRODUCTS: 'sec/api/retail/masterdata/products',
    SAVE_PRODUCT: 'sec/api/retail/masterdata/products/save',
    FETCH_OPTIONS: 'sec/api/retail/masterdata/options',
    CALCULATE_PURCHASE_ORDER_LINE_PRICE: 'sec/api/retail/procurement/pol/price',
    FIND_PRODUCT_UNITPRICE: 'sec/api/retail/procurement/unitPrice',
    GET_PRODUCT_STATUSES: 'sec/api/retail/inventory/product/statuses',
    GET_PRODUCT_GROUPS: 'sec/api/retail/inventory/product/groups',
    GET_STOCK_ENTRIES: 'sec/api/retail/inventory/product/find',
    GET_MOVEMENTS: 'sec/api/retail/inventory/product/movements/find',
    GET_CORRECTION_REASONS: 'sec/api/retail/inventory/corrections/reasons',
    GET_CORRECTION_ACTION: 'sec/api/retail/inventory/corrections/actions',
    POST_CORRECTIONS: 'sec/api/retail/inventory/corrections',
    GET_CONVERSION_VALIDATION: 'sec/api/retail/inventory/conversion/validate',
    POST_CONVERSION: 'sec/api/retail/inventory/conversion',
    POST_STOCK: 'sec/api/retail/inventory/stock',
    GET_PRODUCT_INFO: 'sec/api/retail/inventory/product',
    GET_ORDER_STATUSES: 'sec/api/retail/inventory/order/statuses',
    GET_ORDERS: 'sec/api/retail/inventory/orders',
    GET_ORDER: 'sec/api/retail/inventory/order',
    UPDATE_ORDER: 'sec/api/retail/inventory/order',
    CONFIRM_ORDER: 'sec/api/retail/inventory/order/confirm',
    GET_PRODUCT_LABELS_DOC: 'sec/api/retail/document/labels',
    FIND_PRODUCT_SAME_DESCRIPTION: 'sec/api/retail/masterdata/products-same-description',
    GET_SYSTEM_PARAMETER : 'sec/api/retail/masterdata/systemparam'
};

export function findPurchaseOrders(requestBuilder: RequestBuilder, formData: PurchaseOrderFilterFormData, webParams: WebParams): Promise<List<Record<PurchaseOrderFormData>>> {
    const params = Object.assign({}, formData, webParams);
    return requestBuilder.post(URL.FIND_PURCHASE_ORDERS)
        .setBodyJson(params)
        .toImmutable();
}

export function getPurchaseOrder(requestBuilder: RequestBuilder, params: { uniqueIdentifier: string }) {
    let url = appendQueryParams(URL.GET_PURCHASE_ORDER, params);
    return listItems(requestBuilder, url, HttpRequestMethod.GET);
}

export function findPurchaseOrderLine(uniqueIdentifier: string): Promise<Record<PurchaseOrderLineFormData>> {
    const url = appendQueryParams(URL.FIND_PURCHASE_ORDER_LINE, { uniqueIdentifier });
    return new RequestBuilder().get(url).toImmutable();
}

export function listPurchaseOrderStatuses(requestBuilder: RequestBuilder) {
    return listItems(requestBuilder, URL.LIST_PURCHASE_ORDER_STATUSES, HttpRequestMethod.GET);
}

export function listAllCompanies(requestBuilder: RequestBuilder) {
    return listItems(requestBuilder, URL.LIST_ALL_COMPANIES, HttpRequestMethod.POST);
}

export function getDefaultCompanyCurrency(requestBuilder: RequestBuilder, params: { companyCode: string }) {
    let url = appendQueryParams(URL.LIST_DEFAULT_COMPANY_CURRENCY, params);
    return listItems(requestBuilder, url, HttpRequestMethod.GET);
}

export function getDefaultCompanySupplier(requestBuilder: RequestBuilder, params: { companyCode: string }) {
    let url = appendQueryParams(URL.LIST_DEFAULT_COMPANY_SUPPLIER, params);
    return listItems(requestBuilder, url, HttpRequestMethod.GET);
}

export function calculatePurchaseOrderLinePrice(params: Record<CalculatePurchaseOrderLinePriceData>) {
    const url = appendQueryParams(URL.CALCULATE_PURCHASE_ORDER_LINE_PRICE, params.toJS());
    return new RequestBuilder().get(url).toImmutable();
}

export function findProductUnitPrice(productNo: string, companyCode: string, quantity: number, supplierUUID: string | undefined) {
    let url = appendQueryParams(URL.FIND_PRODUCT_UNITPRICE + '/' + productNo, { companyCode, quantity, supplierUUID });
    return new RequestBuilder().get(url).toJS<RetailPriceResponse>();
}

export function findProductUoms(productNo: string, companyCode: string) {
    const url = appendQueryParams(URL.FIND_PRODUCT_UOMS + '/' + productNo, { companyCode });
    return new RequestBuilder().get(url).toJS<UomOption[]>();
}

export function findProductByNoAndCompanyCode(productNo: string, companyCode: string, excludeGifts: boolean) : Promise<ProductFormData> {
    let url = appendQueryParams(URL.FIND_PRODUCT_BY_NO_AND_COMPANYCODE, { productNo, companyCode, excludeGifts });
    return new RequestBuilder()
        .get(url)
        .toJS<ProductFormData>();
}

export function savePurchaseOrder(requestBuilder: RequestBuilder, params: PurchaseOrderFormData) {
    return requestBuilder
        .post(URL.SAVE_PURCHASE_ORDER)
        .setBodyJson(params)
        .toImmutable();
}

export function sendPurchaseOrder(requestBuilder: RequestBuilder, params: PurchaseOrderFormData) {
    return requestBuilder
        .post(URL.SEND_PURCHASE_ORDER)
        .setBodyJson(params)
        .fetch();
}

export function cancelPurchaseOrder(requestBuilder: RequestBuilder, params: CancelPurchaseOrderFormData) {
    return requestBuilder
        .post(URL.CANCEL_PURCHASE_ORDER)
        .setBodyJson(params)
        .fetch();
}

export function savePurchaseOrderLine(requestBuilder: RequestBuilder, params: PurchaseOrderLineFormData) {
    return requestBuilder
        .post(URL.SAVE_PURCHASE_ORDER_LINE)
        .setBodyJson(params)
        .toImmutable();
}

export function deletePurchaseOrderLine(requestBuilder: RequestBuilder, purchaseOrderLineUuid: string) {
    let body = {
        uniqueIdentifier: purchaseOrderLineUuid
    }
    return requestBuilder
        .post(URL.DELETE_PURCHASE_ORDER_LINE)
        .setBodyJson(body)
        .toImmutable();
}

export function registerPurchaseOrderLines(requestBuilder: RequestBuilder, params: List<PurchaseOrderLineFormData>) {
    return requestBuilder
        .post(URL.REGISTER_PURCHASE_ORDER_LINES)
        .setBodyJson(params)
        .fetch();
}

export function listUoms(requestBuilder: RequestBuilder) {
    return listItems(requestBuilder, URL.LIST_UOMS, HttpRequestMethod.GET);
}

export function listCurrencies(requestBuilder: RequestBuilder) {
    return listItems(requestBuilder, URL.LIST_CURRENCIES, HttpRequestMethod.GET);
}

export function listProductUoms(requestBuilder: RequestBuilder, params: any) {
    let url = appendQueryParams(URL.LIST_PRODUCT_UOMS, params);
    return listItems(requestBuilder, url, HttpRequestMethod.GET);
}

export function getProductGroups(requestBuilder: RequestBuilder) {
    let url = appendQueryParams(URL.GET_PRODUCT_GROUPS, {realmNo: getRealmNo()});
    return listItems(requestBuilder, url, HttpRequestMethod.GET);
}

export function getStockEntries(requestBuilder: RequestBuilder, formData: Record<InventoryOverviewFilterFormData>, webParams: WebParams): Promise<List<Record<InventoryOverviewItem>>> {
    const params = Object.assign({}, formData, webParams);
    return requestBuilder
        .post(URL.GET_STOCK_ENTRIES)
        .setBodyJson(params)
        .toImmutable();
}

export function getMovements(requestBuilder: RequestBuilder, params: Record<StockMovementOverviewFilterFormData>) {
    let url = appendQueryParams(URL.GET_MOVEMENTS, params);
    return listItems(requestBuilder, url, HttpRequestMethod.GET);
}

export function getCorrectionReasons(requestBuilder: RequestBuilder) {
    return listItems(requestBuilder, URL.GET_CORRECTION_REASONS, HttpRequestMethod.GET);
}

export function getCorrectionActions(requestBuilder: RequestBuilder) {
    return listItems(requestBuilder, URL.GET_CORRECTION_ACTION, HttpRequestMethod.GET);
}

export function saveCorrections(requestBuilder: RequestBuilder, params: List<StockCorrection>) {
    return requestBuilder
        .post(URL.POST_CORRECTIONS)
        .setBodyJson(params)
        .fetch();
}

export function validateConversion(params: ConversionValidationRequest) {
    return new RequestBuilder()
        .setUrl(appendQueryParams(URL.GET_CONVERSION_VALIDATION, params))
        .setHttpMethod(HttpRequestMethod.GET)
        .toImmutable();
}

export function saveConversion(requestBuilder: RequestBuilder, params: ConversionFormData) {
    return requestBuilder
        .post(URL.POST_CONVERSION)
        .setBodyJson(params)
        .fetch();
}

export function saveStock(requestBuilder: RequestBuilder, params: StockCreationFormData) {
    return requestBuilder
        .post(URL.POST_STOCK)
        .setBodyJson(params)
        .fetch();
}

export function getProductInfo(requestBuilder: RequestBuilder, params: NoAndCompanySelector): Promise<ProductInfo> {
    return requestBuilder
        .setUrl(appendQueryParams(URL.GET_PRODUCT_INFO, params))
        .setHttpMethod(HttpRequestMethod.GET)
        .toImmutable();
}

export function getOrderStatuses(requestBuilder: RequestBuilder) {
    return listItems(requestBuilder, URL.GET_ORDER_STATUSES, HttpRequestMethod.GET);
}

export function getOutgoingOrders(requestBuilder: RequestBuilder, formData: OutgoingStockOrdersFilterForm, webParams: WebParams): Promise<List<Record<OutgoingSalesOrderItem>>> {
    const params = Object.assign({}, formData, webParams);
    return requestBuilder
        .post(URL.GET_ORDERS)
        .setBodyJson(params)
        .toImmutable();
}

export function getOutgoingOrder(requestBuilder:RequestBuilder, params: NoAndCompanySelector) {
    let url = appendQueryParams(URL.GET_ORDER, params);
    return listItems(requestBuilder,  url, HttpRequestMethod.GET);
}

export function updateOrder(requestBuilder: RequestBuilder, params: UpdateSalesOrderForm) {
    return requestBuilder
        .post(URL.UPDATE_ORDER)
        .setBodyJson(params)
        .fetch();
}

export function postConfirmOrder(requestBuilder: RequestBuilder, params: UpdateSalesOrderForm) {
    return requestBuilder
        .post(URL.CONFIRM_ORDER)
        .setBodyJson(params)
        .fetch();
}

export function findProductSuggestions(query: string, companyCode: string, maxResults: number, availableForPurchase: boolean | undefined, availableForSale: boolean |undefined, excludeGifts?: boolean |undefined): Promise<BasicProductResponse[]> {
    let url = URL.FIND_PRODUCT_SUGGESTIONS;

    if (availableForPurchase || availableForSale) {
        url = appendQueryParams(url, { query, companyCode, maxResults, availableForPurchase, availableForSale, excludeGifts});
    } else {
        url = appendQueryParams(url, { query, companyCode, maxResults, excludeGifts });
    }

    return new RequestBuilder().get(url).toJS();
}

// TODO: move to requestbuilder
function appendQueryParams(url: string, params: any) {
    url = url + '?';
    for (let key in params) {
        if (!url.endsWith('?')) {
            url = url + '&';
        }
        let value = params[key];
        if (isWildCardValue(value)) {
            value = encodeURIComponent(value);
        }
        if ((value && value != null) || value === 0) {
            url = url + key + '=' + value;
        } else {
            url = url + key + '=';
        }
    }
    return url;
}

function isWildCardValue(value: any) {
    return ((value && !Array.isArray(value) && _.isString(value) && typeof value !== "boolean") && (value.startsWith('%') || value.endsWith('%')));
}

export function queryItems(requestBuilder: RequestBuilder, params={}, url: string) {
    return requestBuilder
        .post(url)
        .setBodyJson(params)
        .toImmutable();
}

export function listItems(requestBuilder: RequestBuilder, url: string, method?: HttpRequestMethod) {
    return requestBuilder
        .setUrl(url)
        .setHttpMethod(method || HttpRequestMethod.POST)
        .toImmutable();
}

export function findProduct(requestBuilder: RequestBuilder, params: { productUuid: string, vatCountryCode: string|null }) : Promise<Record<ProductFormData>> {
    let url = appendQueryParams(URL.FIND_PRODUCT_BY_UUID, params);
    return listItems(requestBuilder, url, HttpRequestMethod.GET);
}

export function findProductComponent(requestBuilder: RequestBuilder, params: { productComponentUuid: string }) {
    console.error("Find product isn't implemented on backend yet");
    let url = appendQueryParams(URL.FIND_PRODUCT_COMPONENT, params);
    return listItems(requestBuilder, url, HttpRequestMethod.GET);
}

export function listProducts(requestBuilder: RequestBuilder, formData: ProductFilterFormData, webParams: WebParams): Promise<List<Record<ProductFormData>>> {
    const params = Object.assign({}, formData, webParams);
    return requestBuilder
        .post(URL.LIST_PRODUCTS)
        .setBodyJson(params)
        .toImmutable();
}


export function findProductSameDescription(requestBuilder: RequestBuilder, formData: ProductFilterFormData, webParams: WebParams): Promise<List<Record<ProductFormData>>> {
    const params = Object.assign({}, formData, webParams);
    return requestBuilder
        .post(URL.FIND_PRODUCT_SAME_DESCRIPTION)
        .setBodyJson(params)
        .toImmutable();
}

export function generateProductNo() {
    return new RequestBuilder()
        .post(URL.GENERATE_PRODUCT_NO)
        .toJS<{ productNo: string }>();
}

export function saveProduct(requestBuilder: RequestBuilder, params: ProductFormData) {
    return requestBuilder
        .post(URL.SAVE_PRODUCT)
        .setBodyJson(params)
        .toImmutable();
}

export function getUserInfo(requestBuilder: RequestBuilder) {
    return requestBuilder
        .post(URL.GET_USER_INFO)
        .toImmutable();
}

export function fetchOptions(requestBuilder: RequestBuilder, params: { sources: string }) {
    let url = appendQueryParams(URL.FETCH_OPTIONS, params);
    return listItems(requestBuilder, url, HttpRequestMethod.GET);
}

export function getProductLabelsDoc(requestBuilder: RequestBuilder, params: any) : Promise<Blob> {
    return requestBuilder
        .setUrl(URL.GET_PRODUCT_LABELS_DOC)
        .setBodyJson(params)
        .fetch()
        .then(r => r.blob())
}

export function getSystemParam(requestBuilder: RequestBuilder, param: string, companyCode: string | undefined) : Promise<SystemParam> {
    let url = appendQueryParams(URL.GET_SYSTEM_PARAMETER, { param, companyCode});
    let result = requestBuilder
        .get(url).toJS<SystemParam>();
    return result;
}