import { DialogState, DialogsState } from "./types";
import { Record, Map } from "immutable";
import { Action } from "redux";
import { ActionType, ShowDialogAction, HideDialogAction, ClearDialogAction } from "./actions";

const createInitialState = Record({
    dialogs: Map<string, DialogState>()
});

const createDialog = Record({
    show: false,
    params: null as unknown
});

function handleShowDialog(state: DialogsState, { dialogId, params }: ShowDialogAction) {
    return state.update('dialogs', dialogs => dialogs.set(dialogId, createDialog({
        show: true,
        params
    })));
}

function handleHideDialog(state: DialogsState, { dialogId }: HideDialogAction) {
    return state.update('dialogs', dialogs => dialogs.update(dialogId, dialog => dialog.set('show', false)));
}

function handleClearDialog(state: DialogsState, { dialogId }: ClearDialogAction) {
    return state.update('dialogs', dialogs => dialogs.delete(dialogId));
}

const actions: { [key: string]: (state: DialogsState, action: any) => DialogsState } = {
    [ActionType.SHOW_DIALOG]: handleShowDialog,
    [ActionType.HIDE_DIALOG]: handleHideDialog,
    [ActionType.CLEAR_DIALOG]: handleClearDialog
};

export default function(state: DialogsState = createInitialState(), action: Action): DialogsState {
    if (actions.hasOwnProperty(action.type)) {
        return actions[action.type](state, action);
    }
    return state;
}
