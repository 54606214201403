import {
    DeletePurchaseOrderLineAction,
    PurchaseOrderLineFormData,
    PurchaseOrderMode
} from "../../../types/procurementTypes";
import {ActionButtonEvent} from "../../../types/gridTypes";

export enum ActionType {
    NEW_PURCHASE_ORDER_LINE='procurement.NEW_PURCHASE_ORDER_LINE',
    VIEW_PURCHASE_ORDER_LINE='procurement.VIEW_PURCHASE_ORDER_LINE',
    UPDATE_PURCHASE_ORDER_LINE='procurement.UPDATE_PURCHASE_ORDER_LINE',
    SAVE_PURCHASE_ORDER_LINE='procurement.SAVE_PURCHASE_ORDER_LINE',
    DELETE_PURCHASE_ORDER_LINE='procurement.DELETE_PURCHASE_ORDER_LINE',
    STORE_PURCHASE_ORDER_LINE='procurement.STORE_PURCHASE_ORDER_LINE',
    SET_PURCHASE_ORDER_LINE_RESULT_INDEX='procurement.SET_PURCHASE_ORDER_LINE_RESULT_INDEX'
}

export function newPurchaseOrderLine(companyCode: string, supplierUUID: string | undefined, replaceLocation: boolean): NewPurchaseOrderLineAction {
    return {
        type: ActionType.NEW_PURCHASE_ORDER_LINE,
        companyCode,
        supplierUUID,
        replaceLocation
    };
}

export function viewPurchaseOrderLine(
    companyCode: string,
    supplierUUID: string | undefined,
    purchaseOrderLineUUID: string | undefined,
    resultIndex: number
): ViewPurchaseOrderLineAction {
    return {
        type: ActionType.VIEW_PURCHASE_ORDER_LINE,
        companyCode,
        supplierUUID,
        purchaseOrderLineUUID,
        resultIndex
    };
}

export function updatePurchaseOrderLine(
    companyCode: string,
    supplierUUID: string | undefined,
    purchaseOrderLineUUID: string | undefined,
    resultIndex: number
): UpdatePurchaseOrderLineAction {
    return {
        type: ActionType.UPDATE_PURCHASE_ORDER_LINE,
        companyCode,
        supplierUUID,
        purchaseOrderLineUUID,
        resultIndex
    };
}

export function savePurchaseOrderLine(data: Partial<PurchaseOrderLineFormData>): SavePurchaseOrderLineAction {
    return {
        type: ActionType.SAVE_PURCHASE_ORDER_LINE,
        data
    };
}

export function deletePurchaseOrderLine(event: ActionButtonEvent, mode: PurchaseOrderMode) : DeletePurchaseOrderLineAction {
    const purchaseOrderLineUuid = event.row.id;
    return { type: ActionType.DELETE_PURCHASE_ORDER_LINE, purchaseOrderLineUuid, mode }
}

export function storePurchaseOrderLine(data: Partial<PurchaseOrderLineFormData>): StorePurchaseOrderLineAction {
    return {
        type: ActionType.STORE_PURCHASE_ORDER_LINE,
        data
    };
}

export function setPurchaseOrderLineResultIndex(resultIndex: number): SetPurchaseOrderLineResultIndexAction {
    return {
        type: ActionType.SET_PURCHASE_ORDER_LINE_RESULT_INDEX,
        resultIndex
    };
}

export type NewPurchaseOrderLineAction = {
    type: ActionType.NEW_PURCHASE_ORDER_LINE,
    companyCode: string,
    supplierUUID: string | undefined,
    replaceLocation: boolean
}

export type ViewPurchaseOrderLineAction = {
    type: ActionType.VIEW_PURCHASE_ORDER_LINE,
    companyCode: string,
    supplierUUID: string | undefined,
    purchaseOrderLineUUID: string | undefined,
    resultIndex: number
}

export type UpdatePurchaseOrderLineAction = {
    type: ActionType.UPDATE_PURCHASE_ORDER_LINE,
    companyCode: string,
    supplierUUID: string | undefined,
    purchaseOrderLineUUID: string | undefined,
    resultIndex: number
}

export type SavePurchaseOrderLineAction = {
    type: ActionType.SAVE_PURCHASE_ORDER_LINE,
    data: Partial<PurchaseOrderLineFormData>
}

export type StorePurchaseOrderLineAction = {
    type: ActionType.STORE_PURCHASE_ORDER_LINE,
    data: Partial<PurchaseOrderLineFormData>
}

export type SetPurchaseOrderLineResultIndexAction = {
    type: ActionType.SET_PURCHASE_ORDER_LINE_RESULT_INDEX,
    resultIndex: number,
}
