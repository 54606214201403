import React from 'react';
import PropertiesFileLoader from '../common/PropertiesFileLoader';
import { KEY_LANGUAGE, getAuthToken, getLanguage } from '@pearlchain/component-lib-common';


function getFullDashboardsUrl(baseUrl: string) {
    const searchParams = new URLSearchParams();
    searchParams.set('auth-token', getAuthToken());
    searchParams.set(KEY_LANGUAGE, getLanguage());

    let sepChar = '?';
    if (baseUrl.indexOf('?') >= 0) {
        sepChar = '&';
    }

    return baseUrl + sepChar + searchParams;
}

export default function Home() {
    return <PropertiesFileLoader
            render={(props: any) => {
                return props
                    ? <iframe src={getFullDashboardsUrl(props.urlDashboards as string)} className="dashboards-iframe"/>
                    : null;
            }}
        />
}
