import { List, Record } from "immutable";
import { Column, Row } from "@pearlchain/component-lib-react-pg";
import {ProductGridData, ProductComponentFormData} from "../../types/productTypes";
import { formatPriceWithUom } from "../../powergrid/formatters";

export function productsToRows(data: List<Record<ProductGridData>>,
        idFn: (item: Record<ProductGridData>, index: number) => string,
        columns: Column[],
        t: (value: string) => string): Row[] {
    const rows: Row[] = [];

    let i = 0;
    for (let item of data) {
            const salesPriceIncludingVat = item.get('salesPriceIncludingVat');
            const salesPriceExcludingVat = item.get('salesPrice');
            const uom = item.get('priceUom');
            const purchasePrice = item.get('purchasePrice');
            const currency = item.get('currency');

            const row: Row = columns.map((column) => {
                if (column._key === 'salesPriceExcludingVatWithUOM') {
                  return salesPriceExcludingVat ? formatPriceWithUom(t, salesPriceExcludingVat, currency, uom) : '';
                } else if (column._key === 'salesPriceIncludingVatWithUOM') {
                    return salesPriceIncludingVat ? formatPriceWithUom(t, salesPriceIncludingVat, currency, uom) : '';
                } else if (column._key === 'purchasePriceWithUom') {
                    return purchasePrice ? formatPriceWithUom(t, purchasePrice, currency, uom) : '';
                } else {
                    return item.get(column._key);
                }
            }) as any;
            row.id = idFn(item, i++);
            rows.push(row);
    }
    return rows;
}

export function productComponentsToRows(data: List<Record<ProductComponentFormData>>,
    idFn: (item: Record<ProductComponentFormData>, index: number) => string,
    columns: Column[],
    t: (value: string) => string): Row[] {
    const rows: Row[] = [];

    let i = 0;
    for (let item of data) {
        const salesPricesIncludingVat = item.get('salesPriceIncludingVat');
        const salesPriceExcludingVat = item.get('salesPrice');
        const uom = item.get('uom');
        const currency = item.get('currency');

        const row: Row = columns.map((column) => {
            if (column._key === 'salesPriceExcludingVatWithUOM') {
              return salesPriceExcludingVat ? formatPriceWithUom(t, salesPriceExcludingVat, currency, uom) : '';
            } else if (column._key === 'salesPriceIncludingVatWithUOM') {
                return salesPricesIncludingVat ? formatPriceWithUom(t, salesPricesIncludingVat, currency, uom) : '';
            } else {
                return item.get(column._key);
            }
        }) as any;
        row.id = idFn(item, i++);
        rows.push(row);
    }
    return rows;
}
