import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Secured from '../../auth/Secured'
import { ButtonToolbar, Form } from 'react-bootstrap'
import { FormFieldsLayout } from '@pearlchain/component-lib-ui'
import { stockConversionFormFieldsCreator } from './stockConversionFormFieldsCreator'
import { formValueSelector, InjectedFormProps, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import { StoreState } from '../../../types/storeTypes'
import { ConversionFormData } from '../../../types/inventoryTypes'
import ActionTypes, { convertStocks, validateConversionResponse } from '../actions'
import { getConversionValidationResponse } from '../selectors'
import { getRealmNo, useFetcher } from "@pearlchain/component-lib-common";
import { Auth } from "../../auth/functionalityCodes";
import { useTranslation } from "react-i18next";
import { validateConversion } from "../../remote/api";
import AsyncActionButton from '../../actionButton/AsyncActionButton'
import ActionButton from '../../actionButton/ActionButton'

interface Props {
    fromQuantity: number,
    fromProductNo: string,
    toProductNo: string,
    roasting: boolean,
    convertStocks: typeof convertStocks,
    changeFromProduct: (productNo: string) => void,
    changeToProduct: (productNo: string) => void,
    changeFromProductQuantity: (fromQuantity: number) => void,
    validateConversionResponse: typeof validateConversionResponse
}

function StockConversionPage(props: InjectedFormProps<ConversionFormData, Props> & Props) {

    const {t} = useTranslation();
    const [quantityDisable, setQuantityDisable] = useState(true)

    const { data: validation  } = useFetcher(useCallback(() => {
        return validateConversion({
            fromProductNo: props.fromProductNo,
            toProductNo: props.toProductNo,
            fromQuantity: props.fromQuantity,
            realmNo: getRealmNo(),
            resetForm: false
        })
    }, [props.fromProductNo, props.toProductNo, props.fromQuantity]));

    useEffect(() => {
        if (validation) {
            props.change('fromProductDescription', validation.get('fromProductDescription'));
            props.change('fromUom', validation.get('fromUom'));
            props.change('toProductDescription', validation.get('toProductDescription'));
            props.change('toUom', validation.get('toUom'));
            props.change('toQuantity', validation.get('toQuantity'));
            props.validateConversionResponse(validation);
        }
    }, [validation]);

    useEffect(() => {
        setQuantityDisable(!quantityDisable)
    }, [props.roasting]);

    const formFields = useMemo(() => {
        return stockConversionFormFieldsCreator(t,quantityDisable);
    }, [props.roasting]);

    return (
        <Secured code={Auth.INVENTORY_STOCK_CONVERSION}>
            <Form className="page-container">
                <ButtonToolbar className="mt-2 mb-3">
                    <AsyncActionButton
                        actionId={ActionTypes.CONVERT_STOCKS}
                        variant="primary"
                        type="submit"
                        disabled={!props.valid}
                        onAction={props.handleSubmit((data: ConversionFormData) => {
                            props.convertStocks(data, validation, true, ActionTypes.CONVERT_STOCKS);
                            props.reset();
                        })}
                    >
                        {t('retail.inventory.button.save')}
                    </AsyncActionButton>
                    <AsyncActionButton
                        actionId={ActionTypes.CONVERT_STOCKS_AND_NEW}
                        variant="primary"
                        type="submit"
                        disabled={!props.valid}
                        onAction={props.handleSubmit((data: ConversionFormData) => {
                            console.log(data)
                            props.convertStocks(data, validation, false, ActionTypes.CONVERT_STOCKS_AND_NEW);
                            props.reset();
                        })}
                    >
                        {t('retail.inventory.button.saveAndNew')}
                    </AsyncActionButton>
                    <ActionButton
                        variant="primary"
                        onAction={() => props.reset()}>
                        {t('retail.inventory.button.clear')}
                    </ActionButton>
                </ButtonToolbar>
                <FormFieldsLayout fields={formFields} numCols={{ xs: 2, sm: 2, md: 2, lg: 2 }}/>
            </Form>
        </Secured>
    )
}

const StockConversionForm = reduxForm<ConversionFormData, Props>({
    form: 'stock-conversion-form'
})(StockConversionPage);

export default connect((state: StoreState) => {
        const selector =  formValueSelector('stock-conversion-form');
        return {
            initialValues: {
                realmNo: getRealmNo()
            },
            validationResponse: getConversionValidationResponse(state),
            fromProductNo: selector(state, 'fromProductNo'),
            toProductNo: selector(state, 'toProductNo'),
            fromQuantity:  selector(state, 'fromQuantity'),
            roasting: selector(state, 'roasting')
        }
    },
    { convertStocks, validateConversionResponse }
)(StockConversionForm);
