
export enum ActionType {
    SHOW_DIALOG = 'dialog.SHOW_DIALOG',
    HIDE_DIALOG = 'dialog.HIDE_DIALOG',
    CLEAR_DIALOG = 'dialog.CLEAR_DIALOG'
}

export function showDialog(dialogId: string, params: unknown) {
    return {
        type: ActionType.SHOW_DIALOG,
        dialogId,
        params
    };
}

export function hideDialog(dialogId: string) {
    return {
        type: ActionType.HIDE_DIALOG,
        dialogId
    };
}

export function clearDialog(dialogId: string) {
    return {
        type: ActionType.CLEAR_DIALOG,
        dialogId
    };
}

export type ShowDialogAction = {
    type: ActionType.SHOW_DIALOG,
    dialogId: string;
    params: unknown;
}

export type HideDialogAction = {
    type: ActionType.HIDE_DIALOG,
    dialogId: string;
}

export type ClearDialogAction = {
    type: ActionType.CLEAR_DIALOG,
    dialogId: string;
}
