import {StoreState} from "../../types/storeTypes";
import {Option, ShapeItOptions} from "./types";
import {List} from "immutable";
import { getCachedRequest } from "@pearlchain/component-lib-common";

export function getOptions(state: StoreState) : Map<string, List<Option>> {
    return state.options.get("options");
}

export function getOptionsFromCommonStore(state: StoreState): Map<string, ShapeItOptions> | undefined {
    const request = getCachedRequest(state, 'fetch-options');
    return request && (request.get('data') as any);
}