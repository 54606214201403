import {
    DateBinder,
    FormFieldConfig,
    formFieldConfig,
    TextBinder
} from "@pearlchain/component-lib-ui";
import { getRealmNo } from "@pearlchain/component-lib-common";
import ProductBinder from "../../common/binders/ProductBinder";

export function stockMovementsOverviewFormFieldsCreator() {
    const formFields: { [key: string]: FormFieldConfig } = {
        productNo: formFieldConfig({ label: 'retail.inventory.movements.productNo', translateLabel: true, binder: ProductBinder, companyCode: getRealmNo(), id: "product-binder-id"}),
        productDescription: formFieldConfig({ label: 'retail.inventory.movements.productDescription', translateLabel: true, binder: TextBinder }),
        periodFrom: formFieldConfig({ label: 'retail.inventory.movements.periodFrom', translateLabel: true, binder: DateBinder }),
        periodTo: formFieldConfig({ label: 'retail.inventory.movements.periodTo', translateLabel: true, binder: DateBinder })
    };
    return formFields;
}
