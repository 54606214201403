import React, {useEffect, useState, useMemo, useRef} from 'react'
import {List, Record} from "immutable";
import {
    InventoryOverviewItem,
    Option,
    PrintLabelInfo,
    StockCorrection
} from "../../../types/inventoryTypes";
import { buildStockEntryColumns } from "./gridUtils";
import { mapAuthorizationProps } from "../../auth/AuthUtils";
import {INVENTORY_OVERVIEW_FORM} from "./InventoryOverview";
import {useTranslation} from 'react-i18next';
import {
    processExportToExcel,
    markEntryToPrintLabel,
    fetchReasons,
    fetchCorrectionActions,
    processNewCorrection, viewStockMovements
} from '../actions';
import {AuthorizationProps} from "../../auth/authTypes";
import {WebSortFields} from "../../remote/requestTypes";
import {connect} from "react-redux";
import {StoreState} from "../../../types/storeTypes";
import {getFormValues} from "redux-form";
import {getCorrectionActionsFromState, getInventorySearchResultFromState, getReasonsFromState} from "../selectors";
import ReactTableGrid from '../../../react-table/ReactTableGrid';
import { getStockEntryColumn } from '../../../react-table/ReactTableUtils';
import useComponentVisible from '../../util/hooks/useComponentVisible'
import ExcelButtton from '../../common/ExcelButtton'
import { Column } from '@pearlchain/component-lib-react-pg';

export interface Props extends AuthorizationProps {
    multiSelectMode: boolean,
    page: List<Record<InventoryOverviewItem>>,
    reasons: List<Record<Option>>,
    correctionActions: List<Record<Option>>,
    entries: List<Record<InventoryOverviewItem>>,
    sortFields: WebSortFields,
    setSortFields: (sortFields: WebSortFields) => void,
    processNewCorrection: typeof processNewCorrection,
    viewStockMovements: typeof viewStockMovements,
    processExportToExcel: typeof processExportToExcel,
    markEntryToPrintLabel: typeof markEntryToPrintLabel,
    printLabels: List<Record<PrintLabelInfo>>,
    fetchReasons: () => void,
    fetchCorrectionActions: () => void,
    formValues: any
}

function InventoryOverviewPage(props: Props) {
    const {t} = useTranslation();
    const [values, setValues] = useState<List<Record<InventoryOverviewItem>>>(props.page);

    //Excel action button
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
    const [xpos, setXpos] = useState("")
    const [ypos, setYpos] = useState("")

    //Array of ref inputs, When pressing enter change to the next one
    const inputsRefs = useRef<any>([]);

    useEffect(() => {
        let temp = props.page
        props.printLabels.forEach(e => {
            temp = temp.update(
                props.page.findIndex(function(item) { 
                  return item.get("productNo") === e.get("productNo"); 
                }), function(item) {
                  return item.set("numLabels", e.get("numLabels"));
                }
            ); 

            
        })

        setValues(temp)

        inputsRefs.current = inputsRefs.current.slice(0, props.page.size);

    }, [props.page, props.printLabels]);

    //Set the excel button  location
    const handleExcel = (e: any) => {
        e.preventDefault();
        setIsComponentVisible(true)
        setXpos(`${e.pageX}px`)
        setYpos(`${e.pageY}px`)
    };

    function getHiddenColumns(columns: Column[]) {
        const hiddenColumns: String[] = [];

        let i = 0;
        for (let item of columns) {
            if(item.width == 0){
                hiddenColumns.push(item._key)
            }
        }

        return hiddenColumns;
    }

    const handleExportExcel = () => {
        props.processExportToExcel(props.formValues,values)
        setIsComponentVisible(false)
    };

    function onValueChanged(id: string, key: string, value: unknown, multiSelectMode?: boolean) {
        const columnName: string = key;
        if (multiSelectMode && (columnName === 'reason' || columnName === 'actionType')) {
            setValues((draft) => {
                return draft.map((record: Record<InventoryOverviewItem>) => {
                        const correction: StockCorrection = {
                            uuid: record.get('uniqueIdentifier'),
                            stockEntryNo: record.get('no'),
                            productNo: record.get('productNo'),
                            integerUom: record.get('integerUom'),
                            currentQuantity: record.get('quantity'),
                            remark: record.get('remark')
                        };

                        if (columnName === 'reason') {
                            correction.reason = value as string;
                        } else if (columnName === 'actionType') {
                            correction.actionType = value as string;
                        }

                        props.processNewCorrection(correction);

                        return record.set(columnName, value as string);
                    })
            });

        } else {
            setValues((draft) => {
                return draft.map((record: Record<InventoryOverviewItem>) => {
                    if (record.get('uniqueIdentifier') === id) {

                        if (columnName === 'printLabel' || columnName === 'numLabels') {

                            const marked = value as unknown as boolean;
                            const no: string = record.get('productNo');
                            const source: string = record.get('productSource');
                            const description: string = record.get('productDescription');
                            const numLabels: number = value as number;

                            props.markEntryToPrintLabel(no, source, description, numLabels, marked);
                        } else {
                            const correction: StockCorrection = {
                                uuid: record.get('uniqueIdentifier'),
                                stockEntryNo: record.get('no'),
                                productNo: record.get('productNo'),
                                integerUom: record.get('integerUom'),
                                currentQuantity: record.get('quantity'),
                                remark: record.get('remark')
                            };
                            if (columnName === 'newQuantity') {
                                correction.newQuantity = Number.parseFloat(value as string);
                            } else if (columnName === 'reason') {
                                correction.reason = value as string;
                            } else if (columnName === 'remark') {
                                correction.remark = value as string;
                            } else if (columnName === 'actionType') {
                                correction.actionType = value as string;
                            }

                            props.processNewCorrection(correction);
                        }

                        {/*// @ts-ignore */}
                        return record.set(columnName, value)
                    }

                    return record;
                    })
            });

        }

    }

    const changeFocus = (index:number) => {
        inputsRefs.current[Number(index) + 1].focus()
    };

    const reactTableColumns = useMemo(() => getStockEntryColumn(
        buildStockEntryColumns(t),
        props.reasons,
        props.correctionActions,
        t,
        props.viewStockMovements,
        onValueChanged,
        props.multiSelectMode,
        inputsRefs,
        changeFocus
    ), [props.multiSelectMode, values]);

    const hiddenColumns = useMemo(() => getHiddenColumns(buildStockEntryColumns(t)), [buildStockEntryColumns(t)]);

    const memoizedReactTable = useMemo(() => {
        return (
            <div onContextMenu={(e) => handleExcel(e)}>
                <ReactTableGrid
                    columns={reactTableColumns}
                    hiddenColumns={hiddenColumns}
                    data={values.toJS()}
                    onSortColumnsChanged={(sortColumns) => {
                        if (JSON.stringify(sortColumns) !== "{}") {
                            props.setSortFields(sortColumns);
                        }
                    }}
                />
            </div>
        )
    }, [values, props.multiSelectMode]);

    return (
        <div>
            {isComponentVisible ?
                <div ref={ref}>
                    <ExcelButtton xpos={xpos} ypos={ypos} handleExportExcel={handleExportExcel} t={t} />
                </div> : "" }
            {memoizedReactTable}
        </div>
    )
}

export default connect(
    (state: StoreState) => {
        const formValues = getFormValues(INVENTORY_OVERVIEW_FORM)(state);
        const authorizationProps = mapAuthorizationProps(state);
        return {
            functionalityCodes: authorizationProps.functionalityCodes,
            isCorporateAdmin: authorizationProps.isCorporateAdmin,
            printLabels: state.inventory.get('printLabels') || List(),
            reasons: getReasonsFromState(state),
            correctionActions: getCorrectionActionsFromState(state),
            entries: getInventorySearchResultFromState(state),
            formValues
        }
    },
    {
        processExportToExcel,
        markEntryToPrintLabel,
        fetchReasons,
        fetchCorrectionActions,
        processNewCorrection,
        viewStockMovements
    }
)(InventoryOverviewPage);