import { formatDecimal, formatDate, formatDateTime, formatDateTimeWithSeconds, formatTime, formatTimeWithSeconds, formatYearMonth, Formatter } from '@pearlchain/component-lib-common'
import { GridCreationContext } from './extensionTypes'
import { PgExts, Row, Column } from '@pearlchain/component-lib-react-pg'
import i18next from 'i18next';

export enum FormatType {
    DECIMAL = 'decimal',
    TIME = 'time',
    TIME_SEC = 'time-seconds',
    DATE = 'date',
    DATE_TIME = 'datetime',
    DATE_TIME_SEC = 'datetime-seconds',
    YEAR_MONTH = 'year-month',
    TRANSLATE = 'translate'
}

function translate(key: unknown) {
    return key ? i18next.t('' + key) : '' + key;
}

const formatters: { [K in FormatType]: Formatter } = {
    [FormatType.DECIMAL]: formatDecimal,
    [FormatType.TIME]: formatTime,
    [FormatType.DATE]: formatDate,
    [FormatType.DATE_TIME]: formatDateTime,
    [FormatType.DATE_TIME_SEC]: formatDateTimeWithSeconds,
    [FormatType.TIME_SEC]: formatTimeWithSeconds,
    [FormatType.YEAR_MONTH]: formatYearMonth,
    [FormatType.TRANSLATE]: translate
};

function columnFormatter(formatter: Formatter) {
    return (value: unknown, record: Row[], column: Column) => formatter(value, column.format);
}

export function createFormattingExtension(exts: PgExts, ctx: GridCreationContext) {
    const fmt: { [key: string]: any } = {};
    for (let key in formatters) {
        // bind the formatters to the translation function
        fmt[key] = columnFormatter(formatters[key as keyof typeof formatters]);
    }
    exts.formatting = fmt;
}
