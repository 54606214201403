import React from 'react'
import { getFormValues } from 'redux-form'
import { connect } from 'react-redux'
import { Record, List } from 'immutable'
import { useTranslation } from 'react-i18next';

import { GridReactive, Column, Row, ApplyCallbackDatastore } from '@pearlchain/component-lib-react-pg'
import { RequestBuilder } from '@pearlchain/component-lib-common'

import { Auth } from '../../../auth/functionalityCodes'
import { AuthorizationProps } from '../../../auth/authTypes'
import { isAuthorizedByProps, mapAuthorizationProps } from '../../../auth/AuthUtils'
import { StoreState } from '../../../../types/storeTypes'
import { dataToRows } from '../../../../powergrid/helpers'

import { GridRowEvent, OutgoingSalesOrderItem } from '../../../../types/inventoryTypes'
import { getOutgoingOrders } from '../../../remote/api'
import { createExtensions } from "../../../../powergrid/extensions/contextmenu/powergridHelpers";
import { WebSortFields } from '../../../remote/requestTypes';
import StepThroughPager from '../../../common/pagination/StepThroughPager';
import { DEFAULT_PAGE_SIZE } from '../../../util/helpers';
import { gridColumnsToSortFields } from '../../../remote/requestHelpers';
import {SALES_ORDER_CONFIRMED_STATUS} from "../../actions";

export interface Props extends AuthorizationProps{
    searchResult: List<Record<OutgoingSalesOrderItem>> | undefined,
    sortFields: WebSortFields,
    setSortFields: (sortFields: WebSortFields) => void,
    onClickOrder: (uuid: string, update: boolean) => void,
    formValues: any
}


const SORT_COLUMN_FIELDS_MAPPING = new Map<string, string>([
    ['company', 'company'],
    ['no', 'no'],
    ['partner', 'partner'],
    ['orderDate', 'orderDate'],
    ['requestedDeliveryDate', 'requestedDeliveryDate'],
    ['orderType', 'orderType'],
    ['status', 'status']
]);

function OutgoingStockOrdersOverviewGrid(props: Props) {

    const {t} = useTranslation();

    const columns: Column[] = [
        { _key: 'uniqueIdentifier', width: 0, title: '', idField: true },
        { _key: 'actions',  width: 200, title: t('retail.inventory.orders.overview.actions'), actions: [
                {cssClass: 'btn btn-secondary fa', icon: 'fa-eye', eventName: 'view-outgoing-order', hidden: true},
                {cssClass: 'btn btn-secondary fa', icon: 'fa-pencil-alt', eventName: 'update-outgoing-order', hidden: true}
            ]
        },
        { _key: 'company', width: 200, title: t('retail.inventory.orders.overview.company') },
        { _key: 'no', width: 200, title: t('retail.inventory.orders.overview.no') },
        { _key: 'partner', width: 200, title: t('retail.inventory.orders.overview.partner') },
        { _key: 'orderDate', width: 200, title: t('retail.inventory.orders.overview.orderDate'), formatter: 'date' },
        { _key: 'requestedDeliveryDate', width: 200, title: t('retail.inventory.orders.overview.requestedDeliveryDate'), formatter: 'date' },
        { _key: 'orderType', width: 200, title: t('retail.inventory.orders.overview.orderType'), formatter: 'translate' },
        { _key: 'status', width: 200, title: t('retail.inventory.orders.overview.status'), formatter: 'translate' }
    ];

    const viewOutgoingOrder = { key: 'view-outgoing-order', action: (event: GridRowEvent) => {
        const row = event.row;

        if (row) {
            props.onClickOrder(row.rowData[row.id][3], false)
        }
    }};

    const updateOutgoingOrder = { key: 'update-outgoing-order', action: (event: GridRowEvent) => {
            const row = event.row;

            if (row) {
                props.onClickOrder(row.rowData[row.id][3], true)
            }
    }};

    function resolveGlobalAttribute(row: Row, property: string, attribute: string) {
        if (property === 'view-outgoing-order' && attribute === 'actionHidden') {
            if (isAuthorizedByProps(props, Auth.INVENTORY_VIEW_ORDER)) {
                return false;
            }
        }

        if (property === 'update-outgoing-order' && attribute === 'actionHidden') {
            if (row[8] === SALES_ORDER_CONFIRMED_STATUS) {
                return true;
            }
            if (isAuthorizedByProps(props, Auth.INVENTORY_UPDATE_ORDER)) {
                return false;
            }
        }
    }

    return(
        <StepThroughPager
            data={props.searchResult || List<Record<OutgoingSalesOrderItem>>()}
            pageSize={DEFAULT_PAGE_SIZE}
            fetchNextData={(params) => {
                const formData = props.formValues;
                return getOutgoingOrders(new RequestBuilder(), formData, params);
            }}
            render={(data) => {
                    let rows: Row[] = dataToRows<Record<OutgoingSalesOrderItem>>(data,
                        (x: Record<OutgoingSalesOrderItem>, index) => index + "", columns);

                    return (
                        <GridReactive gridId="outgoing-stock-orders-overview-grid"
                                      data={rows}
                                      classNameOuter="wrap-grid"
                                      columns={columns}
                                      events={[viewOutgoingOrder, updateOutgoingOrder]}
                                      onSortColumnsChanged={(sortColumns) => {
                                          if (sortColumns) {
                                            const sortFields = gridColumnsToSortFields(sortColumns, columns, SORT_COLUMN_FIELDS_MAPPING);
                                            props.setSortFields(sortFields);
                                          }
                                      }}
                                      extensions={
                                          createExtensions({"formatting": true, "resizing": true}, undefined, 'procurement-overview-form-id', {translate: t})
                                      }
                                      createDatastore={(data) =>
                                          new ApplyCallbackDatastore(['view-outgoing-order', 'update-outgoing-order'], ['actionHidden'], resolveGlobalAttribute, data)
                                      }
                        />
                    )
                }
            }/>
    );
}

export default connect(
    (state: StoreState) => {
        const formValues = getFormValues('outgoing-orders-overview-form')(state);
        const authorizationProps = mapAuthorizationProps(state);
        return {
            functionalityCodes: authorizationProps.functionalityCodes,
            isCorporateAdmin: authorizationProps.isCorporateAdmin,
            formValues
        }
    },
    {}
)(OutgoingStockOrdersOverviewGrid);

