import React, { useMemo } from 'react';
import { TranslationFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { createLocationItem } from '@pearlchain/component-lib-common';
import { LocationMenuItem, Navigation } from '@pearlchain/component-lib-ui';
import {connect} from "react-redux";

import { Page } from '../../types/pageTypes';

import { pages } from './pages';
import {isPageAuthorized, mapAuthorizationProps} from "../auth/AuthUtils";
import {AuthorizationProps} from "../auth/authTypes";
import { List } from 'immutable';

interface Props extends AuthorizationProps {
    currentPage: Page|undefined
}

const homeItem = createLocationItem({
    pathName: '/',
    title: 'Home',
    translateTitle: false
});

const prefixLocation = List([homeItem]);

function RetailNavigation(props: Props) {
    const { t } = useTranslation();

    const initialLocation = useMemo(() => {
        const currentPageItem = createLocationItem({
            pathName: props.currentPage ? props.currentPage.path : '/',
            title: props.currentPage ? props.currentPage.title : 'Title',
            translateTitle: !!props.currentPage
        });

        return prefixLocation.push(currentPageItem);
    }, [props.currentPage]);

    return (
        <Navigation
            loginPath="#/login"
            initialLocation={initialLocation}
            prefixLocation={prefixLocation}
            menuItems={getMenuItems(t, props)}
        />
    );
}

function getMenuItems(t: TranslationFunction, props : Props): LocationMenuItem[] {
    return pages.filter(page => {
        return isPageAuthorized(props.functionalityCodes, props.isCorporateAdmin, page) && !page.hide
    }).map((page): LocationMenuItem => {
        const title = t(page.title);
        return {
            title,
            icon: page.icon,
            location: createLocationItem({
                title,
                pathName: page.path
            })
        };
    });
}

export default connect(mapAuthorizationProps)(RetailNavigation)
