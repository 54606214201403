import React, { useState, useMemo, useEffect, useCallback, Fragment } from 'react'
import { connect } from 'react-redux'
import { reduxForm, InjectedFormProps, formValueSelector } from 'redux-form'
import { Form, ButtonToolbar } from 'react-bootstrap'
import {
    pushLocation,
    createLocationItem,
    useFetcher,
    formatPrice
} from '@pearlchain/component-lib-common'
import { Record, List } from 'immutable'
import moment from 'moment'

import { StoreState } from '../../../types/storeTypes'
import Secured from '../../auth/Secured'
import { Auth } from '../../auth/functionalityCodes'
import Alerts from '../../common/Alerts'
import FormFieldsLayout from '../../../form/FormFieldsLayout'
import { ActionButtonEvent } from '../../../types/gridTypes'

import PurchaseOrderLineGrid from './PurchaseOrderLineGrid'
import {
    PurchaseOrderFormData,
    PurchaseOrderLineFormData,
    PurchaseOrderMode,
    DeletePurchaseOrderLineAction,
    GetDefaultCompanyCurrencyAction,
    SavePurchaseOrderIntermediaryAction,
    ClearValidationMessagesAction,
    SupplierIdentifier, createSupplierIdentifier, PurchaseOrderStatus
} from '../../../types/procurementTypes'
import ActionTypes, {
    savePurchaseOrder,
    sendPurchaseOrder,
    getDefaultCompanyCurrency,
    clearValidationMessages,
    getDefaultCompanySupplier,
    commitFormValueChangesToStore,
    updatePurchaseOrderInGrid
} from '../actions'
import {
    getPurchaseOrderLines,
    getPurchaseOrder,
    getCompanyCurrency,
    getDefaultCompany,
    getPurchaseOrderMode,
    getDefaultSupplier,
    getAllCompanies
} from '../selectors'
import CancelPurchaseOrderModal from '../cancel/CancelPurchaseOrderModal'
import { createPurchaseOrderFormFields } from '../formFieldsCreator'
import { updatePurchaseOrder as UpdatePurchaseOrderPage } from '../procurementPage'
import { determinePurchaseOrderLineCurrency } from '../helpers'
import { useTranslation } from 'react-i18next'
import {
    updatePurchaseOrderLine,
    newPurchaseOrderLine,
    viewPurchaseOrderLine, deletePurchaseOrderLine
} from '../purchaseOrderLine/purchaseOrderLineActions'
import { calculateTotals } from './purchaseOrderPriceCalculation'
import { Company } from '@pearlchain/stackbase-common/lib/components/CompanyBinder'
import ConfirmAlert, { ConfirmableAlertOpts } from '../../common/ConfirmAlert'
import AsyncActionButton from '../../actionButton/AsyncActionButton'
import ActionButton from '../../actionButton/ActionButton'
import * as i18next from "i18next";

export interface StoreStateProps {
    purchaseOrderLines?: List<Record<PurchaseOrderLineFormData>>,
    purchaseOrder?: Record<PurchaseOrderFormData>,
    supplier: Record<SupplierIdentifier>,
    companyCode: string,
    companies: Company[] | undefined,
    initialValues: any;
    mode: PurchaseOrderMode,
    currency?: string,
    interCompanyTransaction?: boolean
}

interface DispatchProps extends InjectedFormProps {
    savePurchaseOrder: typeof savePurchaseOrder,
    sendPurchaseOrder: typeof sendPurchaseOrder,
    pushLocation: typeof pushLocation,
    newPurchaseOrderLine: typeof newPurchaseOrderLine,
    viewPurchaseOrderLine: typeof viewPurchaseOrderLine,
    updatePurchaseOrderLine: typeof updatePurchaseOrderLine,
    deletePurchaseOrderLine: (event: ActionButtonEvent, mode: PurchaseOrderMode) => DeletePurchaseOrderLineAction,
    getDefaultCompanyCurrency: (companyCode: string) => GetDefaultCompanyCurrencyAction,
    getDefaultCompanySupplier: typeof getDefaultCompanySupplier,
    savePurchaseOrderIntermediary: (data: Record<PurchaseOrderFormData>) => SavePurchaseOrderIntermediaryAction,
    clearValidationMessages: () => ClearValidationMessagesAction,
    updatePurchaseOrderInGrid: typeof updatePurchaseOrderInGrid,
    commitFormValueChangesToStore: typeof commitFormValueChangesToStore
}

export type Props = StoreStateProps & DispatchProps;

export const FCNewPurchaseOrder = Auth.NEW_PURCHASE_ORDER;

export function NewPurchaseOrder(props: InjectedFormProps<PurchaseOrderFormData, Props> & Props) {
    const {t} = useTranslation();
    const {companyCode, purchaseOrderLines} = props;

    const [confirmAlertOpts, setConfirmAlert] = useState<ConfirmableAlertOpts>();
    const [isCancelPurchaseOrderModalShown, toggleCancelModal] = useState(false);

    useEffect(() => {
        // !Note. We're using this crutch bcs initialization of requestedDeliveryDate with moment() in connect
        // is the cause of: Maximum update depth exceeded
        if (!props.initialValues.requestedDeliveryDate) {
            props.change('requestedDeliveryDate', moment().add(1, "day").toISOString());
        }
    }, []);

    useEffect(() => {
        if (props.purchaseOrder && props.purchaseOrder.get('status') === PurchaseOrderStatus.ORDERED) {
            props.updatePurchaseOrderInGrid(props.purchaseOrder);
        }
    }, [props.purchaseOrder]);

    const formFields = useMemo(() => {
        return createPurchaseOrderFormFields(props.initialValues, props.mode, props.companies, (value: any) => props.getDefaultCompanyCurrency(value));
    }, [props.mode, props.companies]);

    useEffect(() => {
        if (isNewMode(props) && props.initialValues.companyCode) {
            console.info("Getting default company currency");
            props.getDefaultCompanyCurrency(props.initialValues.companyCode);
        }
    }, [props.initialValues.companyCode]);

    useEffect(() => {
        props.getDefaultCompanySupplier();
    }, [props.getDefaultCompanySupplier]);

    useEffect(() => {
        // always clean up validation messages when unmounting
        return function cleanup() {
            props.clearValidationMessages();
        };
    }, []);

    // calculate the new price totals
    const { data: calculation } = useFetcher(useCallback(() => {
        return calculateTotals(companyCode, purchaseOrderLines || List())
    }, [companyCode, purchaseOrderLines]));

    // set the totalPrice field in the form when the price calculation was completed
    useEffect(() => {
        if (calculation) {
            const formatted = formatPrice(calculation.total);
            props.change('totalPrice', formatted);
        }
    }, [calculation]);

    const doSubmit = (data: PurchaseOrderFormData) => {
        props.savePurchaseOrder(data, props.mode, createLocationItem({
            title: UpdatePurchaseOrderPage.title,
            pathName: UpdatePurchaseOrderPage.path
        }));
    };

    const handleSubmitAndValidate = (data: PurchaseOrderFormData) => {
        const lines = calculation && calculation.calculatedLines;
        if (!lines) throw new Error('No calculated lines!');
        doSubmit(data);
    }

    return (
        <Fragment>
            <Secured code={FCNewPurchaseOrder}>
                <Form className="page-container">
                    <ButtonToolbar className="mt-2 mb-3">
                        <Secured code={Auth.SAVE_PURCHASE_ORDER}>
                            <AsyncActionButton
                                actionId={ActionTypes.SAVE_PURCHASE_ORDER}
                                variant="primary"
                                type="submit"
                                disabled={isViewMode(props)}
                                onAction={props.handleSubmit(handleSubmitAndValidate)}
                            >
                                {t('retail.procurement.button.save')}
                            </AsyncActionButton>
                        </Secured>
                        <Secured code={Auth.SEND_PURCHASE_ORDER}>
                            <AsyncActionButton
                                actionId={ActionTypes.SEND_PURCHASE_ORDER}
                                variant="secondary"
                                disabled={isViewMode(props)}
                                onAction={() => props.sendPurchaseOrder(props.purchaseOrder!)}
                            >
                                {t('retail.procurement.button.send')}
                            </AsyncActionButton>
                        </Secured>
                        <Secured code={Auth.CANCEL_PURCHASE_ORDER}>
                            <ActionButton
                                variant="secondary"
                                disabled={isViewMode(props)}
                                onAction={() => toggleCancelModal(true)}
                            >
                                {t('retail.procurement.button.cancel')}
                            </ActionButton>
                        </Secured>
                    </ButtonToolbar>
                    <FormFieldsLayout fields={formFields} numCols={{xs: 1, sm: 2, md: 3, lg: 3}}/>
                    <Alerts/>
                    <ConfirmAlert alert={confirmAlertOpts}/>
                    <Secured code={Auth.NEW_PURCHASE_ORDER_LINE}>
                        <ButtonToolbar className="mt-2 mb-2">
                            <ActionButton
                                variant="secondary"
                                onAction={props.handleSubmit(() => {
                                    props.commitFormValueChangesToStore();
                                    props.newPurchaseOrderLine(props.companyCode, props.supplier && props.supplier.get('uniqueIdentifier'), false);
                                })}
                                disabled={isViewMode(props) || isNewMode(props)}
                            >
                                {t('retail.procurement.button.newPOLine')}
                            </ActionButton>
                        </ButtonToolbar>
                    </Secured>
                    <CancelPurchaseOrderModal show={isCancelPurchaseOrderModalShown}
                                              onHide={() => toggleCancelModal(false)}/>
                    <PurchaseOrderLineGrid
                        purchaseOrderLines={calculation && calculation.calculatedLines}
                        calculation={calculation}
                        mode={props.mode}
                        interCompanyTransaction={props.interCompanyTransaction}
                        deletePurchaseOrderLineAction={(event: ActionButtonEvent, mode: PurchaseOrderMode) => props.deletePurchaseOrderLine(event, props.mode)}
                        updatePurchaseOrderLineAction={(event: ActionButtonEvent) => {
                            const rowData = (event.row as any).rowData[event.row.id];
                            const uuid = rowData[0] as string | undefined;
                            const resultIndex = rowData.index as number;
                            props.commitFormValueChangesToStore();
                            if (props.mode === PurchaseOrderMode.UPDATE) {
                                props.updatePurchaseOrderLine(props.companyCode, props.supplier && props.supplier.get('uniqueIdentifier'), uuid, resultIndex);
                            } else {
                                props.viewPurchaseOrderLine(props.companyCode, props.supplier && props.supplier.get('uniqueIdentifier'), uuid, resultIndex);
                            }
                        }}
                        purchaseOrderLineCurrency={determinePurchaseOrderLineCurrency(props)}/>
                </Form>
            </Secured>
        </Fragment>
    );
}

const isViewMode = (props: Props): boolean => {
    return PurchaseOrderMode.VIEW === props.mode ;
};

const isNotAllowedNewLine = (props: Props): boolean => {
    return props.purchaseOrder ? props.purchaseOrder.get('status') != PurchaseOrderStatus.INITIAL : true;
}

const isNewMode = (props: Props): boolean => {
    return PurchaseOrderMode.NEW === props.mode;
};

const buildInitialValues = (purchaseOrder: Record<PurchaseOrderFormData>) => {
    return {
        uniqueIdentifier: purchaseOrder.get('uniqueIdentifier'),
        companyCode: purchaseOrder.get('companyCode'),
        no: purchaseOrder.get('no'),
        description: purchaseOrder.get('description'),
        status: purchaseOrder.get('status'),
        supplier: purchaseOrder.get('supplier') && createSupplierIdentifier(purchaseOrder.get('supplier')).toJS(),
        requestedDeliveryDate: purchaseOrder.get('requestedDeliveryDate'),
        currency: purchaseOrder.get('currency'),
        cancellationReason: purchaseOrder.get('cancellationReason')
    }
};

type PurchaseOrderInitialValues = {
    supplier?: SupplierIdentifier,
    companyCode?: string,
    currency?: string,
    requestedDeliveryDate?: string,
    status: string
}

const mapStateToProps = (state: StoreState): StoreStateProps => {

    const mode = getPurchaseOrderMode(state);
    const selector = formValueSelector('new-purchase-order-form');
    const purchaseOrderFromState = getPurchaseOrder(state);

    if (purchaseOrderFromState && (PurchaseOrderMode.VIEW === mode || PurchaseOrderMode.UPDATE === mode)) {

        let initialValues: PurchaseOrderInitialValues = buildInitialValues(purchaseOrderFromState);
        initialValues.status = i18next.t(initialValues.status);

        return {
            mode,
            initialValues,
            companyCode: getDefaultCompany(state)!,
            companies: getAllCompanies(state),
            purchaseOrder: purchaseOrderFromState,
            purchaseOrderLines: getPurchaseOrderLines(state),
            interCompanyTransaction: purchaseOrderFromState.get('interCompanyTransaction'),
            currency: selector(state, 'currency'),
            supplier: purchaseOrderFromState.get('supplier')
        }
    } else {
        const selectedSupplier: SupplierIdentifier = selector(state, 'supplier');
        const defaultSupplier: Record<SupplierIdentifier> | undefined = getDefaultSupplier(state);

        let initialValues: PurchaseOrderInitialValues = {};

        initialValues.supplier = selectedSupplier || defaultSupplier && defaultSupplier.toJS();
        initialValues.companyCode = getDefaultCompany(state)!;
        initialValues.currency = getCompanyCurrency(state);

        if (purchaseOrderFromState) {
            initialValues = buildInitialValues(purchaseOrderFromState);
        }

        return {
            mode,
            initialValues,
            companyCode: getDefaultCompany(state)!,
            companies: getAllCompanies(state),
            purchaseOrderLines: getPurchaseOrderLines(state),
            currency: selector(state, 'currency'),
            supplier: selectedSupplier && createSupplierIdentifier(selectedSupplier) || defaultSupplier
        }
    }
};

const NewPurchaseOrderForm = reduxForm<PurchaseOrderFormData, Props>({
    form: 'new-purchase-order-form',
    enableReinitialize: true,
    keepDirtyOnReinitialize: true
})(NewPurchaseOrder);

export default connect((state: StoreState) => {
    return mapStateToProps(state);
}, {
    pushLocation, savePurchaseOrder, sendPurchaseOrder, updatePurchaseOrderLine, viewPurchaseOrderLine,
    deletePurchaseOrderLine, newPurchaseOrderLine, getDefaultCompanyCurrency,
    clearValidationMessages, getDefaultCompanySupplier,
    commitFormValueChangesToStore, updatePurchaseOrderInGrid
})(NewPurchaseOrderForm);
