import { validateCompanyBinder } from "../../common/binders/ValidateCompanyBinder";
import { getRealmNo } from "@pearlchain/component-lib-common";
import { CompanyBinder } from "@pearlchain/stackbase-common";

import UomBinder from "../../common/binders/UomBinder";
import NumberBinder from "../binders/NumberBinder";
import { requiredValidator } from "../../util/validators";
import { isImmutable, List } from "immutable";
import { formFieldConfig, FormFieldConfig } from "../../../form/types";
import ProductBinder from "../../common/binders/ProductBinder";
import TextBinder from "../../common/binders/TextBinder";
import { ProductInfo, CompositeProductInfo } from "../../productInfo/productInfoTypes";
import { Company } from "@pearlchain/component-lib-ui/lib/components/binders/CompanyBinder";

export function stockCreationFormFieldsCreator(
    productInfo: ProductInfo | undefined,
    parentQuantity: number | undefined,
    companies: Company[] | undefined,
    t: (input: string) => string,
) {
    const formFields: { [key: string]: FormFieldConfig } = {
        company: formFieldConfig({ label: 'retail.inventory.creation.company', translateLabel: true, binder: CompanyBinder, validate: validateCompanyBinder(companies) }),
        productNo: formFieldConfig({
            label: 'retail.inventory.creation.productNo',
            translateLabel: true,
            binder: ProductBinder,
            companyCode: getRealmNo(),
            validate: productNoValidator(productInfo, parentQuantity, t)
        }),
        productDescription: formFieldConfig({
            label: 'retail.inventory.creation.productDescription',
            translateLabel: true,
            binder: TextBinder,
            disabled: true
        }),
        uom: formFieldConfig({ label: 'retail.inventory.creation.uom', translateLabel: true,binder: UomBinder, validate: requiredValidator() }),
        quantity: formFieldConfig({ label: 'retail.inventory.creation.quantity', translateLabel: true,binder: NumberBinder, validate: integerUomValidator(productInfo, t) })
    };
    return formFields;
}

export function integerUomValidator(info: ProductInfo | undefined, t: (input: string, parameters?: any) => string) {
    return (input: any) => {
        if (!input || input.length === 0 || (isImmutable(input) && input.isEmpty()))
            return t('retail.inventory.creation.required');

        if (input) {
            const parsedInput = parseFloat(input);

            if (info && info.get('integerUom') === true && !Number.isInteger(parsedInput)) {
                return t('retail.inventory.correction.validate.quantity.integer.message');
            }
        }
    }
}

export function productNoValidator(info: ProductInfo | undefined, parentQuantity: number | undefined, t: (input: string, parameters?: any) => string) {
    return (input: any) => {
        if (!input || input.length === 0 || (isImmutable(input) && input.isEmpty()))
            return t('retail.inventory.creation.required');

        if (info) {
            if (!info.get('productNo')) {
                return `${t('retail.inventory.creation.cantFindProductForNo')} ${input}`;
            }

            if (info.get('stockEntryNo')) {
                return t('retail.inventory.creation.stockEntryAlreadyExists', {
                    product: input
                });
            }

            const products: List<CompositeProductInfo> = info.get('products');

            if (products && products.size > 0) {
                let doesntHaveEnoughQuantity: boolean = false;

                products.map((product: CompositeProductInfo) => {
                    if (!doesntHaveEnoughQuantity) {
                        doesntHaveEnoughQuantity = quantityIsInvalid(product, parentQuantity)
                    }
                });

                if (doesntHaveEnoughQuantity) {
                    return t('retail.inventory.creation.doesntHaveEnoughQuantity1', {
                        product: input
                    });
                }
            }
        }
    }
}

function quantityIsInvalid(record: CompositeProductInfo, parentQuantity: number | undefined) : boolean {
    let invalid: boolean = false;

    if (record) {
        const quantity: number | 0 = record.get('quantity');
        const stockEntryQuantity: number | 0 = record.get('stockEntryQuantity');

        if (parentQuantity) {
            invalid = stockEntryQuantity < parentQuantity * quantity;
        } else {
            invalid = stockEntryQuantity < quantity;
        }
    }

    return invalid;
}
