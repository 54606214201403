import {all, put, takeEvery} from "redux-saga/effects";
import {callRequestHandler} from '@pearlchain/component-lib-common';

import {RequestId} from "../remote/requests";
import {FetchOptionsAction, Option, ShapeItOptions} from "./types";
import {ActionTypes, storeOptions} from "./actions";
import {List, Record} from "immutable";
import i18next from 'i18next'

export default function () {
    return all([
        takeEvery(ActionTypes.FETCH_OPTIONS, handleFetchOptions)
    ]);
}

function* handleFetchOptions(action: FetchOptionsAction) {
    try {
        const [options] = yield* callRequestHandler(RequestId.FETCH_OPTIONS, { sources: action.sources });
        if (options) {
            let translatedOptions = new Map<string, List<Option>>();
            options.forEach(function (value: Record<ShapeItOptions>, key: string) {
                translatedOptions.set(key, value.get('options').map((o) => Record<Option>({value: o, label: i18next.t(o)})()))
            });
            yield put(storeOptions(translatedOptions));
        }
    } catch (error) {
        console.error(error);
    }
}
