import React, { useMemo } from 'react';
import { InputGroup, InputGroupAddon, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Column, Row } from '@pearlchain/component-lib-react-pg';

import SearchBinderModal from './PartnerSearchBinderModal';

import { ModalContainer } from '@pearlchain/component-lib-ui';

import { useModalState } from '../../../util/hooks/useModalState';
import { FormFieldConfig } from '../../../../form/types';
import { BaseBinderProps } from '../types';
import TextBinder from "../TextBinder";
import { SupplierIdentifier } from "../../../../types/procurementTypes";

type Props = {
    columns: Column[],
    formId: string,
    formValueField: string,
    formFields: { [key: string]: FormFieldConfig },
    initialValues?: {},
    onChange?: (input: SupplierIdentifier) => void,
    modalRoot: string,
    doSearch: (formValues: any) => Promise<Row[]>,
    extraSelector?: { [key: string]: unknown },
    modalTitle: string,
    focus?:boolean,
    inputComponent?: React.ComponentType<any>,
    inputProps?: any
} & BaseBinderProps;

export default function PartnerSearchInModalBinder(props: Props) {
    const [modalState, modalActions] = useModalState(false);
    const value = props.input.value as string|undefined;

    function handleComplete(value: SupplierIdentifier) {
        if (props.onChange) props.onChange(value);
        props.input.onBlur(value);
        props.input.onChange(value);
        modalActions.onHide();
    }

    const inputProps = {
        focus: props.focus,
        input: props.input,
        meta: props.meta,
        datacy: props.datacy,
        disabled: props.disabled,
        onChange: props.onChange
    }

    if (props.inputProps) {
        Object.assign(inputProps, props.inputProps);
    }

    const initialValues = useMemo(() => {
        const initialValues: any = props.initialValues || {};
        initialValues[props.formValueField] = value;
        return initialValues;
    }, [props.initialValues])

    return (
        <React.Fragment>
            <InputGroup>
                { props.inputComponent
                    ? React.createElement(props.inputComponent, inputProps)
                    : <TextBinder {... inputProps } disabled={true} labelFieldName={"no"} />}
                <InputGroupAddon addonType="append">
                    <Button onClick={modalActions.onShow}>
                        <FontAwesomeIcon icon={['fas', 'search']}/>
                    </Button>
                </InputGroupAddon>
            </InputGroup>

            { modalState.mounted ? (
                <ModalContainer rootId={props.modalRoot}>
                    <SearchBinderModal
                        {...modalActions}
                        {...modalState}
                        initialValues={initialValues}
                        onComplete={handleComplete}
                        formId={props.formId}
                        formFields={props.formFields}
                        columns={props.columns}
                        onSearch={props.doSearch}
                        extraSelector={props.extraSelector}
                        title={props.modalTitle}
                    />
                </ModalContainer>
            ) : undefined }
        </React.Fragment>
    );
}

