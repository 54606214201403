import { List, Record } from 'immutable';
import { InventoryOverviewItem, Option } from '../../../types/inventoryTypes';
import { Column, Row } from '@pearlchain/component-lib-react-pg';
import { EXTS } from '../../../powergrid/extensions/contextmenu/powergridHelpers';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import {formatThreeDecimal} from "../../../powergrid/formatters";

export const listIsEmpty = (list: List<any>) : boolean => !list || list.size === 0;
export const buildColumns = (t: (value: string) => string) : Column[] => [
    { _key: 'uniqueIdentifier', width: 0, title: '', idField: true },
    { _key: 'actions', width: 80, title: t('retail.inventory.overview.actions'), actions: [
            {
                cssClass: 'btn btn-secondary fa',
                icon: 'fa-exchange-alt',
                eventName: 'view-stock-movements',
                hidden: true
            }]
    },
    { _key: 'company', width: 120, title: t('retail.inventory.overview.company')},
    { _key: 'no', width: 0, title: t('retail.inventory.overview.no')},
    { _key: 'productNo', width: 200, title: t('retail.inventory.overview.productNo')},
    { _key: 'productDescription', width: 320, title: t('retail.inventory.overview.productDescription')},
    { _key: 'productGroup', width: 120, title: t('retail.inventory.overview.productGroup'), formatter: 'translate'},
    { _key: 'currentQuantity', width: 80, title: t('retail.inventory.overview.currentQuantity')},
    { _key: 'actionType', width: 120, title: t('retail.inventory.overview.actionType'), type: 'dropdown'},
    { _key: 'newQuantity', width: 80, title: t('retail.inventory.overview.newQuantity'), editable: true, type: 'number'},
    { _key: 'uom', width: 80, title: t('retail.inventory.overview.uom'), formatter: 'translate'},
    { _key: 'productSalesPriceIncludingVat', width: 90, title: t('retail.product.grid.salesPriceIncludingVat') },
    { _key: 'reason', width: 120, title: t('retail.inventory.overview.reason'), type: 'dropdown'},
    { _key: 'remark', width: 60, title: t('retail.inventory.overview.Info'), editable: true, type: 'text'},
    { _key: 'printLabel', width: 65, title: 'Print Label', editable: true, type: 'number' }
];

export function entriesToRows(entries: List<Record<InventoryOverviewItem>>) : Row[] {
    const rows: Row[] = [];

    if (entries) {
        entries.map((entry: Record<InventoryOverviewItem>, index: number) => {
            const row: Row = [] as unknown as Row;
            row[0] = entry.get('uniqueIdentifier');
            row[2] = entry.get('companyCode');
            row[3] = entry.get('no');
            row[4] = entry.get('productNo');
            row[5] = entry.get('productDescription');
            row[6] = entry.get('productGroup');
            row[7] = formatThreeDecimal(entry.get('quantity'));
            row[8] = entry.get('actionType') || "";
            row[9] = entry.get('newQuantity');
            row[10] = entry.get('uom') || "";
            const price = entry.get('productSalesPriceIncludingVat');
            row[11] = price ? price + " " + entry.get('productPriceCurrency') : "";
            row[12] = entry.get('reason') || "";
            row[13] = entry.get('remark') ;
            row[14] = entry.get('printLabel');
            row[15] = entry.get('integerUom');
            row.id = '' + entry.get('uniqueIdentifier');
            rows.push(row);
        });
    }

    return  rows;
}

export function buildExtensions( entries: List<Record<InventoryOverviewItem>>, reasons: List<Record<Option>>, action: () => void, t: (value: string) => string, correctionActions: List<Record<Option>>) {
    return {
        [EXTS.RESIZING] : true,
        [EXTS.EDITING]: true,
        [EXTS.FORMATTING] : true,
        [EXTS.DIRECT_INPUT]: true,
        [EXTS.CONTEXT_MENU]: {
            commands: [
                {label: t('stocks.overview.export'),  disabled: !entries || entries.isEmpty(), action, icon: faFileExcel}
            ]
        },
        [EXTS.DROPDOWN] : {
            getOptions(record: any, column: any) {
                const result: Option[] = [];

                result.push({value: "", label:"---"});

                if (column._key === "reason") {
                    reasons.map((reason: Record<Option>) => {
                        result.push({value: reason.get('value'), label: t(reason.get('label'))})
                    })
                }

                if (column._key === "actionType") {
                    correctionActions.map((correctionAction: Record<Option>) => {
                        result.push({value: correctionAction.get('value'), label: t(correctionAction.get('label'))})
                    })
                }

                return result;
            }
        }
    }
}

export const SORT_COLUMN_FIELDS_MAPPING = new Map<string, string>([
    ['uniqueIdentifier', 'uniqueIdentifier'],
    ['company', 'company'],
    ['no', 'no'],
    ['productNo', 'productNo'],
    ['productDescription', 'productDescription'],
    ['productGroup', 'productGroup'],
    ['currentQuantity', 'quantity'],
    ['remark', 'remark']
]);

export const buildStockEntryColumns = (t: (value: string) => string) : Column[] => [
    { _key: 'actions', width: 70, title: t('retail.inventory.overview.actions')},
    { _key: 'companyCode', width: 120, title: t('retail.inventory.overview.company')},
    { _key: 'productNo', width: 160, title: t('retail.inventory.overview.productNo')},
    { _key: 'productDescription', width: 320, title: t('retail.inventory.overview.productDescription')},
    { _key: 'productGroup', width: 200, title: t('retail.inventory.overview.productGroup'), formatter: 'translate'},
    { _key: 'quantity', width: 100, title: t('retail.inventory.overview.currentQuantity')},
    { _key: 'actionType', width: 120, title: t('retail.inventory.overview.actionType'), type: 'dropdown'},
    { _key: 'newQuantity', width: 80, title: t('retail.inventory.overview.newQuantity'), editable: true, type: 'number'},
    { _key: 'uom', width: 80, title: t('retail.inventory.overview.uom'), formatter: 'translate'},
    { _key: 'productSalesPriceIncludingVat', width: 90, title: t('retail.product.grid.salesPriceIncludingVat') },
    { _key: 'reason', width: 120, title: t('retail.inventory.overview.reason'), type: 'dropdown'},
    { _key: 'remark', width: 170, title: t('retail.inventory.overview.Info')},
    { _key: 'printLabel', width: 0, title: 'Print Label', editable: true, type: 'number' },
    { _key: 'numLabels', width: 65, title: 'Print Label', editable: true, type: 'number' }
];
