import React, { useEffect, useState } from 'react';
import { Column, Row, useFlexLayout, useResizeColumns, useSortBy, useTable } from 'react-table';
import { WebSortFields } from '../components/remote/requestTypes';
import { getSortedColumns } from './ReactTableUtils';

type Props = {
    columns: Column<{}>[],
    data: {}[],
    onSortColumnsChanged?: (sortColumns: WebSortFields) => void;
    hiddenColumns?: String []
}

export default function ReactTableGrid(props: Props) {
    const data = props.data
    const columns = props.columns

    const [selected, setSelected] = useState(false)
    const [index, setIndex] = useState(-1)
    const [description, setDescription] = useState("")
    const initialState = { hiddenColumns: props.hiddenColumns || [] };

    function handleSelectedRow(i: number, row: Row<{}>){
        setSelected(!selected)
        setIndex(i)
        setDescription(row.values.productDescription)
    }

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        // @ts-ignore
        state: { sortBy }
        // @ts-ignore
    } = useTable({ columns, data, initialState, manualSortBy: true }, useFlexLayout, useResizeColumns, useSortBy)

    useEffect(() => {
        sortBy.map((o: any) => {
            if(props.onSortColumnsChanged){
                props.onSortColumnsChanged(getSortedColumns(o))
            }
        })

    }, [sortBy]);

    //after saving a correction un-select the row
    useEffect(() => {
        if(data[index]){
            if((data[index] as any).productDescription !== description ){
                setIndex(-1)
            }
        }
    }, [data]);

    return (
      <table className={'pearlchain-table'} {...getTableProps()} >
        <thead className="">
            {headerGroups.map(headerGroup => (
                <tr className='tr sticky' {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                        /*// @ts-ignore */
                        <th className="th p-2 th-over" {...column.getHeaderProps()}>
                            {/*// @ts-ignore */}
                            <div className="d-flex" {...column.getSortByToggleProps()}>
                                {column.render('Header')}
                                {/*// @ts-ignore */}
                                <span className="ml-1">{column.isSorted ? column.isSortedDesc ? ' 🔽' : ' 🔼' : ''}</span>
                            </div>
                            {/*// @ts-ignore */}
                            <div {...column.getResizerProps()} className={`resizer ${ column.isResizing ? 'isResizing' : ''}`}  />
                        </th>
                    ))}
                </tr>
                )
            )}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, idx) => {
                prepareRow(row)
                return (
                    <tr className={`tr ${idx === index ? "row-selected" : ""}`} {...row.getRowProps()} onClick={() => handleSelectedRow(idx, row)}>
                        {row.cells.map(cell => {
                            return (
                                <td className={'cell td'} {...cell.getCellProps()}>
                                {cell.render('Cell')}
                                </td>
                            )
                        })}
                </tr>
                )
          })}
        </tbody>
      </table>
    )
}
