import { Column, Row } from "@pearlchain/component-lib-react-pg";

export function dataToRows<T>(data: Iterable<T>, idFn: (item: T, index: number) => string, columns: Column[]): Row[] {
    const rows: Row[] = [];

    let i = 0;
    for (let item of data) {
        const row = columns.map((column) => (item as any).get(column._key)) as Row;
        row.id = idFn(item, i++);
        rows.push(row);
    }

    return rows;
}
