import { WrappedFieldInputProps, WrappedFieldMetaProps } from "redux-form";

export type FormFieldConfig<P=any> = {
    /**
     * Field label
     */
    label: string,
    /**
     * Component to use for the field input
     */
    binder: React.ComponentType<P>,
    /**
     * Whether to translate the form-field label (default = true)
     */
    translateLabel?: boolean,
    labelWidth?: number,
    fieldWidth?: number,
    initialValues?: {},
    /*
     * Function to perform field level validation
     * Return an error message when the field is invalid, or return undefined when the field is valid    
    */
    validate?: (input: any) => string | undefined,
    onChange?: (input: any) => void,
    /*
     * Disable the field
    */
    disabled?: boolean,
    /*
     * Focus field when render
    */
   focus?: boolean
} & Pick<P, Exclude<keyof P, keyof BaseBinderProps>>;

// Validates a field config is correct, such that all required properties of the binder are provided.
//
// Current limitation of Typescript that we have to wrap in this function to get full type-safety; no "existential types", late-bound generics etc. etc... See:
// https://github.com/Microsoft/TypeScript/issues/14466
// https://github.com/Microsoft/TypeScript/issues/26242
export function formFieldConfig<P>(f: FormFieldConfig<P>): FormFieldConfig<P> {
    return f;
}

interface BaseBinderProps {
    id: string,
    disabled?: boolean,
    datacy?: string,
    input: WrappedFieldInputProps,
    meta: WrappedFieldMetaProps
}
