import React from 'react';
import { List, Record } from 'immutable';
import { WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form';
import { RequestIdFetcher } from '@pearlchain/component-lib-common';

import { RequestId } from '../../remote/requests';
import SelectBinder from '../../common/binders/SelectBinder';

interface Props {
    input: WrappedFieldInputProps,
    meta: WrappedFieldMetaProps,
    dependsOn?: {},
    disabled: boolean,
    multi?: boolean
    translateOptions?: boolean,
}

interface Option {
    label: string,
    value: string
}

export default function PurchaseOrderStatusBinder(props: Props) {
    return (
        <RequestIdFetcher
            requestId={RequestId.LIST_PURCHASE_ORDER_STATUSES}
            render={(data: any) => {
                const opts = dataToOptions(data);
                return (
                    <SelectBinder translateOptions={props.translateOptions} options={opts} { ...props }/>
                );
            }}/>
    );
}

export function dataToOptions(data: List<Record<Option>> | undefined): Array<Option> {
    return data ? data.map((d) => ({ value: d.get('value'), label: d.get('label') })).toArray() : [];
}
