import React from 'react';
import { GridReactive, Column, Row } from '@pearlchain/component-lib-react-pg';
import { List } from "immutable";
import { useTranslation } from "react-i18next";

import { createExtensions } from "../../../powergrid/extensions/contextmenu/powergridHelpers";
import { CompositeProductInfo } from '../../productInfo/productInfoTypes';

export interface Props {
    products?: List<CompositeProductInfo>
}

export default function CompositeProductGrid(props: Props) {

    const {t} = useTranslation();
    
    const columns: Column[] = [
        { _key: 'productNo', width: 200, title: t('retail.inventory.creation.productNo'), idField: true  },
        { _key: 'productDescription', width: 200, title: t('retail.inventory.creation.productDescription')},
        { _key: 'quantity', width: 200, title: t('retail.inventory.creation.quantity')},
        { _key: 'uom', width: 200, title: t('retail.inventory.creation.uom'), formatter: 'translate'},
        { _key: 'stockEntryNo', width: 200, title: t('retail.inventory.creation.stockEntryNo')},
        { _key: 'stockEntryQuantity', width: 200, title: t('retail.inventory.creation.stockEntryQuantity')}
    ];

    return(
        <GridReactive gridId="composite-product-overview-grid"
                      classNameOuter="wrap-grid"
                      extensions={createExtensions({"formatting": true}, undefined, 'composite-grid-form-id', {translate: t})}
                      data={entriesToRows(props.products)}
                      columns={columns}/>
    )
}

function entriesToRows(entries?: List<CompositeProductInfo>) : Row[] {
    const rows: Row[] = [];

    if (entries) {
        entries.map((entry: CompositeProductInfo, index: number) => {
            const row: any[] = [];
            
            (row as Row)[0] = entry.get('productNo');
            (row as Row)[1] = entry.get('productDescription');
            (row as Row)[2] = entry.get('quantity');
            (row as Row)[3] = entry.get('uom');
            (row as Row)[4] = entry.get('stockEntryNo');
            if(entry.get('stockEntryQuantity')){
                (row as Row)[5] = entry.get('stockEntryQuantity').toFixed(3);
            }
            (row as Row).id = '' + index;

            rows.push(row as Row);
        });
    }

    return  rows;
}