import React, { useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import { InjectedFormProps, reduxForm } from 'redux-form'
import { ButtonToolbar, Form } from 'react-bootstrap'
import { List, Record } from 'immutable'

import { FormFieldsLayout } from '@pearlchain/component-lib-ui'

import { Auth } from '../../auth/functionalityCodes'
import Secured from '../../auth/Secured'
import { StoreState } from '../../../types/storeTypes'

import ActionTypes, { findStockMovements } from '../actions'
import { getStockMovementEntriesFromState } from '../selectors'
import { stockMovementsOverviewFormFieldsCreator } from './stockMovementsOverviewFormFieldsCreator'
import { StockMovementOverviewFilterFormData, StockMovementOverviewItem } from '../../../types/inventoryTypes'
import StockMovementsOverviewGrid from './StockMovementsOverviewGrid'
import { useTranslation } from "react-i18next";
import { getLocationParam } from '@pearlchain/component-lib-common'
import AsyncActionButton from '../../actionButton/AsyncActionButton'
import ActionButton from '../../actionButton/ActionButton'

interface Props {
    productNo: string | undefined,
    stockMovementEntries: List<Record<StockMovementOverviewItem>>,
    findStockMovements: (data: StockMovementOverviewFilterFormData) => void
}

const THIS_YEAR_FROM = new Date(new Date().getFullYear(), 0, 1).toISOString();

function StockMovementsOverviewPage(props: InjectedFormProps<StockMovementOverviewFilterFormData, Props> & Props) {

    const {t} = useTranslation();

    const formFields = useMemo(() => {
        return stockMovementsOverviewFormFieldsCreator();
    }, []);

    useEffect(() => {
        if (props.productNo) {
            var today = new Date();
            props.findStockMovements({ productNo: props.productNo, periodFrom: THIS_YEAR_FROM, periodTo: today.toISOString() } as StockMovementOverviewFilterFormData);
        }
    }, []);

    return (
        <Secured code={Auth.INVENTORY_VIEW_PRODUCT_FLOW}>
            <div className="page-container">
                <Form>
                    <ButtonToolbar className="mt-2 mb-3">
                        <AsyncActionButton
                            actionId={ActionTypes.FIND_STOCK_MOVEMENTS}
                            variant="primary"
                            type="submit"
                            onAction={props.handleSubmit(props.findStockMovements)}
                        >
                            {t('retail.inventory.button.search')}
                        </AsyncActionButton>
                        <ActionButton
                            variant="primary"
                            className="mr-2"
                            onAction={() => props.initialize(props.initialValues)}>
                            {t('retail.inventory.button.clear')}
                        </ActionButton>
                    </ButtonToolbar>
                    <FormFieldsLayout fields={formFields} numCols={{ xs: 1, sm: 2, md: 4, lg: 4 }}/>
                </Form>
                <StockMovementsOverviewGrid stockMovementEntries={props.stockMovementEntries}/>
            </div>
        </Secured>
    )
}

const StockMovementsOverviewForm = reduxForm<StockMovementOverviewFilterFormData, Props>({
    form: 'stock-movements-overview-form'
})(StockMovementsOverviewPage);


export default connect((state: StoreState) => {
    const productNo = getLocationParam<string>('productNo');
    const productDescription = getLocationParam<string>('productDescription');
    var today = new Date();
    return {
        initialValues: { productNo, productDescription, periodFrom: THIS_YEAR_FROM, periodTo: today.toISOString() },
        productNo,
        stockMovementEntries: getStockMovementEntriesFromState(state)
    }
}, { findStockMovements })(StockMovementsOverviewForm);
