import { CompanyBinder } from '@pearlchain/stackbase-common';
import { FormFieldConfig, formFieldConfig, TextBinder } from "@pearlchain/component-lib-ui";
import { getRealmNo } from "@pearlchain/component-lib-common";
import { CheckboxBinder } from "@pearlchain/component-lib-ui";
import { validateCompanyBinder } from "../../common/binders/ValidateCompanyBinder";

import ProductGroupBinder from "../binders/ProductGroupBinder";
import ProductBinder from "../../common/binders/ProductBinder";
import { Company } from '@pearlchain/stackbase-common/lib/components/CompanyBinder';


export function inventoryOverviewFormFieldsCreator(companies: Company[] | undefined) {
    const formFields: { [key: string]: FormFieldConfig } = {
        company: formFieldConfig({ label: 'retail.inventory.overview.selector.company', translateLabel: true, binder: CompanyBinder, validate: validateCompanyBinder(companies) }),
        productGroup: formFieldConfig({ label: 'retail.inventory.overview.selector.productGroup', translateLabel: true, binder: ProductGroupBinder }),
        productNo: formFieldConfig({ id: "product-binder-id", label: 'retail.inventory.overview.selector.productNo', translateLabel: true, binder: ProductBinder, companyCode: getRealmNo() }),
        productDescription: formFieldConfig({ label: 'retail.inventory.overview.selector.description', translateLabel: true, binder: TextBinder }),
        excludeEmptyStock: formFieldConfig({ label: 'retail.inventory.overview.selector.emptyStock', translateLabel: true, binder: CheckboxBinder }),
        notForSale: formFieldConfig({ label: 'retail.inventory.overview.selector.notForSale', translateLabel: true, binder: CheckboxBinder }),
    };
    return formFields;
}
