import {all, put, takeEvery} from "redux-saga/effects";

import {ActionTypes, persistUserInfoToLocalStorage, receiveUserInfo} from "./actions";
import {callRequestHandler} from '@pearlchain/component-lib-common';
import {RequestId} from "../remote/requests";

export default function() {
    return all([
        takeEvery(ActionTypes.FIND_USER_INFO, handleFindUserInfo)
    ])
}

export function *handleFindUserInfo() {
    const [userInfo] = yield* callRequestHandler(RequestId.GET_USER_INFO);
    if (!userInfo) return;
    yield put(persistUserInfoToLocalStorage(userInfo));
    yield put(receiveUserInfo(userInfo));
}
