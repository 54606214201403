export const ActionTypes = {
    FIND_USER_INFO: 'userInfo.FIND_USER_INFO',
    RECEIVE_USER_INFO: 'userInfo.RECEIVE_USER_INFO',
    PERSIST_USER_INFO_TO_LOCAL_STORAGE: 'userInfo.PERSIST_USER_INFO_TO_LOCAL_STORAGE',
    LOAD_USER_INFO_FROM_LOCAL_STORAGE: 'userInfo.LOAD_USER_INFO_FROM_LOCAL_STORAGE',
    CLEAR_USER_INFO_FROM_LOCAL_STORAGE: 'userInfo.CLEAR_USER_INFO_FROM_LOCAL_STORAGE'
};

export function findUserInfo() {
    return {
        type: ActionTypes.FIND_USER_INFO
    }
}

export function receiveUserInfo(data : any) {
    return {
        type: ActionTypes.RECEIVE_USER_INFO,
        data
    }
}

export function persistUserInfoToLocalStorage(data : any) {
    return {
        type: ActionTypes.PERSIST_USER_INFO_TO_LOCAL_STORAGE,
        data: data
    }
}