import { Action } from "redux";

import { CommonState, createCommonState } from "./types";
import { ActionType, LoadPropertiesAction } from "./actions";

export default function reducers(state: CommonState = createCommonState(), action: Action): CommonState {
    switch (action.type) {
        case ActionType.LOAD_PROPERTIES:
            return handleLoadProperties(state, action as LoadPropertiesAction);
        default:
            return state;
    }
}

function handleLoadProperties(state: CommonState, action: LoadPropertiesAction) {
    return state.set('properties', action.properties);
}