import React from 'react';
import {ProductMode} from "../../types/productTypes";
import ProductComponent from "./ProductComponent";
import {Modal, ModalBody, ModalTitle} from "react-bootstrap";
import {isDirty} from 'redux-form'
import {connect} from "react-redux";
import {confirm} from "../confirmation/confirm";
import {StoreState} from "../../types/storeTypes";
import {useTranslation} from "react-i18next";

export interface Props {
    show: boolean,
    onHide: () => void,
    mode: ProductMode,
    isDirty: boolean
}

export function ProductComponentModal(props: Props) {
    const {t} = useTranslation();
    const showConfirmationIfDirty = () => props.isDirty ? confirm({translationFunction: t ,
        title: t('retail.productComponent.modal.title'),
        message: t('retail.productComponent.modal.message'),
        okLabel: t('retail.productComponent.modal.okLabel'),
        cancelLabel: t('retail.productComponent.modal.cancelLabel')
    }).then(props.onHide) : props.onHide();

    return (
        <Modal show={props.show} size="lg" onHide={showConfirmationIfDirty} enforceFocus={false}>
            <Modal.Header closeButton>
                <ModalTitle>{t('retail.productComponent.modal.header.title')}</ModalTitle>
            </Modal.Header>
            <ModalBody>
                <ProductComponent onSave={props.onHide} mode={props.mode}/>
            </ModalBody>
        </Modal>
    )
}

const mapStateToProps = (state: StoreState) => {
    return {
        isDirty: isDirty("product-component-form")(state)
    }
};

export default connect(mapStateToProps, null)(ProductComponentModal)
