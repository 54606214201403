import React from 'react';
import { InjectedFormProps, reduxForm } from "redux-form";
import { Row, Form } from 'react-bootstrap';
import { FormFieldConfig, FormFieldsLayout } from '@pearlchain/component-lib-ui';
import { useTranslation } from "react-i18next";
import ActionButton from '../../actionButton/ActionButton';
import AsyncActionButtonControlled from '../../actionButton/AsyncActionButtonControlled';

type OwnProps = {
    fields: { [key: string]: FormFieldConfig };
    onFetchData: (values: { [key: string]: FormFieldConfig }) => Promise<unknown>;
};

function SearchBinderForm(props: InjectedFormProps<any, OwnProps> & OwnProps) {
    const { t } = useTranslation();

    function handleReset(){
        props.reset()
        props.change("productNo", "")
    }

    return (
        <Form>
            <FormFieldsLayout fields={props.fields}/>
            <Row>
                <AsyncActionButtonControlled
                    busy={props.submitting}
                    onAction={props.handleSubmit(props.onFetchData)}
                >
                    {t('binder.search')}
                </AsyncActionButtonControlled>&nbsp;
                <ActionButton
                    onAction={handleReset}
                    disabled={props.submitting}
                >
                    {t('binder.clear')}
                </ActionButton>
            </Row>
        </Form>
    );
}

export default reduxForm<any, OwnProps>({})(SearchBinderForm);
