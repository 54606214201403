export enum ActionType {
    ACTION_START = 'action-btn.ACTION_START',
    ACTION_SUCCESS = 'action-btn.ACTION_SUCCESS',
    ACTION_ERROR = 'action-btn.ACTION_ERROR',
    INCREMENT_RUNNING_ACTIONS = 'action-btn.INCREMENT_RUNNING_COUNT',
    DECREMENT_RUNNING_ACTIONS = 'action-btn.DECREMENT_RUNNING_COUNT',
}

export function actionStart(actionId: string): ActionStartAction {
    return { type: ActionType.ACTION_START, actionId };
}

export function actionSuccess(actionId: string): ActionSuccessAction {
    return { type: ActionType.ACTION_SUCCESS, actionId };
}

export function actionError(actionId: string, errorMsg?: string): ActionErrorAction {
    return { type: ActionType.ACTION_ERROR, actionId, errorMsg };
}

export function incrementRunningActions() {
    return { type: ActionType.INCREMENT_RUNNING_ACTIONS };
}

export function decrementRunningActions() {
    return { type: ActionType.DECREMENT_RUNNING_ACTIONS };
}

export type ActionStartAction = {
    type: ActionType.ACTION_START,
    actionId: string;
}

export type ActionSuccessAction = {
    type: ActionType.ACTION_SUCCESS,
    actionId: string;
}

export type ActionErrorAction = {
    type: ActionType.ACTION_ERROR,
    actionId: string;
    errorMsg?: string;
}
