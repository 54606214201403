import React, { useCallback, useEffect, useRef, useReducer } from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

import { BaseBinderProps } from './types';
import { InputGroup, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ProductBinderInput from './ProductBinderInput';
import ProductBinderModal from './ProductBinderModal';
import { useModalState } from '../../util/hooks/useModalState';

library.add(faSearch);

type Props = {
    companyCode: string;
    excludeGifts?: boolean;
    availableForPurchase?: boolean,
    availableForSale?: boolean,
    focus?: boolean;
    extraSelector?: { [key: string]: unknown };
} & BaseBinderProps;

export default function ProductBinder(props: Props) {
    const value = props.input.value;
    const [modalState, modalActions] = useModalState(false);
    const [forceRerenderCount, forceRerender] = useReducer((acc) => acc + 1, 0);

    // when a product was selected from the modal
    const onModalComplete = useCallback((productNo: string) => {
        props.input.onChange(productNo);
        modalActions.onHide();
    }, [props.input.onChange, modalActions]);

    // called when the value in the lookahead changed
    const valueRef = useRef(value);
    const onChange = useCallback((value: string) => {
        valueRef.current = value;
        props.input.onChange(value);
    }, [props.input.onChange]);

    useEffect(() => {
        if (value !== valueRef.current) {
            // the value was updated externally. Force the ProductBinderInput to remount, and therefore reset its internal state to
            // the productNo passed in.
            forceRerender(null);
        }
    }, [value]);

    return (
        <React.Fragment>
            <InputGroup>
                <ProductBinderInput
                    id={props.id}
                    key={forceRerenderCount}
                    productNo={value}
                    companyCode={props.companyCode}
                    excludeGifts={props.excludeGifts}
                    onChange={onChange}
                    availableForPurchase={props.availableForPurchase}
                    availableForSale={props.availableForSale}
                />
                <InputGroup.Append>
                    <Button onClick={modalActions.onShow}>
                        <FontAwesomeIcon icon="search"/>
                    </Button>
                </InputGroup.Append>
            </InputGroup>
            { modalState.mounted ? (
                <ProductBinderModal
                    {...modalState}
                    {...modalActions}
                    productNo={props.input.value}
                    companyCode={props.companyCode}
                    excludeGifts={props.excludeGifts}
                    extraSelector={props.extraSelector}
                    onComplete={onModalComplete}
                />
            ) : undefined }
        </React.Fragment>
    );
}
