import { useCallback } from "react";
import { ProductInfo } from "./productInfoTypes";
import { useFetcher, RequestBuilder } from "@pearlchain/component-lib-common";
import { getProductInfo, findProductByNoAndCompanyCode } from "../remote/api";
import { FetcherState } from "@pearlchain/component-lib-common/lib/components/fetcher/fetcherTypes";
import { ProductFormData } from "../../types/productTypes";

const DELAY = 200;

/**
 * React hook that fetches info about the current product
 */

export function useProductInfo(productNo: string | undefined, companyCode: string, delay?: number): FetcherState<ProductInfo | undefined> {
    const handler = useCallback(() => {
        if (productNo) {
            const params = { no: productNo, company: companyCode };
            return getProductInfo(new RequestBuilder(), params);
        } else {
            return Promise.resolve(undefined);
        }
    }, [productNo, companyCode]);

    if (delay == null) delay = DELAY;
    return useFetcher(handler, { delay });
}

export function useBasicProductInfo(productNo: string | undefined, companyCode: string, delay?: number): FetcherState<ProductFormData | undefined> {
    const handler = useCallback(() => {
        if (productNo) {
            return findProductByNoAndCompanyCode(productNo, companyCode, true);
        } else {
            return Promise.resolve(undefined);
        }
    }, [productNo, companyCode]);

    if (delay == null) delay = DELAY;
    return useFetcher(handler, { delay });
}
