import { useReducer, useMemo, Dispatch } from "react";

/**
 * Helper react hook to manage the state for a modal, exposing actions to control the modal
 */

type State = {
    show: boolean;
    mounted: boolean;
}

enum ActionType {
    SHOW,
    HIDE,
    EXIT
}

type Action = { type: ActionType };
function reducer(state: State, action: Action): State {
    switch (action.type) {
        case ActionType.SHOW:
            return { show: true, mounted: true };
        case ActionType.HIDE:
            return { ...state, show: false };
        case ActionType.EXIT:
            return { ...state, mounted: false };
    }
}

export type ModalActions = {
    onShow(): void;
    onHide(): void;
    onExit(): void;
}

function createActions(dispatch: Dispatch<Action>): ModalActions {
    return {
        onShow: () => dispatch({ type: ActionType.SHOW }),
        onHide: () => dispatch({ type: ActionType.HIDE }),
        onExit: () => dispatch({ type: ActionType.EXIT })
    };
}

export function useModalState(defaultShow?: boolean): [State, ModalActions] {
    const show = !!defaultShow;
    const [state, dispatch] = useReducer(reducer, { show, mounted: show });
    const actions = useMemo(() => createActions(dispatch), []);
    return [state, actions];
}
