import React from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';

type Props = {
    onMoveFirst: () => void;
    onMovePrev: () => void;
    onMoveNext: () => void;
    canMovePrev: boolean;
    canMoveNext: boolean;
}

export default React.memo(function StepThroughPagerButtons(props: Props) {
    return (
        <div className="pager-buttongroup">
            <ButtonGroup>
                <Button disabled={!props.canMovePrev} onClick={props.onMoveFirst}>{'<<'}</Button>
                <Button disabled={!props.canMovePrev} onClick={props.onMovePrev}>{'<'}</Button>
                <Button disabled={!props.canMoveNext} onClick={props.onMoveNext}>{'>'}</Button>
            </ButtonGroup>
        </div>
    )
});
