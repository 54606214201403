import {Record} from "immutable";
import {Action} from "redux";

import {createOptionsState, OptionsState, StoreOptionsAction} from "./types";
import {ActionTypes} from "./actions";

export default function (state: Record<OptionsState> = createOptionsState(), action: Action) {
    switch (action.type) {
        case ActionTypes.STORE_OPTIONS:
            return storeOptions(state, action as StoreOptionsAction);
    }
    return state;
}

function storeOptions(state: Record<OptionsState>, action: StoreOptionsAction) {

    return state.set('options', action.data);
}