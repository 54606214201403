import { Map, Record, RecordOf } from 'immutable';

// Store typings

type ActionButtonStateProps = {
    running: boolean;
    error: boolean;
    errorMsg: string | undefined;
}

export type ActionButtonState = RecordOf<ActionButtonStateProps>;

type ActionButtonsStateProps = {
    actions: Map<string, ActionButtonState>;
    runningCount: number;
}

export type ActionButtonsStoreState = RecordOf<ActionButtonsStateProps>;

// Immutable Record creators

export const createActionButtonState = Record<ActionButtonStateProps>({
    running: false,
    error: false,
    errorMsg: undefined
});

export const createActionButtonsStoreState = Record<ActionButtonsStateProps>({
    actions: Map<string, ActionButtonState>(),
    runningCount: 0
});
