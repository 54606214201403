import React from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { Column, dataToRows, Row } from '@pearlchain/component-lib-react-pg';
import { CompanyBinder } from '@pearlchain/stackbase-common';

import { BaseBinderProps } from '../types';
import PartnerSearchBinder from './PartnerSearchBinder';
import { RequestBuilder } from '@pearlchain/component-lib-common';
import { TextBinder, FormFieldConfig } from '@pearlchain/component-lib-ui';
import { validateCompanyBinder } from '../ValidateCompanyBinder';
import SelectBinder from '../SelectBinder';
import { formFieldConfig } from "../../../../form/types";
import { useSelector } from 'react-redux';
import { getAllCompanies } from '../../../procurement/selectors';
import { Company } from '@pearlchain/stackbase-common/lib/components/CompanyBinder';

library.add(faSearch);

type Props = {
    modalRoot: string,
    doSearch?: (values: any) => Promise<Row[]>,
    supplier?: boolean,
    initialValues?: {},
    modalTitle: string
} & BaseBinderProps;

const URL_FIND_PARTNERS = 'sec/api/masterdata/find/partner';

const columns: Column[] = [
    { _key: 'no', width: 150, title: 'common.binder.customer.grid.no', translateValue: true },
    { _key: 'uniqueIdentifier', width: 0, title: ''},
    { _key: 'name', width: 150, title: 'common.binder.customer.grid.name', translateValue: true },
    { _key: 'vatNo', width: 150, title: 'common.binder.customer.grid.vatNo', translateValue: true },
    { _key: 'address', width: 150, title: 'common.binder.customer.grid.address', translateValue: true },
    { _key: 'line2', width: 150, title: 'common.binder.customer.grid.line2', translateValue: true },
    { _key: 'postalCode', width: 150, title: 'common.binder.customer.grid.postalCode', translateValue: true },
    { _key: 'city', width: 150, title: 'common.binder.customer.grid.city', translateValue: true },
    { _key: 'country', width: 150, title: 'common.binder.customer.grid.country', translateValue: true },
    { _key: 'telephone', width: 150, title: 'common.binder.customer.grid.telephone', translateValue: true },
    { _key: 'language', width: 150, title: 'common.binder.customer.grid.language', translateValue: true }
];

const createFormFields = (companies: Company[] | undefined): { [key: string]: FormFieldConfig } => ({
    companyCodes: formFieldConfig({ label: 'common.selector.company', binder: CompanyBinder, multi: true, validate: validateCompanyBinder(companies) }),
    no: formFieldConfig({ label: 'common.binder.customer.selector.no', binder: TextBinder }),
    name: formFieldConfig({ label: 'common.binder.customer.selector.name', binder: TextBinder }),
    vatNo: formFieldConfig({ label: 'common.binder.customer.selector.vatNo', binder: TextBinder }),
    address: formFieldConfig({ label: 'common.binder.customer.selector.address', binder: TextBinder }),
    postalCode: formFieldConfig({ label: 'common.binder.customer.selector.postalCode', binder: TextBinder }),
    city: formFieldConfig({ label: 'common.binder.customer.selector.city', binder: TextBinder }),
    country: formFieldConfig({ label: 'common.binder.customer.selector.country', binder: TextBinder }),
    department: formFieldConfig({ label: 'common.binder.customer.selector.department', binder: TextBinder }),
    limit: formFieldConfig({ label: 'common.binder.selector.limit', binder: SelectBinder, options: [
            { label: '10', value: 10 },
            { label: '20', value: 20 },
            { label: '50', value: 50 },
            { label: '100', value: 100 }
        ]
    })
});

export default function PartnerBinder(props: Props) {
    function doSearch(values: any): Promise<Row[]> {

        if (props.supplier) {
            values.supplier = true;
        }

        return new RequestBuilder()
            .post(URL_FIND_PARTNERS)
            .setBodyJson(values)
            .toJS<any[]>()
            .then((data) => {
                return dataToRows(data, (d: any) => d.uniqueIdentifier, columns);
            });
    }

    const companies = useSelector(getAllCompanies);

    return (
        <PartnerSearchBinder
            id={"partner-binder-id"}
            formId="partner-binder"
            columns={columns}
            formFields={createFormFields(companies)}
            formValueField="uniqueIdentifier"
            modalRoot={props.modalRoot}
            initialValues={props.initialValues}
            doSearch={props.doSearch || doSearch}
            disabled={props.disabled}
            input={props.input}
            meta={props.meta}
            modalTitle={props.modalTitle}
        />
    )
}
