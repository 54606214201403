import { all, takeEvery, takeLatest, put, select, call } from 'redux-saga/effects'
import { pushLocation, replaceLocation, invokeRequestHandler, callRequestHandler, popLocation, receiveCachedData} from '@pearlchain/component-lib-common'
import {success, error, warning} from 'react-notification-system-redux'
import { getFormValues } from 'redux-form'
import { Record, List } from 'immutable'
import { isMoment } from 'moment'

import ActionTypes, {
    storePurchaseOrder, storePurchaseOrderLines, clearData,
    storeCompanyCurrency, storeValidationMessage, clearValidationMessages,
    setPurchaseOrders,
    storeDefaultCompanySupplier
} from './actions'
import {
    SavePurchaseOrderAction,
    SendPurchaseOrderAction,
    ViewPurchaseOrderAction,
    PurchaseOrderMode,
    UpdatePurchaseOrderAction,
    CancelPurchaseOrderAction,
    PurchaseOrderLineFormData,
    ReceivePurchaseOrderAction,
    StoreReceivePurchaseOrderLineChangeAction,
    PurchaseOrderLineProperty,
    RegisterPurchaseOrderLineData,
    createRegisterPurchaseOrderLineData,
    FindPurchaseOrdersAction,
    PurchaseOrderStatus,
    NewPurchaseOrderAction,
    GetDefaultCompanyCurrencyAction,
    NO_PURCHASEORDER_LINES,
    CANNOT_BE_SENT,
    CANNOT_BE_CANCELLED,
    NO_QUANTITIES_CHANGED,
    SavePurchaseOrderIntermediaryAction,
    NotificationAction,
    PurchaseOrderFormData, createPurchaseOrderForm, SupplierIdentifier, createSupplierIdentifier
} from '../../types/procurementTypes'
import { getPurchaseOrderLines, getPurchaseOrder, getCompanyCurrency } from './selectors'
import { RequestId } from '../remote/requests'
import { purchaseOrderLineColumns } from './receive/ReceivePurchaseOrderLineGrid'
import { getSelectedRealmNo } from '../userInfo/selectors'
import { createWebParams } from '../remote/requestTypes'
import { DEFAULT_PAGE_SIZE } from '../util/helpers'
import purchaseOrderLineSagas from './purchaseOrderLine/purchaseOrderLineSagas'
import { runActionButton, lockActionButtons } from '../util/sagaUtils'
import * as i18next from "i18next";

export default function() {
    return all([
        takeEvery(ActionTypes.FIND_PURCHASE_ORDERS, handleFindPurchaseOrders),
        takeEvery(ActionTypes.NEW_PURCHASE_ORDER, handleNewPurchaseOrder),
        takeEvery(ActionTypes.SAVE_PURCHASE_ORDER, handleSavePurchaseOrder),
        takeEvery(ActionTypes.SAVE_PURCHASE_ORDER_INTERMEDIARY, handleSavePurchaseOrderIntermediary),
        takeLatest(ActionTypes.SEND_PURCHASE_ORDER, handleSendPurchaseOrder),
        takeEvery(ActionTypes.VIEW_PURCHASE_ORDER, handleViewPurchaseOrder),
        takeEvery(ActionTypes.UPDATE_PURCHASE_ORDER, handleUpdatePurchaseOrder),
        takeEvery(ActionTypes.CANCEL_PURCHASE_ORDER, handleCancelPurchaseOrder),
        takeEvery(ActionTypes.RECEIVE_PURCHASE_ORDER, handleReceivePurchaseOrder),
        takeEvery(ActionTypes.STORE_RECEIVE_PURCHASE_ORDER_LINE_CHANGE, handleStoreReceivePurchaseOrderLineChange),
        takeEvery(ActionTypes.REGISTER_PURCHASE_ORDER_LINES, handleRegisterPurchaseOrderLines),
        takeEvery(ActionTypes.REGISTER_ALL_PURCHASE_ORDER_LINES, handleMarksAllPurchaseOrderLinesToBeRegistered),
        takeEvery(ActionTypes.GET_DEFAULT_COMPANY_CURRENCY, handleGetDefaultCompanyCurrency),
        takeEvery(ActionTypes.GET_DEFAULT_COMPANY_SUPPLIER, handleGetDefaultCompanySupplier),
        takeEvery(ActionTypes.FIND_ALL_COMPANIES, handleFindAllCompanies),
        takeEvery(ActionTypes.SHOW_ERROR, handleShowError),
        takeEvery(ActionTypes.COMMIT_FORM_VALUE_CHANGES_TO_STORE, handleCommitFormValueChangesToStore),
        purchaseOrderLineSagas()
    ]);
}

function *handleFindPurchaseOrders(action: FindPurchaseOrdersAction) {
    yield* runActionButton(ActionTypes.FIND_PURCHASE_ORDERS, function *() {
        const formData = action.data;
        formData.beginDate = isMoment(formData.beginDate) ? formData.beginDate.utc().format() : "";
        formData.endDate = isMoment(formData.endDate) ? formData.endDate.utc().format() : "";
        const webParams = createWebParams(0, DEFAULT_PAGE_SIZE, action.sortFields);

        const [result, error] = yield* callRequestHandler(RequestId.FIND_PURCHASE_ORDERS, formData, webParams);

        if (result) {
            yield put(setPurchaseOrders(result));
        } else if (error) {
            throw error;
        }
    })
}

function* handleNewPurchaseOrder(action: NewPurchaseOrderAction) {
    yield put(clearData())
    yield put(pushLocation(action.location.pathName, action.location.title ,{ mode: PurchaseOrderMode.NEW }));
}

function *handleSavePurchaseOrder(action: SavePurchaseOrderAction) {
    yield* runActionButton(ActionTypes.SAVE_PURCHASE_ORDER, function *() {
        const supplier: SupplierIdentifier = action.data.supplier;

        const data = createPurchaseOrderForm(action.data)
                        .set('supplierUniqueIdentifier', supplier && supplier.uniqueIdentifier)
                        .set('supplierNo', supplier && supplier.no)
                        .set('supplierName', supplier && supplier.name);

        if (PurchaseOrderMode.NEW === action.mode) {
            yield put(storePurchaseOrder(data));
            const poLines = yield select(getPurchaseOrderLines);
            const purchaseOrder = yield select(getPurchaseOrder);
            const purchaseOrderWithLines = purchaseOrder.set('lines', poLines);

            const [result, error] = yield* callRequestHandler(
                RequestId.SAVE_PURCHASE_ORDER,
                removeUniqueIdentifierForNewLine(purchaseOrderWithLines)
            );

            if (error) {
                throw error;
            }

            yield call(loadAndStorePurchaseOrder, result.get('uniqueIdentifier'));
            // switch to update mode after creation of a new po
            yield put(replaceLocation(action.location.pathName, action.location.title, { mode: PurchaseOrderMode.UPDATE }));
            yield put(success({title: "Purchase order has been created successfully"}));
        } else if (PurchaseOrderMode.UPDATE === action.mode) {
            yield put(storePurchaseOrder(data));
            const purchaseOrder = yield select(getPurchaseOrder);
            const poLines = yield select(getPurchaseOrderLines);
            const purchaseOrderWithLines = purchaseOrder.set('lines', poLines);

            const [result, error] = yield* callRequestHandler(
                RequestId.SAVE_PURCHASE_ORDER,
                removeUniqueIdentifierForNewLine(purchaseOrderWithLines)
            );

            if (error) {
                throw error;
            }
            yield call(loadAndStorePurchaseOrder, result.get('uniqueIdentifier'));
            yield put(success({title: "Purchase order has been updated successfully"}));
        }
    });
}

/**
 * Note!!!! Because of powergrid we have to initialize uniqueIdentifier for all kinds of lines (new, update ...),
 * because uniqueIdentifier it is idField in powergrid. But, before the actual save, we have to set uniqueIdentifier to
 * undefined for all new lines, in order to avoid issues on the server side and allow to the server to generate those uniqueIdentifiers.
 */
function removeUniqueIdentifierForNewLine(purchaseOrderWithLines: Record<PurchaseOrderFormData>) {
    return purchaseOrderWithLines.update('lines', (lines: List<Record<PurchaseOrderLineFormData>>) =>
         lines.map((line: Record<PurchaseOrderLineFormData>) =>
             line.get('id') ? line : line.set('uniqueIdentifier', undefined)
         )
    );
}

function *handleShowError(action: NotificationAction) {
    yield put(error({title: action.message}));
}

function* handleSavePurchaseOrderIntermediary(action: SavePurchaseOrderIntermediaryAction) {
    yield put(storePurchaseOrder(action.data));
}

/**
 * Requests the server to send a purchase order that is in status Ordered
 */
function *handleSendPurchaseOrder(action: SendPurchaseOrderAction) {
    yield* runActionButton(ActionTypes.SEND_PURCHASE_ORDER, function *() {
        try {
            const purchaseOrder = action.data;
            if (purchaseOrder && purchaseOrder.get('status') === PurchaseOrderStatus.INITIAL) {
                const [result, error] = yield* callRequestHandler(RequestId.SEND_PURCHASE_ORDER, action.data);
                if (error) throw error;
    
                yield call(loadAndStorePurchaseOrder, action.data.get('uniqueIdentifier'));
                yield put(success({ title: 'Purchase order has been sent successfully' }));
                yield put(popLocation(1));
            } else {
                yield put(storeValidationMessage(CANNOT_BE_SENT))
            }
        } catch(err) {
            yield put(error({ title: 'Technical error, please contact your administrator' }));
            throw err;
        }
    });
};

/**
 * Fetches a purchase order with its lines from the server, calculates the total price for each purchase order line
 * and puts everyting in the store
 * @param uniqueIdentifier the uuid of the purchase order
 */
function* loadAndStorePurchaseOrder(uniqueIdentifier: string | undefined) {
    const [purchaseOrder, error] = yield* callRequestHandler(RequestId.GET_PURCHASE_ORDER, { uniqueIdentifier });
    if (error) {
        throw error;
    }
    yield put(storePurchaseOrder(purchaseOrder));
    yield put(storePurchaseOrderLines(purchaseOrder.get('lines')));
}

/**
 * Fetches a purchase order with its lines, puts it in the store and navigates to the update view
 */
function *handleUpdatePurchaseOrder(action: UpdatePurchaseOrderAction) {
    yield* runActionButton(ActionTypes.UPDATE_PURCHASE_ORDER, function *() {
        const [purchaseOrder, error] = yield* callRequestHandler(RequestId.GET_PURCHASE_ORDER, { uniqueIdentifier: action.purchaseOrderId });
        if (error) throw error;
        yield put(storePurchaseOrder(purchaseOrder));
        yield put(storePurchaseOrderLines(purchaseOrder.get('lines')));
        yield put(clearValidationMessages());
        yield put(pushLocation(action.location.pathName, action.location.title, { mode: PurchaseOrderMode.UPDATE }));
    });
};

/**
 * Fetches a purchase order with its lines, puts it in the store and navigates to the read only view
 */
function *handleViewPurchaseOrder(action: ViewPurchaseOrderAction) {
    yield* runActionButton(ActionTypes.VIEW_PURCHASE_ORDER, function *() {
        const [purchaseOrder, error] = yield* callRequestHandler(RequestId.GET_PURCHASE_ORDER, { uniqueIdentifier: action.purchaseOrderUuid });
        if (error) throw error;
        yield put(storePurchaseOrder(purchaseOrder));
        yield put(storePurchaseOrderLines(purchaseOrder.get('lines')));
        yield put(clearValidationMessages());
        yield put(pushLocation(action.location.pathName, action.location.title, { mode: PurchaseOrderMode.VIEW }));
    });
};

/**
 * Requests the server to cancel a purchase order that isn't delivered yet
 */
function *handleCancelPurchaseOrder(action: CancelPurchaseOrderAction) {
    yield* runActionButton(ActionTypes.CANCEL_PURCHASE_ORDER, function *() {
        const purchaseOrder = yield select(getPurchaseOrder);
        if (purchaseOrder && ((purchaseOrder.get('status') === PurchaseOrderStatus.INITIAL) 
            || (purchaseOrder.get('status') === PurchaseOrderStatus.ORDERED))) {
            const cancelPurchaseOrder = action.data.merge({'uniqueIdentifier': purchaseOrder.get('uniqueIdentifier'), 
            'companyCode': purchaseOrder.get('companyCode')});
            yield put(invokeRequestHandler(RequestId.CANCEL_PURCHASE_ORDER, cancelPurchaseOrder));
            yield put(clearValidationMessages());
            yield put(pushLocation(action.location.pathName, action.location.title, {}));
            yield put(success({ title: 'Purchase order has been cancelled successfully' }));
        } else {
            yield put(storeValidationMessage(CANNOT_BE_CANCELLED));
        }
    });
}

/**
 * Fetches the purchase order, loads it in the store, and navigates to the goods receipt page
 */
function *handleReceivePurchaseOrder(action: ReceivePurchaseOrderAction) {
    yield* runActionButton(ActionTypes.RECEIVE_PURCHASE_ORDER, function *() {
        const [purchaseOrder, error] = yield* callRequestHandler(RequestId.GET_PURCHASE_ORDER, { uniqueIdentifier: action.purchaseOrderUuid });
        if (error) throw error;
        let lines = purchaseOrder.get('lines');
        lines = lines.map((value: Record<PurchaseOrderLineFormData>) => {
            return value.withMutations((pol: Record<PurchaseOrderLineFormData>) => {
                if (pol.get('quantityDelivered') == null) {
                    if (pol.get('receiptMasterLines').size > 0) {
                        // according to the domain model, we can have multiple master lines per po line, for now we take the first one
                        const receiptMasterLine = pol.get('receiptMasterLines').get(0)!;
                        pol.set('quantityDelivered', receiptMasterLine.get('quantity'))
                    } else {
                        pol.set('quantityDelivered', 0)
                    }
                }
            })
        }); 
        yield put(storePurchaseOrder(purchaseOrder));
        yield put(storePurchaseOrderLines(lines));
        yield put(pushLocation(action.location.pathName, action.location.title, { }));
    });
}

/**
 * Update the purchase order lines when the user changes the quantity or the 'to be registered' checkbox
 */
function* handleStoreReceivePurchaseOrderLineChange(action: StoreReceivePurchaseOrderLineChangeAction) {
    try {
        // first fetch all the lines from the store
        const purchaseOrderLines = yield select(getPurchaseOrderLines);
        // then search for the record and its index with the matching uniqueIdentifier
        const purchaseOrderLine = purchaseOrderLines.findEntry((value: Record<PurchaseOrderLineFormData>) => {
            return (value.get('uniqueIdentifier') === action.purchaseOrderLineUuid);
        })
        // get the column that is changed
        const column = purchaseOrderLineColumns[action.columnIndex]._key;

        if (column === PurchaseOrderLineProperty.QUANTITY_DELIVERED) {
            var updateValue = Number(action.value)
            if (isNaN(updateValue) && updateValue < 0) {
                updateValue = 0  
            }
            let isUpdatedValueValidForUom = true;
            // update the value in the pol and set to be registered to true
            const updatedPol = purchaseOrderLine[1].withMutations((pol: Record<PurchaseOrderLineFormData>) => {
                if (pol.get('stockUomIsInteger') && !Number.isInteger(updateValue)) {
                    updateValue = Math.floor(updateValue);
                    isUpdatedValueValidForUom = false;
                }
                pol.set(column as any, updateValue);
                pol.set(PurchaseOrderLineProperty.TO_BE_REGISTERED, true)
            });
            yield call(storeReceivePurchaseOrderLine, updatedPol, purchaseOrderLine[0], purchaseOrderLines);

            if (!isUpdatedValueValidForUom) {
                yield put(warning({
                    autoDismiss: 10,
                    title: i18next.t('retail.procurement.receive.warning'),
                    message: i18next.t('retail.procurement.receive.updatedValueNotValidForUom.message')
                }));
            }
        } else if (column === PurchaseOrderLineProperty.TO_BE_REGISTERED) {
            // update the value in the pol
            const updatedPol = purchaseOrderLine[1].withMutations((pol: Record<PurchaseOrderLineFormData>) => {
                pol.set(column as any, action.value);
                if (pol.get('receiptMasterLines').size !== 0 && pol.get("quantityDelivered") == null) {
                    pol.set('quantityDelivered', pol.get('receiptMasterLines').get(0).get('quantity'));
                }
            });
            yield call(storeReceivePurchaseOrderLine, updatedPol, purchaseOrderLine[0], purchaseOrderLines);
        }
    } catch (error) {
        console.log(error);
    }
}

/**
 * Update the purchase order lines in the store
 */
function* storeReceivePurchaseOrderLine(purchaseOrderLine: Record<PurchaseOrderLineFormData>, index: number, purchaseOrderLines: List<Record<PurchaseOrderLineFormData>>) {
    // then replace the matching record with the updated values
    const updatedLines = purchaseOrderLines.set(index, purchaseOrderLine);
    // store the updated lines in the store
    yield put(storePurchaseOrderLines(updatedLines));    
}


/**
 * Creates the RegisterPurchaseOrderLineData object and post it to the server
 */
function *handleRegisterPurchaseOrderLines() {
    yield* runActionButton(ActionTypes.REGISTER_PURCHASE_ORDER_LINES, function *() {
        // fetch the po from the store
        const purchaseOrder = yield select(getPurchaseOrder);
        // fetch all the lines from the store
        const purchaseOrderLines = yield select(getPurchaseOrderLines);

        const updatedLines = purchaseOrderLines.map((value: Record<PurchaseOrderLineFormData>, key: any) => {
            return value.withMutations((pol: Record<PurchaseOrderLineFormData>) => {
                pol.set('toBeRegistered', true);
            })
        })

        const registerPurchaseOrderLine = createRegisterPurchaseOrderLineData().withMutations((value: Record<RegisterPurchaseOrderLineData>) => {
            value.set('supplierUniqueIdentifier', purchaseOrder.getIn(['supplier', 'uniqueIdentifier']));
            value.set('companyCode', purchaseOrder.get('companyCode'))
            value.set('lines', updatedLines)
        });

        if (registerPurchaseOrderLine.get('lines').size > 0) {
            // post the register data to the server
            const [result, error] = yield* callRequestHandler(RequestId.REGISTER_PURCHASE_ORDER_LINES, registerPurchaseOrderLine);
            if (error) throw error;
            yield put(clearValidationMessages());
            yield put(success({title: 'Goods have been received successfully'}))
            yield put(popLocation(1));
            yield call(loadAndStorePurchaseOrder, purchaseOrder.get('uniqueIdentifier'));
        }
    });
}

/**
 * Selects the checkboxes of all the purchase order lines shown in the goods receival page
 * and sets the delivered quantity to the purchased quantity
 */
function* handleMarksAllPurchaseOrderLinesToBeRegistered() {
    try {
        // fetch all the lines from the store 
        const purchaseOrderLines = yield select(getPurchaseOrderLines);
        // mark all the lines as to be registed and set the delivered quantity equal to the ordered quantity
        const updatedLines = purchaseOrderLines.map((value: Record<PurchaseOrderLineFormData>, key: any) => {
            return value.withMutations((pol: Record<PurchaseOrderLineFormData>) => {
                pol.set('toBeRegistered', true);
                if (pol.get('receiptMasterLines').size !== 0) {
                    pol.set('quantityDelivered', pol.get('receiptMasterLines').get(0).get('quantity'));
                } else {
                    pol.set('quantityDelivered', pol.get('quantity'));
                }

            })
        })
        // store the updated lines in the store
        yield put(storePurchaseOrderLines(updatedLines));    
    } catch (error) {
        console.error(error);
    }
}

/**
 * Fetches and stores the default currency of a company
 */
function *handleGetDefaultCompanyCurrency(action: GetDefaultCompanyCurrencyAction) {
    yield* lockActionButtons(function *() {
        const currency = yield select(getCompanyCurrency);
        if (!currency) {
            const [currency, error] = yield* callRequestHandler(RequestId.GET_DEFAULT_COMPANY_CURRENCY, { companyCode: action.companyCode });
            if (error) throw error;
            yield put(storeCompanyCurrency(currency));
        }
    });
}

/**
 * Fetches and stores the default supplier of a company
 */
function *handleGetDefaultCompanySupplier() {
    yield* lockActionButtons(function *() {
        const realmNo = yield select(getSelectedRealmNo);
        const [supplier, error] = yield* callRequestHandler(RequestId.GET_DEFAULT_COMPANY_SUPPLIER, { companyCode: realmNo });
        if (error) throw error;
        yield put(storeDefaultCompanySupplier(supplier));
    });
}

/**
 * Fetches and stores all the companies of the current realm
 * Actually we store it in the common store, and that's probably a little bit hacky...
 */
function *handleFindAllCompanies() {
    yield* lockActionButtons(function *() {
        const [result, error] = yield* callRequestHandler(RequestId.LIST_ALL_COMPANIES, {});
        if (error) throw error;
        yield put(receiveCachedData(RequestId.LIST_ALL_COMPANIES, result));
    });
}

/**
 * Selects the current form-state, merges it with the purchase-order, and saves it in the store
 */
function* handleCommitFormValueChangesToStore() {
    const formValues: PurchaseOrderFormData = yield select(getFormValues('new-purchase-order-form'));

    let order: Record<PurchaseOrderFormData> = yield select(getPurchaseOrder);

    if (!order) {
        order = createPurchaseOrderForm();
    }

    if (order && formValues) {
        order = order.merge(formValues)
            // supplier is stored in redux-form as a plain object, but the purchase-order "supplier" is an immutable map.
            .set('supplier', createSupplierIdentifier(formValues.supplier));

        yield put(storePurchaseOrder(order));
    }
}
