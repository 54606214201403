import React, { useState, useEffect } from "react";
import { Alert, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export type ConfirmableAlertOpts = {
    title: string;
    variant?: string;
    message?: string;
    confirmMessage?: string;
    onConfirm?: () => void;
    onDismiss?: () => void;
}

type Props = {
    alert?: ConfirmableAlertOpts;
}

/**
 * Confirm-able alert component. Displays a warning message inside a bootstrap Alert, with
 * a button below to confirm it anyway.
 */

function ConfirmAlert({ alert: alertOpts }: Props) {
    const [alert, setAlert] = useState(alertOpts);
    const { t } = useTranslation();

    // update the component-state when the alert prop changed
    useEffect(() => {
        setAlert(alertOpts);
    }, [alertOpts]);

    const handleDismiss = () => {
        if (alert && alert.onDismiss) {
            alert.onDismiss();
        }

        setAlert(undefined);
    };

    const handleConfirm = () => {
        if (alert && alert.onConfirm) {
            alert.onConfirm();
        }

        setAlert(undefined);
    };

    if (!alert) {
        // show nothing
        return null;

    } else {
        // show the alert
        return (
            <Alert variant={alert.variant as any} onClose={handleDismiss} dismissible>
                <Alert.Heading>{alert.title}</Alert.Heading>
                {alert.message}
                <hr />
                <div className="d-flex justify-content-end">
                    <Button onClick={handleConfirm} variant={`outline-${alert.variant}` as any}>
                        {alert.confirmMessage || t('retail.alerts.confirm')}
                    </Button>
                </div>
            </Alert>
        );
    }
}

export default React.memo(ConfirmAlert);
