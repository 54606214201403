import {
    PurchaseOrderLineFormData,
    PurchaseOrderFormData,
    SavePurchaseOrderAction, StorePurchaseOrderAction, SendPurchaseOrderAction, UpdatePurchaseOrderAction,
    StorePurchaseOrderLinesAction,
    CancelPurchaseOrderAction, CancelPurchaseOrderFormData, ViewPurchaseOrderAction, PurchaseOrderMode,
    ReceivePurchaseOrderAction,
    StoreReceivePurchaseOrderLineChangeAction,
    PurchaseOrderFilterFormData,
    NewPurchaseOrderAction,
    GetDefaultCompanyCurrencyAction,
    StoreCompanyCurrencyAction,
    ValueData,
    ValidationMessage,
    StoreValidationMessageAction,
    SavePurchaseOrderIntermediaryAction,
    FindAllCompaniesAction,
    ClearValidationMessagesAction,
    GetDefaultCompanySupplierAction,
    NotificationAction,
    StoreDefaultCompanySupplierAction,
    FindPurchaseOrdersAction,
    MarkAllPurchaseOrderLinesRegisteredAction, SetPurchaseOrdersAction, UpdatePurchaseOrderInGridAction
} from "../../types/procurementTypes";
import { Record, List } from "immutable";
import { ActionButtonEvent } from "../../types/gridTypes";
import { LocationItem } from "@pearlchain/component-lib-common";
import { WebSortFields } from "../remote/requestTypes";
import { Option } from "../../types/inventoryTypes";

export const INITIAL_STATUS = "PurchaseOrderStatus.Initial";

const ActionTypes = {
    FIND_PURCHASE_ORDERS: 'procurement.FIND_PURCHASE_ORDERS',
    SET_PURCHASE_ORDERS: 'procurement.SET_PURCHASE_ORDERS',
    NEW_PURCHASE_ORDER: 'procurement.NEW_PURCHASE_ORDER',
    CLEAR_DATA: 'procurement.CLEAR_DATA',
    SAVE_PURCHASE_ORDER: 'procurement.SAVE_PURCHASE_ORDER',
    SAVE_PURCHASE_ORDER_INTERMEDIARY: 'procurement.SAVE_PURCHASE_ORDER_INTERMEDIARY',
    UPDATE_PURCHASE_ORDER: 'procurement.UPDATE_PURCHASE_ORDER',
    UPDATE_PURCHASE_ORDER_IN_GRID: 'procurement.UPDATE_PURCHASE_ORDER_IN_GRID',
    RECEIVE_PURCHASE_ORDER: 'procurement.RECEIVE_PURCHASE_ORDER',
    VIEW_PURCHASE_ORDER: 'procurement.VIEW_PURCHASE_ORDER',
    STORE_PURCHASE_ORDER: 'procurement.STORE_PURCHASE_ORDER',
    STORE_DEFAULT_COMPANY_CURRENCY: 'procurement.STORE_DEFAULT_COMPANY_CURRENCY',
    STORE_DEFAULT_COMPANY_SUPPLIER: 'procurement.STORE_DEFAULT_COMPANY_SUPPLIER',
    STORE_PURCHASE_ORDER_LINES: 'procurement.STORE_PURCHASE_ORDER_LINES',
    SEND_PURCHASE_ORDER: 'procurement.SEND_PURCHASE_ORDER',
    CANCEL_PURCHASE_ORDER: 'procurement.CANCEL_PURCHASE_ORDER',
    CLEAR_PURCHASE_ORDER_LINE_DATA: 'procurement.CLEAR_PURCHASE_ORDER_LINE_DATA',
    STORE_RECEIVE_PURCHASE_ORDER_LINE_CHANGE: 'procurement.STORE_RECEIVE_PURCHASE_ORDER_LINE_CHANGE',
    MARK_ALL_PURCHASE_ORDER_LINES_REGISTERED: 'procurement.MARK_ALL_PURCHASE_ORDER_LINES_REGISTERED',
    REGISTER_PURCHASE_ORDER_LINES: 'procurement.REGISTER_PURCHASE_ORDER_LINE',
    REGISTER_ALL_PURCHASE_ORDER_LINES: 'procurement.REGISTER_ALL_PURCHASE_ORDER_LINES',
    GET_DEFAULT_COMPANY_CURRENCY: 'procurement.GET_DEFAULT_COMPANY_CURRENCY',
    GET_DEFAULT_COMPANY_SUPPLIER: 'procurement.GET_DEFAULT_COMPANY_SUPPLIER',
    STORE_VALIDATION_MESSAGE: 'procurement.STORE_VALIDATION_MESSAGE',
    CLEAR_VALIDATION_MESSAGES: 'procurement.CLEAR_VALIDATION_MESSAGES',
    FIND_ALL_COMPANIES: 'procurement.FIND_ALL_COMPANIES',
    SHOW_ERROR: 'procurement.SHOW_ERROR',
    COMMIT_FORM_VALUE_CHANGES_TO_STORE: 'procurement.COMMIT_FORM_VALUE_CHANGES_TO_STORE'
}

export function showError(message: string) : NotificationAction {
    return {
        type: ActionTypes.SHOW_ERROR,
        message
    }
}

export function findPurchaseOrders(data: PurchaseOrderFilterFormData, sortFields: WebSortFields): FindPurchaseOrdersAction {
    return { type: ActionTypes.FIND_PURCHASE_ORDERS, data, sortFields };
}

export function updatePurchaseOrderInGrid(order: Record<PurchaseOrderFormData>) : UpdatePurchaseOrderInGridAction {
    return { type: ActionTypes.UPDATE_PURCHASE_ORDER_IN_GRID, order };
}

export function setPurchaseOrders(orders: List<Record<PurchaseOrderFormData>>): SetPurchaseOrdersAction {
    return { type: ActionTypes.SET_PURCHASE_ORDERS, orders };
}

export function newPurchaseOrder(location: LocationItem) : NewPurchaseOrderAction {
    return { type: ActionTypes.NEW_PURCHASE_ORDER, location };
}

export function clearData() {
    return { type: ActionTypes.CLEAR_DATA };
}

export function clearPurchaseOrderLineData() {
    return { type: ActionTypes.CLEAR_PURCHASE_ORDER_LINE_DATA };
}

export function savePurchaseOrder(data: PurchaseOrderFormData, mode: PurchaseOrderMode, location: LocationItem) : SavePurchaseOrderAction {
    return { type: ActionTypes.SAVE_PURCHASE_ORDER, data, mode, location };
}

export function savePurchaseOrderIntermediary(data: Record<PurchaseOrderFormData>) : SavePurchaseOrderIntermediaryAction {
    return { type: ActionTypes.SAVE_PURCHASE_ORDER_INTERMEDIARY, data };
}

export function updatePurchaseOrder(event: ActionButtonEvent, location: LocationItem) : UpdatePurchaseOrderAction {
    const purchaseOrderId = event.row.id;
    return { type: ActionTypes.UPDATE_PURCHASE_ORDER, purchaseOrderId, location };
}

export function receivePurchaseOrder(event: ActionButtonEvent, location: LocationItem) : ReceivePurchaseOrderAction {
    const purchaseOrderUuid = event.row.id;
    return { type: ActionTypes.RECEIVE_PURCHASE_ORDER, purchaseOrderUuid, location };
}

export function viewPurchaseOrder(event: ActionButtonEvent, location: LocationItem) : ViewPurchaseOrderAction {
    const purchaseOrderUuid = event.row.id;
    return { type: ActionTypes.VIEW_PURCHASE_ORDER, purchaseOrderUuid, location };
}

export function sendPurchaseOrder(data: Record<PurchaseOrderFormData>) : SendPurchaseOrderAction {
    return { type: ActionTypes.SEND_PURCHASE_ORDER, data };
}

export function storePurchaseOrderLines(data: List<Record<PurchaseOrderLineFormData>>) : StorePurchaseOrderLinesAction {
    return { type: ActionTypes.STORE_PURCHASE_ORDER_LINES, data };
}

export function storePurchaseOrder(data: Record<PurchaseOrderFormData>) : StorePurchaseOrderAction {
    return { type: ActionTypes.STORE_PURCHASE_ORDER, data };
}

export function storeCompanyCurrency(currency: Record<ValueData>) : StoreCompanyCurrencyAction {
    return { type: ActionTypes.STORE_DEFAULT_COMPANY_CURRENCY, currency };
}

export function storeDefaultCompanySupplier(supplier: Record<Option>) : StoreDefaultCompanySupplierAction {
    return { type: ActionTypes.STORE_DEFAULT_COMPANY_SUPPLIER, supplier };
}

export function storeValidationMessage(message: Record<ValidationMessage>) : StoreValidationMessageAction {
    return { type: ActionTypes.STORE_VALIDATION_MESSAGE, message }
}

export function clearValidationMessages() : ClearValidationMessagesAction {
    return { type: ActionTypes.CLEAR_VALIDATION_MESSAGES }
}

export function cancelPurchaseOrder(data: Record<CancelPurchaseOrderFormData>, location: LocationItem) : CancelPurchaseOrderAction {
    return { type: ActionTypes.CANCEL_PURCHASE_ORDER, data, location };
}

export function storeReceivePurchaseOrderLineChange(purchaseOrderLineUuid: string, columnIndex: number, value: string) : StoreReceivePurchaseOrderLineChangeAction {
    return { type: ActionTypes.STORE_RECEIVE_PURCHASE_ORDER_LINE_CHANGE, purchaseOrderLineUuid, columnIndex, value }
}

export function markAllPurchaseOrderLinesRegistered(registered: boolean): MarkAllPurchaseOrderLinesRegisteredAction {
    return { type: ActionTypes.MARK_ALL_PURCHASE_ORDER_LINES_REGISTERED, registered };
}

export function registerPurchaseOrderLines() {
    return { type: ActionTypes.REGISTER_PURCHASE_ORDER_LINES };
}

export function registerAllPurchaseOrderLines() {
    return { type: ActionTypes.REGISTER_ALL_PURCHASE_ORDER_LINES };
}

export function getDefaultCompanyCurrency(companyCode: string) : GetDefaultCompanyCurrencyAction {
    return { type: ActionTypes.GET_DEFAULT_COMPANY_CURRENCY, companyCode }
}

export function getDefaultCompanySupplier() : GetDefaultCompanySupplierAction {
    return { type: ActionTypes.GET_DEFAULT_COMPANY_SUPPLIER }
}

export function findAllCompanies() : FindAllCompaniesAction {
    return { type: ActionTypes.FIND_ALL_COMPANIES }    
}

export function commitFormValueChangesToStore() {
    return { type: ActionTypes.COMMIT_FORM_VALUE_CHANGES_TO_STORE };
}

export default ActionTypes;
