import { Action } from "redux";
import { StoreState } from "../../types/storeTypes";
import { ActionType } from './actions';

export default function (state: StoreState | undefined, action: Action): StoreState | undefined {
    if (action.type === ActionType.LOGIN_CLEAR_STATE) {
        // resets the entire redux-state when the login page is shown.
        // TODO: move this to component-lib
        return undefined;
    } else {
        return state;
    }
}
