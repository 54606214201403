import React from 'react'
import { List, Record } from 'immutable'
import { useTranslation } from 'react-i18next'

import { GridReactive, Column, Row, ApplyCallbackDatastore } from '@pearlchain/component-lib-react-pg'

import { GridRowEvent, SalesOrderLine } from '../../../../types/inventoryTypes'
import { createExtensions } from "../../../../powergrid/extensions/contextmenu/powergridHelpers";
import { isAuthorizedByProps, mapAuthorizationProps } from "../../../auth/AuthUtils";
import { Auth } from "../../../auth/functionalityCodes";
import { AuthorizationProps } from "../../../auth/authTypes";
import { connect } from "react-redux";
import { StoreState } from "../../../../types/storeTypes";
import { popLocation, pushLocation, pushLocationItem } from "@pearlchain/component-lib-common";
import { LocationItem } from "@pearlchain/component-lib-common/src/components/location/types";
import { openOrderLineForEdit } from "../../actions";

export interface Props  extends AuthorizationProps {
    update: boolean,
    lines: List<Record<SalesOrderLine>>,
    openOrderLineForEdit: (line: SalesOrderLine) => void,
    updateOrderLineInOrder: (line: SalesOrderLine) => void,
    pushLocationItem: (item: LocationItem) => void,
    popLocation: (steps: number) => void,
}

function DetailedOutgoingOrderLinesGrid(props: Props) {

    const { t } = useTranslation();

    let rows: Row[] = [];

    const columns: Column[] = [
        { _key: 'uniqueIdentifier', width: 0, title: '', idField: true },
        { _key: 'actions',  width: 200, title: t('retail.inventory.orders.detailed.actions'), actions: [
                {cssClass: `btn ${props.update ? 'btn-secondary' : 'btn-default'} fa`, icon: 'fa-pencil-alt', eventName: 'update-outgoing-order-line', hidden: true}
            ]
        },
        { _key: 'productNo',  width: 200, title: t('retail.inventory.orders.detailed.productNo') },
        { _key: 'productDescription',  width: 200, title: t('retail.inventory.orders.detailed.productDescription') },
        { _key: 'quantityOrdered',  width: 200, title: t('retail.inventory.orders.detailed.quantityOrdered') },
        { _key: 'unitPrice',  width: 200, title: t('retail.inventory.orders.detailed.unitPrice') },
        { _key: 'price',  width: 200, title: t('retail.inventory.orders.detailed.price') },
        { _key: 'quantityShipped',  width: 200, title: t('retail.inventory.orders.detailed.quantityShipped') },

    ];

    if (props.lines) {
        props.lines.map((entry: Record<SalesOrderLine>, index: number) => {
            const row: any[] = [];

            (row as Row)[0] = entry.get('uniqueIdentifier');
            (row as Row)[2] = entry.get('productNo');
            (row as Row)[3] = entry.get('productDescription');
            (row as Row)[4] = entry.get('quantityOrdered') && `${entry.get('quantityOrdered')} ${t(entry.get('orderQuantityUom'))}`;
            (row as Row)[5] = entry.get('unitPrice') && `${entry.get('unitPrice')} ${entry.get('currency')} / ${t(entry.get('orderQuantityUom'))}`;
            (row as Row)[6] = entry.get('totalPrice') && `${entry.get('totalPrice')} ${entry.get('currency')}`;
            (row as Row)[7] = entry.get('quantityShipped') && entry.get('quantityShipped') + ' ' + t(entry.get('uom'));

            (row as Row).id = '' + index;

            rows.push(row as Row);
        });
    }

    const updateLine = { key: 'update-outgoing-order-line', action: (event: GridRowEvent) => {
            const row = event.row;
            if (row && props.update && props.lines) {
                let list = props.lines!.filter((line: Record<SalesOrderLine>) => line.get('uniqueIdentifier') === row.rowData[row.id][0]);
                props.openOrderLineForEdit(list.get(0));
            }
        }};


    function resolveGlobalAttribute(row: Row, property: string, attribute: string) {
        if (property === 'update-outgoing-order-line' && attribute === 'actionHidden') {
            if (isAuthorizedByProps(props, Auth.INVENTORY_UPDATE_ORDER_LINE)) {
                return false;
            }
        }
    }

    return(
        <GridReactive
            classNameOuter="wrap-grid"
            gridId="update-outgoing-order-lin-grid"
            data={rows}
            extensions={createExtensions({"formatting": true}, undefined, 'detailed-outgoing-order-lines-grid-id', {translate: t})}
            columns={columns}
            events={[updateLine]}
            createDatastore={(data) =>
                new ApplyCallbackDatastore(['update-outgoing-order-line'], ['actionHidden'], resolveGlobalAttribute, data)
            }
        />
    )
}

export default connect(
    (state: StoreState) => {
        const authorizationProps = mapAuthorizationProps(state);
        return {
            functionalityCodes: authorizationProps.functionalityCodes,
            isCorporateAdmin: authorizationProps.isCorporateAdmin,
        }
    },
    {pushLocation, popLocation, pushLocationItem, openOrderLineForEdit}
)(DetailedOutgoingOrderLinesGrid);


